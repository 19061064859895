import React, { Component } from "react";
import axios from 'axios';
import utils from '../utils.js';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import ServiceLoader from './service-loader.js';
let {enc_key,app_api,user_info} = require('../library.js');
export default class HoldBillPopup extends Component {
    constructor() {
        super();
        this.state = {
            hold_bills_list : [],
            temp_hold_bills_list : [],
            active_hold :0,
            show_loader : true,
            search_text : '',
        };
        
    }
    componentWillUnmount() {
      document.removeEventListener("keydown", this.handleHoldBillShortcut);
    }
    componentDidMount(){
        document.addEventListener("keydown", this.handleHoldBillShortcut);
    }
    handleHoldBillShortcut = (e)=>{
        let key_code = e.which;
     
        if(e.altKey && !e.ctrlKey && key_code===72){
          e.preventDefault();
          if(key_code===72){
            
            
                this.handleGetHoldBills();
            
          }
        }
        if(key_code===27){
          var element = document.getElementById('hold-bill-list');
          if(element.style.display==="block"){
            this.handleCloseHoldBillsPopup();
          }
        }
        
        if(key_code===40){
          var element = document.getElementById('hold-bill-list');
          var hold_list = document.querySelectorAll(".hold-list");
          if(element.style.display==="block"){
            let active_hold = this.state.active_hold;
            active_hold++;
            if(hold_list.length>=(active_hold+1)){
              this.setState({active_hold:active_hold});
            }
          }
        }
        
        if(key_code===38){
          var element = document.getElementById('hold-bill-list');
          var hold_list = document.querySelectorAll(".hold-list");
          if(element.style.display==="block"){
            let active_hold = this.state.active_hold;
            active_hold--;
            if(active_hold>=0){
              this.setState({active_hold:active_hold});
            }
          }
        }
        
        if(key_code===13){
          var element = document.getElementById('hold-bill-list');
          var hold_list = document.querySelectorAll(".hold-list");
          if(element.style.display==="block"){
            let active_hold = this.state.active_hold;
            hold_list[active_hold].click();
          }
        }
    }
    handleSelectInvoiceId = (e) => {
        let target = e.currentTarget.getAttribute("target");
        let id = e.currentTarget.id;
        if(this.props.data.screen==='order'){
          window.location.href = '/new-order/edit/'+target+'/'+id;
        }else{
          window.location.href = '/new-billing/edit/'+target+'/'+id;
        }
        this.handleCloseHoldBillsPopup();
      }
  
      handleCloseHoldBillsPopup = ()  =>{
        var element = document.getElementById('hold-bill-list');
        element.classList.add("fade");
        element.style.display="none"; 
      }
      handleGetHoldBills = () =>{
        var element = document.getElementById('hold-bill-list');
        element.classList.remove("fade");
        element.style.display="block"; 
        if(this.state.hold_bills_list.length===0){
            this.setState({show_loader:true});
            let body = {};
            body = {
                    store_name : user_info.store_name,
                    branch_id : user_info.login_id,
                    user_id : user_info.user_id,
                    active_tab : this.props.data.billing_type,
                    update_id : this.props.data.update_id,
                    uuid : utils.uuid(),
                    role_id : user_info.access_level,
                    token : user_info.token,
                    _schema : user_info._schema,
                    screen : this.props.data.screen
                }
            let api = app_api+'/get_hold_bills';
            axios.post(api, body)
            .then(function (response) {
                this.setState({ hold_bills_list: response.data.data });
                this.setState({ temp_hold_bills_list: response.data.data });
                this.setState({show_loader:false});
                document.getElementById("hold-bill-search").focus();
            }.bind(this)).catch(function(res){
                if(res.response){
                
                }
            })
        }
      }
      handleSearch = (e) =>{
          let userInput = e.currentTarget.value;
          this.setState({search_text:userInput});
          let hold_bills_list = this.state.temp_hold_bills_list;
          let hblist = [];
          hold_bills_list.map( (value, index) => { 
            let value1 = value.invoice_number;
            let value2 = value.contact_name;
            let value3 = value.grand_total;

            if((value2.toLowerCase().indexOf(userInput.toLowerCase()) > -1) || (value1.toLowerCase().indexOf(userInput.toLowerCase()) > -1) || (value3.toLowerCase().indexOf(userInput.toLowerCase()) > -1)){
                hblist.push(value);
            }
        });
        this.setState({hold_bills_list:hblist})
      }
      handleShowHoldBill = () =>{
        this.handleGetHoldBills();
      }
    render() {
        return (
            <div className="modal fade" id="hold-bill-list" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <span id="handleShowHoldBill" onClick={this.handleShowHoldBill}></span>
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title hbtitle">Hold {this.props.data.billing_type.replace('New','').replaceAll("_"," ")}</h5>
                  </div>
                  <div className="modal-body" style={{overflow: 'auto',maxHeight: '500px'}}>
                    <div className="row">
                      <div className="col-md-12 text-center">
                      {(this.state.show_loader==true) &&
                        <ServiceLoader show={this.state.show_loader}/>
                      } 
                      {(this.state.show_loader===false) &&
                        <>
                         <input type="text" className="form-control" placeholder="Type to search" id="hold-bill-search" onChange={this.handleSearch}/>
                         <table className="table">
                            <thead>
                              <tr>
                                <th className="text-left">Date</th>
                                <th className="text-left">Invoice #</th>
                                <th className="text-left">Contact</th>
                                <th className="text-left">Staff</th>
                                <th className="text-right">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                            {(this.state.hold_bills_list.length > 0) ?this.state.hold_bills_list.map( (value, index) => {
                               return (
                                  <tr key={index} className={(this.state.active_hold===index)?'active hold-list':'hold-list'} onClick={this.handleSelectInvoiceId} target={value.target} id={value.invoice_id}>
                                    <td className="text-left">{value.date}</td>
                                    <td className="text-left">{value.invoice_number}</td>
                                    <td className="text-left">{value.contact_name}</td>
                                    <td className="text-left">{value.staff_name}</td>
                                    <td className="text-right">₹{value.grand_total}</td>
                                  </tr>
                                )
                              }) :  <tr><td colSpan={5} style={{textTransform:'capitalize'}}>No hold {this.props.data.billing_type.replaceAll('_',' ')} found.</td></tr>
                            }
                            </tbody>
                         </table>
                         </>
                      }
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={this.handleCloseHoldBillsPopup}>Close</button>
                  </div>
                </div>
              </div>
            </div> 
        );
    }
}