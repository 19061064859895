import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import Breadcrumbs from "./breadcrumbs";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTimes,faSearch,faTrash,faCaretLeft,faCaretRight,faSpinner,faEye} from '@fortawesome/free-solid-svg-icons';
import check_access from './access-level.js';
import Autocomplete from "./autocomplete";
import ProductCategory from "./product-category";
import ProductUnits from "./product-units";
import DrawerLink from './open-drawer-link.js';
import ServiceLoader from './service-loader.js';
import ModalBox from "./modal-box";
import Link from './link.js';
import utils from '../utils.js';
import PrintBarcode from "./barcode-print";
let {enc_key,app_api,user_info,gst_options} = require('../library.js');
var CryptoJS = require("crypto-js");
let db;
let _this;

export default class ViewProduct extends Component {
    constructor() {
      super();
      this.state = {
          show_loader : true,
          loading_process :'Loading...',
          active_tab:'add',
         
          variantList : [],
          v_opt_counter : 1,
          is_variant_checked:false,
          update_id:0,
          inventory_item:[],
          margin : 0,
          profit : 0,
          selectedFile:'',
          variation_list : [],
          inventory_log : [],
          options_head:[],
          product_image : 'https://app.business-booster.net/app/uploads/no-image.png',
          inventory_status : 'Active',
          category_name : '',
          unit_name : '',
          total_quantity : 0,
          is_loading : false,
          page : 1,
          scrollPos:0,
          loaded_all:false,
          is_show_more:true,
          page_title : 'View Product',
          log_item_name : '',
          log_item_code : '',
          item_codes_arr : [],
          custom_fields_json : [],
          on_off_msp : false,
          barcode_data_info : [],
        
      };
      _this = this;
      
      this.scrollPos = 0;
      this.variantList = [];
      this.is_access = check_access.is_access.bind(this);
      this.currency_format = utils.currency_format.bind(this);
      this.isBottom = this.isBottom.bind(this);
      this.trackScrolling = this.trackScrolling.bind(this);
      this.load_more_inventory_log = this.load_more_inventory_log.bind(this);
    }
    handlePrintBarcode = () =>{
      var element = document.getElementById('barcode-print-quantity-pop');
        element.classList.remove("fade");
        element.style.display="block"; 
    }
   load_more_inventory_log(){
        let active_tab = this.state.active_tab;
        this.setState({is_loading:true});
        let update_id = this.state.update_id;
        let body = {};
        body = {
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, _schema : user_info._schema,
            inventory_id : update_id,
            page : this.state.page,
            target : active_tab,
         }
        let api = app_api+'/get_inventory_log';
        axios.post(api, body)
          .then(function (response) {
              let resp = response.data.data;
              let is_show_more = response.data.is_show_more;
              this.setState({is_show_more:is_show_more});
              if(resp.length>0){
                let inventory_log = this.state.inventory_log;
             
                for(let i=0;i<resp.length;i++){
                  inventory_log.push(resp[i]);
                }
                let total_quantity = 0;
                for(let i=0;i<inventory_log.length;i++){
                  total_quantity = total_quantity+parseInt(inventory_log[i].quantity);
                }

                this.setState({inventory_log:inventory_log,total_quantity:total_quantity});
                let page = this.state.page;
                page = page+1;
                this.setState({page,page,is_loading:false});
                //document.addEventListener('wheel', this.trackScrolling);
              }else{
                this.setState({is_loading:false});
                this.setState({loaded_all:true});
                //document.removeEventListener('wheel', this.trackScrolling);
              }
        }.bind(this)).catch(function(res){
            if(res.response){
              if(res.response.data){
                  
              }
            }
        })
    }

    isBottom(el) {
      if ((document.body.getBoundingClientRect()).top > this.scrollPos){
        return false;
      }else{
        this.scrollPos = (document.body.getBoundingClientRect()).top;
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;
        if (windowBottom >= docHeight) {
          return true;
        } else {
          return false;
        }
      }
    }

    componentWillUnmount() {
      document.removeEventListener('wheel', this.trackScrolling);
    }

    trackScrolling(event){
    if (event.deltaY > 0){
       var st = window.pageYOffset || document.documentElement.scrollTop; 
       if (st > this.state.scrollPos){
        const wrappedElement = document.getElementsByTagName('body')[0];
        if (this.isBottom(wrappedElement)) {
          if(this.state.active_branch!==""){
            document.removeEventListener('wheel', this.trackScrolling);
            this.load_more_inventory_log();
          }
        }
       } else {
          
       }
       let scrollPos = st <= 0 ? 0 : st;
       this.setState({scrollPos:scrollPos})
     }
    };

   
    componentDidMount(){
      
      let curl = window.location.href;
      let url = curl.split("/"); 
      let current_url = url[url.length - 1];
      let prev_url = url[url.length - 2];
      this.setState({current_url:current_url});
      this.setState({prev_url:prev_url});
      let cpage;
      let current_page;
      let body = {};
  
      const product_view = curl.match(/products\/view\/(\d+$)/i);
      
      if(product_view){
                let update_id = parseInt(product_view[1]);
        this.setState({active_tab:'edit',update_id:update_id});
        let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token, _schema : user_info._schema,
              id : update_id,

           }
        let api = app_api+'/get_product_on_id';
      
        axios.post(api, body)
          .then(function (response) {
              let resp = response.data.data;

              let varient = resp[0].varient;
              let inventory_status = resp[0].inventory_status;
              if(inventory_status==='Draft'){
                inventory_status = 'Inactive';
              }
              let variation_list = resp.inventory_meta;

              let additional_data = resp.additional_data;

              if(additional_data.custom_fields!==undefined){
                this.setState({custom_fields_json:additional_data.custom_fields});
              }
              
              let options_head = resp.option_head;
              let category_name = resp.category_name;
              let unit_name = resp.unit_name;
              this.setState({product_image:resp.inventory_image,options_head:options_head,inventory_status:inventory_status,category_name:category_name,unit_name:unit_name});
              if(variation_list.length>0){
                this.setState({multiple_variant:true});
              }else{
                this.setState({multiple_variant:false});
              }
              this.setState({show_loader:false})
              if(variation_list.length>0){
               
                for(let i=0;i<variation_list.length;i++){
                  let item_name = variation_list[i].item_name;
                  let item_data = variation_list[i];
                  item_data.update_id = this.state.update_id;

                  this.variantList.push(<VariationList item_data={item_data} label={item_name} index={i}/>);
                }
                this.setState({variantList:this.variantList});
              }

             if(resp[0]!==undefined){
              let selling_price = (resp[0].selling_price==='')?0:resp[0].selling_price;
              let purchase_price = (resp[0].purchase_price==='')?0:resp[0].purchase_price;

              purchase_price = parseFloat(purchase_price);
              selling_price = parseFloat(selling_price);

              let profit = selling_price-purchase_price;
              let margin = (profit/selling_price)*100;
              if(isNaN(margin)){
                margin = 0;
              }
              this.setState({profit:profit,margin:margin});

              if(variation_list.length>0){
                this.setState({is_variant_checked:true});
              }
              let item_sku = resp[0].sku;
              let item_name = resp[0].item_name;
              if(item_sku===null){
                let sku_auto = '';
                let iname = item_name.replace(" ",'');
                let n = iname.toUpperCase();
               
              }
              this.setState({ 'inventory_item':  resp[0]}); 
              let value = resp[0];
              
                let id = value.inventory_id;
                let status = value.inventory_status;
                let stat_txt = '';
                if(status==='Draft'){
                  stat_txt = ' (Inactive)';
                }
                item_name = value.item_name+" - Qty:"+value.availability+stat_txt;
                if(value.is_parent===true){
                  item_name = value.item_name+stat_txt;
                }
                let sku   = value.sku; 
                let category = resp.category_name;
              
                if(sku===null){
                  sku = "";
                }
                let barcode_name = value.barcode_name;
                if(barcode_name===null){
                    barcode_name = '';
                }
                let alias_barcode = value.alias_barcode;
                if(alias_barcode===null){
                    alias_barcode = '';
                }

                let temp_opt = {value1:sku,value2:item_name,value3:barcode_name,value4:alias_barcode,alias_barcode:alias_barcode,item_name:value.item_name,sku:sku,category:category,availability:value.availability,varient:value.varient,inventory_status:value.inventory_status,item_desc:value.item_desc,purchase_price:value.purchase_price,selling_price:value.selling_price,custom_text:value.custom_text,barcode_name:barcode_name,parent_id:value.parent_id,parent_name:value.parent_name,size:value.size,city:value.city,state:value.state,phone1:value.phone1,phone2:value.phone2,email:value.email,encrypted_purchase_price:value.enc_pp,encrypted_selling_price:value.enc_sp,inventory_id:id};

                if(additional_data.custom_fields){
                  let custom_field_json = additional_data.custom_fields;
                  for (let cfi = 0; cfi < custom_field_json.length; cfi++) {
                    const cf_element = custom_field_json[cfi];
                    if(cf_element.is_barcode_active!==undefined && cf_element.is_barcode_active===true){
                      let cfid = 'cf'+cf_element.id;
                      let cfvalue = cf_element.value;
                      temp_opt[cfid] = cfvalue;
                    }
                  }
                }
                this.setState({barcode_data_info:temp_opt});
              
            }
        }.bind(this)).catch(function(res){
            if(res.response){
              if(res.response.data){
                  
              }
            }
        })
      }
      const product_log = curl.match(/products\/log\/(\d+$)/i);
      if(product_log){
        this.setState({page_title:'Log'});
        //document.addEventListener('wheel', this.trackScrolling);
        let update_id = parseInt(product_log[1]);
        this.setState({active_tab:'log',update_id:update_id});
        let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token, _schema : user_info._schema,
              inventory_id : update_id,
              page : this.state.page,
              target : 'log',
           }
        let api = app_api+'/get_inventory_log';
      
        axios.post(api, body)
          .then(function (response) {
              let resp = response.data.data;
              let is_show_more = response.data.is_show_more;
              this.setState({item_codes_arr:response.data.item_codes_arr});
              this.setState({log_item_name:response.data.item_name});
              this.setState({log_item_code:response.data.item_code});
              this.setState({is_show_more:is_show_more});
              this.setState({inventory_log:resp,total_quantity:response.data.total_quantity});
              this.setState({page:2});
        }.bind(this)).catch(function(res){
            if(res.response){
              if(res.response.data){
                  
              }
            }
        })
      }
      const product_log_old = curl.match(/products\/log_old\/(\d+$)/i);
      if(product_log_old){
        this.setState({page_title:'Log'});
        //document.addEventListener('wheel', this.trackScrolling);
        let update_id = parseInt(product_log_old[1]);
        this.setState({active_tab:'log_old',update_id:update_id});
        let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token, _schema : user_info._schema,
              inventory_id : update_id,
              page : this.state.page,
              target : 'log_old',
           }
        let api = app_api+'/get_inventory_log';
      
        axios.post(api, body)
          .then(function (response) {
              let resp = response.data.data;
              let is_show_more = response.data.is_show_more;
              this.setState({is_show_more:is_show_more});
              this.setState({inventory_log:resp,total_quantity:response.data.total_quantity});
              if(resp.length===0 && this.state.page===1){
                this.setState({loading_process:'Oops ! No log(s) available'});
              }
              this.setState({page:2});
        }.bind(this)).catch(function(res){
            if(res.response){
              if(res.response.data){
                  
              }
            }
        })
      }

      let ssend_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          access_level : user_info.access_level,
          keys:"'on_off_msp'",
      }
      let sapi = app_api+'/get_settings';
      axios.post(sapi, ssend_data)
        .then(function (response) {
          let resp = response.data;
        
          if(resp.on_off_msp!==undefined){
            let on_off_msp = resp.on_off_msp;
            this.setState({on_off_msp:on_off_msp});
          }
          
      }.bind(this)).catch(function(res){
          
      })
    } 

    handleItemCodeFilter = (e) =>{
      let thisvalue = e.currentTarget.value;
      this.setState({update_id:thisvalue});
      window.location.href = '/products/log/'+thisvalue;
    }
  

    render() {
        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                  <nav className="navbar border-bottom">
                    <div className="row">
                      <div className="col-md-4">
                        <Breadcrumbs link="/products" title="Product" sub_title={this.state.page_title}/>
                      </div>
                      <div className="col-md-3" style={{marginLeft:(utils.isMobile())?'25px':''}}>
                        <div className="input-group mrtop-10">
                          <Autocomplete
                            options={[]}
                            title="Type to search product"
                            data_target="search_view_product"
                            id={"item-name-0"}
                            className="auto-comp-opt"
                            data_id={0}
                            active_tab="inventory"
                            input_id={"item-box-0"}
                            target="item-box"
                            clsName = ""
                          />
                        </div>
                      </div>
                      <div className="col-md-5"  style={{marginTop:(utils.isMobile())?'16px':''}}>
                        <button className="btn btn-success nav-btn" type="button" onClick={this.handlePrintBarcode}>Print Barcode</button>
                        <a href={"/products/edit/"+this.state.update_id}><button className="btn btn-success nav-btn" type="button">Edit Product</button></a>
                      </div>
                    </div>
                  </nav>

                <div className="row" style={{marginRight:'0px',marginTop:(utils.isMobile())?'33px':''}}>
                  <div className="col-md-12">
                
                        <div className="nav-container" style={{border:'none'}}>
                          <ul className="nav nav-pills" style={{width:'97%'}}>
                            <li className={this.state.active_tab==='edit'?'active':''}><a href={"/products/view/"+this.state.update_id}>Product Info</a></li>
                           
                            <li className={this.state.active_tab==='log'?'active':''}><a href={"/products/log/"+this.state.update_id}>Inventory Log</a></li>
                            {/* <li className={this.state.active_tab==='log_old'?'active':''}><a href={"/products/log_old/"+this.state.update_id}>Old Log</a></li> */}
                        
                          </ul>
                        </div>
                     
                  </div>
                </div>
                  
                  {(this.state.active_tab==='edit') &&
                  <div className="row view-product-block" style={{position:'relative'}}>
                    <ServiceLoader show={this.state.show_loader}/>
                    <input type="hidden" id="deleted_items" />
                    <div className="col-md-7">
                      <div className="container-fluid" style={{marginBottom:'10px'}}>
                        <div className="content-area">
                           <div className="main-content" style={{padding:'20px'}}>
                              <div className="row">
                                <div className="col-md-12">
                                  <h4 style={{fontSize:'20px'}}>Product Info</h4>
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label style={{fontSize:'14px',color:'gray'}}>Product Name</label>
                                </div>
                                <div className="col-md-5">
                                  <p>{(this.state.inventory_item.item_name)?this.state.inventory_item.item_name:'-'}</p>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label style={{fontSize:'14px',color:'gray'}}>Description</label>
                                </div>
                                <div className="col-md-5">
                                  <p>{(this.state.inventory_item.item_desc)?this.state.inventory_item.item_desc:'-'}</p>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label style={{fontSize:'14px',color:'gray'}}>Category</label>
                                </div>
                                <div className="col-md-5">
                                  <p dangerouslySetInnerHTML={{ __html:(this.state.category_name)?this.state.category_name:'-'}}></p>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                              <div className="row mrtop-10 hide">
                                <div className="col-md-3">
                                  <label style={{fontSize:'14px',color:'gray'}}>SKU</label>
                                </div>
                                <div className="col-md-5">
                                  <p>{(this.state.inventory_item.sku)?this.state.inventory_item.sku:'-'}</p>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label style={{fontSize:'14px',color:'gray'}}>Barcode</label>
                                </div>
                                <div className="col-md-5">
                                  
                                  <p>{(this.state.inventory_item.barcode_name)?this.state.inventory_item.barcode_name:'-'}</p>
                                </div> 
                                <div className="col-md-4">
                                 
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label style={{fontSize:'14px',color:'gray'}}>Alias Barcode</label>
                                </div>
                                <div className="col-md-5">
                                  <p>{(this.state.inventory_item.alias_barcode)?this.state.inventory_item.alias_barcode:'-'}</p>
                                </div> 
                                <div className="col-md-4">
                                 
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label style={{fontSize:'14px',color:'gray'}}>HSN Code</label>
                                </div>
                                <div className="col-md-5">
                                  <p>{(this.state.inventory_item.hsn)?this.state.inventory_item.hsn:'-'}</p>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-3">
                                  <label style={{fontSize:'14px',color:'gray'}}>Part Name</label>
                                </div>
                                <div className="col-md-5">
                                  <p>{(this.state.inventory_item.part_name)?this.state.inventory_item.part_name:'-'}</p>
                                </div> 
                                <div className="col-md-4">
                                  
                                </div>
                              </div>
                           </div>
                        </div>
                      </div>
                      {(!this.state.is_variant_checked) &&
                        <>
                        {(this.state.variantList.length<1) &&
                          <div className="container-fluid" style={{marginTop:'25px',marginBottom:'10px'}}>
                            <div className="content-area">
                              <div className="main-content" style={{padding:'20px'}}>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <h4 style={{fontSize:'20px'}}>Pricing</h4>
                                    </div>
                                  </div>
                                  <div className="row mrtop-10">
                                    <div className="col-md-3">
                                      <label style={{fontSize:'14px',color:'gray'}}>Purchase Price</label>
                                    </div>
                                    <div className="col-md-5">
                                      <p>{(this.state.inventory_item.purchase_price)?'₹'+this.currency_format(this.state.inventory_item.purchase_price):'-'}</p>
                                    </div> 
                                    <div className="col-md-4">
                                      
                                    </div>
                                  </div>
                                  <div className="row mrtop-10">
                                    <div className="col-md-3">
                                      <label style={{fontSize:'14px',color:'gray'}}>Quantity</label>
                                    </div>
                                    <div className="col-md-5">
                                      <DrawerLink target="different_barcode" url='' name={this.state.inventory_item.quantity} schema=''  info={this.state.inventory_item.child_barcode_info} page_title={this.state.inventory_item.item_name}/>
                                    </div> 
                                    <div className="col-md-4">
                                      
                                    </div>
                                  </div>
                                  <div className="row mrtop-10">
                                    <div className="col-md-3">
                                      <label style={{fontSize:'14px',color:'gray'}}>Unit</label>
                                    </div>
                                    <div className="col-md-5">
                                      <p>{(this.state.unit_name)?this.state.unit_name:'-'}</p>
                                    </div> 
                                    <div className="col-md-4">
                                      
                                    </div>
                                  </div>
                                  <div className="row mrtop-10">
                                    <div className="col-md-3">
                                      <label style={{fontSize:'14px',color:'gray'}}>Tax</label>
                                    </div>
                                    <div className="col-md-5">
                                      <p>{(this.state.inventory_item.tax)?this.state.inventory_item.tax:'-'}</p>
                                    
                                    </div> 
                                    <div className="col-md-4">
                                      
                                    </div>
                                  </div>
                                  <div className="row mrtop-10">
                                    <div className="col-md-3">
                                      <label style={{fontSize:'14px',color:'gray'}}>Tax Type</label>
                                    </div>
                                    <div className="col-md-5">
                                      <p style={{textTransform:'capitalize'}}>{(this.state.inventory_item.pp_tax_type)?this.state.inventory_item.pp_tax_type+ ' tax':'-'}</p>
                                    </div> 
                                    <div className="col-md-4">
                                      
                                    </div>
                                  </div>
                                  <div className="row mrtop-10">
                                    <div className="col-md-3">
                                      <label style={{fontSize:'14px',color:'gray'}}>Discount</label>
                                    </div>
                                    <div className="col-md-5">
                                      
                                        <p>{(this.state.inventory_item.discount)?this.state.inventory_item.discount+'%':'-'}</p>
                                      
                                    </div> 
                                    <div className="col-md-4">
                                      
                                    </div>
                                  </div>
                                  <div style={{marginTop:'10px',borderBottom:'2px dotted #d7d7d7'}}></div>
                                  <div className="row mrtop-10">
                                    <div className="col-md-3">
                                      <label style={{fontSize:'14px',color:'gray'}}>Selling Price</label>
                                    </div>
                                    <div className="col-md-5">
                                      <p>{(this.state.inventory_item.selling_price)?'₹'+this.currency_format(this.state.inventory_item.selling_price):'-'}</p>
                                    </div> 
                                    <div className="col-md-4">
                                      
                                    </div>
                                  </div>
                                  {(this.state.on_off_msp===true) &&
                                    <div className="row mrtop-10">
                                      <div className="col-md-3">
                                        <label style={{fontSize:'14px',color:'gray'}}>Min. Selling Price</label>
                                      </div>
                                      <div className="col-md-5">
                                        <p>{(this.state.inventory_item.lowest_selling_price)?'₹'+this.currency_format(this.state.inventory_item.lowest_selling_price):'-'}</p>
                                      </div> 
                                      <div className="col-md-4">
                                        
                                      </div>
                                    </div>
                                  }
                                  <div className="row mrtop-10">
                                    <div className="col-md-3">
                                      <label style={{fontSize:'14px',color:'gray'}}>Wholesale Price</label>
                                    </div>
                                    <div className="col-md-5">
                                      <p>{(this.state.inventory_item.wholesale_price)?'₹'+this.currency_format(this.state.inventory_item.wholesale_price):'-'}</p>
                                    </div> 
                                    <div className="col-md-4">
                                      
                                    </div>
                                  </div>
                                  <div className="row mrtop-10">
                                    <div className="col-md-3">
                                      <label style={{fontSize:'14px',color:'gray'}}>Tax</label>
                                    </div>
                                    <div className="col-md-5">
                                      <p>{(this.state.inventory_item.sp_tax)?this.state.inventory_item.sp_tax:'-'}</p>
                                      
                                    </div> 
                                    <div className="col-md-4">
                                      
                                    </div>
                                  </div>
                                  <div className="row mrtop-10">
                                    <div className="col-md-3">
                                      <label style={{fontSize:'14px',color:'gray'}}>Tax Type</label>
                                    </div>
                                    <div className="col-md-5">
                                      <p style={{textTransform:'capitalize'}}>{(this.state.inventory_item.sp_tax_type)?this.state.inventory_item.sp_tax_type+ " Tax":'-'}</p>
                                    </div> 
                                    <div className="col-md-4">
                                      
                                    </div>
                                  </div>
                                  <div className="row mrtop-10">
                                    <div className="col-md-3">
                                      <label style={{fontSize:'14px',color:'gray'}}>Discount</label>
                                    </div>
                                    <div className="col-md-5">
                                      <div className="input-group">
                                        <p>{(this.state.inventory_item.sp_discount)?this.state.inventory_item.sp_discount+'%':'-'}</p>
                                        
                                      </div>
                                    </div> 
                                    <div className="col-md-4">
                                      
                                    </div>
                                  </div>
                                  <div className="row" style={{marginTop:'20px'}}>
                                    <div className="col-md-6">
                                      <span>Margin : </span><span id="product-margin">{this.currency_format(this.state.margin)}%</span>
                                    </div> 
                                    <div className="col-md-6">
                                      <span>Profit : </span><span id="product-profit">₹{this.currency_format(this.state.profit)}</span>
                                    </div>
                                  </div>
                              </div>
                            </div>
                          </div>
                        }
                        </>
                      }
                      <div className="container-fluid" style={{marginTop:'25px',marginBottom:'10px'}}>
                        <div className="content-area">
                        
                        {(this.state.is_variant_checked) &&
                          <>
                         
                           {(this.state.variantList.length>0) &&
                           <div className="main-content" style={{borderTop:'1px solid #d7d7d7',padding:'20px'}}>
                              <div className="row">
                                <div className="col-md-12">
                                    <label style={{fontWeight:'bold'}}>Variants</label>
                                   
                                </div>
                              </div>
                              
                              <div className="row variation-options" style={{paddingTop:'5px',paddingBottom: '15px'}}>
                                <div className="col-md-12">
                                  <table className="table" style={{display: 'block',overflowX: 'auto',whiteSpace:'nowrap'}}>
                                    <thead>
                                      <tr>  
                                        <th></th>
                                        {(this.state.options_head && this.state.options_head.length > 0) ?this.state.options_head.map( (value, index) => {
                                           return (
                                            <>
                                            {(value!=="") &&
                                              <th>{value}</th>
                                            }
                                            </>
                                          )
                                         }) : '' }
                                      
                                        <th>Purchase Price</th>
                                        <th>Selling Price</th>
                                        <th>Available</th>
                                        <th>SKU</th>
                                        <th>Barcode</th>
                                        <th style={{width:'100%'}}></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.variantList}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                           </div>
                         }
                          </>
                         }
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <div className="container-fluid">
                        <div className="content-area">
                           <div className="main-content" style={{padding:'20px'}}>
                              <div className="row">
                                <div className="col-md-4">
                                  <label style={{fontSize:'14px',color:'gray'}}>Status</label>
                                </div>
                                <div className="col-md-8">
                                  <p>{(this.state.inventory_status)?this.state.inventory_status:'-'}</p>
                                </div> 
                              </div>
                           </div>
                        </div>
                      </div>
                      <div className="container-fluid">
                        <div className="content-area">
                           <div className="main-content" style={{padding:'20px'}}>
                              <div className="row">
                                <div className="col-md-12 text-center">
                                    <div style={{width: '150px',height: '150px',border: '1px solid #d7d7d7',margin: '0 auto'}}>
                                      <div id="uploaded_photo" style={{backgroundImage:'url('+this.state.product_image+')',backgroundRepeat:'no-repeat',backgroundSize:'100% 100%',height:'100%'}}></div>
                                    </div>
                                </div>
                              
                              </div>
                           </div>
                        </div>
                      </div>
                      <div className="container-fluid">
                        <div className="content-area">
                           <div className="main-content" style={{padding:'20px'}}>
                              <div className="row">
                                <div className="col-md-12">
                                  <h4 style={{fontSize:'20px'}}>Additional Info</h4>
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-4">Bundle Quantity</div>
                                <div className="col-md-8">
                                  <p>{(this.state.inventory_item.bundle_quantity)?this.state.inventory_item.bundle_quantity:'-'}</p>
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-4">Custom Text</div>
                                <div className="col-md-8">
                                  <p>{(this.state.inventory_item.custom_text)?this.state.inventory_item.custom_text:'-'}</p>
                                </div>
                              </div>
                           </div>
                        </div>
                      </div>
                      <div className="container-fluid">
                        <div className="content-area">
                           <div className="main-content" style={{padding:'20px'}}>
                              <div className="row">
                                <div className="col-md-12">
                                  <h4 style={{fontSize:'20px'}}>Other</h4>
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-4">Reorder Quantity</div>
                                <div className="col-md-8">
                                  <p>{(this.state.inventory_item.reorder_quantity)?this.state.inventory_item.reorder_quantity:'-'}</p>
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-4">Opening Stock</div>
                                <div className="col-md-8">
                                  <p>{(this.state.inventory_item.opening_stock)?this.state.inventory_item.opening_stock:'-'}</p>
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <div className="col-md-4">Comission</div>
                                <div className="col-md-8">
                                  <p>
                                    {(this.state.inventory_item.comission_type==='rs') &&
                                      <>₹</>
                                    }
                                    {(this.state.inventory_item.comission)?this.state.inventory_item.comission:'-'}
                                    {(this.state.inventory_item.comission_type==='per') &&
                                      <>%</>
                                    }
                                  </p>
                                </div>
                              </div>
                           </div>
                        </div>
                      </div>
                      <div className="container-fluid">
                        <div className="content-area">
                           <div className="main-content" style={{padding:'20px'}}>
                              <div className="row">
                                <div className="col-md-12">
                                  <h4 style={{fontSize:'20px'}}>Custom Fields</h4>
                                </div>
                              </div>
                              <div className="row mrtop-10">
                                <table className="table cftbl" style={{width:'100%'}}>
                                  {(this.state.custom_fields_json.length>0) &&
                                      <thead>
                                        <tr>  
                                          <th>Name</th>
                                          <th>Value</th>
                                        </tr>
                                      </thead>
                                  }
                                  <tbody>
                                  {(this.state.custom_fields_json.length>0) &&
                                    <>
                                    {this.state.custom_fields_json.map((value,index) => {
                                      return (
                                        <tr key={'cfp-'+index}>
                                          <td>{value.name}</td>
                                          <td><p>{value.value}</p></td>
                                        </tr>
                                      )
                                    })}
                                    </>
                                  }
                                 
                                  </tbody>
                                </table>
                              </div>
                           </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  }
                  {this.state.active_tab==='log' &&
                    <div className="row view-product-block" style={{marginRight:'0px'}}>
                    <input type="hidden" id="deleted_items" />
                    <div className="col-md-12">
                      <div className="container-fluid" style={{marginBottom:'10px'}}>
                        <div className="content-area">
                           <div className="main-content" style={{padding:'20px'}}>
                              <div className="row">
                                <div className="col-md-8">
                                  <h4 style={{fontSize:'20px'}}>
                                    Log for {this.state.log_item_name} 
                                  </h4>
                                </div>
                                <div className="col-md-4" style={{marginBottom:'15px'}}>
                                  <div className="row">
                                    <div className="col-md-4 text-right">
                                      <label>Item Code :</label>
                                    </div>
                                    <div className="col-md-8">
                                      <select className="form-control" value={this.state.update_id} onChange={this.handleItemCodeFilter}>

                                      {this.state.item_codes_arr.map((value,index) =>
                                        <option value={value.id}>{value.code}</option>
                                      )}
                                      
                                      </select>
                                    </div>
                                  </div>
                              
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="table-responsive">
                                  <table className="table">
                                    <thead>
                                      <tr> 
                                        <th>Date</th>
                                        
                                        <th>Invoice</th>
                                        <th>Action</th>
                                        <th className="text-right">Last Available</th>
                                        <th className="text-right">Log Qty.</th>
                                        <th className="text-right">Available</th>
                                     
                                      </tr>
                                    </thead>
                                       <tbody>
                                         {(this.state.inventory_log.length > 0) ?this.state.inventory_log.map( (value, index) => {
                                        
                                         return (
                                          <tr key={ index } id={index} className="contact-row">
                                            
                                            <td>{value.created_on}</td>
                                            
                                            <td>
                                              {(value.invoice_url!=='' && value.type!=='Quotation') &&
                                                <DrawerLink target="billing" url={value.invoice_url} name={value.invoice} schema={value.schema}/>
                                              }
                                              {(value.invoice_url!=='' && value.type=='Quotation') &&
                                                <a href={value.invoice_url} target="_blank" className="drawer-link">{value.invoice}</a>
                                              }
                                              {(value.invoice_url==='') &&
                                              <>
                                                {value.invoice}
                                                </>
                                              }
                                            </td>
                                            <td>{value.operation}</td>
                                            <td className="text-right">{value.last_quantity}</td>
                                            <td className="text-right">{value.quantity}</td>
                                            <td className="text-right">{utils.floating_point(value.availability)}</td>
                                      
                                          </tr>
                                        )
                                       }) : <tr><td colSpan="6" className="text-center">{this.state.loading_process}</td></tr> }
                                        
                                      {(this.state.is_loading) &&
                                        <tr><td colSpan={6} className="text-center">{this.state.loading_process}</td></tr>
                                      }

                                      </tbody>
                                      <tfoot>
                                      
                                        {(this.state.is_loading===false && this.state.inventory_log.length > 0 && this.state.loaded_all===false && this.state.is_show_more===true) &&
                                          <tr>
                                              <th colSpan="7" className="text-center">
                                                <button type="button" className="btn btn-outline-info" onClick={this.load_more_inventory_log}>Load More</button>
                                              </th>
                                          </tr>
                                        }
                                      </tfoot>
                                  </table>
                                </div>
                                </div>
                              </div>
                           </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  }
                  {this.state.active_tab==='log_old' &&
                    <div className="row view-product-block" style={{marginRight:'0px'}}>
                    <input type="hidden" id="deleted_items" />
                    <div className="col-md-12">
                      <div className="container-fluid" style={{marginBottom:'10px'}}>
                        <div className="content-area">
                           <div className="main-content" style={{padding:'20px'}}>
                              <div className="row">
                                <div className="col-md-3">
                                  <h4 style={{fontSize:'20px'}}>Inventory Logs</h4>
                                </div>
                                <div className="col-md-9">
                                  <p style={{fontSize:'16px',color:'red'}}>This log is available till <b>14th Sep 2021</b>. If you want to check logs after <b>14th Sep 2021</b>, <a href={"/products/log/"+this.state.update_id}>please click here.</a></p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="table-responsive">
                                  <table className="table">
                                    <thead>
                                      <tr> 
                                        <th>Date</th>
                                        <th>Item Name</th>
                                        <th>Invoice</th>
                                        <th>Action</th>
                                        <th className="text-right">Quantity</th>
                                      </tr>
                                    </thead>
                                       <tbody>
                                         {(this.state.inventory_log.length > 0) ?this.state.inventory_log.map( (value, index) => {
                                        
                                         return (
                                          <tr key={ index } id={index} className="contact-row">
                                            
                                            <td>{value.created_on}</td>
                                            <td>
                                              <Link link={"/products/view/"+value.inventory_id} name={value.item_name} />
                                            
                                            </td> 
                                            <td>
                                              {(value.invoice_url!=='') &&
                                                <Link link={value.invoice_url} name={value.invoice} />
                                              }
                                              {(value.invoice_url==='') &&
                                              <>
                                                {value.invoice}
                                                </>
                                              }
                                            </td>
                                            <td>{value.operation}</td>
                                          
                                            <td className="text-right">{value.quantity}</td>
                                         
                                      
                                          </tr>
                                        )
                                       }) : <tr><td colSpan="7" className="text-center">{this.state.loading_process}</td></tr> }
                                        
                                      {(this.state.is_loading) &&
                                        <tr><td colSpan={7} className="text-center">{this.state.loading_process}</td></tr>
                                      }

                                      </tbody>
                                      <tfoot>
                                        <tr><th></th><th></th><th></th><th></th><th className="text-right">{this.state.total_quantity}</th></tr>
                                        {(this.state.is_loading===false && this.state.inventory_log.length > 0 && this.state.loaded_all===false && this.state.is_show_more===true) &&
                                          <tr>
                                              <th colSpan="7" className="text-center">
                                                <button type="button" className="btn btn-outline-info" onClick={this.load_more_inventory_log}>Load More</button>
                                              </th>
                                          </tr>
                                        }
                                      </tfoot>
                                  </table>
                                </div>
                                </div>
                              </div>
                           </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  }
                </div>
                <ModalBox id="delete_confirm" title="Delete" data_id="Delete"/>
                {(this.state.barcode_data_info.barcode_name) &&
                  <PrintBarcode barcode_data_info={this.state.barcode_data_info}/>
                }
            </div>
        );
    }
}
class VariationList extends Component {
  constructor(props) {
      super(props)
      this.state = {
          
      }
      this.currency_format = utils.currency_format.bind(this);
  }

  render() {
  return  <tr className="variation-item" id={"variation-item-"+this.props.index} data-id={this.props.index} style={{position:'relative'}}>
            <td>
              <div style={{backgroundImage:'url('+this.props.item_data.image+')',backgroundRepeat:'no-repeat',backgroundSize:'100% 100%',height: '40px',width:'40px',border: '1px solid #d7d7d7'}}></div>
            </td>
           
            <td id={"item-lbl-"+this.props.index} className={(this.props.item_data.inventory_id>0)?'hide':'-'}>
                {this.props.label}
            </td>
            
            {(this.props.item_data.inventory_id>0) &&
              <>
                {(this.props.item_data.option1!=="" && this.props.item_data.option1!==null) &&
                  <td>
                    <p>{(this.props.item_data.value1)?this.props.item_data.value1:'-'}</p>
                  </td>
                }
                {(this.props.item_data.option2!=="" && this.props.item_data.option2!==null) &&
                  <td>
                    <p>{(this.props.item_data.value2)?this.props.item_data.value2:'-'}</p>
                  </td>
                }
                {(this.props.item_data.option3!=="" && this.props.item_data.option3!==null) &&
                  <td>
                    <p>{(this.props.item_data.value3)?this.props.item_data.value3:'-'} </p>
                  </td>
                }
              </>
            }
            <td style={{minWidth:'110px'}}>
              <p>₹{this.currency_format(this.props.item_data.purchase_price)}</p>
            </td>
            <td style={{minWidth:'110px'}}>
              <p>₹{this.currency_format(this.props.item_data.selling_price)}</p>
            </td>
            <td style={{minWidth:'110px'}}><p>{this.props.item_data.availability}</p></td>
            <td style={{minWidth:'110px'}}><p>{this.props.item_data.sku}</p></td>
            <td style={{width:'100%'}}><p>{this.props.item_data.barcode_name}</p></td>
            
            <td><a href={"/variant/view/"+this.props.item_data.update_id+"/"+this.props.item_data.inventory_id}><FontAwesomeIcon icon={faEye} id={this.props.index} data-id={this.props.index}/></a></td>
            
          </tr>
  }
}