let utils = require('./utils.js');
let {enc_key,app_api,user_info} = require('./library.js');

var CryptoJS = require("crypto-js");
var axios = require("axios");

let db;
let dbReq ;
let TypeArr = [];

if(user_info){
	//console.log(utils.uuid());
	
	let version = Math.floor((Math.random() * 100) + 1);
	if(localStorage.getItem('IdbVersion')){
		version = localStorage.getItem('IdbVersion');
		version = parseInt(version);
	}else{
		localStorage.setItem('IdbVersion',version);
	}
	const request = window.indexedDB.open('BaazarERP', version);
	request.onupgradeneeded = (event) => {
		let db = event.target.result;
		let store = db.createObjectStore('contacts', {keyPath:'id'});
		let index = store.createIndex('idIndex', 'id');
	};
	request.onerror = (event) => {
		console.error(`Database error: ${event.target.errorCode}`);
	};
	request.onsuccess = (event) => {
		const db = event.target.result;
		if(user_info){
			const txn = db.transaction('contacts', 'readwrite');
			const store = txn.objectStore('contacts');
			var req = store.count();
			req.onsuccess = function() {
				if (req.result === 0) {
					let body = {
						store_name : user_info.store_name,
						branch_id : user_info.login_id,
						user_id : user_info.user_id,
						uuid : utils.default.uuid(),
						role_id : user_info.access_level,
						token : user_info.token,
						_schema : user_info._schema,
					}
					let api = app_api+'/get_contact';
					axios.post(api, body)
						.then(function (response) {
							let resp_data = response.data;
							for(let i=0;i<resp_data.length;i++){
								let key  = resp_data[i].contact_id;
								let type = resp_data[i].type;
								let contact_type = resp_data[i].contact_type;
								let value= resp_data[i];		            
								value = CryptoJS.AES.encrypt(JSON.stringify(value), enc_key).toString();
								insertContact(db, {
									id: key,
									type: type,
									contact_type: contact_type,
									data: value
								});
							}
					}.bind(this)).catch(function(res){
						if(res.response){
							//this.setState({loading_process:'Record(s) not found'});
						}
					})
				}
			}	
		}
	};
}
function insertContact(db, contact) {
    const txn = db.transaction('contacts', 'readwrite');
    const store = txn.objectStore('contacts');
    let query = store.put(contact);
    query.onsuccess = function (event) {
        console.log(event);
    };
    query.onerror = function (event) {
        console.log(event.target.errorCode);
    }
    txn.oncomplete = function () {
        db.close();
    };
}
module.exports = dbReq;