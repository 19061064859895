import React, { Component } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../admin.css';
import { EditorState,ContentState, convertToRaw,convertFromRaw  } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Leftnav from "../leftnav";
import axios from 'axios';
import utils from '../../utils.js';
import ModalBox from "../modal-box";
import TermsAndConditions from "../terms-and-conditions";
import PaymentModes from "../payment-modes";
import AddOldBills from "../add-old-bills";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ProductUnits from "../product-units";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusSquare,faSpinner,faCalendarAlt, faTimes  } from '@fortawesome/free-solid-svg-icons';
import Notification from "../notification.js";
import check_access from '../access-level.js';
import PrintTemplateDesing from "./print-template-design.js";
let {enc_key,app_api,user_info,states} = require('../../library.js');

/* this.state.editorState = EditorState.createWithContent(
  convertFromRaw(JSON.parse(this.props.rawState))
); */

export default class SettingsOther extends Component {
     constructor() {
      super();
      this.state = {
        is_open_billing_counter : false,
        selected_staff_while_billing : false,
        is_show_custom_desing_print : false,
        bill_sequence : {},
        more_rows : '',
        custom_print_page : '',
        print_settings:'',
        sale_page_size : 'A4',
        purchase_page_size : 'A4',
        no_of_page_to_print : 1,
        invoice_title : '',
        credit_bill_general_contact : false,
        default_payment_mode_for_parchase : '',
        default_payment_mode_for_sale : '',
        buffer_amount_for_credit_limit : '',
        advance_sale_data : false,
        encrypted_purchase_price : {},
        encrypted_selling_price : {},
        generate_new_sequence_on_year_change : true,
        deduct_inventory_on_purchase_quotation_created : false,
        is_create_quotation : false,
        is_financial_created : true,
        is_financial_year_active : false,
        financialFromDate: new Date("April 01 "+new Date().getFullYear()),
        financialToDate:  new Date("March 31 "+(new Date().getFullYear() + 1)),
        comission_type : 'rs',
        is_global_commission : false,
        global_commission : 0,
        is_item_commission : false,
        item_commission : 0,
        is_add_old_bills : false,
        delete_cancelled_bill : false,
        send_e_invoice : false,
        enable_orders : false,
        enable_audit_trial : false,
        enable_tcs : false,
        is_financial_year_closed : false,
        tcs_percent : '',
        commission_list : [{id:0,range1:'',range2:'',value:'',sym:'per'}],
        editorStateHeader : EditorState.createEmpty(),
        editorStateFooter : EditorState.createEmpty(),
        sales_sp_disc_type : 'per',
        purchase_sp_disc_type : 'per',
        purchase_pp_disc_type : 'per',
        global_discount_type : 'per',
      }
      this.more_rows = [];
      this.terms = [];
      this.pp_enc_num = [1,2,3,4,5,6,7,8,9,0];
      this.sp_enc_num = [1,2,3,4,5,6,7,8,9,0];
      this.openModal = this.openModal.bind(this);
      this.handleAddMoreTC = this.handleAddMoreTC.bind(this);
      this.handleSaveTC = this.handleSaveTC.bind(this);
      this.handlePageChange = this.handlePageChange.bind(this);
      this.handleSavePrintSetting = this.handleSavePrintSetting.bind(this);
      this.handleAllowGeneralContact = this.handleAllowGeneralContact.bind(this);
      this.handleAllowSPLowerThanPP = this.handleAllowSPLowerThanPP.bind(this);
      this.handleBarcodeBillView = this.handleBarcodeBillView.bind(this);
      this.handleItemBillView = this.handleItemBillView.bind(this);
      this.handleBufferAmountForCreditLimit = this.handleBufferAmountForCreditLimit.bind(this);
      this.handleOptChange = this.handleOptChange.bind(this);
      this.handleSavePPEncrypt = this.handleSavePPEncrypt.bind(this);
      this.handleDispatchedDetails = this.handleDispatchedDetails.bind(this);
    
      this.is_access = check_access.is_access.bind(this);
    }
  
    handleChangeOpenCloseCounter = (e) =>{
      let is_checked = e.currentTarget.checked;
      this.setState({is_open_billing_counter:is_checked})
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'is_open_billing_counter',
          value : is_checked
      }
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
      }.bind(this)).catch(function(res){
          
      })
    }

    handleSendEInvoice = (e) =>{
      let is_checked = e.currentTarget.checked;
      this.setState({send_e_invoice:is_checked})
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'send_e_invoice',
          value : is_checked
      }
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
      }.bind(this)).catch(function(res){
          
      })
    }
    handleEnableTrialBalance = (e) =>{
      let is_checked = e.currentTarget.checked;
      this.setState({enable_audit_trial:is_checked})
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'enable_audit_trial',
          value : is_checked
      }
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
      }.bind(this)).catch(function(res){
          
      })
    }
    handleEnableOrders = (e) =>{
      let is_checked = e.currentTarget.checked;
      this.setState({enable_orders:is_checked})
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'enable_orders',
          value : is_checked
      }
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
      }.bind(this)).catch(function(res){
          
      })
    }
    handleEnableTCS = (e) =>{
      let is_checked = e.currentTarget.checked;
      this.setState({enable_tcs:is_checked})
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'enable_tcs',
          value : is_checked
      }
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
      }.bind(this)).catch(function(res){
          
      })
    }
    handleSelectStaffWhileBilling = (e) =>{
      let is_checked = e.currentTarget.checked;
      this.setState({selected_staff_while_billing:is_checked})
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'selected_staff_while_billing',
          value : is_checked
      }
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
      }.bind(this)).catch(function(res){
          
      })
    }
    handleSaveTCSPercent = (e) =>{
      let thisid = e.currentTarget.id;
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
      let tcs_percent = document.getElementById("tcs_percent").value;
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'tcs_percent',
          value : tcs_percent
      }
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
          document.getElementById(thisid).classList.remove("hide");
          document.getElementById(thisid+'-btn-spinner').classList.add("hide");
      }.bind(this)).catch(function(res){
          
      })
    }

    handleChangePaymentModeForPurchase = (e) =>{
      let bpm = localStorage.getItem("p_mode");
      this.setState({default_payment_mode_for_parchase:bpm});
      localStorage.removeItem("p_mode");
    }

    handleChangePaymentModeForSale = (e) =>{
      let bpm = localStorage.getItem("p_mode");
      this.setState({default_payment_mode_for_sale:bpm});
      localStorage.removeItem("p_mode");
    }

    handleIsGobalCommissionCheck =(e)=>{
        let thisvalue = e.currentTarget.checked;
        this.setState({is_global_commission:thisvalue});
        let send_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, _schema : user_info._schema,
            name : 'is_global_commission',
            value : thisvalue
        }
        let api = app_api+'/save_option';
        axios.post(api, send_data)
          .then(function (response) {
        }.bind(this)).catch(function(res){
            
        })
    }

    handleGlobalComission =(e)=>{
        let thisvalue = e.currentTarget.value;
        this.setState({global_commission:thisvalue});
        let send_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, _schema : user_info._schema,
            name : 'global_commission',
            value : thisvalue
        }
        let api = app_api+'/save_option';
        axios.post(api, send_data)
          .then(function (response) {
        }.bind(this)).catch(function(res){
            
        })
    }
    handleIsItemCommissionCheck =(e)=>{
      let thisvalue = e.currentTarget.checked;
      this.setState({is_item_commission:thisvalue});
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'is_item_commission',
          value : thisvalue
      }
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
      }.bind(this)).catch(function(res){
          
      })
  }

  handleItemComission =(e)=>{
      let thisvalue = e.currentTarget.value;
      this.setState({item_commission:thisvalue});
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'item_commission',
          value : thisvalue
      }
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
      }.bind(this)).catch(function(res){
          
      })
  }
    handleGenerateNewSequenceOnYearChange = e =>{
      let is_checked = e.currentTarget.checked;
      this.setState({generate_new_sequence_on_year_change:is_checked});

      if(this.state.is_financial_created===true){
        let send_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, _schema : user_info._schema,
            value : is_checked,
        }
        
        let api = app_api+'/change_financial_year_sequence';
        axios.post(api, send_data)
          .then(function (response) {
        }.bind(this)).catch(function(res){
            
        })
      }
    }

    handleSaveFinancialYearSettings = e =>{
      let thisid = e.currentTarget.id;
      //document.getElementById(thisid).classList.add("hide");
     // document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
      
      let financial_data = {
          start_from : this.state.financialFromDate,
          end_to : this.state.financialToDate,
          generate_new_sequence_on_year_change : this.state.generate_new_sequence_on_year_change,
      }

      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'financial_year',
          value : financial_data,
      }
      
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
          //document.getElementById(thisid).classList.remove("hide");
          //document.getElementById(thisid+'-btn-spinner').classList.add("hide");
          window.location.reload();
      }.bind(this)).catch(function(res){
          
      })
    }

    financialFromDate = date => {
      this.setState({
        financialFromDate: date
      });
    }; 

    financialToDate = date => {
      this.setState({
        financialToDate: date
      });
    }; 

    handleDispatchedDetails(e){

      let thisvalue = e.currentTarget.checked;
      
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'advance_sale_data',
          value : thisvalue
      }
      
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
          
      }.bind(this)).catch(function(res){
          
      })
    }
    handleSavePPEncrypt(e){
      let thisid = e.currentTarget.id;
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
      let enc_data = {};
      let qs = document.querySelectorAll(".enc-data");
      for(let i=0;i<qs.length;i++){
        let k = qs[i].getAttribute('data-id');
        let v = qs[i].value;
        enc_data[k] = v;
      }
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'encrypted_purchase_price',
          value : JSON.stringify(enc_data)
      }
      
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
          document.getElementById(thisid).classList.remove("hide");
          document.getElementById(thisid+'-btn-spinner').classList.add("hide");
      }.bind(this)).catch(function(res){
          
      })
    }
    handleSaveSPEncrypt = (e)=>{
      let thisid = e.currentTarget.id;
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
      let enc_data = {};
      let qs = document.querySelectorAll(".enc-sp-data");
      for(let i=0;i<qs.length;i++){
        let k = qs[i].getAttribute('data-id');
        let v = qs[i].value;
        enc_data[k] = v;
      }
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'encrypted_selling_price',
          value : JSON.stringify(enc_data)
      }
      
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
          document.getElementById(thisid).classList.remove("hide");
          document.getElementById(thisid+'-btn-spinner').classList.add("hide");
      }.bind(this)).catch(function(res){
          
      })
    }
    handleOptChange(e){
        let is_checked = e.currentTarget.checked;
        let id = e.currentTarget.id;
        if(is_checked){
          document.getElementById('lbl-'+id).classList.add('disabled');
        }else{
          document.getElementById('lbl-'+id).classList.remove('disabled');
        }
    }
    handlePurOptChange(e){
        let is_checked = e.currentTarget.checked;
        let id = e.currentTarget.id;
        if(is_checked){
          document.getElementById('plbl-'+id).classList.add('disabled');
        }else{
          document.getElementById('plbl-'+id).classList.remove('disabled');
        }
    }
    handlePageChange(e){
      let id=e.currentTarget.id;
      if(id==='purchase-page-size'){
        this.setState({purchase_page_size:e.currentTarget.value});
      }else if(id==='sale-page-size'){
        this.setState({sale_page_size:e.currentTarget.value});
      }
    }

    handleSavePrintSetting(e){
      let thisid = e.currentTarget.id;
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
      let print_hide = document.querySelectorAll(".print-check:checked");
      //let size = document.getElementById("page-size").value;

      let sales_invoice_title = document.getElementById("sales_invoice_title").value;
      let purchase_invoice_title = document.getElementById("purchase_invoice_title").value;

      let sale_page_size = document.getElementById("sale-page-size").value;
      let purchase_page_size = document.getElementById("purchase-page-size").value;
     

      let print_settings = [];
      //print_settings.push(size);

      let invoice_title = {
          sales_invoice_title : sales_invoice_title,
          purchase_invoice_title : purchase_invoice_title
      }

      let page_size = {
          sale_page_size : sale_page_size,
          purchase_page_size : purchase_page_size
      }

      let nopp = document.getElementById("no_of_page_to_print").value;
      let no_of_page_to_print = {no_of_page_to_print:nopp};

      print_settings.push(no_of_page_to_print);
      print_settings.push(invoice_title);
      print_settings.push(page_size);

      for(let i=0;i<print_hide.length;i++){
          let value = print_hide[i].value;
          print_settings.push(value);
      }

      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'print_settings',
          value : print_settings
      }
      
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
          document.getElementById(thisid).classList.remove("hide");
          document.getElementById(thisid+'-btn-spinner').classList.add("hide");
      }.bind(this)).catch(function(res){
          
      })

    }
    handleAllowGeneralContact(e){
      let thisid = e.currentTarget.id;
      let is_general_contact = false;
      if(e.currentTarget.checked===true){
        is_general_contact = true;
      }
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'credit_bill_general_contact',
          value : is_general_contact
      }
      
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
          /*document.getElementById(thisid).classList.remove("hide");
          document.getElementById(thisid+'-btn-spinner').classList.add("hide");*/
      }.bind(this)).catch(function(res){
          
      })

    }
    handleAllowSPLowerThanPP(e){
      let thisid = e.currentTarget.id;
      let sp_lower_than_pp = false;
      if(e.currentTarget.checked===true){
        sp_lower_than_pp = true;
      }
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'sp_lower_than_pp',
          value : sp_lower_than_pp
      }
      
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
      }.bind(this)).catch(function(res){
          
      })

    }
    handleBarcodeBillView(e){
      let thisid = e.currentTarget.id;
      let barcode_name_on_the_bill_view = false;
      if(e.currentTarget.checked===true){
        barcode_name_on_the_bill_view = true;
      }
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'barcode_name_on_the_bill_view',
          value : barcode_name_on_the_bill_view
      }
      
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
      }.bind(this)).catch(function(res){
          
      })

    }

    handleItemBillView(e){
      let thisid = e.currentTarget.id;
      let item_name_on_the_bill_view = false;
      if(e.currentTarget.checked===true){
        item_name_on_the_bill_view = true;
      }
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'item_name_on_the_bill_view',
          value : item_name_on_the_bill_view
      }
      
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
      }.bind(this)).catch(function(res){
          
      })

    }
    handleBufferAmountForCreditLimit(e){
      let thisvalue = e.currentTarget.value;
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'buffer_amount_for_credit_limit',
          value : thisvalue
      }
      
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
      }.bind(this)).catch(function(res){
          
      })
    }

    handleSaveTC(e){
      let thisid = e.currentTarget.id;
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
      let term_cond = document.querySelectorAll(".t-and-c-box");
      this.terms = [];
      for(let i=0;i<term_cond.length;i++){
          let value = term_cond[i].value;
          this.terms.push(value);
      }

        let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'terms_and_conditions',
          value : this.terms,
      }
      
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
         
          document.getElementById(thisid).classList.remove("hide");
          document.getElementById(thisid+'-btn-spinner').classList.add("hide");
      }.bind(this)).catch(function(res){
          
      })
    }
    handleSaveCommissionRange = (e) =>{
      let thisid = e.currentTarget.id;
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
      
        let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'commission_list_settings',
          value : this.state.commission_list,
      }
      
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
         
          document.getElementById(thisid).classList.remove("hide");
          document.getElementById(thisid+'-btn-spinner').classList.add("hide");
      }.bind(this)).catch(function(res){
          
      })
    }

    handleAddMoreTC(e){
      let com_cls = document.getElementsByClassName("terms-container");
      let index = com_cls.length;
      this.more_rows.push(<TermsAndConditions terms="" show="box" key={index} index={index}/>);
      this.setState({more_rows:this.more_rows});
      
    }

    openModal(event) {
      let thisid = event.currentTarget.getAttribute('data_target');
      if(thisid!==null){
        var element = document.getElementById(thisid);
        element.classList.remove("fade");
        element.style.display="block";
      }
    }

    componentDidMount(){
      let fldata = {
        store_name:user_info.store_name,
        branch_id:user_info.login_id,
        user_id : user_info.user_id,
        uuid : utils.uuid(),
        role_id : user_info.access_level,
        token : user_info.token,
        _schema : user_info._schema,
      }
      let fl_api = app_api+'/get_financial_year_list';
      axios.post(fl_api, fldata)
        .then(function (response) {
          let resp = response.data;
          this.setState({is_financial_year_closed:resp.is_financial_year_closed});
      }.bind(this)).catch(function(res){
          
      })
        let keys = "'terms_and_conditions','print_settings','credit_bill_general_contact','sp_lower_than_pp','default_payment_mode_for_parchase','default_payment_mode_for_sale','barcode_name_on_the_bill_view','item_name_on_the_bill_view','buffer_amount_for_credit_limit','encrypted_purchase_price','advance_sale_data','is_financial_year_active','financial_year','is_financial_created','deduct_inventory_on_purchase_quotation_created','sale_field_hide','is_create_quotation','is_global_commission','global_commission','is_item_commission','item_commission','encrypted_selling_price','purchase_field_hide','bill_sequence','delete_cancelled_bill','send_e_invoice','enable_orders','enable_tcs','tcs_percent','sales_list_opt_visibility','sales_return_list_opt_visibility','purchase_list_opt_visibility','purchase_return_list_opt_visibility','sales_order_list_opt_visibility','sales_order_return_list_opt_visibility','purchase_order_list_opt_visibility','purchase_order_return_list_opt_visibility','commission_list_settings','header_content','footer_content','sales_sp_disc_type','purchase_sp_disc_type','purchase_pp_disc_type','global_discount_type','enable_audit_trial','print_template','selected_staff_while_billing','is_open_billing_counter'";
        let set_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, _schema : user_info._schema,
            keys:keys
        }
        let opt_api = app_api+'/get_settings';
        axios.post(opt_api, set_data)
          .then(function (response) {
            let resp = response.data;
            if(resp.print_template!==undefined){
              let print_template = resp.print_template;
              this.setState({custom_print_page:print_template.design_name});
            } 
            if(resp.terms_and_conditions){
              let terms = resp.terms_and_conditions;
              for(let i=0;i<terms.length;i++){
                this.more_rows.push(<TermsAndConditions terms={terms[i]} show="box" key={i} index={i}/>);
              }
              this.setState({more_rows:this.more_rows});
            }else{
              this.more_rows.push(<TermsAndConditions terms="" show="box" key={0} index={0}/>);
              this.setState({more_rows:this.more_rows});
            }

            if(resp.print_settings){
              let ps = resp.print_settings;
              let invoice_title = {sales_invoice_title:"",purchase_invoice_title:""};
              let page_size = {sale_page_size:"A4",purchase_page_size:"A4"};
              for(let i=0;i<ps.length;i++){
                  if(ps[i]!==undefined && ps[i].no_of_page_to_print!==undefined){
                    this.setState({no_of_page_to_print:ps[i].no_of_page_to_print});
                  }

                  if(ps[i]!==undefined && ps[i].sales_invoice_title!==undefined){
                    invoice_title.sales_invoice_title = ps[i].sales_invoice_title;
                    invoice_title.purchase_invoice_title = ps[i].purchase_invoice_title;
                  }
                  if(ps[i]!==undefined && ps[i].sale_page_size!==undefined){
                    page_size.sale_page_size = ps[i].sale_page_size;
                    page_size.purchase_page_size = ps[i].purchase_page_size;
                  }
              }
              
              this.setState({print_settings:ps, sale_page_size: page_size.sale_page_size, purchase_page_size: page_size.purchase_page_size,invoice_title:invoice_title});
              let print_check = document.querySelectorAll(".print-check");
              for(let i=0;i<print_check.length;i++){
                  let check = print_check[i].value;
                 
                  if(ps.indexOf(check)>=0){
                      print_check[i].checked = true;
                      document.getElementById('lbl-'+check).classList.add('disabled');
                  }
              }
            }
            if(resp.credit_bill_general_contact){
              let ps = resp.credit_bill_general_contact;
              if(ps===true){
                document.getElementById("allow_general_contact").checked = true;
              }
              this.setState({credit_bill_general_contact:ps});
            }
            if(resp.delete_cancelled_bill){
              let ps = resp.delete_cancelled_bill;
              this.setState({delete_cancelled_bill:ps});
            }
            if(resp.send_e_invoice){
              let ps = resp.send_e_invoice;
              this.setState({send_e_invoice:ps});
            }
            if(resp.enable_orders){
              let ps = resp.enable_orders;
              this.setState({enable_orders:ps});
            }
            if(resp.enable_audit_trial){
              let ps = resp.enable_audit_trial;
              this.setState({enable_audit_trial:ps});
            }
            if(resp.enable_tcs){
              let ps = resp.enable_tcs;
              this.setState({enable_tcs:ps});
            }
            if(resp.selected_staff_while_billing){
              let ps = resp.selected_staff_while_billing;
              this.setState({selected_staff_while_billing:ps});
            }
            if(resp.is_open_billing_counter){
              let ps = resp.is_open_billing_counter;
              this.setState({is_open_billing_counter:ps});
            }
            if(resp.tcs_percent){
              let ps = resp.tcs_percent;
              this.setState({tcs_percent:ps});
            }
            if(resp.sale_field_hide){
              let ps = resp.sale_field_hide;
             
              let sfield_hide = document.querySelectorAll(".hide-opt-check");
              for(let i=0;i<sfield_hide.length;i++){
                  let check = sfield_hide[i].value;
                 
                  if(ps.indexOf(check)>=0){
                      sfield_hide[i].checked = true;
                      document.getElementById('lbl-'+check).classList.add('disabled');
                  }
              }
            }
            if(resp.sales_list_opt_visibility){
              let ps = resp.sales_list_opt_visibility;
             
              let sfield_hide = document.querySelectorAll(".hide-sales-list-opt");
              for(let i=0;i<sfield_hide.length;i++){
                  let check = sfield_hide[i].value;
                 
                  if(ps.indexOf(check)>=0){
                      sfield_hide[i].checked = true;
                      document.getElementById('lbl-'+check).classList.add('disabled');
                  }
              }
            }
            if(resp.sales_return_list_opt_visibility){
              let ps = resp.sales_return_list_opt_visibility;
             
              let sfield_hide = document.querySelectorAll(".hide-sales-return-list-opt");
              for(let i=0;i<sfield_hide.length;i++){
                  let check = sfield_hide[i].value;
                 
                  if(ps.indexOf(check)>=0){
                      sfield_hide[i].checked = true;
                      document.getElementById('lbl-'+check).classList.add('disabled');
                  }
              }
            }
            if(resp.purchase_list_opt_visibility){
              let ps = resp.purchase_list_opt_visibility;
             
              let sfield_hide = document.querySelectorAll(".hide-purchase-list-opt");
              for(let i=0;i<sfield_hide.length;i++){
                  let check = sfield_hide[i].value;
                 
                  if(ps.indexOf(check)>=0){
                      sfield_hide[i].checked = true;
                      document.getElementById('lbl-'+check).classList.add('disabled');
                  }
              }
            }
            if(resp.purchase_return_list_opt_visibility){
              let ps = resp.purchase_return_list_opt_visibility;
             
              let sfield_hide = document.querySelectorAll(".hide-purchase-return-list-opt");
              for(let i=0;i<sfield_hide.length;i++){
                  let check = sfield_hide[i].value;
                 
                  if(ps.indexOf(check)>=0){
                      sfield_hide[i].checked = true;
                      document.getElementById('lbl-'+check).classList.add('disabled');
                  }
              }
            }
            if(resp.sales_order_list_opt_visibility){
              let ps = resp.sales_order_list_opt_visibility;
             
              let sfield_hide = document.querySelectorAll(".hide-sales-order-list-opt");
              for(let i=0;i<sfield_hide.length;i++){
                  let check = sfield_hide[i].value;
                 
                  if(ps.indexOf(check)>=0){
                      sfield_hide[i].checked = true;
                      document.getElementById('lbl-'+check).classList.add('disabled');
                  }
              }
            }
            if(resp.sales_order_return_list_opt_visibility){
              let ps = resp.sales_order_return_list_opt_visibility;
             
              let sfield_hide = document.querySelectorAll(".hide-sales-order-return-list-opt");
              for(let i=0;i<sfield_hide.length;i++){
                  let check = sfield_hide[i].value;
                 
                  if(ps.indexOf(check)>=0){
                      sfield_hide[i].checked = true;
                      document.getElementById('lbl-'+check).classList.add('disabled');
                  }
              }
            }
            if(resp.purchase_order_list_opt_visibility){
              let ps = resp.purchase_order_list_opt_visibility;
             
              let sfield_hide = document.querySelectorAll(".hide-purchase-order-list-opt");
              for(let i=0;i<sfield_hide.length;i++){
                  let check = sfield_hide[i].value;
                 
                  if(ps.indexOf(check)>=0){
                      sfield_hide[i].checked = true;
                      document.getElementById('lbl-'+check).classList.add('disabled');
                  }
              }
            }
            if(resp.purchase_order_return_list_opt_visibility){
              let ps = resp.purchase_order_return_list_opt_visibility;
             
              let sfield_hide = document.querySelectorAll(".hide-purchase-order-return-list-opt");
              for(let i=0;i<sfield_hide.length;i++){
                  let check = sfield_hide[i].value;
                 
                  if(ps.indexOf(check)>=0){
                      sfield_hide[i].checked = true;
                      document.getElementById('lbl-'+check).classList.add('disabled');
                  }
              }
            }
            if(resp.purchase_field_hide){
              let ps = resp.purchase_field_hide;
             
              let pfield_hide = document.querySelectorAll(".hide-pur-opt-check");
            
              for(let i=0;i<pfield_hide.length;i++){
                  let check = pfield_hide[i].value;
                  let strikeout = pfield_hide[i].id;
                  if(ps.indexOf(check)>=0){
                      pfield_hide[i].checked = true;
                      document.getElementById('plbl-'+strikeout).classList.add('disabled');
                  }
              }
            }
            if(resp.sp_lower_than_pp){
              let ps = resp.sp_lower_than_pp;
              if(ps===true){
                document.getElementById("sp_lower_than_pp").checked = true;
              }
              this.setState({sp_lower_than_pp:ps});
            }
            if(resp.barcode_name_on_the_bill_view){
              let ps = resp.barcode_name_on_the_bill_view;
              if(ps===true){
                document.getElementById("barcode_name_on_the_bill_view").checked = true;
              }
              this.setState({barcode_name_on_the_bill_view:ps});
            }
            if(resp.item_name_on_the_bill_view){
              let ps = resp.item_name_on_the_bill_view;
              if(ps===true){
                document.getElementById("item_name_on_the_bill_view").checked = true;
              }
              this.setState({item_name_on_the_bill_view:ps});
            }

            if(resp.default_payment_mode_for_parchase){
              localStorage.removeItem('p_mode');
              let ps = resp.default_payment_mode_for_parchase;
              this.setState({default_payment_mode_for_parchase:ps});
            }
            if(resp.default_payment_mode_for_sale){
              let ps = resp.default_payment_mode_for_sale;
              localStorage.removeItem('p_mode');
              this.setState({default_payment_mode_for_sale:ps});
            }
            if(resp.buffer_amount_for_credit_limit){
              let ps = resp.buffer_amount_for_credit_limit;
              this.setState({buffer_amount_for_credit_limit:ps});
            }
            if(resp.encrypted_purchase_price){
              let ps = resp.encrypted_purchase_price;
              this.setState({encrypted_purchase_price:ps});
            }
            if(resp.encrypted_selling_price){
              let ps = resp.encrypted_selling_price;
              this.setState({encrypted_selling_price:ps});
            }
          
            if(resp.financial_year){
              let ps = resp.financial_year;
              let from_date = new Date(ps.start_from);
              let to_date = new Date(ps.end_to);
              let generate_new_sequence_on_year_change = ps.generate_new_sequence_on_year_change;
              this.setState({generate_new_sequence_on_year_change:generate_new_sequence_on_year_change});
              this.setState({financialFromDate:from_date});
              this.setState({financialToDate:to_date});
            }
          
            if(resp.is_financial_created!==undefined){
              let ps = resp.is_financial_created;
              this.setState({is_financial_created:ps});
            }else{
              this.setState({is_financial_created:false});
            }
          
            if(resp.is_financial_year_active!==undefined){
              let ps = resp.is_financial_year_active;
              this.setState({is_financial_year_active:ps});
            }else if(resp.is_financial_year_active===undefined){
              this.setState({is_financial_year_active:true});
            }
            if(resp.advance_sale_data){
              let ps = resp.advance_sale_data;
              if(ps===true){
                document.getElementById("advance_sale_data").checked = true;
              }
              this.setState({advance_sale_data:ps});
            }
            if(resp.deduct_inventory_on_purchase_quotation_created){
              let ps = resp.deduct_inventory_on_purchase_quotation_created;
              
              this.setState({deduct_inventory_on_purchase_quotation_created:ps});
            }
            if(resp.is_create_quotation){
              let ps = resp.is_create_quotation;
              this.setState({is_create_quotation:ps});
            }
            if(resp.is_global_commission){
              let ps = resp.is_global_commission;
              this.setState({is_global_commission:ps});
            }
            if(resp.global_commission){
              let ps = resp.global_commission;
              this.setState({global_commission:ps});
            }
            if(resp.is_item_commission){
              let ps = resp.is_item_commission;
              this.setState({is_item_commission:ps});
            }
            if(resp.item_commission){
              let ps = resp.item_commission;
              this.setState({item_commission:ps});
            }
            if(resp.bill_sequence){
              let ps = resp.bill_sequence;
              this.setState({bill_sequence:ps});
            }
            if(resp.commission_list_settings){
              let ps = resp.commission_list_settings;
              this.setState({commission_list:ps});
            }
            if(resp.sales_sp_disc_type){
              let ps = resp.sales_sp_disc_type;
              this.setState({sales_sp_disc_type:ps});
            }
            if(resp.purchase_sp_disc_type){
              let ps = resp.purchase_sp_disc_type;
              this.setState({purchase_sp_disc_type:ps});
            }
            if(resp.purchase_pp_disc_type){
              let ps = resp.purchase_pp_disc_type;
              this.setState({purchase_pp_disc_type:ps});
            }
            if(resp.global_discount_type){
              let ps = resp.global_discount_type;
              this.setState({global_discount_type:ps});
            }
            if(resp.header_content){
              let ps = resp.header_content;
              ps = EditorState.createWithContent(
                convertFromRaw(ps)
              );
              this.setState({editorStateHeader:ps});
            }
            if(resp.footer_content){
              let ps = resp.footer_content;
              ps = EditorState.createWithContent(
                convertFromRaw(ps)
              );
              this.setState({editorStateFooter:ps});
            }
            //this.setState({settings:resp});
        }.bind(this)).catch(function(res){
            
        })
    }

    handleActivationFinancialYear = (e) =>{
      let is_financial_year_active = e.currentTarget.checked;
      let is_financial_created = this.state.is_financial_created;
      this.setState({is_financial_year_active:is_financial_year_active});
      if(is_financial_year_active===false || is_financial_created===true){
        let send_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, _schema : user_info._schema,
            name : 'is_financial_year_active',
            value : is_financial_year_active,
        }

        let api = app_api+'/save_option';
        axios.post(api, send_data)
          .then(function (response) {
          
        }.bind(this)).catch(function(res){
            
        })
      }
      if(is_financial_year_active===true){
        let qs = document.querySelectorAll('.handleShowFinancialYearList');
        if(qs[1]!==undefined){
          qs[1].click();
        }
      }else if(is_financial_year_active===false){
        let qs = document.querySelectorAll('.handleHideFinancialYearList');
        if(qs[1]!==undefined){
          qs[1].click();
        }
      }
    }

    handleDeductInventoryOnPQC =(e)=>{

      let thisvalue = e.currentTarget.checked;
      this.setState({deduct_inventory_on_purchase_quotation_created:thisvalue});
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'deduct_inventory_on_purchase_quotation_created',
          value : thisvalue
      }
      
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
      }.bind(this)).catch(function(res){
          
      })

    }

    handleIsCreateQuotation =(e)=>{
      let thisvalue = e.currentTarget.checked;
      this.setState({is_create_quotation:thisvalue});
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'is_create_quotation',
          value : thisvalue
      }
      
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
      }.bind(this)).catch(function(res){
          
      })
    }

    handleDeleteCancelledBill =(e)=>{
      let thisvalue = e.currentTarget.checked;
      this.setState({delete_cancelled_bill:thisvalue});
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'delete_cancelled_bill',
          value : thisvalue
      }
      
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
      }.bind(this)).catch(function(res){
          
      })
    }

    handlePageCountChange = (e) =>{
      let thisval = e.currentTarget.value;
      this.setState({no_of_page_to_print:thisval});
    }

    handleSaveSaleOption = (e)=>{
      let thisid = e.currentTarget.id;
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
      let sales_sp_disc_type = document.querySelector('input[name="sales-sp-disc"]:checked').value;


      let sfhide = document.querySelectorAll(".hide-opt-check:checked");
      
      let sale_field_hide = [];
     
      for(let i=0;i<sfhide.length;i++){
          let value = sfhide[i].value;
          sale_field_hide.push(value);
      }

      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'sale_field_hide',
          value : sale_field_hide
      }
      
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
          document.getElementById(thisid).classList.remove("hide");
          document.getElementById(thisid+'-btn-spinner').classList.add("hide");
      }.bind(this)).catch(function(res){
          
      })    
      send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'sales_sp_disc_type',
          value : sales_sp_disc_type
      }
      api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
      }.bind(this)).catch(function(res){
          
      })    
    }
    handleSaveBillListOption = (e)=>{
      let thisid = e.currentTarget.id;
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
      let sfhide = document.querySelectorAll(".hide-sales-list-opt:checked");
      let pfhide = document.querySelectorAll(".hide-purchase-list-opt:checked");
      let srfhide = document.querySelectorAll(".hide-sales-return-list-opt:checked");
      let prfhide = document.querySelectorAll(".hide-purchase-return-list-opt:checked");
      
      let sale_field_hide = [];
     
      for(let i=0;i<sfhide.length;i++){
          let value = sfhide[i].value;
          sale_field_hide.push(value);
      }
      let purchase_field_hide = [];
     
      for(let i=0;i<pfhide.length;i++){
          let value = pfhide[i].value;
          purchase_field_hide.push(value);
      }
      let sale_return_field_hide = [];
      for(let i=0;i<srfhide.length;i++){
          let value = srfhide[i].value;
          sale_return_field_hide.push(value);
      }
      let purchase_return_field_hide = [];
      for(let i=0;i<prfhide.length;i++){
          let value = prfhide[i].value;
          purchase_return_field_hide.push(value);
      }

      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'sales_list_opt_visibility',
          value : sale_field_hide
      }
      
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
          document.getElementById(thisid).classList.remove("hide");
          document.getElementById(thisid+'-btn-spinner').classList.add("hide");
      }.bind(this)).catch(function(res){
          
      })    
      let pur_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'purchase_list_opt_visibility',
          value : purchase_field_hide
      }
      
      let papi = app_api+'/save_option';
      axios.post(papi, pur_data)
        .then(function (response) {
          
      }.bind(this)).catch(function(res){
          
      })    
      let sr_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'sales_return_list_opt_visibility',
          value : sale_return_field_hide
      }
      
      let srapi = app_api+'/save_option';
      axios.post(srapi, sr_data)
        .then(function (response) {
          
      }.bind(this)).catch(function(res){
          
      })    
      let pr_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'purchase_return_list_opt_visibility',
          value : purchase_return_field_hide
      }
      
      let prapi = app_api+'/save_option';
      axios.post(prapi, pr_data)
        .then(function (response) {
          
      }.bind(this)).catch(function(res){
          
      })
      
      let sofhide = document.querySelectorAll(".hide-sales-order-list-opt:checked");
      let pofhide = document.querySelectorAll(".hide-purchase-order-list-opt:checked");
      let sorfhide = document.querySelectorAll(".hide-sales-order-return-list-opt:checked");
      let porfhide = document.querySelectorAll(".hide-purchase-order-return-list-opt:checked");
      
      let sale_order_field_hide = [];
     
      for(let i=0;i<sofhide.length;i++){
          let value = sofhide[i].value;
          sale_order_field_hide.push(value);
      }
      let purchase_order_field_hide = [];
     
      for(let i=0;i<pofhide.length;i++){
          let value = pofhide[i].value;
          purchase_order_field_hide.push(value);
      }
      let sale_order_return_field_hide = [];
      for(let i=0;i<sorfhide.length;i++){
          let value = sorfhide[i].value;
          sale_order_return_field_hide.push(value);
      }
      let purchase_order_return_field_hide = [];
      for(let i=0;i<porfhide.length;i++){
          let value = porfhide[i].value;
          purchase_order_return_field_hide.push(value);
      }

      let so_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'sales_order_list_opt_visibility',
          value : sale_order_field_hide
      }
      
      let soapi = app_api+'/save_option';
      axios.post(soapi, so_data)
        .then(function (response) {
          
      }.bind(this)).catch(function(res){
          
      })    
      let pur_or_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'purchase_order_list_opt_visibility',
          value : purchase_order_field_hide
      }
      
      let poapi = app_api+'/save_option';
      axios.post(poapi, pur_or_data)
        .then(function (response) {
          
      }.bind(this)).catch(function(res){
          
      })    
      let sor_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'sales_order_return_list_opt_visibility',
          value : sale_order_return_field_hide
      }
      
      let sorapi = app_api+'/save_option';
      axios.post(sorapi, sor_data)
        .then(function (response) {
          
      }.bind(this)).catch(function(res){
          
      })    
      let por_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'purchase_order_return_list_opt_visibility',
          value : purchase_order_return_field_hide
      }
      
      let porapi = app_api+'/save_option';
      axios.post(porapi, por_data)
        .then(function (response) {
          
      }.bind(this)).catch(function(res){
          
      })
    }
    handleSavePurchaseOption = (e)=>{
      let thisid = e.currentTarget.id;
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
      let purchase_pp_disc_type = document.querySelector('input[name="purchase-pp-disc"]:checked').value;
      let purchase_sp_disc_type = document.querySelector('input[name="purchase-sp-disc"]:checked').value;
      let pfhide = document.querySelectorAll(".hide-pur-opt-check:checked");
      
      let purchase_field_hide = [];
     
      for(let i=0;i<pfhide.length;i++){
          let value = pfhide[i].value;
          purchase_field_hide.push(value);
      }

      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'purchase_field_hide',
          value : purchase_field_hide
      }
      
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
          document.getElementById(thisid).classList.remove("hide");
          document.getElementById(thisid+'-btn-spinner').classList.add("hide");
      }.bind(this)).catch(function(res){
          
      })    
       send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'purchase_pp_disc_type',
          value : purchase_pp_disc_type
      }
      
       api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
          
      }.bind(this)).catch(function(res){
          
      })    
       send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'purchase_sp_disc_type',
          value : purchase_sp_disc_type
      }
      
       api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
          
      }.bind(this)).catch(function(res){
          
      })    
    }

    handleChangeCommissionType = e =>{
      let thisval = e.currentTarget.value;
      this.setState({comission_type:thisval})
    }


    handleAddOldBills = () =>{
        this.setState({is_add_old_bills:true});
        window.scrollTo(0, 0);
    }


    handleCloseOldBill = () =>{
        this.setState({is_add_old_bills:false});
    }

    handleSaveSequence = (e) =>{
      const thisid = e.currentTarget.id;
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-btn-spinner').classList.remove("hide");

      let sequences = {}

      let qs = document.querySelectorAll('.bill-sequence');
      for(let i=0; i< qs.length;i++){
        let key = qs[i].id;
        let value = qs[i].value;
        sequences[key] = value;
      }
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'bill_sequence',
          value : sequences
      }
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
          document.getElementById(thisid).classList.remove("hide");
          document.getElementById(thisid+'-btn-spinner').classList.add("hide");
      }.bind(this)).catch(function(res){
          
      })
    }
    handleAddMoreCommissionRange = () =>{
      let new_list = {id:0,range1:'',range2:'',value:'',sym:'per'};
      let commission_list = this.state.commission_list;
      commission_list.push(new_list);
      this.setState({commission_list:commission_list});
    }
    handleRemoveCommissionList = (e) =>{
      let id = e.currentTarget.getAttribute('data-id');
      
      let com_list = this.state.commission_list;
      com_list.splice(id,1);
      this.setState({commission_list:com_list});
     
    }
    handleChangeCommissionValue = (e) =>{
      let thisval = e.currentTarget.value;
      let key = e.currentTarget.getAttribute('data-id');
      let target = e.currentTarget.getAttribute('data-target');
      let commission_list = this.state.commission_list;
      commission_list[key][target] = thisval;
      
      this.setState({commission_list:commission_list});
    }
    onEditorHeaderStateChange = (data)=>{
      const rawState = JSON.stringify(convertToRaw(data.getCurrentContent()));
      //console.log(rawState);
      //let getHTML = draftToHtml(rawState);
      this.setState({editorStateHeader:data});
    }
    onEditorFooterStateChange = (data)=>{
      this.setState({editorStateFooter:data});
    }
    handeSaveEditorContent = (e) =>{
      let thisid = e.currentTarget.id;
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
      let data_id = e.currentTarget.getAttribute('data-id');
      let content = '';
      if(data_id==='header_content'){
        content = this.state.editorStateHeader;
        content = convertToRaw(content.getCurrentContent());
      }else if(data_id==='footer_content'){
        content = this.state.editorStateFooter;
        content = convertToRaw(content.getCurrentContent());
      }
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : data_id,
          value : content
      }
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
          document.getElementById(thisid).classList.remove("hide");
          document.getElementById(thisid+'-btn-spinner').classList.add("hide");
      }.bind(this)).catch(function(res){
          
      })
    }
    handleChangeDiscountType = (e) =>{
      let thisvalue = e.currentTarget.value;
      let target = e.currentTarget.getAttribute('data-target');
      if(target==='sales-sp'){
        this.setState({sales_sp_disc_type:thisvalue});
      }else if(target==='purchase-sp'){
        this.setState({purchase_sp_disc_type:thisvalue});
      }else if(target==='purchase-pp'){
        this.setState({purchase_pp_disc_type:thisvalue});
      }
    }
    handleReOpenFinancialYear = (e) =>{
      if (window.confirm("Are you sure? you want to close this financial year") == false) {
        return false;
      }
      let thisid = e.currentTarget.id;
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-spinner').classList.remove("hide");
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, 
          _schema : user_info._schema
      }
      let api = app_api+'/re_open_financial_year';
      axios.post(api, send_data)
        .then(function (response) {
          document.getElementById(thisid).classList.remove("hide");
          document.getElementById(thisid+'-spinner').classList.add("hide");
          window.location.reload();
      }.bind(this)).catch(function(res){
          
      })
    }

    handleChangeGolbalDiscountType = (e) =>{
      let thisvalue = e.currentTarget.value;
      this.setState({global_discount_type:thisvalue})
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, 
          _schema : user_info._schema,
          name : 'global_discount_type',
          value : thisvalue
      }
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
      }.bind(this)).catch(function(res){
          
      })
    }

    handleDesignNewPrintTemplate = () =>{
      window.location.href ='/settings/desing-print-template';
    }

    render() {
        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-8">
                        <div className="mod-title"><span>Billing & Payments</span></div>
                    </div>
                    <div className="col-md-4">
                        <div className="pull-right"><Notification /></div>
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                  <div className="content-area">
                    {(this.state.is_add_old_bills===false) &&
                    <>
                     <div className="main-content">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="row">
                            <div className="col-md-12">
                                  <div className="box-container">
                                    <div className="box-head">
                                      <label>BILL SEQUENCE SETTINGS</label>
                                    </div>
                                    <div className="box-body" style={{padding:'15px'}}>
                                      <div className="row">
                                        <div className="col-md-6 border-right">
                                            <div className="row">
                                              <div className="col-md-12 mrbtm-10">
                                              <span className="fe-title">SALE SEQUENCE</span>
                                                <div className="input-group">
                                                  <div className="input-group-prepend">
                                                    <span className="input-group-text no-border-right" style={{color:'#808080'}}>
                                                      #SB
                                                    </span>
                                                    <input type="number" className="form-control no-border-left bill-sequence" id="sale" defaultValue={(this.state.bill_sequence.sale!==undefined)?this.state.bill_sequence.sale:''}/>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-md-12 mrbtm-10">
                                                <span className="fe-title">SALES RETURN SEQUENCE</span>
                                                <div className="input-group">
                                                  <div className="input-group-prepend">
                                                    <span className="input-group-text no-border-right" style={{color:'#808080'}}>
                                                      #SRB
                                                    </span>
                                                    <input type="number" className="form-control no-border-left bill-sequence" id="sales_return"  defaultValue={(this.state.bill_sequence.sales_return!==undefined)?this.state.bill_sequence.sales_return:''}/>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-md-12 mrbtm-10">
                                              <span className="fe-title">PURCHASE SEQUENCE</span>
                                                <div className="input-group">
                                                  <div className="input-group-prepend">
                                                    <span className="input-group-text no-border-right" style={{color:'#808080'}}>
                                                      #PB
                                                    </span>
                                                    <input type="number" className="form-control no-border-left bill-sequence" id="purchase"  defaultValue={(this.state.bill_sequence.purchase!==undefined)?this.state.bill_sequence.purchase:''}/>
                                                  </div>
                                                </div>
                                              </div>
                                              
                                              <div className="col-md-12 mrbtm-10">
                                              <span className="fe-title">PURCHASE RETURN SEQUENCE</span>
                                                <div className="input-group">
                                                  <div className="input-group-prepend">
                                                    <span className="input-group-text no-border-right" style={{color:'#808080'}}>
                                                      #PRB
                                                    </span>
                                                    <input type="number" className="form-control no-border-left bill-sequence" id="purchase_return"  defaultValue={(this.state.bill_sequence.purchase_return!==undefined)?this.state.bill_sequence.purchase_return:''}/>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                              <div className="col-md-12 mrbtm-10">
                                              <span className="fe-title">SALE ORDER SEQUENCE</span>
                                                <div className="input-group">
                                                  <div className="input-group-prepend">
                                                    <span className="input-group-text no-border-right" style={{color:'#808080'}}>
                                                      #SO
                                                    </span>
                                                    <input type="number" className="form-control no-border-left bill-sequence" id="sale_order" defaultValue={(this.state.bill_sequence.sale_order!==undefined)?this.state.bill_sequence.sale_order:''}/>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-md-12 mrbtm-10">
                                                <span className="fe-title">SALES ORDER RETURN SEQUENCE</span>
                                                <div className="input-group">
                                                  <div className="input-group-prepend">
                                                    <span className="input-group-text no-border-right" style={{color:'#808080'}}>
                                                      #SOR
                                                    </span>
                                                    <input type="number" className="form-control no-border-left bill-sequence" id="sales_order_return"  defaultValue={(this.state.bill_sequence.sales_order_return!==undefined)?this.state.bill_sequence.sales_order_return:''}/>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-md-12 mrbtm-10">
                                              <span className="fe-title">PURCHASE ORDER SEQUENCE</span>
                                                <div className="input-group">
                                                  <div className="input-group-prepend">
                                                    <span className="input-group-text no-border-right" style={{color:'#808080'}}>
                                                      #PO
                                                    </span>
                                                    <input type="number" className="form-control no-border-left bill-sequence" id="purchase_order"  defaultValue={(this.state.bill_sequence.purchase_order!==undefined)?this.state.bill_sequence.purchase_order:''}/>
                                                  </div>
                                                </div>
                                              </div>
                                              
                                              <div className="col-md-12 mrbtm-10">
                                              <span className="fe-title">PURCHASE RETURN SEQUENCE</span>
                                                <div className="input-group">
                                                  <div className="input-group-prepend">
                                                    <span className="input-group-text no-border-right" style={{color:'#808080'}}>
                                                      #POR
                                                    </span>
                                                    <input type="number" className="form-control no-border-left bill-sequence" id="purchase_order_return"  defaultValue={(this.state.bill_sequence.purchase_order_return!==undefined)?this.state.bill_sequence.purchase_order_return:''}/>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                        </div>
                                        
                                      </div>
                                    </div>
                                   
                                    <div className="text-right" style={{borderRight:'1px solid #d7d7d7',borderBottom:'1px solid #d7d7d7',borderLeft:'1px solid #d7d7d7',padding:'10px'}}>
                                   
                                        <button type="button" className="btn btn-success" id="save-sequence" onClick={this.handleSaveSequence}>Save Settings</button>
                                        <button type="button" id="save-sequence-btn-spinner" className="hide btn btn-success btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                    </div>
                                    
                                  </div>
                                </div>

                            <div className="col-md-12">
                              <div className="box-container">
                                <div className="box-head">
                                  <label>PAYMENT MODES</label>
                                  {this.is_access('settings-bill-payment-create') &&
                                    <FontAwesomeIcon onClick={this.openModal} data_target="add_payment_mode"  icon={faPlusSquare} className="pull-right margin-5 fa-icon"/>
                                  }
                                </div>
                                <div className="box-body">
                                  <PaymentModes show="list" />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                                  <div className="box-container">
                                    <div className="box-head">
                                      <label>UNITS</label>
                                      {this.is_access('settings-bill-payment-create') &&
                                        <FontAwesomeIcon onClick={this.openModal} data_target="add_unit" icon={faPlusSquare} className="pull-right margin-5 fa-icon"/>
                                      }
                                    </div>
                                    <div className="box-body">
                                        <ProductUnits show="list" />
                                    </div>
                                  </div>
                                </div>
                            <div className="col-md-12">
                            <div className="box-container">
                              <div className="box-head">
                                <label>PRINT SETTINGS</label>
                              </div>
                              <div className="box-body" style={{padding:'10px 20px'}}>
                                <div className="row">
                                  <div className="col-md-12">
                                    <button type="button" className="btn btn-secondary" onClick={this.handleDesignNewPrintTemplate} style={{width:'100%'}}>Design Custom Print Template</button>
                                  </div>
                                  <div className="col-md-12">
                                    <hr />
                                  </div>
                                  <div className="col-md-6">
                                    <label htmlFor="page-size">Sale Page Size</label>
                                    <select className="form-control" style={{width:'200px'}} id="sale-page-size" value={this.state.sale_page_size} onChange={this.handlePageChange}>
                                      <option value="A4">A4</option>
                                      <option value="A5">A5</option>
                                      <option value="Thermal">Thermal</option>
                                      {(this.state.custom_print_page!=="") &&
                                        <option value="custom">{this.state.custom_print_page}</option>
                                      }
                                    </select>
                                    {(this.state.sale_page_size==='custom') &&
                                      <p className="mrtop-10" style={{color:'red',fontSize:'11px',borderBottom: '1px solid #ced4da'}}>FOLLOWING SETTINGS WILL NOT WORK WITH SELECTED SALE PAGE SIZE</p>
                                    }
                                    <p className="mrtop-10" style={{color:'#717171',fontSize:'14px',borderBottom: '1px solid #ced4da'}}>HIDE FROM PRINT PAGE</p>
                                    <div>
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input print-check" type="checkbox" id="mobile" value="mobile" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="mobile" id="lbl-mobile">MOBILE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input print-check" type="checkbox" id="email" value="email" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="email" id="lbl-email">EMAIL</label>
                                      </div>
                                    </div>
                                    {/*<div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input print-check" type="checkbox" id="signature" value="signature" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="signature" id="lbl-signature">SIGNATURE</label>
                                      </div>
                                    </div>*/}

                                    {/*<div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input print-check" type="checkbox" id="old-balance" value="old-balance"/>
                                      <label className="form-check-label chklbl" htmlFor="old-balance" id="lbl-mobile">OLD BALANCE</label>
                                      </div>
                                    </div> */}
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input print-check" type="checkbox" id="terms-and-conditions" value="terms-and-conditions" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="terms-and-conditions" id="lbl-terms-and-conditions">TERMS & CONDITIONS</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input print-check" type="checkbox" id="tax-info" value="tax-info" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="tax-info" id="lbl-tax-info">TAX INFO</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input print-check" type="checkbox" id="hsn-code" value="hsn-code" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="hsn-code" id="lbl-hsn-code">HSN CODE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input print-check" type="checkbox" id="discount" value="discount" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="discount" id="lbl-discount">DISCOUNT</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input print-check" type="checkbox" id="company-name" value="company-name" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="company-name" id="lbl-company-name">COMPANY NAME</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input print-check" type="checkbox" id="company-address" value="company-address" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="company-address" id="lbl-company-address">COMPANY ADDRESS</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input print-check" type="checkbox" id="sno-in-items" value="sno-in-items" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="sno-in-items" id="lbl-sno-in-items">S. NO. ITEMS</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input print-check" type="checkbox" id="price-in-items" value="price-in-items" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="price-in-items" id="lbl-price-in-items">PRICE IN SALES ITEMS</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input print-check" type="checkbox" id="barcode-in-items" value="barcode-in-items" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="barcode-in-items" id="lbl-barcode-in-items">BARCODE NAME ON BILL VIEW</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input print-check" type="checkbox" id="item-name-in-items" value="item-name-in-items" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="item-name-in-items" id="lbl-item-name-in-items">ITEM NAME ON BILL VIEW</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input print-check" type="checkbox" id="item-desc-in-items" value="item-desc-in-items" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="item-desc-in-items" id="lbl-item-desc-in-items">ITEM DESCRIPTION</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input print-check" type="checkbox" id="bill-of-supply" value="bill-of-supply" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="bill-of-supply" id="lbl-bill-of-supply">BILL OF SUPPLY</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input print-check" type="checkbox" id="pay-qr" value="pay-qr" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="pay-qr" id="lbl-pay-qr">UPI PAY QR</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input print-check" type="checkbox" id="bill-pay-mode" value="bill-pay-mode" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="bill-pay-mode" id="lbl-bill-pay-mode">PAYMENT TYPE <span style={{textTransform:'capitalize'}}>(Only for Thermal)</span></label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <label htmlFor="purchase-page-size">Purchase Page Size</label>
                                    <select className="form-control" style={{width:'200px'}} id="purchase-page-size" value={this.state.purchase_page_size} onChange={this.handlePageChange}>
                                      <option value="A4">A4</option>
                                      <option value="A5">A5</option>
                                      <option value="Thermal">Thermal</option>
                                    </select>
                                    <label className="mrtop-10" htmlFor="sales_invoice_title">Sales Invoice Title</label>
                                    <input type="text" className="form-control" id="sales_invoice_title" defaultValue={this.state.invoice_title.sales_invoice_title}/>

                                    <label htmlFor="purchase_invoice_title">Purchase Invoice Title</label>
                                    <input type="text" className="form-control" id="purchase_invoice_title" defaultValue={this.state.invoice_title.purchase_invoice_title}/>
                                    
                                    <label htmlFor="no_of_page_to_print" className="mrtop-10">No. of page(s) to print</label>
                                    <input type="text" className="form-control" id="no_of_page_to_print" value={this.state.no_of_page_to_print} onChange={this.handlePageCountChange}/>
                                    
                                  </div>
                                </div>
                              </div>
                              <div className="text-right" style={{borderRight:'1px solid #d7d7d7',borderBottom:'1px solid #d7d7d7',borderLeft:'1px solid #d7d7d7',padding:'10px'}}>
                                <button type="button" className="btn btn-success" id="save-print" onClick={this.handleSavePrintSetting}>Save Settings</button>
                                <button type="button" id="save-print-btn-spinner" className="hide btn btn-success btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                              </div>
                            </div>
                          </div>
                          
                            </div>
                          </div>
                          <div className="col-md-6">
                              <div className="row">
                              <div className="col-md-12">
                                  <div className="box-container">
                                    <div className="box-head">
                                      <label>FINANCIAL YEAR</label>
                                    </div>
                                    <div className="box-body" style={{padding:'15px'}}>
                                      <div className="row">
                                        <div className="col-md-12 mrbtm-10">
                                            <div className="form-check form-check-inline">
                                              <input className="form-check-input" type="checkbox" id="is-financial-year-active" value="is-financial-year-active" onChange={this.handleActivationFinancialYear} checked={this.state.is_financial_year_active}/>
                                              <label className="form-check-label chklbl" htmlFor="is-financial-year-active" style={{fontWeight:'bold'}}>
                                                ACTIVATE/DEACTIVE FINANCIAL YEAR
                                              </label>
                                            </div>
                                        </div>
                                        {(this.state.is_financial_year_active===true) &&
                                        <>
                                        <div className="col-md-6">
                                          <span className="fe-title">STARTS FROM</span>
                                          <div className="input-group">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text no-border-right">
                                                <FontAwesomeIcon icon={faCalendarAlt} />
                                              </span>
                                              {(this.state.is_financial_created===false) &&
                                                <DatePicker className="form-control  no-border-left" selected={this.state.financialFromDate} onChange={this.financialFromDate} dateFormat="dd MMMM"/>
                                              }
                                            {(this.state.is_financial_created===true) &&
                                              <DatePicker className="form-control  no-border-left" selected={this.state.financialFromDate} dateFormat="dd MMMM"/>
                                            }
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <span className="fe-title">END TO</span>
                                          <div className="input-group">
                                            <div className="input-group-prepend">
                                              <span className="input-group-text no-border-right">
                                                <FontAwesomeIcon icon={faCalendarAlt} />
                                              </span>
                                              {(this.state.is_financial_created===false) &&
                                                <DatePicker className="form-control  no-border-left" selected={this.state.financialToDate} onChange={this.financialToDate} dateFormat="dd MMMM"/>
                                              }
                                              {(this.state.is_financial_created===true) &&
                                                <DatePicker className="form-control  no-border-left" selected={this.state.financialToDate} dateFormat="dd MMMM"/>
                                              }
                                            </div>
                                          </div>
                                        </div>
                                        </>
                                        }
                                      </div>
                                      {(this.state.is_financial_year_active===true) &&
                                      <div className="row mrtop-20">
                                        <div className="col-md-12">
                                            <div className="form-check form-check-inline">
                                              <input className="form-check-input" type="checkbox" id="regenerate-finanical-sequence" value="regenerate-finanical-sequence" onChange={this.handleGenerateNewSequenceOnYearChange} checked={this.state.generate_new_sequence_on_year_change}/>
                                              <label className="form-check-label chklbl" htmlFor="regenerate-finanical-sequence">
                                                GENERATE NEW BILLING SEQUENCE ON FINANCIAL YEAR CHANGE</label>
                                            </div>
                                          </div>
                                          {(this.state.is_financial_year_closed===true && (user_info.access_level==="1" || user_info.access_level===1)) &&
                                            <div className="col-md-12">
                                              <p style={{margin:'0px'}}>This financial year has been closed.</p>
                                              <button type="button" className="btn btn-secondary" id="reopen-fin-year-btn" onClick={this.handleReOpenFinancialYear}>RE-OPEN THIS FINANCIAL YEAR</button>
                                              <button type="button" id="reopen-fin-year-btn-spinner" className="hide btn btn-secondary"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                            </div>
                                          }
                                      </div>
                                    }
                                    </div>
                                    {(this.state.is_financial_year_active===true) &&
                                      <div className="text-right" style={{borderRight:'1px solid #d7d7d7',borderBottom:'1px solid #d7d7d7',borderLeft:'1px solid #d7d7d7',padding:'10px'}}>
                                      {(this.state.is_financial_created===false) &&
                                        <>
                                          <button type="button" className="btn btn-success" id="save-financial" onClick={this.handleSaveFinancialYearSettings}>Save Settings</button>
                                          <button type="button" id="save-financial-btn-spinner" className="hide btn btn-success btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                        </>
                                      }
                                      {(this.state.is_financial_created===true) &&
                                        <button type="button" className="btn btn-secondary">Save Settings</button>
                                      }
                                      </div>
                                    }
                                  </div>
                                </div>
                                
                                <div className="col-md-12">
                              <div className="box-container">
                                <div className="box-head">
                                  <label>Other Settings</label>
                                </div>
                                <div className="box-body">
                                  <table className="table">
                                    <tbody>
                                      <tr>
                                        <td>
                                            <div className="form-check form-check-inline">
                                              <input className="form-check-input" type="checkbox" id="is_open_billing_counter" value="is_open_billing_counter" onChange={this.handleChangeOpenCloseCounter} checked={this.state.is_open_billing_counter}/>
                                              <label className="form-check-label chklbl" htmlFor="is_open_billing_counter">OPEN/CLOSE COUNTER EVERYDAY</label>
                                            </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                            <div className="form-check form-check-inline">
                                              <input className="form-check-input" type="checkbox" id="selected_staff_while_billing" value="selected_staff_while_billing" onChange={this.handleSelectStaffWhileBilling} checked={this.state.selected_staff_while_billing}/>
                                              <label className="form-check-label chklbl" htmlFor="selected_staff_while_billing">SELECT STAFF WHILE BILLING</label>
                                            </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <>
                                            
                                            <div className="form-check form-check-inline">
                                              <input className="form-check-input" type="checkbox" id="enable_tcs" value="enable_tcs" onChange={this.handleEnableTCS} checked={this.state.enable_tcs}/>
                                              <label className="form-check-label chklbl" htmlFor="enable_tcs">ENABLE TCS</label>
                                            </div>
                                            {(this.state.enable_tcs===true) &&
                                              <div className="input-group" style={{width:'176px'}}>
                                                <input id="tcs_percent" type="text" className="form-control no-border-right" defaultValue={this.state.tcs_percent}/>
                                                <div className="input-group-append">
                                                  <span className="input-group-text pad-left-none">%</span>
                                                </div>
                                                  <button type="button" id='save-tcs' className="btn btn-success mrleft-10" onClick={this.handleSaveTCSPercent}>Save</button>
                                                  <button type="button" id="save-tcs-btn-spinner" className="hide btn btn-success mrleft-10 btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                              </div>
                                            }
                                          </>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id="enable_audit_trial" value="enable_audit_trial" onChange={this.handleEnableTrialBalance} checked={this.state.enable_audit_trial}/>
                                            <label className="form-check-label chklbl" htmlFor="enable_audit_trial">ENABLE AUDIT TRIAL</label>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr className="hide">
                                        <td>
                                          <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id="enable_orders" value="enable_orders" onChange={this.handleEnableOrders} checked={this.state.enable_orders}/>
                                            <label className="form-check-label chklbl" htmlFor="enable_orders">ENABLE ORDERS ( SALES | PURCHASE | SR | PR )</label>
                                          </div>
                                        </td>
                                      </tr>
                                      {(user_info.gst_type==='regular' && user_info.gst_number!=="") &&
                                      <tr>
                                        <td>
                                          <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id="send_e_invoice" value="send_e_invoice" onChange={this.handleSendEInvoice} checked={this.state.send_e_invoice}/>
                                            <label className="form-check-label chklbl" htmlFor="send_e_invoice">ENABLE E-INVOICE</label>
                                          </div>
                                        </td>
                                      </tr>
                                      }
                                      <tr>
                                        <td>
                                          <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id="delete_cancelled_bill" value="delete_cancelled_bill" onChange={this.handleDeleteCancelledBill} checked={this.state.delete_cancelled_bill}/>
                                            <label className="form-check-label chklbl" htmlFor="delete_cancelled_bill">DELETE CANCELLED BILL</label>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id="allow_general_contact" value="allow_general_contact" onChange={this.handleAllowGeneralContact} defaultChecked={(this.state.credit_bill_general_contact)?true:false}/>
                                            <label className="form-check-label chklbl" htmlFor="allow_general_contact">Credit Bill to General Contact</label>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id="sp_lower_than_pp" value="sp_lower_than_pp" onChange={this.handleAllowSPLowerThanPP} defaultChecked={(this.state.sp_lower_than_pp)?true:false}/>
                                            <label className="form-check-label chklbl" htmlFor="sp_lower_than_pp">Selling Price Lower Than Purchase Price</label>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <label>Default payment mode for purchase entries</label>
                                          <PaymentModes show="dropdown" target="default_payment_mode_for_parchase" default={this.state.default_payment_mode_for_parchase}/>
                                          <span id="handleChangePaymentModeForPurchase" onClick={this.handleChangePaymentModeForPurchase}></span>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <label>Default payment mode for sales entries</label>
                                          <PaymentModes show="dropdown" target="default_payment_mode_for_sale" default={this.state.default_payment_mode_for_sale}/>
                                          <span id="handleChangePaymentModeForSale" onClick={this.handleChangePaymentModeForSale}></span>
                                        </td>
                                      </tr>
                                      <tr style={{display:'none'}}>
                                        <td>
                                          <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id="item_name_on_the_bill_view" value="item_name_on_the_bill_view" onChange={this.handleItemBillView} defaultChecked={(this.state.item_name_on_the_bill_view)?true:false}/>
                                            <label className="form-check-label chklbl" htmlFor="item_name_on_the_bill_view">ITEM NAME ON THE BILL VIEW</label>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr style={{display:'none'}}>
                                        <td>
                                          <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id="barcode_name_on_the_bill_view" value="barcode_name_on_the_bill_view" onChange={this.handleBarcodeBillView} defaultChecked={(this.state.barcode_name_on_the_bill_view)?true:false}/>
                                            <label className="form-check-label chklbl" htmlFor="barcode_name_on_the_bill_view">BARCODE NAME ON THE BILL VIEW</label>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <label for="buffer_amount_for_credit_limit">Buffer amount for credit limit</label>
                                          <div className="input-group" style={{width:'176px'}}>
                                            <input id="buffer_amount_for_credit_limit" type="text" className="form-control no-border-right" defaultValue={this.state.buffer_amount_for_credit_limit} onChange={this.handleBufferAmountForCreditLimit}/>
                                            <div className="input-group-append">
                                              <span className="input-group-text pad-left-none">%</span>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" id="advance_sale_data" value="advance_sale_data" onChange={this.handleDispatchedDetails} defaultChecked={(this.state.advance_sale_data)?true:false}/>
                                            <label className="form-check-label chklbl" htmlFor="advance_sale_data">ADVANCE SALE DATA</label>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div>
                                            <div className="form-check form-check-inline">
                                              <input className="form-check-input" type="checkbox" id="is_create_quotation" value="is_create_quotation" checked={(this.state.is_create_quotation)?true:false} onChange={this.handleIsCreateQuotation}/>
                                              <label className="form-check-label chklbl" htmlFor="is_create_quotation">CREATE QUOTATIONS</label>
                                            </div>
                                          </div>
                                          {(this.state.is_create_quotation===true) &&
                                          <div style={{marginLeft:'20px'}}>
                                            <div className="form-check form-check-inline">
                                              <input className="form-check-input" type="checkbox" id="deduct_inventory_on_purchase_quotation_created" value="deduct_inventory_on_purchase_quotation_created" checked={(this.state.deduct_inventory_on_purchase_quotation_created)?true:false} onChange={this.handleDeductInventoryOnPQC}/>
                                              <label className="form-check-label chklbl" htmlFor="deduct_inventory_on_purchase_quotation_created">DEDUCT INVENTORY IF PURCHASE QUOTATIONS CREATED</label>
                                            </div>
                                          </div>
                                        }
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                        <label className="form-check-label chklbl" htmlFor="global-discount-type">GLOBAL DISCOUNT TYPE</label>
                                          <div>
                                            <div className="form-check form-check-inline">
                                              <input className="form-check-input" type="radio" name="global-discount" id="global-discount-per" value="per" checked={(this.state.global_discount_type==='per')?true:false} data-target='global-discount-type' onChange={this.handleChangeGolbalDiscountType}/>
                                              <label className="form-check-label" for="global-discount-per">%</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                              <input className="form-check-input" type="radio" name="global-discount" id="global-discount-rs" value="rs" checked={(this.state.global_discount_type==='rs')?true:false}data-target='global-discount-type' onChange={this.handleChangeGolbalDiscountType}/>
                                              <label className="form-check-label" for="global-discount-rs">₹</label>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div>
                                            <div className="form-check form-check-inline">
                                              <input className="form-check-input" type="checkbox" id="is_global_commission" value="is_global_commission" checked={(this.state.is_global_commission)?true:false} onChange={this.handleIsGobalCommissionCheck}/>
                                              <label className="form-check-label chklbl" htmlFor="is_global_commission">GLOBAL COMMISSION</label>
                                            </div>
                                          </div>
                                          {(this.state.is_global_commission===true) &&
                                          <div className="input-group" style={{width:'176px'}}>
                                            <input id="global_commission" type="text" className="form-control no-border-right" value={this.state.global_commission} onChange={this.handleGlobalComission}/>
                                            <div className="input-group-append">
                                              <span className="input-group-text pad-left-none">%</span>
                                            </div>
                                          </div>
                                        }
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <div>
                                            <div className="form-check form-check-inline">
                                              <input className="form-check-input" type="checkbox" id="is_item_commission" value="is_item_commission" checked={(this.state.is_item_commission)?true:false} onChange={this.handleIsItemCommissionCheck}/>
                                              <label className="form-check-label chklbl" htmlFor="is_item_commission">ITEM COMMISSION</label>
                                            </div>
                                          </div>
                                          {(this.state.is_item_commission===true) &&
                                          <div className="input-group" style={{width:'176px'}}>
                                            <input id="item_commission" type="text" className="form-control no-border-right" value={this.state.item_commission} onChange={this.handleItemComission}/>
                                            <div className="input-group-append">
                                              <span className="input-group-text pad-left-none">%</span>
                                            </div>
                                          </div>
                                        }
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                            <button type="button" className="btn btn-outline-success btn-block" onClick={this.handleAddOldBills}>ADD/IMPORT BILLS</button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12">
                            <div className="box-container">
                              <div className="box-head">
                                <label>HIDE FROM CREATE SALE PAGE</label>
                              </div>
                              <div className="box-body" style={{padding:'10px 20px'}}>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-opt-check" type="checkbox" id="shsn" value="shsn" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="shsn" id="lbl-shsn">HSN</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-opt-check" type="checkbox" id="sdesc" value="sdesc" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="sdesc" id="lbl-sdesc">DESCRIPTION</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-opt-check" type="checkbox" id="sunit" value="sunit" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="sunit" id="lbl-sunit">UNIT</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-opt-check" type="checkbox" id="sprice" value="sprice" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="sprice" id="lbl-sprice">PRICE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input hide-opt-check" type="checkbox" id="sdisc" value="sdisc" onChange={this.handleOptChange}/>
                                        <label className="form-check-label chklbl" htmlFor="sdisc" id="lbl-sdisc">DISCOUNT</label>
                                      </div>
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="sales-sp-disc" id="sales-sp-disc-per" value="per" checked={(this.state.sales_sp_disc_type==='per')?true:false} data-target='sales-sp' onChange={this.handleChangeDiscountType}/>
                                        <label className="form-check-label" for="sales-sp-disc-per">%</label>
                                      </div>
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="sales-sp-disc" id="sales-sp-disc-rs" value="rs" checked={(this.state.sales_sp_disc_type==='rs')?true:false}data-target='sales-sp' onChange={this.handleChangeDiscountType}/>
                                        <label className="form-check-label" for="sales-sp-disc-rs">₹</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-opt-check" type="checkbox" id="sgst" value="sgst" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="sgst" id="lbl-sgst">GST %</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-opt-check" type="checkbox" id="staxtype" value="staxtype" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="staxtype" id="lbl-staxtype">TAX TYPE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-opt-check" type="checkbox" id="stotal" value="stotal" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="stotal" id="lbl-stotal">TOTAL</label>
                                      </div>
                                    </div>
                                   
                                    
                                  </div>
                                 
                                </div>
                              </div>
                              <div className="text-right" style={{borderRight:'1px solid #d7d7d7',borderBottom:'1px solid #d7d7d7',borderLeft:'1px solid #d7d7d7',padding:'10px'}}>
                                <button type="button" className="btn btn-success" id="save-sale-opt" onClick={this.handleSaveSaleOption}>Save Settings</button>
                                <button type="button" id="save-sale-opt-btn-spinner" className="hide btn btn-success btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="box-container">
                              <div className="box-head">
                                <label>HIDE FROM CREATE PURCHASE PAGE</label>
                              </div>
                              <div className="box-body" style={{padding:'10px 20px'}}>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-pur-opt-check" type="checkbox" id="pdesc" value="sdesc" onChange={this.handlePurOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="pdesc" id="plbl-pdesc">DESCRIPTION</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-pur-opt-check" type="checkbox" id="punit" value="sunit" onChange={this.handlePurOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="punit" id="plbl-punit">UNIT</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-pur-opt-check" type="checkbox" id="phsn" value="shsn" onChange={this.handlePurOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="phsn" id="plbl-phsn">HSN/SAC</label>
                                      </div>
                                    </div>
                                    
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input hide-pur-opt-check" type="checkbox" id="ppdisc" value="ppdisc" onChange={this.handlePurOptChange}/>
                                        <label className="form-check-label chklbl" htmlFor="ppdisc" id="plbl-ppdisc">PP. DISCOUNT</label>
                                      </div>
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="purchase-pp-disc" id="purchase-pp-disc-per" value="per" checked={(this.state.purchase_pp_disc_type==='per')?true:false}data-target='purchase-pp' onChange={this.handleChangeDiscountType}/>
                                        <label className="form-check-label" for="purchase-pp-disc-per">%</label>
                                      </div>
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="purchase-pp-disc" id="purchase-pp-disc-rs" value="rs" checked={(this.state.purchase_pp_disc_type==='rs')?true:false} data-target='purchase-pp' onChange={this.handleChangeDiscountType}/>
                                        <label className="form-check-label" for="purchase-pp-disc-rs">₹</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-pur-opt-check" type="checkbox" id="ppgst" value="ppgst" onChange={this.handlePurOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="ppgst" id="plbl-ppgst">PP.GST %</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-pur-opt-check" type="checkbox" id="pptaxtype" value="pptaxtype" onChange={this.handlePurOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="pptaxtype" id="plbl-pptaxtype">PP. TAX TYPE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input hide-pur-opt-check" type="checkbox" id="pdisc" value="sdisc" onChange={this.handlePurOptChange}/>
                                        <label className="form-check-label chklbl" htmlFor="pdisc" id="plbl-pdisc">SP. DISCOUNT</label>
                                      </div>
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="purchase-sp-disc" id="purchase-sp-disc-per" value="per"  checked={(this.state.purchase_sp_disc_type==='per')?true:false} data-target='purchase-sp' onChange={this.handleChangeDiscountType}/>
                                        <label className="form-check-label" for="purchase-sp-disc-per">%</label>
                                      </div>
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="purchase-sp-disc" id="purchase-sp-disc-rs" value="rs" checked={(this.state.purchase_sp_disc_type==='rs')?true:false}data-target='purchase-sp' onChange={this.handleChangeDiscountType}/>
                                        <label className="form-check-label" for="purchase-sp-disc-rs">₹</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-pur-opt-check" type="checkbox" id="pgst" value="sgst" onChange={this.handlePurOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="pgst" id="plbl-pgst">SP. GST %</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-pur-opt-check" type="checkbox" id="ptaxtype" value="staxtype" onChange={this.handlePurOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="ptaxtype" id="plbl-ptaxtype">SP. TAX TYPE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-pur-opt-check" type="checkbox" id="pbarcode" value="pbarcode" onChange={this.handlePurOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="pbarcode" id="plbl-pbarcode">BARCODE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-pur-opt-check" type="checkbox" id="paliasbarcode" value="paliasbarcode" onChange={this.handlePurOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="paliasbarcode" id="plbl-paliasbarcode">ALIAS BARCODE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-pur-opt-check" type="checkbox" id="pcategory" value="pcategory" onChange={this.handlePurOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="pcategory" id="plbl-pcategory">CATEGORY</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-pur-opt-check" type="checkbox" id="preorderquantity" value="preorderquantity" onChange={this.handlePurOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="preorderquantity" id="plbl-preorderquantity">RE-ORDER QUANTITY</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-pur-opt-check" type="checkbox" id="ptotal" value="stotal" onChange={this.handlePurOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="ptotal" id="plbl-ptotal">TOTAL</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="text-right" style={{borderRight:'1px solid #d7d7d7',borderBottom:'1px solid #d7d7d7',borderLeft:'1px solid #d7d7d7',padding:'10px'}}>
                                <button type="button" className="btn btn-success" id="save-purchase-opt" onClick={this.handleSavePurchaseOption}>Save Settings</button>
                                <button type="button" id="save-purchase-opt-btn-spinner" className="hide btn btn-success btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                              </div>
                            </div>
                          </div>
                              </div>

                          </div>
                        </div>

                     </div>
                   
                     <div className="main-content">
                        <div className="row">
                        <div className="col-md-12">
                            <div className="box-container">
                              <div className="box-head">
                                <label>HIDE FROM BILLING LIST VIEW</label>
                              </div>
                              <div className="box-body" style={{padding:'10px 20px'}}>
                                <div className="row">
                                  <div className="col-md-12 mrbtm-10 border-bottom">
                                    <h3 style={{padding:'5px',background: '#f1f1f1'}}>BILLING</h3>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-3 border-right">
                                    <h4 className="border-bottom" style={{background: '#f1f1f1',padding: '5px'}}>SALE</h4>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-list-opt" type="checkbox" id="sldate" value="sldate" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="sldate" id="lbl-sldate">DATE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-list-opt" type="checkbox" id="slname" value="slname" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="slname" id="lbl-slname">NAME</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-list-opt" type="checkbox" id="slamount" value="slamount" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="slamount" id="lbl-slamount">AMOUNT</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-list-opt" type="checkbox" id="slpending-amt" value="slpending-amt" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="slpending-amt" id="lbl-slpending-amt">PENDING AMOUNT</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-list-opt" type="checkbox" id="slstatus" value="slstatus" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="slstatus" id="lbl-slstatus">STATUS</label>
                                      </div>
                                    </div>
                                    <hr/>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-list-opt" type="checkbox" id="slagent" value="slagent" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="slagent" id="lbl-slagent">AGENT NAME</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-list-opt" type="checkbox" id="sltransport" value="sltransport" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="sltransport" id="lbl-sltransport">TRANSPORT</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-list-opt" type="checkbox" id="sllrno" value="sllrno" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="sllrno" id="lbl-sllrno">LR NO.</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-list-opt" type="checkbox" id="slsuprefno" value="slsuprefno" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="slsuprefno" id="lbl-slsuprefno">SUPPLIER'S REFRENCE NO</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-list-opt" type="checkbox" id="sldelivery-note" value="sldelivery-note" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="sldelivery-note" id="lbl-sldelivery-note">DELIVERY NOTE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-list-opt" type="checkbox" id="sloth-note-from-sup" value="sloth-note-from-sup" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="sloth-note-from-sup" id="lbl-sloth-note-from-sup">OTHER NOTE FROM SUPPLIER</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-list-opt" type="checkbox" id="slother-ref" value="slother-ref" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="slother-ref" id="lbl-slother-ref">OTHER REFERENCE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-list-opt" type="checkbox" id="slterms-of-delivery" value="slterms-of-delivery" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="slterms-of-delivery" id="lbl-slterms-of-delivery">TERMS OF DELIVERY</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-list-opt" type="checkbox" id="slpieces-in-parcel" value="slpieces-in-parcel" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="slpieces-in-parcel" id="lbl-slpieces-in-parcel">PIECES IN PARCEL</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-list-opt" type="checkbox" id="slno-of-bales" value="slno-of-bales" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="slno-of-bales" id="lbl-slno-of-bales">NO. OF PARCELS/BALES</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-list-opt" type="checkbox" id="slpacked-by" value="slpacked-by" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="slpacked-by" id="lbl-slpacked-by">PACKED BY</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-list-opt" type="checkbox" id="slchecked-by" value="slchecked-by" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="slchecked-by" id="lbl-slchecked-by">CHECKED BY</label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-3 border-right">
                                    <h4 className="border-bottom" style={{background: '#f1f1f1',padding: '5px'}}>PURCHASE</h4>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-list-opt" type="checkbox" id="pldate" value="pldate" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="pldate" id="lbl-pldate">DATE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-list-opt" type="checkbox" id="plname" value="plname" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="plname" id="lbl-plname">NAME</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-list-opt" type="checkbox" id="plamount" value="plamount" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="plamount" id="lbl-plamount">AMOUNT</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-list-opt" type="checkbox" id="plpayment-mode" value="plpayment-mode" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="plpayment-mode" id="lbl-plpayment-mode">MODE OF PAYMENT</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-list-opt" type="checkbox" id="plstatus" value="plstatus" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="plstatus" id="lbl-plstatus">STATUS</label>
                                      </div>
                                    </div>
                                    <hr/>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-list-opt" type="checkbox" id="plinvdate" value="plinvdate" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="plinvdate" id="lbl-plinvdate">INVOICE DATE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-list-opt" type="checkbox" id="plagent" value="plagent" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="plagent" id="lbl-plagent">AGENT NAME</label>
                                      </div>
                                    </div>
                                    
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-list-opt" type="checkbox" id="pltransport" value="pltransport" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="pltransport" id="lbl-pltransport">TRANSPORT</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-list-opt" type="checkbox" id="pllrno" value="pllrno" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="pllrno" id="lbl-pllrno">LR NO.</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-list-opt" type="checkbox" id="plsuprefno" value="plsuprefno" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="plsuprefno" id="lbl-plsuprefno">SUPPLIER'S REFRENCE NO</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-list-opt" type="checkbox" id="pldelivery-note" value="pldelivery-note" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="pldelivery-note" id="lbl-pldelivery-note">DELIVERY NOTE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-list-opt" type="checkbox" id="ploth-note-from-sup" value="ploth-note-from-sup" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="ploth-note-from-sup" id="lbl-ploth-note-from-sup">OTHER NOTE FROM SUPPLIER</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-list-opt" type="checkbox" id="plother-ref" value="plother-ref" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="plother-ref" id="lbl-plother-ref">OTHER REFERENCE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-list-opt" type="checkbox" id="plterms-of-delivery" value="plterms-of-delivery" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="plterms-of-delivery" id="lbl-plterms-of-delivery">TERMS OF DELIVERY</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-list-opt" type="checkbox" id="plpieces-in-parcel" value="plpieces-in-parcel" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="plpieces-in-parcel" id="lbl-plpieces-in-parcel">PIECES IN PARCEL</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-list-opt" type="checkbox" id="plno-of-bales" value="plno-of-bales" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="plno-of-bales" id="lbl-plno-of-bales">NO. OF PARCELS/BALES</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-list-opt" type="checkbox" id="plpacked-by" value="plpacked-by" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="plpacked-by" id="lbl-plpacked-by">PACKED BY</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-list-opt" type="checkbox" id="plchecked-by" value="plchecked-by" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="plchecked-by" id="lbl-plchecked-by">CHECKED BY</label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-3 border-right">
                                    <h4 className="border-bottom" style={{background: '#f1f1f1',padding: '5px'}}>SALES RETURN</h4>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-return-list-opt" type="checkbox" id="srldate" value="srldate" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="srldate" id="lbl-srldate">DATE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-return-list-opt" type="checkbox" id="srlname" value="srlname" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="srlname" id="lbl-srlname">NAME</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-return-list-opt" type="checkbox" id="srlamount" value="srlamount" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="srlamount" id="lbl-srlamount">AMOUNT</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-return-list-opt" type="checkbox" id="srlstatus" value="srlstatus" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="srlstatus" id="lbl-srlstatus">STATUS</label>
                                      </div>
                                    </div>
                                    <hr/>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-return-list-opt" type="checkbox" id="srlagent" value="srlagent" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="srlagent" id="lbl-srlagent">AGENT NAME</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-return-list-opt" type="checkbox" id="srltransport" value="srltransport" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="srltransport" id="lbl-srltransport">TRANSPORT</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-return-list-opt" type="checkbox" id="srllrno" value="srllrno" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="srllrno" id="lbl-srllrno">LR NO.</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-return-list-opt" type="checkbox" id="srlsuprefno" value="srlsuprefno" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="srlsuprefno" id="lbl-srlsuprefno">SUPPLIER'S REFRENCE NO</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-return-list-opt" type="checkbox" id="srldelivery-note" value="srldelivery-note" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="srldelivery-note" id="lbl-srldelivery-note">DELIVERY NOTE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-return-list-opt" type="checkbox" id="srloth-note-from-sup" value="srloth-note-from-sup" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="srloth-note-from-sup" id="lbl-srloth-note-from-sup">OTHER NOTE FROM SUPPLIER</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-return-list-opt" type="checkbox" id="srlother-ref" value="srlother-ref" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="srlother-ref" id="lbl-srlother-ref">OTHER REFERENCE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-return-list-opt" type="checkbox" id="srlterms-of-delivery" value="srlterms-of-delivery" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="srlterms-of-delivery" id="lbl-srlterms-of-delivery">TERMS OF DELIVERY</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-return-list-opt" type="checkbox" id="srlpieces-in-parcel" value="srlpieces-in-parcel" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="srlpieces-in-parcel" id="lbl-srlpieces-in-parcel">PIECES IN PARCEL</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-return-list-opt" type="checkbox" id="srlno-of-bales" value="srlno-of-bales" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="srlno-of-bales" id="lbl-srlno-of-bales">NO. OF PARCELS/BALES</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-return-list-opt" type="checkbox" id="srlpacked-by" value="srlpacked-by" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="srlpacked-by" id="lbl-srlpacked-by">PACKED BY</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-return-list-opt" type="checkbox" id="srlchecked-by" value="srlchecked-by" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="srlchecked-by" id="lbl-srlchecked-by">CHECKED BY</label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-3 border-right">
                                    <h4 className="border-bottom" style={{background: '#f1f1f1',padding: '5px'}}>PURCHASE RETURN</h4>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-return-list-opt" type="checkbox" id="prldate" value="prldate" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="prldate" id="lbl-prldate">DATE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-return-list-opt" type="checkbox" id="prlname" value="prlname" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="prlname" id="lbl-prlname">NAME</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-return-list-opt" type="checkbox" id="prlamount" value="prlamount" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="prlamount" id="lbl-prlamount">AMOUNT</label>
                                      </div>
                                    </div>
                                   
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-return-list-opt" type="checkbox" id="prlstatus" value="prlstatus" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="prlstatus" id="lbl-prlstatus">STATUS</label>
                                      </div>
                                    </div>
                                    <hr/>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-return-list-opt" type="checkbox" id="prlinvdate" value="prlinvdate" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="prlinvdate" id="lbl-prlinvdate">INVOICE DATE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-return-list-opt" type="checkbox" id="prlagent" value="prlagent" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="prlagent" id="lbl-prlagent">AGENT NAME</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-return-list-opt" type="checkbox" id="prltransport" value="prltransport" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="prltransport" id="lbl-prltransport">TRANSPORT</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-return-list-opt" type="checkbox" id="prllrno" value="prllrno" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="prllrno" id="lbl-prllrno">LR NO.</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-return-list-opt" type="checkbox" id="prlsuprefno" value="prlsuprefno" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="prlsuprefno" id="lbl-prlsuprefno">SUPPLIER'S REFRENCE NO</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-return-list-opt" type="checkbox" id="prldelivery-note" value="prldelivery-note" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="prldelivery-note" id="lbl-prldelivery-note">DELIVERY NOTE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-return-list-opt" type="checkbox" id="prloth-note-from-sup" value="prloth-note-from-sup" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="prloth-note-from-sup" id="lbl-prloth-note-from-sup">OTHER NOTE FROM SUPPLIER</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-return-list-opt" type="checkbox" id="prlother-ref" value="prlother-ref" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="prlother-ref" id="lbl-prlother-ref">OTHER REFERENCE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-return-list-opt" type="checkbox" id="prlterms-of-delivery" value="prlterms-of-delivery" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="prlterms-of-delivery" id="lbl-prlterms-of-delivery">TERMS OF DELIVERY</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-return-list-opt" type="checkbox" id="prlpieces-in-parcel" value="prlpieces-in-parcel" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="prlpieces-in-parcel" id="lbl-prlpieces-in-parcel">PIECES IN PARCEL</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-return-list-opt" type="checkbox" id="prlno-of-bales" value="prlno-of-bales" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="prlno-of-bales" id="lbl-prlno-of-bales">NO. OF PARCELS/BALES</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-return-list-opt" type="checkbox" id="prlpacked-by" value="prlpacked-by" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="prlpacked-by" id="lbl-prlpacked-by">PACKED BY</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-return-list-opt" type="checkbox" id="prlchecked-by" value="prlchecked-by" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="prlchecked-by" id="lbl-prlchecked-by">CHECKED BY</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {(this.state.enable_orders===true) &&
                                <>
                                <div className="row mrtop-10  border-top">
                                  <div className="col-md-12 mrbtm-10 border-bottom mrtop-10">
                                    <h3 style={{padding:'5px',background: '#f1f1f1'}}>ORDERS</h3>
                                  </div>
                                </div>
                                <div className="row">
                                <div className="col-md-3 border-right">
                                    <h4 className="border-bottom" style={{background: '#f1f1f1',padding: '5px'}}>SALE</h4>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-list-opt" type="checkbox" id="soldate" value="soldate" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="soldate" id="lbl-soldate">DATE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-list-opt" type="checkbox" id="solname" value="solname" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="solname" id="lbl-solname">NAME</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-list-opt" type="checkbox" id="solamount" value="solamount" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="solamount" id="lbl-solamount">AMOUNT</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-list-opt" type="checkbox" id="solpending-amt" value="solpending-amt" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="solpending-amt" id="lbl-solpending-amt">PENDING AMOUNT</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-list-opt" type="checkbox" id="solstatus" value="solstatus" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="solstatus" id="lbl-solstatus">STATUS</label>
                                      </div>
                                    </div>
                                    <hr/>
                                    
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-list-opt" type="checkbox" id="solagent" value="solagent" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="solagent" id="lbl-solagent">AGENT NAME</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-list-opt" type="checkbox" id="soltransport" value="soltransport" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="soltransport" id="lbl-soltransport">TRANSPORT</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-list-opt" type="checkbox" id="sollrno" value="sollrno" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="sollrno" id="lbl-sollrno">LR NO.</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-list-opt" type="checkbox" id="solsuprefno" value="solsuprefno" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="solsuprefno" id="lbl-solsuprefno">SUPPLIER'S REFRENCE NO</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-list-opt" type="checkbox" id="soldelivery-note" value="soldelivery-note" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="soldelivery-note" id="lbl-soldelivery-note">DELIVERY NOTE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-list-opt" type="checkbox" id="soloth-note-from-sup" value="soloth-note-from-sup" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="soloth-note-from-sup" id="lbl-soloth-note-from-sup">OTHER NOTE FROM SUPPLIER</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-list-opt" type="checkbox" id="solother-ref" value="solother-ref" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="solother-ref" id="lbl-solother-ref">OTHER REFERENCE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-list-opt" type="checkbox" id="solterms-of-delivery" value="solterms-of-delivery" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="solterms-of-delivery" id="lbl-solterms-of-delivery">TERMS OF DELIVERY</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-list-opt" type="checkbox" id="solpieces-in-parcel" value="solpieces-in-parcel" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="solpieces-in-parcel" id="lbl-solpieces-in-parcel">PIECES IN PARCEL</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-list-opt" type="checkbox" id="solno-of-bales" value="solno-of-bales" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="solno-of-bales" id="lbl-solno-of-bales">NO. OF PARCELS/BALES</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-list-opt" type="checkbox" id="solpacked-by" value="solpacked-by" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="solpacked-by" id="lbl-solpacked-by">PACKED BY</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-list-opt" type="checkbox" id="solchecked-by" value="solchecked-by" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="solchecked-by" id="lbl-solchecked-by">CHECKED BY</label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-3 border-right">
                                    <h4 className="border-bottom" style={{background: '#f1f1f1',padding: '5px'}}>PURCHASE</h4>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-list-opt" type="checkbox" id="poldate" value="poldate" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="poldate" id="lbl-poldate">DATE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-list-opt" type="checkbox" id="polname" value="polname" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="polname" id="lbl-polname">NAME</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-list-opt" type="checkbox" id="polamount" value="polamount" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="polamount" id="lbl-polamount">AMOUNT</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-list-opt" type="checkbox" id="polpayment-mode" value="polpayment-mode" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="polpayment-mode" id="lbl-polpayment-mode">MODE OF PAYMENT</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-list-opt" type="checkbox" id="polstatus" value="polstatus" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="polstatus" id="lbl-polstatus">STATUS</label>
                                      </div>
                                    </div>
                                    <hr/>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-list-opt" type="checkbox" id="polinvdate" value="polinvdate" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="polinvdate" id="lbl-polinvdate">INVOICE DATE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-list-opt" type="checkbox" id="polagent" value="polagent" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="polagent" id="lbl-polagent">AGENT NAME</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-list-opt" type="checkbox" id="poltransport" value="poltransport" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="poltransport" id="lbl-poltransport">TRANSPORT</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-list-opt" type="checkbox" id="pollrno" value="pollrno" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="pollrno" id="lbl-pollrno">LR NO.</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-list-opt" type="checkbox" id="polsuprefno" value="polsuprefno" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="polsuprefno" id="lbl-polsuprefno">SUPPLIER'S REFRENCE NO</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-list-opt" type="checkbox" id="poldelivery-note" value="poldelivery-note" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="poldelivery-note" id="lbl-poldelivery-note">DELIVERY NOTE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-list-opt" type="checkbox" id="poloth-note-from-sup" value="poloth-note-from-sup" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="poloth-note-from-sup" id="lbl-poloth-note-from-sup">OTHER NOTE FROM SUPPLIER</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-list-opt" type="checkbox" id="polother-ref" value="polother-ref" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="polother-ref" id="lbl-polother-ref">OTHER REFERENCE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-list-opt" type="checkbox" id="polterms-of-delivery" value="polterms-of-delivery" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="polterms-of-delivery" id="lbl-polterms-of-delivery">TERMS OF DELIVERY</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-list-opt" type="checkbox" id="polpieces-in-parcel" value="polpieces-in-parcel" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="polpieces-in-parcel" id="lbl-polpieces-in-parcel">PIECES IN PARCEL</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-list-opt" type="checkbox" id="polno-of-bales" value="polno-of-bales" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="polno-of-bales" id="lbl-polno-of-bales">NO. OF PARCELS/BALES</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-list-opt" type="checkbox" id="polpacked-by" value="polpacked-by" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="polpacked-by" id="lbl-polpacked-by">PACKED BY</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-list-opt" type="checkbox" id="polchecked-by" value="polchecked-by" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="polchecked-by" id="lbl-polchecked-by">CHECKED BY</label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-3 border-right">
                                    <h4 className="border-bottom" style={{background: '#f1f1f1',padding: '5px'}}>SALES RETURN</h4>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-return-list-opt" type="checkbox" id="sorldate" value="sorldate" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="sorldate" id="lbl-sorldate">DATE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-return-list-opt" type="checkbox" id="sorlname" value="sorlname" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="sorlname" id="lbl-sorlname">NAME</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-return-list-opt" type="checkbox" id="sorlamount" value="sorlamount" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="sorlamount" id="lbl-sorlamount">AMOUNT</label>
                                      </div>
                                    </div>
                                    
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-return-list-opt" type="checkbox" id="sorlstatus" value="sorlstatus" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="sorlstatus" id="lbl-sorlstatus">STATUS</label>
                                      </div>
                                    </div>
                                    <hr/>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-return-list-opt" type="checkbox" id="sorlagent" value="sorlagent" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="sorlagent" id="lbl-sorlagent">AGENT NAME</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-return-list-opt" type="checkbox" id="sorltransport" value="sorltransport" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="sorltransport" id="lbl-sorltransport">TRANSPORT</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-return-list-opt" type="checkbox" id="sorllrno" value="sorllrno" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="sorllrno" id="lbl-sorllrno">LR NO.</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-return-list-opt" type="checkbox" id="sorlsuprefno" value="sorlsuprefno" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="sorlsuprefno" id="lbl-sorlsuprefno">SUPPLIER'S REFRENCE NO</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-return-list-opt" type="checkbox" id="sorldelivery-note" value="sorldelivery-note" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="sorldelivery-note" id="lbl-sorldelivery-note">DELIVERY NOTE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-return-list-opt" type="checkbox" id="sorloth-note-from-sup" value="sorloth-note-from-sup" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="sorloth-note-from-sup" id="lbl-sorloth-note-from-sup">OTHER NOTE FROM SUPPLIER</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-return-list-opt" type="checkbox" id="sorlother-ref" value="sorlother-ref" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="sorlother-ref" id="lbl-sorlother-ref">OTHER REFERENCE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-return-list-opt" type="checkbox" id="sorlterms-of-delivery" value="sorlterms-of-delivery" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="sorlterms-of-delivery" id="lbl-sorlterms-of-delivery">TERMS OF DELIVERY</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-return-list-opt" type="checkbox" id="sorlpieces-in-parcel" value="sorlpieces-in-parcel" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="sorlpieces-in-parcel" id="lbl-sorlpieces-in-parcel">PIECES IN PARCEL</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-return-list-opt" type="checkbox" id="sorlno-of-bales" value="sorlno-of-bales" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="sorlno-of-bales" id="lbl-sorlno-of-bales">NO. OF PARCELS/BALES</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-return-list-opt" type="checkbox" id="sorlpacked-by" value="sorlpacked-by" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="sorlpacked-by" id="lbl-sorlpacked-by">PACKED BY</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-sales-order-return-list-opt" type="checkbox" id="sorlchecked-by" value="sorlchecked-by" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="sorlchecked-by" id="lbl-sorlchecked-by">CHECKED BY</label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-3 border-right">
                                    <h4 className="border-bottom" style={{background: '#f1f1f1',padding: '5px'}}>PURCHASE RETURN</h4>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-return-list-opt" type="checkbox" id="porldate" value="porldate" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="porldate" id="lbl-porldate">DATE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-return-list-opt" type="checkbox" id="porlname" value="porlname" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="porlname" id="lbl-porlname">NAME</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-return-list-opt" type="checkbox" id="porlamount" value="porlamount" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="porlamount" id="lbl-porlamount">AMOUNT</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-return-list-opt" type="checkbox" id="porlstatus" value="porlstatus" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="porlstatus" id="lbl-porlstatus">STATUS</label>
                                      </div>
                                    </div>
                                    <hr/>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-return-list-opt" type="checkbox" id="porlinvdate" value="porlinvdate" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="porlinvdate" id="lbl-porlinvdate">INVOICE DATE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-return-list-opt" type="checkbox" id="porlagent" value="porlagent" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="porlagent" id="lbl-porlagent">AGENT NAME</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-return-list-opt" type="checkbox" id="porltransport" value="porltransport" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="porltransport" id="lbl-porltransport">TRANSPORT</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-return-list-opt" type="checkbox" id="porllrno" value="porllrno" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="porllrno" id="lbl-porllrno">LR NO.</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-return-list-opt" type="checkbox" id="porlsuprefno" value="porlsuprefno" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="porlsuprefno" id="lbl-porlsuprefno">SUPPLIER'S REFRENCE NO</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-return-list-opt" type="checkbox" id="porldelivery-note" value="porldelivery-note" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="porldelivery-note" id="lbl-porldelivery-note">DELIVERY NOTE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-return-list-opt" type="checkbox" id="porloth-note-from-sup" value="porloth-note-from-sup" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="porloth-note-from-sup" id="lbl-porloth-note-from-sup">OTHER NOTE FROM SUPPLIER</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-return-list-opt" type="checkbox" id="porlother-ref" value="porlother-ref" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="porlother-ref" id="lbl-porlother-ref">OTHER REFERENCE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-return-list-opt" type="checkbox" id="porlterms-of-delivery" value="porlterms-of-delivery" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="porlterms-of-delivery" id="lbl-porlterms-of-delivery">TERMS OF DELIVERY</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-return-list-opt" type="checkbox" id="porlpieces-in-parcel" value="porlpieces-in-parcel" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="porlpieces-in-parcel" id="lbl-porlpieces-in-parcel">PIECES IN PARCEL</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-return-list-opt" type="checkbox" id="porlno-of-bales" value="porlno-of-bales" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="porlno-of-bales" id="lbl-porlno-of-bales">NO. OF PARCELS/BALES</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-return-list-opt" type="checkbox" id="porlpacked-by" value="porlpacked-by" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="porlpacked-by" id="lbl-porlpacked-by">PACKED BY</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input hide-purchase-order-return-list-opt" type="checkbox" id="porlchecked-by" value="porlchecked-by" onChange={this.handleOptChange}/>
                                      <label className="form-check-label chklbl" htmlFor="porlchecked-by" id="lbl-porlchecked-by">CHECKED BY</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                </>
                                }
                              </div>
                              <div className="text-right" style={{borderRight:'1px solid #d7d7d7',borderBottom:'1px solid #d7d7d7',borderLeft:'1px solid #d7d7d7',padding:'10px'}}>
                                <button type="button" className="btn btn-success" id="save-bl-opt" onClick={this.handleSaveBillListOption}>Save Settings</button>
                                <button type="button" id="save-bl-opt-btn-spinner" className="hide btn btn-success btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="box-container">
                              <div className="box-head">
                                <label>COMMISSION RANGE SETTINGS</label>
                              </div>
                              <div className="box-body">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>
                                        From Value
                                      </th>
                                      <th>
                                        To Value
                                      </th>
                                      <th className="text-right">
                                        Comission
                                      </th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  
                                  {(this.state.commission_list.map((value,index)=>{
                                    return (
                                      <tr>
                                          <td><input type="text" className="form-control" placeholder="Enter from value" value={value.range1} data-target='range1'  onChange={this.handleChangeCommissionValue} data-id={index}/></td>
                                          <td><input type="text" className="form-control" placeholder="Enter to value" value={value.range2} data-target='range2' onChange={this.handleChangeCommissionValue}data-id={index}/></td>
                                          <td><>
                                            <input type="text" className="form-control w-100px pull-right" data-target='value' value={value.value} onChange={this.handleChangeCommissionValue}data-id={index}/>
                                            <div className="form-group round-blk">
                                              <div className="form-check">
                                                <input className="form-check-input" type="radio" name={"commission_sym_"+index} id={"com-rs-"+index} value="rs" checked={(value.sym==='rs')?true:false} data-target="sym" onChange={this.handleChangeCommissionValue} data-id={index}/>
                                                <label className="form-check-label rs-sym" htmlFor={"com-rs-"+index}>
                                                  ₹
                                                </label>
                                              </div>
                                              <div className="form-check">
                                                <input className="form-check-input" type="radio" name={"commission_sym_"+index} value="per" id={"com-per-"+index} checked={(value.sym==='per')?true:false} data-target="sym" onChange={this.handleChangeCommissionValue} data-id={index}/>
                                                <label className="form-check-label" htmlFor={"com-per-"+index}>
                                                  %
                                                </label>
                                              </div>
                                            </div>
                                          </></td>
                                          <td>
                                            {(index>0) &&
                                              <FontAwesomeIcon icon={faTimes} className={"rm-item pull-right fa-icon"} onClick={this.handleRemoveCommissionList} data-id={index}/>
                                            }
                                          </td>
                                      </tr>
                                      )
                                    }))}
                                    
                                  </tbody>
                                </table>

                                <div className="text-right" style={{paddingRight:'18px'}}>
                                  <label style={{color:'#1e7e34',cursor:'pointer'}} id="add_more_terms" onClick={this.handleAddMoreCommissionRange}>Add More</label>
                                </div>
                              </div>
                              <div className="text-right" style={{borderRight:'1px solid #d7d7d7',borderBottom:'1px solid #d7d7d7',borderLeft:'1px solid #d7d7d7',padding:'10px'}}>
                                <button type="button" className="btn btn-success" id="save-commission-list" onClick={this.handleSaveCommissionRange}>Save</button>
                                <button type="button" id="save-commission-list-btn-spinner" className="hide btn btn-success btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                              </div>
                            </div>
                          </div>  
                        <div className="col-md-12">
                              <div className="box-container">
                                  <div className="box-head">
                                    <label>ENCRYPT PURCHASE PRICE</label>
                                  </div>
                                  <div className="box-body">
                                      <table className="table">
                                        <tbody className="text-center">
                                         
                                          <tr>
                                          <td>
                                            <label>PREFIX</label>
                                            <input type="text" className="form-control enc-data" data-id="enc-pp-prefix" defaultValue={(this.state.encrypted_purchase_price && this.state.encrypted_purchase_price.value && this.state.encrypted_purchase_price.value['enc-pp-prefix']!==undefined)? this.state.encrypted_purchase_price.value['enc-pp-prefix']:''}/>
                                          </td>
                                          {(this.pp_enc_num.length > 0) ? this.pp_enc_num.map( (value, index) => {
                                             return (
                                              <td>
                                                <label>{value}</label>
                                                <input type="text" className="form-control enc-data" data-id={value} defaultValue={(this.state.encrypted_purchase_price && this.state.encrypted_purchase_price.value && this.state.encrypted_purchase_price.value[value]!==undefined)? this.state.encrypted_purchase_price.value[value]:''}/>
                                              </td>
                                              )
                                           }) : ''}
                                           <td>
                                              <label>SUFFIX</label>
                                              <input type="text" className="form-control  enc-data" data-id="enc-pp-sufix" defaultValue={(this.state.encrypted_purchase_price && this.state.encrypted_purchase_price.value && this.state.encrypted_purchase_price.value['enc-pp-sufix']!==undefined)? this.state.encrypted_purchase_price.value['enc-pp-sufix']:''}/>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                  </div>
                                  <div className="text-right" style={{borderRight:'1px solid #d7d7d7',borderBottom:'1px solid #d7d7d7',borderLeft:'1px solid #d7d7d7',padding:'10px'}}>
                                  <button type="button" className="btn btn-success" id="save-pp-enc" onClick={this.handleSavePPEncrypt}>Save Changes</button>
                                  <button type="button" id="save-pp-enc-btn-spinner" className="hide btn btn-success btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/>
                                  </button>
                                  </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="box-container">
                                  <div className="box-head">
                                    <label>ENCRYPT SELLING PRICE</label>
                                  </div>
                                  <div className="box-body">
                                      <table className="table">
                                        <tbody className="text-center">
                                         
                                          <tr>
                                          <td>
                                            <label>PREFIX</label>
                                            <input type="text" className="form-control enc-sp-data" data-id="enc-sp-prefix" defaultValue={(this.state.encrypted_selling_price && this.state.encrypted_selling_price.value && this.state.encrypted_selling_price.value['enc-sp-prefix']!==undefined)? this.state.encrypted_selling_price.value['enc-sp-prefix']:''}/>
                                          </td>
                                          {(this.sp_enc_num.length > 0) ? this.sp_enc_num.map( (value, index) => {
                                             return (
                                              <td>
                                                <label>{value}</label>
                                                <input type="text" className="form-control enc-sp-data" data-id={value} defaultValue={(this.state.encrypted_selling_price && this.state.encrypted_selling_price.value && this.state.encrypted_selling_price.value[value]!==undefined)? this.state.encrypted_selling_price.value[value]:''}/>
                                              </td>
                                              )
                                           }) : ''}
                                           <td>
                                              <label>SUFFIX</label>
                                              <input type="text" className="form-control  enc-sp-data" data-id="enc-sp-sufix" defaultValue={(this.state.encrypted_selling_price && this.state.encrypted_selling_price.value && this.state.encrypted_selling_price.value['enc-sp-sufix']!==undefined)? this.state.encrypted_selling_price.value['enc-sp-sufix']:''}/>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                  </div>
                                  <div className="text-right" style={{borderRight:'1px solid #d7d7d7',borderBottom:'1px solid #d7d7d7',borderLeft:'1px solid #d7d7d7',padding:'10px'}}>
                                  <button type="button" className="btn btn-success" id="save-sp-enc" onClick={this.handleSaveSPEncrypt}>Save Changes</button>
                                  <button type="button" id="save-sp-enc-btn-spinner" className="hide btn btn-success btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/>
                                  </button>
                                  </div>
                              </div>
                            </div>
                          <div className="col-md-12">
                            <div className="box-container">
                              <div className="box-head">
                                <label>TERMS & CONDITIONS</label>
                              </div>
                              <div className="box-body">
                                <ul>
                                  {this.state.more_rows}
                                </ul>
                                <div className="text-right" style={{paddingRight:'18px'}}>
                                  <label style={{color:'#1e7e34',cursor:'pointer'}} id="add_more_terms" onClick={this.handleAddMoreTC}>Add More</label>
                                </div>
                              </div>
                              <div className="text-right" style={{borderRight:'1px solid #d7d7d7',borderBottom:'1px solid #d7d7d7',borderLeft:'1px solid #d7d7d7',padding:'10px'}}>
                                <button type="button" className="btn btn-success" id="save-terms" onClick={this.handleSaveTC}>Save T&C</button>
                                <button type="button" id="save-terms-btn-spinner" className="hide btn btn-success btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="box-container">
                              <div className="box-head">
                                <label>HEADER SETTINGS</label>
                              </div>
                              <div className="box-body">
                              <Editor
                                editorState={this.state.editorStateHeader}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={this.onEditorHeaderStateChange}
                              />        
                              </div>
                              <div className="text-right" style={{borderRight:'1px solid #d7d7d7',borderBottom:'1px solid #d7d7d7',borderLeft:'1px solid #d7d7d7',padding:'10px'}}>
                                <button type="button" className="btn btn-success" id="save-header-setting" onClick={this.handeSaveEditorContent} data-id="header_content">Save</button>
                                <button type="button" id="save-header-setting-btn-spinner" className="hide btn btn-success btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="box-container">
                              <div className="box-head">
                                <label>FOOTER SETTINGS</label>
                              </div>
                              <div className="box-body">
                              <Editor
                                editorState={this.state.editorStateFooter}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={this.onEditorFooterStateChange}
                              />        
                              </div>
                              <div className="text-right" style={{borderRight:'1px solid #d7d7d7',borderBottom:'1px solid #d7d7d7',borderLeft:'1px solid #d7d7d7',padding:'10px'}}>
                                <button type="button" className="btn btn-success" id="save-footer-setting" onClick={this.handeSaveEditorContent} data-id="footer_content">Save</button>
                                <button type="button" id="save-footer-setting-btn-spinner" className="hide btn btn-success btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                              </div>
                            </div>
                          </div>
                        </div>

                     </div>
                     </>
                    }
                    {(this.state.is_add_old_bills===true) &&
                      <AddOldBills handleCloseOldBill = {this.handleCloseOldBill.bind()}/>
                    }
                  </div>
                </div>
              </div>
              <ModalBox id="add_contact_type" title="ADD CONTACT TYPE" data_id="Contact Type"/>
              
              <ModalBox id="add_business_type" title="ADD BUSINESS TYPE"  data_id="Business Type"/>
              <ModalBox id="add_category" title="ADD CATEGORY"  data_id="Category"/>
              <ModalBox id="add_unit" title="ADD UNIT"  data_id="Unit"/>
              <ModalBox id="delete_confirm" title="Delete"  data_id="Delete"/>
              {(this.state.is_show_custom_desing_print===true) &&
                <PrintTemplateDesing />
              } 
            </div>
        );

    }
}