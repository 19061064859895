let user_info = JSON.parse(localStorage.getItem('user'));
let admin_info = JSON.parse(localStorage.getItem('HFnmKMOHHO'));

let a_info = '';
if(admin_info!==null){
	a_info = admin_info.response;
}

let u_info = '';
let access_level = '';
if(user_info!==null){
	u_info =user_info.response;
	access_level = user_info.access_level;
}

let states = ['Andaman and Nicobar Islands-35', 'Andhra Pradesh (New)-37', 'Arunachal Pradesh-12', 'Assam-18', 'Bihar-10', 'Chandigarh-04', 'Chattisgarh-22', 'Dadra and Nagar Haveli-26', 'Daman and Diu-25', 'Delhi-07', 'Goa-30', 'Gujarat-24', 'Haryana-06', 'Himachal Pradesh-02', 'Jammu and Kashmir-01', 'Jharkhand-20', 'Karnataka-29', 'Kerala-32', 'Lakshadweep Islands-31', 'Madhya Pradesh-23', 'Maharashtra-27', 'Manipur-14', 'Meghalaya-17', 'Mizoram-15', 'Nagaland-13', 'Odisha-21', 'Pondicherry-34', 'Punjab-03', 'Rajasthan-08', 'Sikkim-11', 'Tamil Nadu-33', 'Telangana-36', 'Tripura-16', 'Uttar Pradesh-09', 'Uttarakhand-05', 'West Bengal-19'];

let gst_options = [];
gst_options.push({key:0,value:'GST 0%'});
gst_options.push({key:5,value:'GST 5%'});
gst_options.push({key:10,value:'GST 10%'});
gst_options.push({key:12,value:'GST 12%'});
gst_options.push({key:18,value:'GST 18%'});
gst_options.push({key:25,value:'GST 25%'});

let erp_lib = {
				'enc_key' : '7kzDGUfUU4pM8UxY',
				//'app_api' : 'http://45.32.126.205/api', 
				'app_api' : 'https://app.business-booster.net/app/api',
				'user_info' : u_info,
				'admin_info' : a_info,
				'access_level' : access_level,
				'states' : states,
				'gst_options' : gst_options,
			  };
module.exports = erp_lib;