import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import ContactTypes from "./contact-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactWhatsapp from 'react-whatsapp';
import ProductCategory from "./product-category";
import { faPlus,faSpinner,faPrint,faDownload,faExclamationTriangle  } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import utils from '../utils.js';
import AccountTypes from "./account-types";
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let db;
if(user_info){
  let version = localStorage.getItem('IdbVersion');
  version = parseInt(version);
  let dbReq = window.indexedDB.open('BaazarERP', version);
  dbReq.onupgradeneeded = function(event) {
    db = event.target.result;
  }
  dbReq.onsuccess = function(event) {
    db = event.target.result;
  }
}

export default class ModalBox extends Component {
     constructor() {
      super();
      this.state = {
          inventory_images : [],
          save_error:'',
          einv_error:false,
          einv_error_list : [],
          transfer_list : [],
      }
      this.delete_images = [];
      this.product_images = [];
      this.closeModel = this.closeModel.bind(this);
      this.handleOK = this.handleOK.bind(this);
      this.saveChanges = this.saveChanges.bind(this);
      this.saveContact = this.saveContact.bind(this);
      this.deleteRecord = this.deleteRecord.bind(this);
      this.handleNavImgItem = this.handleNavImgItem.bind(this);
      this.handleUploadImg = this.handleUploadImg.bind(this);
      this.updateProductImage = this.updateProductImage.bind(this);
      this.sendVerificationLink = this.sendVerificationLink.bind(this);
      this.onFileChange = this.onFileChange.bind(this);
      this.handleDeleteImage = this.handleDeleteImage.bind(this);
      this.handleReturnBill = this.handleReturnBill.bind(this);
      this.handleCloseVerificationLink = this.handleCloseVerificationLink.bind(this);
      this.handleUserCreditBuffer = this.handleUserCreditBuffer.bind(this);
      this.handlePrint = this.handlePrint.bind(this);
      this.handleRefreshPage = this.handleRefreshPage.bind(this);
      this.isMobile = utils.isMobile.bind(this);
    }

    componentDidMount(){
      this.setState({transfer_list:this.props.transfer_list})
      document.addEventListener('keydown', this.handleTriggerEvent);
    }

    handleTriggerEvent = (e) =>{
        if(document.getElementById('add_category')){
          let is_save_category = document.getElementById("add_category").style.display;
          if(e.which===13){
            if(is_save_category==="block"){
              //document.getElementById("save-cat-btn").click();
            }
          }
          if(e.which===27){
            if(is_save_category==="block"){
              document.getElementById("close-save-cat-but").click();
            }
          }
        }
    }

    handleRefreshPage(e){
      this.handleShareThroughEmail(e);
      setTimeout(() => {
          window.location.reload();
      }, 100);
    }

    handleShareThroughEmail = (e) =>{
      let id = e.currentTarget.id;
      /* let share_email = document.getElementById('share-email').checked;
      if(share_email===true){
        let id = localStorage.getItem('share_id');
        let sharable_link = localStorage.getItem('sharable_link');
        if(id!=="" && id!==null){
          let body = {  
              id : id,
              sharable_link : sharable_link,
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
          }
          let api = app_api+'/share_bill_through_email';
          axios.post(api, body)
            .then(function (response) {
             
          }).catch(function(res){
           
          })
        }
      } */
      if(id=='no-print'){
        setTimeout(() => {
          let screen = localStorage.getItem('screen');
          if(screen==='new_order'){
            window.location.href = '/orders';
            localStorage.removeItem('screen');
          }else{
            window.location.href = '/billings';
          }
        }, 100);
      }
    }

    handlePrint(e){
      this.handleShareThroughEmail(e);
      document.getElementById("handlePrint").click();
      var element = document.getElementById('print_bill');
      element.classList.add("fade");
      element.style.display="none"; 
    }

    handleUserCreditBuffer(e){
      if(document.getElementById("add-item-to-list")){
        localStorage.setItem('use_cl_buff','yes');
        localStorage.setItem('use_buff_amount','yes');
        document.getElementById("add-item-to-list").click();
        localStorage.removeItem('use_cl_buff');
      }
      document.getElementById("buffer-amount-msg").innerHTML = '';
      document.getElementById("use-buffer-amount").classList.add('hide');
      var element = document.getElementById(this.props.id);
      element.classList.add("fade");
      element.style.display="none"; 
    }
    handleCloseVerificationLink(e){
        window.location.reload();
    }

    handleReturnBill(e){
      this.props.handleReturnBill(e);
    }

    saveContact(event){
     
        let name = document.getElementById('new_name').value;
        if(name===""){
          document.getElementById('new_name').style.border='1px solid red';
          return false;
        }
        let id = event.currentTarget.id;
        document.getElementById(id).classList.add("hide");
        document.getElementById(id+'-btn-spinner').classList.remove("hide");
        var e = document.getElementById("contact-type-select");
        var contact_type_name = e.options[e.selectedIndex].text;
        let type = e.value;
        let email = document.getElementById('new_email').value;
        let phone = document.getElementById('new_phone').value;
        let discount = document.getElementById('new_discount').value;
        
        localStorage.setItem('phone_1',phone);


        let info_body = {
                type : type,
                name : name,
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
                email : email,
                phone_1 : phone,
                phone_2 : '',
                address_1 : '',
                address_2 : '',
                city : '',
                state : '',
                pin : '',
                gst_number : '',
                pan_no : '',
                payment_mode : '',
                bank_name : '',
                account_no : '',
                ifsc_code : '',
                credit_limit : '',
                bank_address : '',
                credit_days : '',
                bank_city : '',
                notes : '',
                contact_type : contact_type_name,
                discount : discount
            };

        if(document.getElementById("phone_1")){
          document.getElementById("phone_1").value = phone;
        }
        document.getElementById("contact_add_type").value="onthefly";
        let api = app_api+'/create_contact';
        axios.post(api, info_body)
          .then(function (response) {
            let data = response.data;

            if(data.success === 1){
             let resp = response.data.response;
             let insert_id = parseInt(resp.insert_id);
            if(document.getElementById("contact_box")){
              document.getElementById("contact_box").value = info_body.name;
            }
            if(document.getElementById("customer-dropdown")){
              document.getElementById("customer-dropdown").value = insert_id;
            }
             let disc = 0;
             if(info_body.discount!==""){
              disc = info_body.discount;
             }
             localStorage.setItem('discount',disc);
             localStorage.setItem('state',"");
             localStorage.setItem('payment_mode','all');

            document.getElementById('new_name').value = "";
            document.getElementById('new_email').value = "";
            document.getElementById('new_phone').value = "";
            document.getElementById('new_discount').value = 0;

            document.getElementById("close-contact").click();
            if(document.getElementById("contact_box")){
              document.getElementById("contact_box").focus();
            }
            document.getElementById(id).classList.remove("hide");
            document.getElementById(id+'-btn-spinner').classList.add("hide");
            try{
             let transaction = db.transaction("contacts", "readwrite");
             let contacts = transaction.objectStore("contacts");
             let contact = CryptoJS.AES.encrypt(JSON.stringify(info_body), enc_key).toString();
             let contact_type = info_body.type;
             let contact_data = {};
             contact_data['id'] = parseInt(insert_id);
             contact_data['type'] = parseInt(contact_type);
             contact_data['contact_type'] = contact_type_name;
             contact_data['data'] = contact;
             contacts.add(contact_data);
           }catch(error){
            //console.log(error);
           }
         }else{
            document.getElementById(id).classList.remove("hide");
              document.getElementById(id+'-btn-spinner').classList.add("hide");
            document.getElementById("name-exist").innerHTML = data.message;
         }
        }.bind(this)).catch(function(res){
            if(res.response){
              if(res.response.data){
                  
              }
            }
        })
    
    
    }

    handleDeleteImage(e){
      let thisid = e.currentTarget.id;
      let index = e.currentTarget.getAttribute('data-id');
      let product_id = e.currentTarget.getAttribute('data-target');
      delete this.state.inventory_images[product_id][index];
      this.setState({inventory_images: this.state.inventory_images});
      this.delete_images.push(thisid);

    }

    updateProductImage(e){
      let thisid = e.currentTarget.id;
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
      let img_cls = document.getElementsByClassName("upload-img-row");
      let photoData = new FormData();
      let inventory_ids = [];
      for(let i=0;i<img_cls.length;i++){
          let id = img_cls[i].getAttribute('data-id');
          let img_elem = document.getElementById("upload-inv-img-"+id);
          let inventory_id = img_elem.getAttribute('data-id');
          if(inventory_ids.indexOf(inventory_id)<0){
            inventory_ids.push(inventory_id);
          }

          let upload_photo = img_elem.files[0];
          if(upload_photo!==undefined){
            photoData.append(`photo[${inventory_id}][${i}]`, upload_photo);
        
          }
      }
      photoData.append('store_name',user_info.store_name);
      photoData.append('branch_id',user_info.login_id);
      photoData.append('user_id',user_info.user_id);
      photoData.append('uuid',utils.uuid());
      photoData.append('role_id',user_info.access_level);
      photoData.append('token',user_info.token);
      photoData.append('_schema',user_info._schema);
      photoData.append('inventory_ids',inventory_ids);
      photoData.append('deleted_images',this.delete_images);
    
      let api = app_api+'/upload_inventory_image';
      axios.post(api, photoData)
        .then(function (response) {
          document.getElementById(thisid).classList.remove("hide");
          document.getElementById(thisid+'-btn-spinner').classList.add("hide");
          var element = document.getElementById(this.props.id);
          element.classList.add("fade");
          element.style.display="none"; 
          window.location.reload();
      }.bind(this)).catch(function(res){
          
      })
    }

    onFileChange(e){
      let inventory_id = document.getElementById("current-inventory-id").value;
      let id = document.getElementById("current-id").value;
      let output = URL.createObjectURL(e.target.files[0]);
      let img = '<img class="pro-up-img" src='+output+' />';
      document.getElementById(id).innerHTML = img;
    }

    handleUploadImg(e){
      let img_id = e.currentTarget.getAttribute('data-target');
      if(img_id!==null){
        this.delete_images.push(img_id);
      }
      let id = e.currentTarget.id;
      let data_id = e.currentTarget.getAttribute('data-id');
      document.getElementById("upload-inv-img-"+data_id).click();
      document.getElementById("current-id").value = id;
    }

    handleNavImgItem(e){
        let thisid = e.currentTarget.id;
        let classes = document.querySelectorAll('.pro-imgs-menu');
        for(let i=0;i<classes.length;i++){
            classes[i].classList.remove('active');
        }
        e.currentTarget.classList.add('active');

        let cls = document.querySelectorAll('.pimg-continer');
        for(let i=0;i<cls.length;i++){
            cls[i].classList.add('hide');
        }
        document.getElementById("pimg-continer-"+thisid).classList.remove('hide');
        
        document.getElementById("current-inventory-id").value = thisid;
    }
    sendVerificationLink(e){
      let thisid = e.currentTarget.id;
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
      let id = document.getElementById("verify-id").value;
      let email = document.getElementById("verify-email").value;
      let username = document.getElementById("verify-username").value;
      if(email!==""){
        let body = {  
            id : id,
            email : email,
            username : username,
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
         }
        let api = app_api+'/resend_verify_link';
        axios.post(api, body)
          .then(function (response) {
            document.getElementById(thisid+'-btn-spinner').classList.add("hide");
            document.getElementById('resend-vl-hidden').classList.remove("hide");
            document.getElementById('verification-success-msg').classList.remove("hide");
            let hideCls = document.querySelectorAll(".success-resend");
            for(let i=0;i<hideCls.length;i++){
              hideCls[i].classList.add('hide');
            }
        }).catch(function(res){
            if(res.response){
                if(res.response.data){
                    
                }
            }
        })
      }
    }
    deleteRecord(event){
        let deleteValue = document.getElementById("deleteValue").value;
        let deleteIndex = document.getElementById("deleteIndex").value;
        let deleteTarget = document.getElementById("deleteTarget").value;
        let deleteAction = document.getElementById("deleteAction").value;
        let deleteScreen = document.getElementById("deleteScreen").value;
        let is_without_effecting_inventory = false;
        let e_invoice_cancel_reason = false;
        if(document.getElementById("without-effecting-inventory")){
          is_without_effecting_inventory = document.getElementById("without-effecting-inventory").checked;
        }
        if(document.getElementById("e_invoice_cancel_reason")){
          e_invoice_cancel_reason = document.getElementById("e_invoice_cancel_reason").value;
        }
        if(deleteValue=='expense-trash' || deleteValue=='expense' || deleteValue==='billing-trash' || deleteValue==='billing' || deleteValue==='hold_bill'){
          let element = document.getElementById("billing-row-"+deleteIndex);
          let col_span = element.getAttribute('data-id');
          let msg = 'Deletion Successful';
          if(deleteAction==='Cancel' || deleteAction==='cancel'){
            msg = 'Cancelation Successful';
          }else if(deleteAction==='close'){
            msg = 'Closed Successful';
          }
          let str = '<td class="row-trash" colspan="'+col_span+'">'+msg+'</td>';
          element.innerHTML = str;
        }else if(deleteValue=='contact' || deleteValue=='payment' || deleteValue=='inventory' || deleteValue=='variation'){
          let element = document.getElementById("contact-row-"+deleteIndex);
          if(deleteValue==='payment'){
             element = document.getElementById("billing-row-"+deleteIndex);
          }
          let col_span = 6;
          if(deleteValue==='inventory'){
            col_span = 8;
          }else if(deleteValue==='variation'){
            col_span = 9;
          }else if(deleteValue==='payment'){
            col_span = 7;
          }
          if(element){
            let str = '<td class="row-trash" colspan="'+col_span+'">Deletion Successful</td>';
            element.innerHTML = str;
          }
        }else if(deleteValue!=='transport' && deleteValue!=='account_type' && deleteValue!=='expense-view' && deleteValue!=='expense-view-trash' && deleteValue!=='debit_credit_note' && deleteValue!=='product' && deleteValue!=='variant' && deleteTarget!=='delete_barcode_template' && deleteTarget!=='delete_users' && deleteTarget!=='delete_company' && deleteValue!=='user_role' && deleteValue!=='billing_view'&& deleteValue!=='hold_view' && deleteValue!=='billing' && deleteValue!=='billing-trash' && deleteValue!=='payment-view' && deleteValue!=='expense_type'  && deleteValue!=='stock_transfer'){
          document.getElementById(deleteTarget+'_'+deleteIndex).remove();
        }

        deleteIndex = parseInt(deleteIndex);
        if(deleteTarget==='delete_contact'){
          try{
            var deltrans = db.transaction("contacts", "readwrite");
            var objectStore = deltrans.objectStore("contacts");
            var objectStoreRequest = objectStore.delete(deleteIndex);
          }catch(error){}
        }
        
        
        let body = {  
            id : deleteIndex,
            value : deleteValue,
            action : deleteAction,
            screen : deleteScreen,
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            is_without_effecting_inventory : is_without_effecting_inventory,
            e_invoice_cancel_reason : e_invoice_cancel_reason,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
         }
        let api = app_api+'/'+deleteTarget;
        axios.post(api, body)
          .then(function (response) {
            if(deleteValue==='expense-trash' || deleteValue==='account_type' || deleteValue==='expense' ||deleteValue==='debit_credit_note' || deleteValue==='user_role' || deleteValue==='inventory' || deleteValue==='variation' || deleteValue==='billing' || deleteValue==='hold_bill' || deleteValue==='billing-trash'|| deleteValue==='contact' || deleteValue==='payment'|| deleteValue==='expense_type' || deleteValue==='transport'  || deleteValue==='stock_transfer'){
              window.location.reload();
            }else if(deleteValue==='billing_view'){
              window.location.href = '/billings';
            }else if(deleteValue==='payment-view'){
              window.location.href = '/payments/trash';
            }else if(deleteValue==='hold_view'){
              window.location.href = '/billings';
            }else if(deleteTarget==='delete_barcode_template' || deleteTarget==='delete_company' || deleteTarget==='delete_users'){
              window.location.reload();
            }else if(deleteValue==='variant'){
              let product_id = document.getElementById("dataIndex").value;
              window.location.href = '/products/edit/'+product_id;
            }else if(deleteValue==='expense-view' || deleteValue==='expense_view' || deleteValue==='expense-view-trash'){
              window.location.href = '/expenses';
            }else if(deleteValue==='product'){
              window.location.href = '/products';
            }else if(deleteTarget==='delete_contact_type'){
              window.location.reload();
            }
        }).catch(function(res){
            if(res.response){
                if(res.response.data){
                    
                }
            }
        })
        if(document.getElementById(this.props.id)){
          var element = document.getElementById(this.props.id);
          element.classList.add("fade");
          element.style.display="none"; 
        }
    }
    handleOK(event) {
     /* let value = document.getElementById("ok_cancel_txt").value;
      let target_id = document.getElementById("target_id").value;
      let target_index = document.getElementById("target_index").value;*/

      let value = localStorage.getItem("ok_cancel_txt");
      let target_id = localStorage.getItem("target_id");
      let target_index = localStorage.getItem("target_index");

      let id = event.currentTarget.id;
      var element = document.getElementById("ok_cancel_poup");
      element.classList.add("fade");
      element.style.display="none"; 
      
      if(document.getElementById(target_id)){
        document.getElementById(target_id).value = value;

    
        if(document.getElementById("iitem-name")){
          let item_id = document.getElementById("iitem-name").value;
          if(item_id!=="" && item_id!=="0"){
            item_id = parseInt(item_id);
            let i_opt = JSON.parse(localStorage.getItem('item_options'));
            let lsp = (i_opt[item_id]!==null && i_opt[item_id].lowest_selling_price!==undefined)?i_opt[item_id].lowest_selling_price:'';
            if(document.getElementById("price-hidden")){
              document.getElementById("price-hidden").value  = lsp
            };
          }
        }
        

        let save_row = localStorage.getItem("save_row");
        if(save_row==='yes'){
          localStorage.removeItem("save_row");
          document.getElementById("handleCalculationAfterMSPSelect").click();
          document.getElementById("add-item-to-list").click();
        }else{
          document.getElementById(target_id).focus();
        }
        localStorage.removeItem("ok_cancel_txt");
        localStorage.removeItem("target_id");
        localStorage.removeItem("target_index");
        localStorage.removeItem("target_referer");
      }
    }
    closeModel(event) {
      let id = event.currentTarget.id;
      
      
      if(document.getElementById("new_name")){
        document.getElementById("new_name").value = "";
      }
      if(document.getElementById("new_email")){
        document.getElementById("new_email").value = "";
      }
      if(document.getElementById("new_phone")){
        document.getElementById("new_phone").value = "";
      }
      if(document.getElementById("new_discount")){
        document.getElementById("new_discount").value = 0;
      }

      if(id==='img-pop-close'){
        window.location.reload();
      }
      if(id==='close-popup'){
        /*let referer = document.getElementById("target_referer").value;
        let target_id = document.getElementById("target_id").value;
        let target_index = document.getElementById("target_index").value;*/

        let referer = localStorage.getItem("target_referer");
        let target_id = localStorage.getItem("target_id");
        let target_index = localStorage.getItem("target_index");
        let save_row = localStorage.getItem("save_row");
        if(document.getElementById("iitem-selling-price")){
          if(save_row==='yes'){
            localStorage.removeItem("save_row");
            document.getElementById("add-item-to-list").click();
          }else{
            document.getElementById("sp-discount").focus();
          }
        }else if(document.getElementById("discount")){
          if(save_row==='yes'){
            localStorage.removeItem("save_row");
            document.getElementById("add-item-to-list").click();
          }else{
            document.getElementById("discount").focus();
          }
        }else{
          if(save_row==='yes'){
            localStorage.removeItem("save_row");
            document.getElementById("add-item-to-list").click();
          }else{
            if(document.getElementById("iitem-gst")){
              document.getElementById("iitem-gst").focus();
            }
          }
        }
        localStorage.removeItem("ok_cancel_txt");
        localStorage.removeItem("target_id");
        localStorage.removeItem("target_index");
        localStorage.removeItem("target_referer");
      }

      if(id=="close-notice"){

        let is_close_disc = false;
        if(document.getElementById("item_target")){
          let item_target = document.getElementById("item_target").value;
          if(item_target==='check_discount'){
            document.getElementById("discount").focus();
            document.getElementById("item_target").value = '';
            is_close_disc = true;
          }
        }

        if(is_close_disc===false){
          let item_availabe_hidden = document.getElementById("item_availabe_hidden").value;
          let item_index_hidden = document.getElementById("item_index_hidden").value;
          if(item_availabe_hidden!=="" && item_index_hidden!==""){
            document.getElementById("iitem-quantity").value = item_availabe_hidden;
            document.getElementById("iitem-quantity").focus();
          }
        }
      }

      var element = document.getElementById(this.props.id);
      element.classList.add("fade");
      element.style.display="none"; 
      if(document.getElementById("handleClosePayMode")){
        document.getElementById("handleClosePayMode").click();
        
      }
    }

    componentWillReceiveProps(nextProps){
      this.setState({inventory_images:nextProps.inventory_images});
    }

    saveChanges(event) {
        let body = {};
        let api = '';
        let save_value = '';
        let this_id = this.props.id;

        if(this.props.id==="add_expense_types"){
            save_value = document.getElementById("expense_type").value;
            let expnese_category = document.querySelector('input[name="expnese_category"]:checked').value;
          
           document.getElementById('expense_type').value = '';
           api = app_api+'/save_expense_type';
           body = {
              type : save_value,
              category : expnese_category,
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        }else if(this.props.id==="add_contact_type"){
            save_value = document.getElementById('add_new_contact_type').value;
          
           document.getElementById('add_new_contact_type').value = '';
           api = app_api+'/contacts_type';
           body = {
              type : save_value,
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        }else if(this.props.id==="add_contact_group"){
            save_value = document.getElementById('add_new_contact_group').value;
          
           document.getElementById('add_new_contact_group').value = '';
           api = app_api+'/create_contact_group';
           body = {
              group : save_value,
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        }else if(this.props.id==='add_payment_mode'){
           let payment_mode_id = document.getElementById("payment_mode_id").value;
           if(payment_mode_id===""){
            payment_mode_id = 0;
           }
           save_value = document.getElementById('payment_mode').value;
           save_value = save_value.trim();
           let sval = save_value.toLowerCase();
           let qs = document.querySelectorAll('.pay-mode-name');
           if(payment_mode_id===0){
             for(let i=0;i<qs.length;i++){
               let val = qs[i].innerHTML.toLowerCase();
               val = val.trim();
               if(sval===val){
                 this.setState({save_error:save_value+' is already exist'});
                 document.getElementById('payment_mode').style.border = '1px solid red';
                 return false;
                }
              }
           }
           this.setState({save_error:''});
           document.getElementById('payment_mode').value = '';
           let is_direct_pay = document.getElementById("is_direct_pay").checked;
           let is_cheque = document.getElementById("is_cheque").checked;
           let account_type = 0;
           if(document.getElementById("account_type")){
              account_type = document.getElementById("account_type").value;
           }
           api = app_api+'/payment_mode';
           body = {
              id : payment_mode_id,
              payment_mode : save_value,
              is_direct_pay : is_direct_pay,
              account_type : account_type,
              is_cheque : is_cheque,
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        }else if(this.props.id==='add_business_type'){
           save_value = document.getElementById('business_type').value;
          
           document.getElementById('business_type').value = '';
           api = app_api+'/create_business_type';
           body = {
              business_type : save_value,
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        }else if(this.props.id==='add_unit'){
           save_value = document.getElementById('unit').value;
           save_value = save_value.trim();
           let sval = save_value.toLowerCase();
           let qs = document.querySelectorAll('.p-unit-name');
           for(let i=0;i<qs.length;i++){
              let val = qs[i].innerHTML.toLowerCase();
              val = val.trim();
              if(sval===val){
                this.setState({save_error:save_value+' is already exist'});
                document.getElementById('unit').style.border = '1px solid red';
                return false;
              }
           }
           this.setState({save_error:''});
           document.getElementById('unit').value = '';
           api = app_api+'/create_unit';
           body = {
              unit_name : save_value,
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        }
    
        let save_target_type = document.getElementById("save-target-type").value;
        if(save_value!==""){
          axios.post(api, body)
            .then(function (response) { 
              let insert_id = 0;
              if(response.data.response){
                 let resp = response.data.response;
                 insert_id = parseInt(resp.insert_id);
                 if(document.getElementById("inserted_group")){
                    document.getElementById("inserted_group").value = insert_id;
                  }
              }
              if(save_target_type==='product' && document.getElementById("create-category-button")){
                document.getElementById("product-category-refresh").click();
                localStorage.setItem("new-category-id",insert_id);
              }
              if(save_target_type==='billing-category'){
                document.getElementById("product-category-refresh").click();
                localStorage.setItem("new-category-id",insert_id);
              }else if(save_target_type==='add_contact'){
                if(document.getElementById("handleResetContactGroup")){
                  document.getElementById("handleResetContactGroup").click();
                }
              }else{
                window.location.reload();
              }
          }).catch(function(res){
              if(res.response){
                  if(res.response.data){
                      
                  }
              }
          })
          var element = document.getElementById(this.props.id);
          element.classList.add("fade");
          element.style.display="none"; 
        }else{
          if(this.props.id==="add_contact_type"){
             document.getElementById('add_new_contact_type').style.border = '1px solid red';
          }else if(this.props.id==='add_contact_group'){
             document.getElementById('add_new_contact_group').style.border = '1px solid red';
          }else if(this.props.id==='add_payment_mode'){
             document.getElementById('payment_mode').style.border = '1px solid red';
          }else if(this.props.id==='add_business_type'){
             document.getElementById('business_type').style.border = '1px solid red';
          }else if(this.props.id==='add_unit'){
             document.getElementById('unit').style.border = '1px solid red';
          }else if(this.props.id==='add_expense_types'){
             document.getElementById('expense_type').style.border = '1px solid red';
          }
        }
    }

    closeCategoryModel = (e) =>{
        var element = document.getElementById('add_category');
        element.classList.add("fade");
        element.style.display="none";

        if(document.getElementById("tag-box-category")){
          document.getElementById("tag-box-category").focus();
        }
    }
     
    saveCategory = (e) => {
        let this_id = e.currentTarget.id;
        let save_value = document.getElementById('category').value;
        let category_sequence = document.getElementById('category_sequence').value;
        let category_short_name = document.getElementById('category_short_name').value;
        if(save_value===""){
          return false;
        }
        document.getElementById(this_id).classList.add("hide");
        document.getElementById(this_id+"-spinner").classList.remove("hide");

        let category_target = document.getElementById("save-category-target-type").value;
        

        let parent = document.getElementById('parent_category').value;
        let update_category_id = document.getElementById('update_category_id').value;
        document.getElementById('category').value = '';
        let api = app_api+'/create_category';
        let body = {
          category : save_value,
          parent : parent,
          short_name : category_short_name,
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          user_id : user_info.user_id,
          update_id : update_category_id,
          category_sequence : category_sequence,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
        }
    
        axios.post(api, body)
          .then(function (response) { 
            
            let insert_id = response.data.response.insert_id;
            document.getElementById(this_id).classList.remove("hide");
            document.getElementById(this_id+"-spinner").classList.add("hide");
            
            if(category_target==='product'){
              const tags_default = document.getElementById("tags-value-category").value;
             
              let t_default = tags_default.split(",");
              
              let tags = [];
              for(let i=0;i<t_default.length;i++){
                  let tag_name = t_default[i];
                  if(tag_name!==""){
                    tags.push(tag_name);
                  }
              }
              tags.push(save_value);
              const tags_id_default = document.getElementById("tags-id-category").value;
              let tid_default = tags_id_default.split(",");
              let tags_id = [];
              for(let i=0;i<tid_default.length;i++){
                  let tag_name = tid_default[i];
                  if(tag_name!==""){
                    tags_id.push(tag_name);
                  }
              }
              tags_id.push(insert_id);

              document.getElementById("tags-value-category").value = tags.toString();
              document.getElementById("tags-id-category").value = tags_id.toString();
              document.getElementById("tag-box-category").value = '';
              if(document.getElementById("handleResetTagList")){
                document.getElementById("handleResetTagList").click();
                this.closeCategoryModel();
                if(document.getElementById("iitem-total-txt")){
                  document.getElementById("iitem-total-txt").focus();
                }
                if(document.getElementById("handleLoadCategories")){
                  document.getElementById("handleLoadCategories").click();
                }
              }
            }else{
              window.location.reload();
            }

        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                    
                }
            }
        })
      }

      handleGenerateEInvoice = () =>{
          document.getElementById('generate-e-inv').classList.add("hide");
          document.getElementById('generate-e-inv-btn-spinner').classList.remove("hide");
          let invoice_id = 0;//this.props.invoice_id;
          if(this.props.invoice_id!==undefined){
            invoice_id = this.props.invoice_id;
          }

          let body = {  
              invoice_id : invoice_id,
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              store_state : user_info.state,
              target : this.props.target,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
          }
          let api = app_api+'/generate_e_envoice';
          axios.post(api, body)
            .then(function (response) {
              document.getElementById('generate-e-inv').classList.remove("hide");
              document.getElementById('generate-e-inv-btn-spinner').classList.add("hide");
              let resp = response.data;
              if(resp.status_cd==1){
                this.handleCloseEInvoice();
                window.location.reload();
              }else{
                this.setState({einv_error:true});
                this.setState({einv_error_list:resp.Error});
              }
          }.bind(this)).catch(function(res){
              if(res.response){
                  if(res.response.data){
                      
                  }
              }
          })

      }

      
      handleCloseEInvoice = () =>{
        var element = document.getElementById('generate_e_invoice_popup');
        element.classList.add("fade");
        element.style.display="none"; 
        this.setState({einv_error:false});

      }

    render() {

       

        let img_count = 6;
        let img_len_arr = [];
        for(let i=1;i<=img_count;i++){
          img_len_arr.push(i);
        }
        return (
          <>
            {(this.props.id==='add_contact_type' || this.props.id==='add_expense_types'|| this.props.id==='add_payment_mode' || this.props.id==='add_business_type' || this.props.id==='add_unit'|| this.props.id==='add_contact_group') &&
            <div className="modal fade" id={this.props.id}  role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">{this.props.title}</h5>
                  </div>
                  <div className="modal-body">
                  
                    <div className="row">
                      <div className="col-md-12">
                          {this.props.id==='add_contact_type' &&
                          <input type="text" name="type" className="form-control" placeholder="Please enter contact type" id="add_new_contact_type"/>
                          } 
                          {this.props.id==='add_contact_group' &&
                          <input type="text" name="type" className="form-control" placeholder="Please enter contact group" id="add_new_contact_group"/>
                          }

                          {this.props.id==='add_payment_mode' &&
                          <div className="row">
                            <div className="col-md-12">
                              <input type="hidden" name="payment_mode_id" id="payment_mode_id" value={(this.props.payment_mode_data && this.props.payment_mode_data.id)?this.props.payment_mode_data.id:0}/>
                              <input type="text" name="payment_mode" className="form-control" placeholder="Please enter payment mode" id="payment_mode"  defaultValue={(this.props.payment_mode_data && this.props.payment_mode_data.payment_mode)?this.props.payment_mode_data.payment_mode:''} disabled={(this.props.payment_mode_data && this.props.payment_mode_data.id && parseInt(this.props.payment_mode_data.id)>0 && parseInt(this.props.payment_mode_data.id)<4)?true:false}/>
                            </div>
                            {(localStorage.getItem('is_account_module')==='true') &&
                              <div className="col-md-12 mrtop-10">
                                <AccountTypes show='dropdown' id="account_type" default={(this.props.payment_mode_data && this.props.payment_mode_data.account_type)?this.props.payment_mode_data.account_type:''}/>
                              </div>
                            }
                            <div className="col-md-12 mrtop-10">
                              <div className="form-check form-check-inline mrtop-10">
                                <input className="form-check-input" type="checkbox"  id="is_cheque"  disabled={(this.props.payment_mode_data && this.props.payment_mode_data.id && parseInt(this.props.payment_mode_data.id)>0 && parseInt(this.props.payment_mode_data.id)<4)?true:false}/>
                                <label className="form-check-label" htmlFor="is_cheque" >Is this cheque?</label>
                              </div>
                              <div className="form-check form-check-inline mrtop-10">
                                <input className="form-check-input" type="checkbox"  id="is_direct_pay"  disabled={(this.props.payment_mode_data && this.props.payment_mode_data.id && parseInt(this.props.payment_mode_data.id)>0 && parseInt(this.props.payment_mode_data.id)<3)?true:false}/>
                                <label className="form-check-label" htmlFor="is_direct_pay" >Direct Payment?</label>
                              </div>
                            </div>
                          </div>
                          }


                          {this.props.id==='add_expense_types' &&
                            <>
                              <label htmlFor="expense_type" style={{color:'#646464'}}>Expense Type</label>
                              <input type="text" name="expense_type" className="form-control" placeholder="Please enter expense type" id="expense_type"/>

                              <div className="mrtop-10">
                                <label style={{color:'#646464'}}>Expense Category</label>
                                <div>
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" id="expnese_category1" name="expnese_category" value="indirect"  defaultChecked="true"/>
                                    <label className="form-check-label" for="expnese_category1">Indirect Expense</label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" id="expnese_category" name="expnese_category" value="direct"/>
                                    <label className="form-check-label" for="expnese_category">Direct Expense</label>
                                  </div>
                                </div>
                              </div>
                            </>
                          }

                          {this.props.id==='add_business_type' &&
                            <input type="text" name="business_type" className="form-control" placeholder="Please enter business type" id="business_type"/>
                          }

                          {this.props.id==='add_unit' &&
                          <input type="text" name="unit" className="form-control" placeholder="Please enter unit" id="unit"/>
                          }
                          <input type="hidden" id="save-target-type" />
                          <p className="error">{this.state.save_error}</p>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={this.closeModel}>Close</button>
                    <button type="button" data-id={this.props.id} className="btn btn-success pull-right" onClick={this.saveChanges}>Save changes</button>
                  </div>
                </div>
              </div>
            </div>
          }

          {(this.props.id==='add_category') && 
            <div className="modal fade" id="add_category"  role="dialog" aria-labelledby="add-category-title" aria-hidden="true">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="add-category-title">ADD CATEGORY</h5>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12">
                        <label htmlFor="category">Category</label>
                        <input type="text" name="category" className="form-control" placeholder="Please enter category" id="category"/>
                        <input type="hidden" id="update_category_id" />
                        <input type="hidden" id="save-category-target-type" />
                    </div>
                    <div className="col-md-12 mrtop-10">
                        <label htmlFor="category_short_name">Short Name</label>
                        <input type="text" name="category_short_name" className="form-control" placeholder="Please enter category short name" id="category_short_name"/>
                       
                    </div>
                    <div className="col-md-12 mrtop-10">
                        <label htmlFor="category_sequence">Barcode Sequence</label>
                        <input type="text" name="category_sequence" className="form-control" placeholder="Barcode Sequence" id="category_sequence"/>
                       
                    </div>
                    <div className="col-md-12 mrtop-10">
                      <label htmlFor="parent_category">Parent Category</label>
                      <ProductCategory show="dropdown" target="create_category" id="parent_category"/>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" id="close-save-cat-but" onClick={this.closeCategoryModel}>Close</button>
                  <button type="button" id="save-cat-btn" className="btn btn-success pull-right" onClick={this.saveCategory}>Save Category</button>
                  <button type="button" id="save-cat-btn-spinner" className="hide btn btn-success pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                </div>
              </div>
            </div>
          </div>
          }

          {this.props.id==='delete_confirm' &&
            <div id={this.props.id} className="modal fade">
            <div className="modal-dialog modal-confirm">
              <div className="modal-content">
                <div className="modal-header flex-column">
                  <div className="icon-box">
                    <span className="material-icons">&times;</span>
                  </div>            
                  <h4 className="modal-title w-100">Are you sure?</h4>  
                  <input type="hidden" id="deleteValue" />
                  <input type="hidden" id="deleteIndex" />
                  <input type="hidden" id="deleteTarget" />
                  <input type="hidden" id="dataIndex" />
                  <input type="hidden" id="deleteAction" />
                  <input type="hidden" id="deleteScreen" />
                </div>
                <div className="modal-body">
                  <p style={{whiteSpace: 'break-spaces'}} id="delete_message">{(this.props.delete_message)?this.props.delete_message:'Do you really want to delete these records? This process cannot be undone.'}</p>
                  {((this.props.target==='sale' || this.props.target==='sales' || this.props.target==='sales_return') && this.props.active_type!=='trash') &&
                    <div id="cancel-e-inv-block" className="hide" style={{marginBottom:'20px',textAlign:'left'}}>
                        <div className="alert alert-warning text-center" role="alert" style={{padding: '2px',fontSize: '12px'}}><p style={{margin:'0px'}}>If you cancel/delete this bill e-invoice will cancel as well</p></div>
                        <label htmlFor="e_invoice_cancel_reason">Please select eInvoice cancel reason</label>
                        <select className="form-control" id="e_invoice_cancel_reason">
                            <option value={2}>Data Entry Mistake</option>
                            <option value={1}>Duplicate</option>
                            <option value={3}>Order Cancel</option>
                            <option value={4}>Other</option>
                        </select>
                    </div>
                  }
                  {((this.props.target==='sale' || this.props.target==='sales' || this.props.target==='sales_return' || this.props.target==='purchase' || this.props.target==='purchase_return') && this.props.active_type!=='trash') &&
                    <div className="form-check form-check-inline" id="without-inv-blck">
                      <input className="form-check-input" type="checkbox" id="without-effecting-inventory"/>
                      <label className="form-check-label chklbl" htmlFor="without-effecting-inventory">WITHOUT EFFECTING INVENTORY</label>
                    </div>
                  }
                </div>
                <div className="modal-footer justify-content-center">
                  <button type="button" className="btn btn-secondary" onClick={this.closeModel}>No</button>
                  <button type="button" className="btn btn-danger" onClick={this.deleteRecord}>Yes</button>
                </div>
              </div>
            </div>
          </div>   
          }
          {this.props.id==='notice_box' &&
            <div id={this.props.id} className="modal fade">
            <div className="modal-dialog modal-confirm">
              <div className="modal-content">
                <div className="modal-header flex-column">         
                  <h4 className="modal-title w-100" id="notice_message">Are you sure?</h4>  
                </div>
                <div className="modal-footer justify-content-center">
                <input type="hidden" id="item_availabe_hidden" />
                <input type="hidden" id="item_index_hidden" />
                <input type="hidden" id="item_target" />
                  <button type="button" id="close-notice" className="btn btn-danger" onClick={this.closeModel}>OK</button>
                </div>
              </div>
            </div>
          </div>   
          }
          {this.props.id==='generate_e_invoice_popup' &&
            <div id={this.props.id} className="modal fade">
              <div className="modal-dialog modal-confirm" style={{width:'unset'}}>
                {(this.state.einv_error===false) &&
                <div className="modal-content">
                    <div className="modal-header flex-column">
                      <div className="icon-box icon-print">
                        <span className="material-icons"><FontAwesomeIcon icon={faExclamationTriangle}/></span>
                      </div>            
                      <h5 className="modal-title w-100">Are you sure? You want to generate e-Invoice for the bill</h5>  
                    </div>
                  <div className="modal-body" style={{padding:'0'}}>
                    <p style={{whiteSpace: 'break-spaces',margin:'0'}}>Once you generate e-Invoice you can't modify/update the bill.</p>
                    <p className="error">{this.state.einv_error}</p>
                  </div>
                  <div className="modal-footer justify-content-center">
                    <button type="button" className="btn btn-secondary" onClick={this.handleCloseEInvoice}>No</button>
                    <button type="button" className="btn btn-success" onClick={this.handleGenerateEInvoice} id="generate-e-inv">Yes</button>
                    <button type="button" id="generate-e-inv-btn-spinner" className="hide btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                  </div>
                </div>
                }
                {(this.state.einv_error===true) &&
                <div className="modal-content">
                    <div className="modal-header flex-column">
                      <div className="icon-box icon-print">
                        <span className="material-icons"><FontAwesomeIcon icon={faExclamationTriangle}/></span>
                      </div>            
                      <p className="modal-title w-100 error">Oops ! error found while submitting the e-Invoice, please correct the information by using following error list</p>  
                    </div>
                    <hr/>
                  <div className="modal-body" style={{padding:'0',textAlign:'left',color:'#333333'}}>
                    <ul>

                    {this.state.einv_error_list.map( (value, index) => {
                      return (
                        <li>
                          {value.ErrorCode} - {value.ErrorMessage}
                        </li>
                      )
                    })}
                    </ul>
                  </div>
                  <div className="modal-footer text-right">
                    <button type="button" className="btn btn-secondary" onClick={this.handleCloseEInvoice}>Ok</button>
                  </div>
                </div>
                }
              </div>
            </div>    
          }
          {this.props.id==='ok_cancel_poup' &&
            <div id={this.props.id} className="modal fade">
            <div className="modal-dialog modal-confirm">
              <div className="modal-content">
                <div className="modal-header flex-column">
                  <div className="icon-box icon-print">
                    <span className="material-icons"><FontAwesomeIcon icon={faExclamationTriangle}/></span>
                  </div>            
                  <h5 className="modal-title w-100"  id="message_body"></h5>  
                </div>
                <div className="modal-footer justify-content-center">
                  
                  <input className="hide" type="text" value="" id="ok_cancel_txt" />
                  <input className="hide" type="text" value="" id="target_id" />
                  <input className="hide" type="text" value="" id="target_index" />
                  <input className="hide" type="text" value="" id="target_referer" />
                  <button type="button" id="close-popup" className="btn btn-success" onClick={this.closeModel}>Close</button>
                  <button type="button" id="keep-button" className="btn btn-danger" onClick={this.handleOK}>OK</button>
                </div>
              </div>
            </div>
          </div>   
          }
          {this.props.id==='info_box' &&
            <div className="modal fade" id="info_box" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">{this.props.title}</h5>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-md-12">
                         <div id="data-info"></div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={this.closeModel}>Close</button>
                   
                  </div>
                </div>
              </div>
            </div> 
          }
          {this.props.id==='buffer_credit_limit' &&
            <div id={this.props.id} className="modal fade">
              <div className="modal-dialog modal-confirm">
                <div className="modal-content">
                  <div className="modal-header flex-column">
                    <div className="icon-box icon-print">
                      <span className="material-icons"><FontAwesomeIcon icon={faExclamationTriangle}/></span>
                    </div>     
                    <h5 className="modal-title w-100" style={{fontSize:'18px'}}>Oops ! You have exceeded credit limit.</h5>  
                    <h5 className="modal-title w-100" style={{fontSize:'18px'}} id="buffer-amount-msg"></h5>  
                  </div>
                  
                  <div className="modal-footer justify-content-center">
                    <button type="button" id="close-use-buffer-amount" className="btn btn-danger" onClick={this.closeModel}>Stop Billing</button>
                    <button type="button" id="use-buffer-amount" className="btn btn-success hide" onClick={this.handleUserCreditBuffer}>Continue</button>
                  </div>
                </div>
              </div>
            </div> 
          }
          {this.props.id==='resend-verify-link' &&
            <div className="modal fade" id="resend-verify-link" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Resend Verification Link</h5>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-md-12 success-resend">
                         <input type="hidden" id="verify-id"/>
                         <input type="hidden" id="verify-username"/>
                         
                         <input type="text" id="verify-email" className="form-control" placeholder="Enter Email id" />
                      </div>
                      <div className="col-md-12 hide" id="verification-success-msg">
                        <p>We have sent a varification link to your Email ID. Please open your email and click on verification link to verify your account.</p>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary success-resend" onClick={this.closeModel}>Close</button>
                    <button type="button" className="btn btn-success pull-right success-resend" onClick={this.sendVerificationLink} id="resend-vl">Send Link</button>
                    <button type="button" className="btn btn-success pull-right hide" onClick={this.handleCloseVerificationLink} id="resend-vl-hidden">OK</button>
                    <button type="button" id="resend-vl-btn-spinner" className="hide btn btn-success  pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                  </div>
                </div>
              </div>
            </div> 
          }
          {this.props.id==='product-images' &&
            <div id={this.props.id} className="modal fade">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-4">
                        <h5 className="pro-img-title">Product</h5>
                        <div className="bg-light border-right">
                          <div className="list-group list-group-flush">
                            <a className="pro-imgs-menu list-group-item list-group-item-action active" onClick={this.handleNavImgItem} id={this.props.inventory_item.inventory_id}>{this.props.inventory_item.item_name}</a>
                          </div>
                        </div> 
                        {(this.props.inventory_meta && this.props.inventory_meta.length>0) &&
                          <>
                        <h5 className="mrtop-10 pro-img-title">Variations</h5>
                        <div className="bg-light border-right">
                          <div className="list-group list-group-flush">
                          {(this.props.inventory_meta.length > 0) ?this.props.inventory_meta.map( (value, index) => {
                           return (
                              <a className="pro-imgs-menu list-group-item list-group-item-action" id={value.insert_id} onClick={this.handleNavImgItem}>{value.item_name}</a>
                            )
                           }) : ''}
                            
                          </div>
                        </div>
                        </>
                      }
                    </div>
                    <div className="col-md-8">
                       <h5 className="pro-img-title">PRODUCT IMAGES</h5>
                       
                       <input type="hidden" id="current-id" />
                       <input type="hidden" id="current-inventory-id" defaultValue={this.props.inventory_item.inventory_id}/>
                       <div className="pimg-continer"  id={"pimg-continer-"+this.props.inventory_item.inventory_id}>
                         <div className="row mrtop-20">
                             {img_len_arr.map( (value, index) => {
                           return (
                            <div className="col-md-4 mrbtm-20 upload-img-row" data-id={index+''+this.props.inventory_item.inventory_id}>
                              <input type="file" onChange={this.onFileChange} id={"upload-inv-img-"+index+''+this.props.inventory_item.inventory_id} className="hide" accept="image/*" data-id={this.props.inventory_item.inventory_id}/>

                              {(this.state.inventory_images && this.state.inventory_images[this.props.inventory_item.inventory_id] && this.state.inventory_images[this.props.inventory_item.inventory_id][index])?<div className="pro-imgs">
                                <>
                                <div id={"pro-imgs-"+index+''+this.props.inventory_item.inventory_id}>
                                  <img src={this.state.inventory_images[this.props.inventory_item.inventory_id][index].image} className="pro-up-img" />
                                </div>
                                <div className="mod-pro-img-blk">
                                  <ul>
                                    <li id={this.state.inventory_images[this.props.inventory_item.inventory_id][index].img_id} data-id={index} onClick={this.handleDeleteImage} data-target={this.props.inventory_item.inventory_id}>Delete</li>
                                    <li data-target={this.state.inventory_images[this.props.inventory_item.inventory_id][index].img_id} onClick={this.handleUploadImg} data-id={index+''+this.props.inventory_item.inventory_id} id={"pro-imgs-"+index+''+this.props.inventory_item.inventory_id}>Change</li>
                                  </ul>
                                </div>
                              </>

                                </div>:<div className="pro-imgs" onClick={this.handleUploadImg} data-id={index+''+this.props.inventory_item.inventory_id} id={"pro-imgs-"+index+''+this.props.inventory_item.inventory_id}><span className="img-sym">+</span></div>
                              }
                            </div>
                            )})}
                         </div>
                      </div>
                     
                        {(this.props.inventory_meta.length > 0) ?this.props.inventory_meta.map( (value, index) => {
                           return (
                          <div className="pimg-continer hide" id={"pimg-continer-"+value.insert_id}>
                         <div className="row mrtop-20">
                            {img_len_arr.map( (vl, idx) => {
                           return (
                            <div className="col-md-4 mrbtm-20 upload-img-row" data-id={idx+''+value.insert_id}>
                            <input type="file" onChange={this.onFileChange} id={"upload-inv-img-"+idx+''+value.insert_id} className="hide" accept="image/*" data-id={value.insert_id}/>
                              {(this.state.inventory_images && this.state.inventory_images[value.insert_id] && this.state.inventory_images[value.insert_id][idx])?<div className="pro-imgs"  data-id={idx+''+value.insert_id}>
                              <>
                                <div id={"pro-imgs-"+idx+''+value.insert_id}>
                                  <img src={this.state.inventory_images[value.insert_id][idx].image} className="pro-up-img" />
                                </div>
                                <div className="mod-pro-img-blk">
                                  <ul>
                                    <li id={this.state.inventory_images[value.insert_id][idx].img_id} data-id={idx} onClick={this.handleDeleteImage} data-target={value.insert_id}>Delete</li>
                                    <li data-target={this.state.inventory_images[value.insert_id][idx].img_id} onClick={this.handleUploadImg} data-id={idx+''+value.insert_id} id={"pro-imgs-"+idx+''+value.insert_id}>Change</li>
                                  </ul>
                                </div>
                              </>
                                </div>:<div className="pro-imgs" onClick={this.handleUploadImg} data-id={idx+''+value.insert_id} id={"pro-imgs-"+idx+''+value.insert_id}><span className="img-sym">+</span></div>
                              }
                            </div>
                           
                            )})}
                         </div>
                      </div>  
                      )
                     }) : ''}

                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={this.closeModel} id="img-pop-close">Close</button>
                    <button type="button" className="btn btn-success pull-right" onClick={this.updateProductImage} id="img-save">Update Images</button>
                   <button type="button" id="img-save-btn-spinner" className="hide btn btn-success  pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                  </div>
              </div>
            </div>
          </div>   
          }
          {this.props.id==='preview-image' &&
            <div id={this.props.id} className="modal fade" onClick={this.closeModel} style={{overflowY:'scroll'}}>
            <div className="modal-dialog modal-lg" style={{textAlign:'center'}} >
              <div className="">
                <div className="">
                  <img id="preview-img-src" src="" style={{maxWidth:'100%'}}/>
                </div>
              </div>
            </div>
          </div>   
          }
          {this.props.id==='print_bill' &&

            <div id={this.props.id} className="modal fade">
            <div className="modal-dialog modal-confirm">
              <div className="modal-content">
                <div className="modal-header flex-column">
                  
                  <div className="icon-box icon-print">
                    <span className="material-icons"><FontAwesomeIcon icon={faPrint}/></span>
                  </div>            
                  <h5 className="modal-title w-100">Do you want to print this bill?</h5>  
                 
                </div>
                <div className="modal-footer justify-content-center" style={{padding:'0px'}}>
                  <div className="row print-pop-foot">
                   {/*  <div className="col-md-12 hide" id="share-email-block">
                        <div className="form-check form-check-inline">
                          <input className="form-check-input print-check" type="checkbox" id="share-email" value="share-email"/>
                          <label className="form-check-label chklbl" htmlFor="share-email" id="lbl-share-email">SHARE THIS BILL VIA <b><u style={{fontSize:'16px'}}>E</u></b>MAIL?</label>
                        </div>
                    </div> */}
                    <div className="col-md-4 mrtop-10">
                      <a  onClick={this.handleShareThroughEmail} id="no-print"><button type="button" className="btn btn-secondary"> <b><u>N</u></b>o</button></a>
                    </div>
                    <div className="col-md-4 mrtop-10" onClick={this.handleRefreshPage} id="share-wa">
                      {(this.props.whatsapp_data.whatsapp_phone!=='' && this.props.whatsapp_data.whatsapp_phone!==false && this.props.whatsapp_data.whatsapp_msg!=='') &&
                        <ReactWhatsapp number={this.props.whatsapp_data.whatsapp_phone} message={this.props.whatsapp_data.whatsapp_msg} element="div"><button type="button" id="icons8-whatsapp" className="btn btn-success" style={{minWidth:'50px',position:'relative'}}><div className="icons8-whatsapp" style={{height:'25px',width:'25px'}}></div> <b style={{position:'absolute',top:'10px'}}><u>W</u></b></button></ReactWhatsapp>
                      }
                    </div>
                    <div className="col-md-4 mrtop-10">
                    {(this.isMobile()===true)&&
                        <a id="yes-print" onClick={this.handleShareThroughEmail}><button type="button" className="btn btn-success">Yes</button></a>
                    }
                    {(this.isMobile()!==true)&&
                      <button type="button" id="yes-print" onClick={this.handlePrint} className="btn btn-success"><b><u>Y</u></b>es</button>
                    }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  

          }
          {this.props.id==='export_contact_csv' &&

            <div id='export_contact_csv' className="modal fade">
            <div className="modal-dialog modal-confirm">
            <form method="POST" action={app_api+"/export_csv"}>
              <div className="modal-content">
                <div className="modal-header flex-column">
                  <div className="icon-box icon-print">
                    <span className="material-icons"><FontAwesomeIcon icon={faDownload}/></span>
                  </div>            
                  <label className="modal-title w-100" style={{fontSize: '14px',fontWeight: 'bold',color: '#5d5d5d'}}>CHECK THE BELOW ITEM(S) TO EXPORT</label>  
                  <input type="hidden" value={user_info.login_id} name="branch_id" />
                  <input type="hidden" value={user_info.store_name} name="store_name" />
                  <input type="hidden" value='contact' name="target" />
                  <div className="row">
                    <div className="col-md-6 text-left mrtop-10">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input temp-check" type="checkbox" id="name"  name="export_items[]" value="NAME"/>
                        <label className="form-check-label chklbl" htmlFor="name">NAME</label>
                      </div>
                    </div> 
                    <div className="col-md-6 text-left mrtop-10">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input temp-check" type="checkbox"  name="export_items[]" id="contact_type" value="CONTACT TYPE"/>
                        <label className="form-check-label chklbl" htmlFor="contact_type">CONTACT TYPE</label>
                      </div>
                    </div>  
                    <div className="col-md-6 text-left mrtop-10">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input temp-check" type="checkbox" id="email"  name="export_items[]" value="EMAIL"/>
                        <label className="form-check-label chklbl" htmlFor="email">EMAIL</label>
                      </div>
                    </div> 
                    <div className="col-md-6 text-left mrtop-10">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input temp-check" type="checkbox" id="phone_1"  name="export_items[]" value="PHONE 1"/>
                        <label className="form-check-label chklbl" htmlFor="phone_1">PHONE 1</label>
                      </div>
                    </div>  
                    <div className="col-md-6 text-left mrtop-10">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input temp-check" type="checkbox" id="phone_2"  name="export_items[]" value="PHONE 2"/>
                        <label className="form-check-label chklbl" htmlFor="phone_2">PHONE 2</label>
                      </div>
                    </div> 
                    <div className="col-md-6 text-left mrtop-10">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input temp-check" type="checkbox" id="city"  name="export_items[]" value="CITY"/>
                        <label className="form-check-label chklbl" htmlFor="city">CITY</label>
                      </div>
                    </div> 
                    <div className="col-md-6 text-left mrtop-10">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input temp-check" type="checkbox" id="state" name="export_items[]" value="STATE"/>
                        <label className="form-check-label chklbl" htmlFor="state">STATE</label>
                      </div>
                    </div> 
                    <div className="col-md-6 text-left mrtop-10">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input temp-check" type="checkbox" id="pincode" name="export_items[]"  value="PINCODE"/>
                        <label className="form-check-label chklbl" htmlFor="pincode">PINCODE</label>
                      </div>
                    </div> 
                    <div className="col-md-6 text-left mrtop-10">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input temp-check" type="checkbox" id="address_1" name="export_items[]"  value="ADDRESS 1"/>
                        <label className="form-check-label chklbl" htmlFor="address_1">ADDRESS 1</label>
                      </div>
                    </div> 
                    <div className="col-md-6 text-left mrtop-10">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input temp-check" type="checkbox" id="address_2" name="export_items[]" value="ADDRESS 2"/>
                        <label className="form-check-label chklbl" htmlFor="address_2">ADDRESS 2</label>
                      </div>
                    </div>
                    <div className="col-md-6 text-left mrtop-10">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input temp-check" type="checkbox" id="gst_number" name="export_items[]"  value="GST NUMBER"/>
                        <label className="form-check-label chklbl" htmlFor="gst_number">GST NUMBER</label>
                      </div>
                    </div> 
                    <div className="col-md-6 text-left mrtop-10">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input temp-check" type="checkbox" id="pan_number" name="export_items[]" value="PAN NUMBER"/>
                        <label className="form-check-label chklbl" htmlFor="pan_number">PAN NUMBER</label>
                      </div>
                    </div> 
                    <div className="col-md-6 text-left mrtop-10">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input temp-check" type="checkbox" id="account_number" name="export_items[]"  value="ACCOUNT NUMBER"/>
                        <label className="form-check-label chklbl" htmlFor="account_number">ACCOUNT NUMBER</label>
                      </div>
                    </div> 
                    <div className="col-md-6 text-left mrtop-10">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input temp-check" type="checkbox" id="ifsc_code" name="export_items[]" value="IFSC CODE"/>
                        <label className="form-check-label chklbl" htmlFor="ifsc_code">IFSC CODE</label>
                      </div>
                    </div> 
                    <div className="col-md-6 text-left mrtop-10">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input temp-check" type="checkbox" id="bank_name" name="export_items[]" value="BANK NAME"/>
                        <label className="form-check-label chklbl" htmlFor="bank_name">BANK NAME</label>
                      </div>
                    </div> 
                    <div className="col-md-6 text-left mrtop-10">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input temp-check" type="checkbox" id="bank_city" name="export_items[]" value="BANK CITY"/>
                        <label className="form-check-label chklbl" htmlFor="bank_city">BANK CITY</label>
                      </div>
                    </div> 
                    <div className="col-md-6 text-left mrtop-10">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input temp-check" type="checkbox" id="bank_address" name="export_items[]" value="BANK ADDRESS"/>
                        <label className="form-check-label chklbl" htmlFor="bank_address">BANK ADDRESS</label>
                      </div>
                    </div> 
                    
                    <div className="col-md-6 text-left mrtop-10">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input temp-check" type="checkbox" id="credit_limit" name="export_items[]" value="CREDIT LIMIT"/>
                        <label className="form-check-label chklbl" htmlFor="credit_limit">CREDIT LIMIT</label>
                      </div>
                    </div> 
                    <div className="col-md-6 text-left mrtop-10">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input temp-check" type="checkbox" id="credit_days" name="export_items[]" value="CREDIT DAYS"/>
                        <label className="form-check-label chklbl" htmlFor="credit_days">CREDIT DAYS</label>
                      </div>
                    </div> 
                    <div className="col-md-6 text-left mrtop-10">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input temp-check" type="checkbox" id="discount" name="export_items[]"  value="DISCOUNT"/>
                        <label className="form-check-label chklbl" htmlFor="discount">DISCOUNT</label>
                      </div>
                    </div>
                    <div className="col-md-6 text-left mrtop-10">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input temp-check" type="checkbox" id="notes" name="export_items[]" value="NOTES"/>
                        <label className="form-check-label chklbl" htmlFor="notes">NOTES</label>
                      </div>
                    </div> 
                  </div>
                </div>

                <div className="modal-footer justify-content-center">
                  <button type="button" className="btn btn-secondary" onClick={this.closeModel}>Close</button>
                  <button type="submit" className="btn btn-success">Export</button>
                </div>
              </div>
            </form>
            </div>
          </div>  

          }
          {this.props.id==='add_contact' &&
          <div className="modal fade" id={this.props.id}  role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">{this.props.title}</h5>
                  </div>
                  <div className="modal-body">
                  
                    <div className="row">
                      <div className="col-md-6">
                          <label>TYPE <span className="red">*</span></label>
                          <ContactTypes show="dropdown" name="new_contact_type" target="add-contact"/>
                      </div>
                      <div className="col-md-6">
                          <label htmlFor="new_name">NAME <span className="red">*</span></label>
                          <input type="text" className="form-control" placeholder="" id="new_name" autoFocus/>
                          <p className="error" id="name-exist"></p>
                      </div>
                    </div>
                    <div className="row mrtop-10">
                      
                      <div className="col-md-12">
                          <label htmlFor="new_email">EMAIL</label>
                          <input type="text" className="form-control" placeholder="" id="new_email"/>
                      </div>
                    </div>
                    <div className="row mrtop-10">
                      <div className="col-md-6">
                          <label htmlFor="new_phone">PHONE</label>
                          <input type="text" className="form-control" placeholder="" id="new_phone"/>
                      </div>
                      <div className="col-md-6">
                          <label htmlFor="new_discount">DISCOUNT %</label>
                          <input type="text" className="form-control" id="new_discount" placeholder="0" defaultValue={0}/>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={this.closeModel} id="close-contact">Close</button>
                    <button type="button" data-id={this.props.id} className="btn btn-success pull-right" id="create-contact" onClick={this.saveContact}>Save Contact</button>
                    <button type="button" id="create-contact-btn-spinner" className="hide btn btn-success pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                  </div>
                </div>
              </div>
            </div>
          }

          {this.props.id==='previous-list-items' &&
            <div className="modal fade" id="previous-list-items" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="list-item-title"></h5>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-md-12">
                         <table className="table no-block">
                            <tbody id="prev-list-item-block">
                             
                            </tbody>
                         </table>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" id="closeListButton" onClick={this.closeModel}>Close</button>
                    <button type="button" id="doneListButton" className="btn btn-success pull-right" onClick={this.handleReturnBill}>Done</button>
                   
                  </div>
                </div>
              </div>
            </div> 
          }
          
          {(this.props.id==='stock_transfer') &&
              <div className="modal" id="stock_transfer" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:'block'}}>
                <div className="modal-dialog modal-lg" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title hbtitle">Stock Transfer Request</h5>
                    </div>
                    <div className="modal-body" style={{overflow: 'auto',maxHeight: '500px'}}>
                      <div className="row">
                        <div className="col-md-12">
                          <p style={{color: 'gray',fontFamily: 'monospace'}}>Received a stock transfer request from store <b>{this.props.transfer_list[0].store_name}</b> on <b>{this.props.transfer_list[0].transfer_date}</b>, once you accept this request it will create a new purchase entry and it will add a new stock in the system</p>
                        </div>
                        <div className="col-md-12 text-center">
                      
                       
                        <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col" className="text-left">PRODUCT NAME</th>
                            <th scope="col">QUANTITY</th>
                            <th scope="col">Purchase Price</th>
                            <th style={{width:'100px'}} scope="col" className="text-center">Selling Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(this.state.transfer_list.length > 0 && this.state.transfer_list[0].meta_data) ?this.state.transfer_list[0].meta_data.map( (value, index) => {
                          
                           return (
                            <tr key={ index } id={"billing-row-"+value.item_id} className={"contact-row"}>
                              <td className="text-left">{value.item_name}</td>
                              <td>{value.quantity}</td>
                              <td>
                                {(value.inventory_meta[0]!==undefined && value.inventory_meta[0].purchase_price!==undefined)?value.inventory_meta[0].purchase_price:''}
                              </td>
                              <td style={{width:'100px'}}  className="text-center">
                                <input type="text" className="form-control" defaultValue={(value.inventory_meta[0]!==undefined && value.inventory_meta[0].selling_price!==undefined)?value.inventory_meta[0].selling_price:''} data-id={index} onChange={this.handleChangeStockSP}/>
                              </td>
                            </tr>
                          )
                         }) : <tr><td colSpan={4} className="text-center">Loading...</td></tr> }
                           
                         
                        </tbody>
                      </table>
                         
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" onClick={this.handleCloseStockTransfer}>Close</button>
                      <button type="button" className="btn btn-danger pull-right" onClick={this.handleDeclineTransferStock} id="declined">Decline</button>
                      <button type="button" id="declined-btn-spinner" className="hide btn btn-danger pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                      <button type="button" className="btn btn-success pull-right" onClick={this.handleAcceptTransferStock} id="accept">Accept</button>
                      <button type="button" id="accept-btn-spinner" className="hide btn btn-success pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                    </div>
                  </div>
                </div>
              </div> 
          }
          {(this.props.id==='purchase_return_request') &&
              <div className="modal" id="purchase_return_request" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:'block'}}>
                <div className="modal-dialog modal-lg" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title hbtitle">Purchase Return Request</h5>
                    </div>
                    <div className="modal-body" style={{overflow: 'auto',maxHeight: '500px'}}>
                      <div className="row">
                        <div className="col-md-12">
                          <p style={{color: 'gray',fontFamily: 'monospace'}}>Received a purchase return request from store <b>{this.props.purchase_return_request.request.store_name}</b> on <b>{this.props.purchase_return_request.request_date}</b>, once you accept this request it will create a new sales return entry and it will put back the stock in the system</p>
                        </div>
                        <div className="col-md-12 text-center">
                      
                       
                        <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col" className="text-left">PRODUCT NAME</th>
                            <th scope="col">QUANTITY</th>
                            
                          </tr>
                        </thead>
                        <tbody>
                          {(this.props.purchase_return_request.request && this.props.purchase_return_request.request.items) ?this.props.purchase_return_request.request.items.map( (value, index) => {
                           return (
                            <tr key={ index } id={"billing-row-"+value.item_id} className={"contact-row"}>
                              <td className="text-left">{value.item_text}</td>
                              <td>{value.quantity}</td>
                            </tr>
                          )
                         }) : <tr><td colSpan={3} className="text-center">Loading...</td></tr> }
                           
                         
                        </tbody>
                      </table>
                         
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" onClick={this.handleClosePRRequest}>Close</button>
                      {/*<button type="button" className="btn btn-danger pull-right" onClick={this.handleDeclinePRRequest} id="declined">Decline</button>
                      <button type="button" id="declined-btn-spinner" className="hide btn btn-danger pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>*/}
                      <button type="button" className="btn btn-success pull-right" onClick={this.handleAcceptPRRequest} id="accept">Accept</button>
                      <button type="button" id="accept-btn-spinner" className="hide btn btn-success pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                    </div>
                  </div>
                </div>
              </div> 
          }

          </>
        );
    }

    handleChangeStockSP = (e) =>{
      let thisvalue = e.currentTarget.value;
      let thidid = e.currentTarget.getAttribute('data-id');
      let transfer_list = this.state.transfer_list;
      transfer_list[0].meta_data[thidid].inventory_meta[0].selling_price = thisvalue;
      this.setState({transfer_list:transfer_list});
    }
    handleClosePRRequest = () =>{
      if(document.getElementById("handleHideSalesReturnRequestPopup")){
        document.getElementById("handleHideSalesReturnRequestPopup").click();
      }
      let body = {  
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          request_id : this.props.purchase_return_request.request_id,
          action : 'close'
      }
      let api = app_api+'/purchase_return_request_action';
      axios.post(api, body)
        .then(function (response) {
          //window.location.href = '/settings/stock-transfer';
      }).catch(function(res){
      })
    }
    handleAcceptPRRequest = (e) =>{
      let thisid = e.currentTarget.id;
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
        let body = {  
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            request_id : this.props.purchase_return_request.request_id,
            action : 'accept'
        }
        let api = app_api+'/purchase_return_request_action';
        axios.post(api, body)
          .then(function (response) {
            window.location.href = '/billings/sales_return';
        }).catch(function(res){
        })
    }
    handleDeclinePRRequest = (e) =>{
      let thisid = e.currentTarget.id;
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
      let body = {  
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          request_id : this.props.purchase_return_request.request_id,
          action : 'decline'
      }
      let api = app_api+'/purchase_return_request_action';
      axios.post(api, body)
        .then(function (response) {
          window.location.href = '/billings/sales_return';
      }).catch(function(res){
      })
    }
    handleCloseStockTransfer = () =>{
      if(document.getElementById("handleHideStockTransferPopup")){
        document.getElementById("handleHideStockTransferPopup").click();
      }
      let body = {  
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          transfer_data : this.state.transfer_list,
          action : 'close'
      }
      let api = app_api+'/account_transfer_action';
      axios.post(api, body)
        .then(function (response) {
          //window.location.href = '/settings/stock-transfer';
      }).catch(function(res){
      })
    }
    handleAcceptTransferStock = (e) =>{
      let thisid = e.currentTarget.id;
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
        let body = {  
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            transfer_data : this.state.transfer_list,
            action : 'accept'
        }
        let api = app_api+'/account_transfer_action';
        axios.post(api, body)
          .then(function (response) {
            window.location.href = '/billings/purchase';
        }).catch(function(res){
        })
    }
    handleDeclineTransferStock = (e) =>{
      let thisid = e.currentTarget.id;
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
      let body = {  
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          transfer_data : this.state.transfer_list,
          action : 'decline'
      }
      let api = app_api+'/account_transfer_action';
      axios.post(api, body)
        .then(function (response) {
          window.location.href = '/settings/stock-transfer';
      }).catch(function(res){
      })
    }
}