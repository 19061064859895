import React, {Component} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCaretDown,faUser, faBars,faCaretUp, faCaretRight, faPowerOff,faAngleDoubleLeft,faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import check_access from './access-level.js';
import handleRedirect from './redirect.js';
import ShortcutHelp from "./shortcut-help.js";
import FirebaseEvent from '../firebase/events';
import Maintenance from "./maintenance";
import FinancialYear from "./financial-year";
import utils from '../utils.js';
import { ToastContainer, toast } from 'react-toastify';
import ModalBox from "./modal-box.js";
import ToggleOrderPopup from "./toggle-orders-popup.js";
let {enc_key,app_api,user_info,access_level} = require('../library.js');
var CryptoJS = require("crypto-js");
const current_route = window.location.pathname;
if(!user_info && current_route!=='/' && current_route!=='/user-verify' && current_route!=='/forgot-pwd' && !current_route.match(/\/admin-area/g) && !current_route.match(/\/pdf/g)){
  window.location.href="/";
}
let _this;
let cdb;
localStorage.removeItem('is_account_module');
if(user_info){
  let version = localStorage.getItem('IdbVersion');
  version = parseInt(version);
  let cdbReq = window.indexedDB.open('BaazarERP', version);
  cdbReq.onupgradeneeded = function(event) {
    cdb = event.target.result;
  }
  cdbReq.onsuccess = function(event) {
    cdb = event.target.result;
  }
}

export default class Leftnav extends Component {
  constructor() {
        super();
        this.state = {
          logo_url : '',
          company_name : '',
          active_url :'',
          sub_menu :'',
          switch_data :[],
          billing_url : '',
          payment_url : '',
          is_billing:false,
          isMobile : false,
          enable_tcs : false,
          enable_audit_trial : false,
          is_accounting_module : false,
          menu_class : '',
          maintenanceSettings : [],
          maintain_page_collapse:'',
          is_create_quotation:'',
          contact_menu_lbl:'',
          enable_orders : false,
          modules : [],
          stock_transfers : [],
          purchase_return_request : {},
          is_show_sales_return_request : false,
          is_stock_transfer : false,
          linked_stores : [],
          is_child_stroe : false
        }
        _this = this;
        this.maintenanceSettings = [];
        this.handleMenuExpand = this.handleMenuExpand.bind(this);
        this.handleExpandMenu = this.handleExpandMenu.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleSwitchBranch = this.handleSwitchBranch.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleRedirect = handleRedirect.redirect.bind(this);
        this.handleReadStickeyNotification = this.handleReadStickeyNotification.bind(this);
        this.handleContactSync = this.handleContactSync.bind(this);
        this.handleMenuCollapse = this.handleMenuCollapse.bind(this);
        this.handleMenuState = this.handleMenuState.bind(this);
        this.resetMenuState = this.resetMenuState.bind(this);
        this.uuid = utils.uuid.bind(this);
        this.is_access = check_access.is_access.bind(this);
        this.isMobile = utils.isMobile.bind(this);
    }
    handleMenuCollapse(e){
      let id = e.currentTarget.id;
      let cls = document.querySelectorAll(".menu-collapse-icon");
      for(let i=0;i<cls.length;i++){
        cls[i].classList.toggle('hide');
      }
      document.getElementById('sidebar-wrapper').classList.toggle('shrink-side-bar');
      let data_target = document.querySelector(".menu-collapse-icon.hide").getAttribute('data-target');
      localStorage.setItem('collapse-menu',data_target);
      let menu_text = document.querySelectorAll('.menu-txt-parent');
      for(let i=0;i<menu_text.length;i++){
        let menu_name = '';
        if(data_target==='collpase'){
           menu_name = menu_text[i].innerHTML;
          menu_name = menu_name.charAt(0);
        }else{
           menu_name = menu_text[i].getAttribute('data-value');
        }
        menu_text[i].innerHTML = menu_name;
      }
      if(data_target==='collpase'){
        this.setState({maintain_page_collapse:'maintain-collapse'});
      }else{
        this.setState({maintain_page_collapse:''});
      }
    }
    handleReadStickeyNotification(e){
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          name : 'new_purchase_created',
          value : false,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
      }
      let npcapi = app_api+'/save_option';
      axios.post(npcapi, send_data)
        .then(function (response) {
        this.setState({is_billing:false});
      }.bind(this)).catch(function(res){
          
      })
    }

    handleKeyPress(e){
      
      if(this.state.active_url==='new-pos'){
        return true;
      }
      if(document.getElementById("bill-payment-popup")){
        let is_block = document.getElementById("bill-payment-popup").style.display;
        if(is_block==='block'){
          return true;
        }
      }
      if(document.getElementById("billing-pos-payment-popup")){
        let is_block = document.getElementById("billing-pos-payment-popup").style.display;
        if(is_block==='block'){
          return true;
        }
      }
      if(e.which===112){
        e.preventDefault();
        window.location.href = '/new-billing/1';
      }else if(e.which===113){
        e.preventDefault();
        window.location.href = '/new-payment/all';
      }
      if(e.ctrlKey && e.altKey && e.which===79){
        e.preventDefault();
        let body = {  
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          store_state : user_info.state,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
      }
      let api = app_api+'/toggle_enable_orders';
      axios.post(api, body)
      .then(function (response) {
          window.location.reload();
        }.bind(this)).catch(function(res){
          
        })
      }
    }

    handleExpandMenu(e){
      document.getElementById("sidebar-wrapper").classList.toggle("expand-menu");
    }

    handleSwitchBranch(e){
        let thisid = e.currentTarget.id;
        document.getElementById("company_lists").classList.toggle("hide");
    }
    handleMenuExpand(e){
        let thisid = e.currentTarget.id;
        document.getElementById("sub-"+thisid).classList.toggle("active");
        document.getElementById("r-"+thisid).classList.toggle("active");
        document.getElementById("l-"+thisid).classList.toggle("active");
        this.setState({maintain_page_collapse:''});
    }

    handleContactSync(cid){
      let list_data = {};
      list_data = {
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          id : cid,
          type : 'contact_sync'
       }
      let api = app_api+'/get_contact';
      let xhr = new XMLHttpRequest();
      xhr.open('POST', api);
      xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
      xhr.send(JSON.stringify(list_data));
      xhr.onload = function() {
      if (xhr.status != 200) { // analyze HTTP status of the response
        //console.log(`Error ${xhr.status}: ${xhr.statusText}`); // e.g. 404: Not Found
      } else { // show the result
          let response = JSON.parse(xhr.response);
          let resp_data = response;
          
          for(let i=0;i<resp_data.length;i++){
            let key  = resp_data[i].contact_id;
            let type = resp_data[i].type;
            let contact_type = resp_data[i].contact_type;
            let value= resp_data[i];                
            value = CryptoJS.AES.encrypt(JSON.stringify(value), enc_key).toString();
            let trans = cdb.transaction("contacts", "readwrite");
            let ctype = trans.objectStore("contacts");
            let contact_value = {};
            contact_value['id'] = key;
            contact_value['type'] = type;
            contact_value['contact_type'] = contact_type;
            contact_value['data'] = value;
            ctype.add(contact_value);
          }
         
        }
      };
      xhr.onprogress = function(event) {
      if (event.lengthComputable) {
        //console.log(`Received ${event.loaded} of ${event.total} bytes`);
      } else {
         //cdb.close();
        //console.log(`Received ${event.loaded} bytes`); // no Content-Length
      }
      };
      xhr.onerror = function() {
      //console.log("Request failed");
      };
    }

    handleMenuState(){
      let curl = window.location.href;
      const active_url = curl.match(/billing|quotation|contact|setting|payment|inventory|reports|product|inventories|variant|credit-debit-note|view-credit-debit|expenses|new-expense|edit-expense|view-expense|profile|dashboard|transports|accounting|view-accounts|order|orders|new-pos|}+/i);
      let sub_menu = curl.match(/company-details|billings-and-payments|inventory|contacts|user-management|link-store|stock-transfer|accounts|barcode-management|products|inventories|variant|notifications|sales_return|purchase_return|purchase|daily-sales-report|transactions|payments\/in|payments\/out|payments\/cheques|order\/payments\/in|order\/payments\/out|credit-debit-note\/debit|credit-debit-note|view-credit-debit\/debit|view-credit-debit\/credit|expenses|tax|tcs-tax|pending-invoice|profit-loss|sales-man|product-report|retained-customer|stock-ledger|audit-trial|unpaid_bills|adjust_unpaid_bills|trial-balance|}+/i);
      
      if(active_url && active_url[0]!==undefined && (active_url[0]==='new-expense' || active_url[0]==='edit-expense' || active_url[0]==='view-expense')){
        active_url[0] = 'expenses';
        sub_menu = false;
      }
      
      if(active_url && active_url[0]==="order"){
        active_url[0] = 'orders';
      }

      if(active_url){
        this.setState({active_url:active_url[0]});
      }

      if(sub_menu && (active_url[0]!=='inventory' && active_url[0]!=='contact' && active_url[0]!=='expenses')){
        this.setState({sub_menu:sub_menu[0]});

      }
      const add_billing_type = curl.match(/new-billing\/(\d+$)/i);

      if(add_billing_type){
          if(add_billing_type[1]==='1'){
            this.setState({sub_menu:''});
          }else if(add_billing_type[1]==='2'){
            this.setState({sub_menu:'purchase'});
          }else if(add_billing_type[1]==='3'){
            this.setState({sub_menu:'sales_return'});
          }else if(add_billing_type[1]==='4'){
            this.setState({sub_menu:'purchase_return'});
          }
      }
      const add_order_type = curl.match(/new-order\/(\d+$)/i);

      if(add_order_type){
          if(add_order_type[1]==='1'){
            this.setState({sub_menu:'sale'});
          }else if(add_order_type[1]==='2'){
            this.setState({sub_menu:'purchase'});
          }else if(add_order_type[1]==='3'){
            this.setState({sub_menu:'sales_return'});
          }else if(add_order_type[1]==='4'){
            this.setState({sub_menu:'purchase_return'});
          }
      }
      const add_order_payment_type = curl.match(/new-payment\/order\/(\S+$)/i);
      const add_payment_type = curl.match(/new-payment\/(\S+$)/i);
      if(add_order_payment_type){
        this.setState({active_url:'orders'});
          if(add_order_payment_type[1]==='all' || add_order_payment_type[1]==='in'){
            this.setState({sub_menu:'order/payments/in'});
            this.setState({menu_class:'newpaymentin'});
          }else if(add_order_payment_type[1]==='out'){
            this.setState({sub_menu:'order/payments/out'});
            this.setState({menu_class:'newpaymentout'});
          }
      }else if(add_payment_type){
          if(add_payment_type[1]==='all' || add_payment_type[1]==='in'){
            this.setState({sub_menu:'payments/in'});
            this.setState({menu_class:'newpaymentin'});
          }else if(add_payment_type[1]==='out'){
            this.setState({sub_menu:'payments/out'});
            this.setState({menu_class:'newpaymentout'});
          }else if(add_payment_type[1]==='cheques'){
            this.setState({sub_menu:'payments/cheques'});
            this.setState({menu_class:'newpaymentcheques'});
          }
      }
    }

    resetMenuState(e){
      this.handleMenuState();
    }

    handleGetLinkedStores = () =>{
      if(user_info.access_level==="1" || user_info.access_level===1){
        let body = {  
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          store_state : user_info.state,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          target : 'switch'
      }
      let api = app_api+'/get_linked_stores';
      axios.post(api, body)
      .then(function (response) {
          let resp = response.data;
          this.setState({linked_stores:resp.data});
          this.setState({is_child_stroe:resp.is_child});
        }.bind(this)).catch(function(res){
          
        })
      }
    }

    componentDidMount(){
      if(user_info._schema===undefined){
        window.location.href = '/login';
      }
      var isMobile = this.isMobile();
      if(isMobile){
        this.setState({isMobile:true});
        localStorage.removeItem('collapse-menu');
      }
      if(localStorage.getItem('collapse-menu')=="collpase"){
        this.setState({maintain_page_collapse:'maintain-collapse'});
        setTimeout(function(){
          let cls = document.querySelectorAll(".menu-collapse-icon");
          for(let i=0;i<cls.length;i++){
            cls[i].classList.toggle('hide');
          }
          //document.getElementById('sidebar-wrapper').classList.toggle('shrink-side-bar');
          let menu_text = document.querySelectorAll('.menu-txt-parent');
          for(let i=0;i<menu_text.length;i++){
            let menu_name = menu_text[i].innerHTML;
            menu_name = menu_name.charAt(0);
            menu_text[i].innerHTML = menu_name;
          }
        },1);
      }
      document.addEventListener("keydown", this.handleKeyPress);
      this.handleRedirect();
      let billing_url = '';
      if(this.is_access('billing-sale')){
        billing_url = '/billings';
      }else if(this.is_access('billing-purchase')){
        billing_url = '/billings/purchase';
      }else if(this.is_access('billing-sales-return')){
        billing_url = '/billings/sales_return';
      }else if(this.is_access('billing-purchase-return')){
        billing_url = '/billings/purchase_return';
      }
      this.setState({billing_url:billing_url});

      let payment_url = '';
      if(this.is_access('payments-in') && this.is_access('payments-out')){
        payment_url = '/payments';
      }else if(this.is_access('payments-in')){
        payment_url = '/payments/in';
      }else if(this.is_access('payments-out')){
        payment_url = '/payments/out';
      }
      this.setState({payment_url:payment_url});
      
      this.setState({logo_url:user_info.logo_url});
      
      this.handleMenuState();
      let curl = window.location.href;
      const is_billing = curl.match(/billings$/i);
      const is_billing1 = curl.match(/billings\/([a-z_A-Z]+$)/i);
      const is_billing2 = curl.match(/billings\/([a-z_A-Z]+)\/(\d+$)/i);

      var body = {
        key: "maintenance_modes",
        target: "user",
      };
      axios.post(app_api+'/admin/get_option', body)
        .then(function (response) {
        
        if(response.data.length>0){
          this.maintenanceSettings = response.data;
          this.setState({maintenanceSettings:this.maintenanceSettings});
        }
      }.bind(this)).catch(function(res){
          
      })

       let bc_send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          access_level : user_info.access_level,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          keys:"'barcode_sequence_settings','contact_saved_ids','is_create_quotation','enable_orders','enable_tcs','enable_audit_trial'"
      }
      let bc_api = app_api+'/get_settings';
      axios.post(bc_api, bc_send_data)
        .then(function (response) {
          let resp = response.data;
          if(resp.enable_orders){
            this.setState({enable_orders:resp.enable_orders});
          }
          if(resp.enable_tcs){
            this.setState({enable_tcs:resp.enable_orders});
          }
          if(resp.enable_audit_trial){
            this.setState({enable_audit_trial:resp.enable_audit_trial});
          }
          if(resp.is_create_quotation){
            let ps = resp.is_create_quotation;
            this.setState({is_create_quotation:ps});
            if(ps!==true && this.state.active_url==='quotation'){
              window.location.href ='/billings';
            }
          }else{
            
            if(this.state.is_create_quotation!==true && this.state.active_url==='quotation'){
              window.location.href ='/billings';
            }
          }
          if(resp.barcode_sequence_settings){
            let barcode_sequence_settings = resp.barcode_sequence_settings;
            let is_auto_generate_barcode = barcode_sequence_settings.is_auto_generate_barcode;
            localStorage.setItem('is_auto_generate_barcode',is_auto_generate_barcode);
          }else{
            localStorage.setItem('is_auto_generate_barcode',true);
            let barcode_preview = {preview:'001',is_auto_generate_barcode:true,barcode_prefix:'',barcode_separator:'',barcode_category_sequence:'',barcode_category_separator:'',barcode_sequence_number:'001',barcode_sequence_separator:'',barcode_sufix:''};

            let bs_send_data = {
                store_name:user_info.store_name,
                branch_id:user_info.login_id,
                user_id : user_info.user_id,
                name : 'barcode_sequence_settings',
                value : barcode_preview,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
            }
            
            let bsapi = app_api+'/save_option';
            axios.post(bsapi, bs_send_data)
              .then(function (response) {
            }.bind(this)).catch(function(res){
                
            })
          }

         /* if(resp.contact_saved_ids!==undefined){
            let cids = resp.contact_saved_ids;
            for(let j=cids.length-1;j>=0;j--){
                let cid = parseInt(cids[j]);
                let version = localStorage.getItem('IdbVersion');
                version = parseInt(version);
                let dbReq = window.indexedDB.open('BaazarERP', version);
                let db;
                dbReq.onupgradeneeded = function(event) {
                  db = event.target.result;
                }
                dbReq.onsuccess = function(event) {
                  db = event.target.result;
                  try{
                    let transaction = db.transaction("contacts", "readwrite");
                    let contacts = transaction.objectStore("contacts");
                    let request = contacts.get(cid);
                    request.onsuccess = function(e) {
                      let cursor =  e.target.result;
                      if(cursor===undefined){
                        _this.handleContactSync(cid);
                      }
                      db.close();
                    }; 
                  }catch{}
                }
            }
          }*/

      }.bind(this)).catch(function(res){
          if(res.response){
            if(res.response.data){
                
            }
          }
      })
      this.setState({company_name:user_info.company_name});
      let send_data = {
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
        }
        let api = app_api+'/get_branch_info';
        axios.post(api, send_data)
          .then(function (response) {
            let resp = response.data;

            this.setState({switch_data:resp});
            for(let i=0;i<resp.length;i++){
              let branch_id     = resp[0].branch_id;
              let logo_url      = resp[0].logo_url;
              let company_name  = resp[0].company_name;
              let company_sub_title  = resp[0].company_sub_title;
              let gst_type  = resp[0].gst_type;
              this.setState({is_accounting_module:resp[0].is_accounting_module});
              this.setState({modules:resp[0].modules});
              if(resp[0].stock_transfers!==undefined){
                this.setState({stock_transfers:resp[0].stock_transfers});
              }
              if(resp[0].purchase_return_request!==undefined && resp[0].purchase_return_request.request_id!==undefined){
                this.setState({purchase_return_request:resp[0].purchase_return_request,is_show_sales_return_request:true});
              }
              this.setState({is_stock_transfer:true});
              let is_account_module = 'false';
              if(resp[0].is_accounting_module===true){
                is_account_module = 'true';
                this.setState({contact_menu_lbl:'Accounts'});
              }else{
                this.setState({contact_menu_lbl:'Contacts'});
              }
              localStorage.setItem('is_account_module',is_account_module);
              if(branch_id==user_info.login_id){
                 let user_info = JSON.parse(localStorage.getItem('user'));
                  user_info.response.company_name = company_name;
                  user_info.response.company_sub_title = company_sub_title;
                  user_info.response.gst_type = gst_type;
                  user_info.response.logo_url = logo_url;
                  user_info = JSON.stringify(user_info);
                  localStorage.setItem('user', user_info);
              }
            }
        }.bind(this)).catch(function(res){
            
        })

        let clog = {
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            role_id : user_info.access_level,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
        }
        let clapi = app_api+'/check_is_logged_in';
        axios.post(clapi, clog)
          .then(function (response) {
            let resp = response.data;
            if(resp.login_count===0){
              window.location.href = '/login';
            }
            try{
              let db;
           
              let version = localStorage.getItem('IdbVersion');
              version = parseInt(version);
              let dbReq = window.indexedDB.open('BaazarERP', version);
              dbReq.onupgradeneeded = function(event) {
                db = event.target.result;
              }
              dbReq.onsuccess = function(event) {
                db = event.target.result;
                let transaction = db.transaction("contacts", "readwrite");
                let contacts = transaction.objectStore("contacts");
                let contact_count = contacts.count();
                contact_count.onsuccess = function(e) {
                  let value = e.target.result;
                  if(value!==undefined){
                    if(resp.contact_count!==value){
                      var request = window.indexedDB.deleteDatabase("ERP");
                      request.onsuccess = function(e) {  };
                      request.onblocked = function(e) {
                      };
                      request.onerror = function(e) {};
                    }
                  }
                }
              }
              
            }catch(e){}
        }.bind(this)).catch(function(res){
          window.location.href = '/login';
        })
        let fetch_inv = localStorage.getItem('fetch_inv');
        if(fetch_inv){
            let _this = this;
            let fetch_timer = setInterval(function() {
              fetch_inv = localStorage.getItem('fetch_inv');
              if(fetch_inv){
                var d1 = new Date();
                var d2 = new Date(fetch_inv);
                
                if(d1>d2){
                  localStorage.removeItem('fetch_inv');
                  let body = {};
                  body = {
                        store_name : user_info.store_name,
                        branch_id : user_info.login_id,
                        user_id : user_info.user_id,
                        active_tab :'New Sale',
                        uuid : utils.uuid(),
                        role_id : user_info.access_level,
                        token : user_info.token,
                        _schema : user_info._schema,
                    }
                  let api = app_api+'/inventory/get_inventory_items';
                  axios.post(api, body)
                    .then(function (response) {
                  }.bind(_this)).catch(function(res){
                      if(res.response){
                        if(res.response.data){
                            
                        }
                      }
                  })
                  clearInterval(fetch_timer);
                }
              }else{
                clearInterval(fetch_timer);
              }
          }, 1000);
        }
        this.handleGetLinkedStores();
    }

    handleHideStockTransferPopup = ()=>{
      this.setState({is_stock_transfer:false});
    }
    handleHideSalesReturnRequestPopup = ()=>{
      this.setState({is_show_sales_return_request:false});
    }

    handleOpenStockTransferReqeust = ()=>{
      let id = localStorage.getItem('stock_transfer_id');
      let body = {  
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          store_state : user_info.state,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          id : id
      }
      let api = app_api+'/open_stock_transfer_popup';
      axios.post(api, body)
      .then(function (response) {
        this.setState({stock_transfers:response.data.stock_transfers});
        this.setState({is_stock_transfer:true});
        localStorage.removeItem('stock_transfer_id')
      }.bind(this)).catch(function(res){
        
      })
    }
    handleOpenPurchaseReturnRequestReqeust = ()=>{
      let id = localStorage.getItem('request_id');
      let body = {  
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          store_state : user_info.state,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          id : id
      }
      let api = app_api+'/get_purchase_return_request_on_id';
      axios.post(api, body)
      .then(function (response) {
        this.setState({purchase_return_request:response.data.response,is_show_sales_return_request:true});
        localStorage.removeItem('request_id')
      }.bind(this)).catch(function(res){
        
      })
    }

    handleCompanyChange(e){
      let branch_id = e.currentTarget.id;
      let index = e.currentTarget.getAttribute('data-id');
      let data = this.state.switch_data[index];

      let logo_url = data.logo_url;
      let company_name = data.company_name;
      let email = data.email;
      let user_info = JSON.parse(localStorage.getItem('user'));
      user_info.response.login_id = parseInt(branch_id);
      user_info.response.company_name = company_name;
      user_info.response.logo_url = logo_url;
      user_info.response.email = email;
      user_info = JSON.stringify(user_info);
      localStorage.setItem('user', user_info);
      window.location.reload();
    }

    handleChangeStore = (e) =>{
      localStorage.clear();
      let sname = e.currentTarget.id;
      let body = {  
          store_name : sname,
          parent_store_name : user_info.store_name,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
      }
      let api = app_api+'/linked_store_login';
      axios.post(api, body)
      .then(function (response) {
        let success = response.data.success;
        if(success===1){
          let store_name = {store_name:sname};
          store_name = CryptoJS.AES.encrypt(JSON.stringify(store_name), enc_key).toString();
          localStorage.setItem('store_name',JSON.stringify(store_name));
          localStorage.setItem('user', JSON.stringify(response.data));
          window.location = "/billings";
        }else if(success===2){
          
        }
      }.bind(this)).catch(function(res){
        
      })
    }
    render() {
        let access = this.state.access_level;
        let active_url = this.state.active_url;
        let hostname = window.location.hostname;
        let shrink_side_bar = '';
        
        if(localStorage.getItem('collapse-menu')=="collpase"){
          shrink_side_bar = 'shrink-side-bar';
        }
        return (
            <>
            <span id="handleOpenStockTransferReqeust" onClick={this.handleOpenStockTransferReqeust}></span>
            <span id="handleOpenPurchaseReturnRequestReqeust" onClick={this.handleOpenPurchaseReturnRequestReqeust}></span>
            {(this.state.is_billing===true) && 
              <a onClick={this.handleReadStickeyNotification} className="stickey-notification" href="/products/draft" target="_blank">
                We have created the items in Draft
              </a>
            }
            {(this.state.stock_transfers.length>0 && this.state.is_stock_transfer===true) &&
              <>
                <span id="handleHideStockTransferPopup" onClick={this.handleHideStockTransferPopup}></span>
                <ModalBox id="stock_transfer" transfer_list={this.state.stock_transfers}/>
              </>
            }
            {(this.state.is_show_sales_return_request===true) &&
              <>
                <span id="handleHideSalesReturnRequestPopup" onClick={this.handleHideSalesReturnRequestPopup}></span>
                <ModalBox id="purchase_return_request" purchase_return_request={this.state.purchase_return_request}/>
              </>
            }
            {(this.is_access('notifications') && hostname!=='45.32.126.205') &&
              <FirebaseEvent />
            }

            {(this.is_access('notifications') && hostname==='45.32.126.205') &&
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                toastClassName='toast-error'
                />
            }
            {(this.is_access('notifications')===false) &&
                <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                toastClassName='toast-error'
                />
            }
            {(this.state.maintenanceSettings && this.state.maintenanceSettings.length > 0) ?this.state.maintenanceSettings.map( (value, index) => {
               return (
                  <Maintenance value={value} index={index} target="user" className={"maintain-page "+this.state.maintain_page_collapse} id={"maintain-page-"+index}/>
              )
            }) : '' }
            <FinancialYear className={"maintain-page "+this.state.maintain_page_collapse} target="strip"/>
            <div  id="sidebar-wrapper" className={"bg-light border-right "+shrink_side_bar}>
                <div className={"mobile-menu "+this.state.menu_class} onClick={this.handleExpandMenu}>
                  <FontAwesomeIcon icon={faBars}/>
                </div>
                <span id="resetMenuState" onClick={this.resetMenuState}></span>
                <div className="sidebar-heading">
                  <a className="navbar-brand menu-brand" href="/">
                    <span id="Company_Name">{this.state.company_name}</span>
                  </a>
                  {this.state.linked_stores && this.state.linked_stores.length > 0 &&
                  <>
                  <div className="branch-switcher" onClick={this.handleSwitchBranch}>
                    <div className="switch">
                      <FontAwesomeIcon icon={faCaretUp} />
                      </div>
                      <div className="switch">
                    <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </div>
                  
                  <div className="br-list-blk hide" id="company_lists">
                      <ul className="list-group">
                        {(this.state.linked_stores.length > 0) ?this.state.linked_stores.map( (value, index) => {

                           return (
                            <li key={index} className="list-group-item br-li" onClick={this.handleChangeStore} id={value.linked_store_name}>{value.linked_store_name}</li>
                          )
                         }) : '' }
                      </ul>
                  </div>
                  </>
                  }
                </div>
                <div className="list-group list-group-flush" id="menu-items">

                  {(this.is_access('billing') && this.state.billing_url!=='') &&
                  <div className="sub-menu-bind">
                  <a onClick={this.handleMenuExpand} id="billing" className={"list-group-item list-group-item-action parent-menu main-menu-item "+((active_url==='billing' || active_url==='')?'active':'')}><span className="menu-txt-parent" data-value="Billings">Billings</span> 
                    <span className="caret-icon"> 
                      <span className={"menu-direct "+((active_url!=='billing' && active_url!=='')?'active':'')} id="r-billing">
                        <FontAwesomeIcon icon={faCaretRight} />
                      </span> 
                      <span className={"menu-direct "+((active_url==='billing' || active_url==='')?'active':'')} id="l-billing">
                        <FontAwesomeIcon icon={faCaretDown} />
                      </span>
                    </span>
                    <ShortcutHelp  arrow="left" target="new" shortcuts={['Ctrl+Alt+Shift+B']}/>
                  </a>
                  <div className={"list-group list-group-flush let-sub-menu "+((active_url==='billing' || active_url==='' || active_url==='quotation')?'active':'')} id="sub-billing">
                    <a className={"list-group-item list-group-item-action sub-menu-parent"}><span className="menu-txt">Billings</span></a>

                     {(this.is_access('billing-sale')) &&
                      <a id="billing-sale-menu" className={"list-group-item list-group-item-action "+(((active_url==='billing' || active_url==='') && this.state.sub_menu==='')?'current':'')} href="/billings"><span className="menu-txt">Sales</span></a>
                     }
                     {(this.is_access('billing-purchase')) &&
                      <a className={"list-group-item list-group-item-action "+(((active_url==='billing' || active_url==='') && this.state.sub_menu==='purchase')?'current':'')} href="/billings/purchase"><span className="menu-txt">Purchase</span></a>
                     }
                     {(this.is_access('billing-sales-return')) &&
                      <a className={"list-group-item list-group-item-action "+(((active_url==='billing' || active_url==='') && this.state.sub_menu==='sales_return')?'current':'')} href="/billings/sales_return"><span className="menu-txt">Sales Return</span></a>
                      }
                      {(this.is_access('billing-purchase-return')) &&
                      <a className={"list-group-item list-group-item-action "+(((active_url==='billing' || active_url==='') && this.state.sub_menu==='purchase_return')?'current':'')} href="/billings/purchase_return"><span className="menu-txt">Purchase Return</span></a>
                      }
                      {(this.is_access('billing-quotation') && this.state.is_create_quotation===true) &&
                      <a className={"list-group-item list-group-item-action "+(((active_url==='billing' || active_url==='') && this.state.active_url==='quotation')?'current':'')} href="/quotation"><span className="menu-txt">Quotation</span></a>
                      }
                      <a className={"list-group-item list-group-item-action "+(((active_url==='billing' || active_url==='') && this.state.sub_menu==='unpaid_bills')?'current':'')} href="/billings/unpaid_bills"><span className="menu-txt">Unpaid Sale Bills</span></a>
                    </div>
                  </div>
                  }
                  {(this.is_access('billing') && this.state.billing_url!=='' && this.state.enable_orders===true) &&
                  <div className="sub-menu-bind">
                  <a onClick={this.handleMenuExpand} id="orders" className={"list-group-item list-group-item-action parent-menu main-menu-item "+((active_url==='orders')?'active':'')}><span className="menu-txt-parent" data-value="Orders">Orders</span> 
                    <span className="caret-icon"> 
                      <span className={"menu-direct "+((active_url!=='orders')?'active':'')} id="r-orders">
                        <FontAwesomeIcon icon={faCaretRight} />
                      </span> 
                      <span className={"menu-direct "+((active_url==='orders')?'active':'')} id="l-orders">
                        <FontAwesomeIcon icon={faCaretDown} />
                      </span>
                    </span>
                    <ShortcutHelp  arrow="left" target="new" shortcuts={['Ctrl+Alt+Shift+B']}/>
                  </a>
                  <div className={"list-group list-group-flush let-sub-menu "+((active_url==='orders')?'active':'')} id="sub-orders">
                    <a className={"list-group-item list-group-item-action sub-menu-parent"}><span className="menu-txt">Orders</span></a>

                     {(this.is_access('billing-sale')) &&
                      <a id="orders-sale-menu" className={"list-group-item list-group-item-action "+(((active_url==='orders') && (this.state.sub_menu==='' || this.state.sub_menu==='sale'))?'current':'')} href="/orders"><span className="menu-txt">Sales Order</span></a>
                     }
                     {(this.is_access('billing-purchase')) &&
                      <a className={"list-group-item list-group-item-action "+((active_url==='orders' && this.state.sub_menu==='purchase')?'current':'')} href="/orders/purchase"><span className="menu-txt">Purchase Order</span></a>
                     }
                     {(this.is_access('billing-sales-return')) &&
                      <a className={"list-group-item list-group-item-action "+((active_url==='orders' && this.state.sub_menu==='sales_return')?'current':'')} href="/orders/sales_return"><span className="menu-txt">Sales Order Return</span></a>
                      }
                      {(this.is_access('billing-purchase-return')) &&
                      <a className={"list-group-item list-group-item-action "+((active_url==='orders' && this.state.sub_menu==='purchase_return')?'current':'')} href="/orders/purchase_return"><span className="menu-txt">Purchase Order Return</span></a>
                      }
                      {(this.is_access('payments-in')) &&
                        <a  className={"list-group-item list-group-item-action "+((active_url==='orders' && this.state.sub_menu==='order/payments/in')?'current':'')} href="/order/payments/in"><span className="menu-txt">Payment IN</span></a>
                      }
                      {(this.is_access('payments-out')) &&
                        <a className={"list-group-item list-group-item-action "+((active_url==='orders' && this.state.sub_menu==='order/payments/out')?'current':'')} href="/order/payments/out"><span className="menu-txt">Payment OUT</span></a>
                      }
                    </div>
                  </div>
                  }
                  {(this.is_access('payments') && this.state.payment_url!=='') &&
                  <div className="sub-menu-bind">
                    <a onClick={this.handleMenuExpand} id="payments" className={"list-group-item list-group-item-action main-menu-item "+(active_url==='payment'?'active':'')}>
                    <span className="menu-txt-parent"  data-value="Payments">Payments</span> 
                    <span className="caret-icon"> 
                      <span className={"menu-direct "+((active_url!=='payment')?'active':'')} id="r-payments">
                        <FontAwesomeIcon icon={faCaretRight} />
                      </span> 
                      <span className={"menu-direct "+((active_url==='payment')?'active':'')} id="l-payments">
                        <FontAwesomeIcon icon={faCaretDown} />
                      </span>
                    </span>
                    <ShortcutHelp  arrow="left" target="new" shortcuts={['Ctrl+Alt+Shift+P']}/>
                    </a>
                    <a href={this.state.payment_url} className={"list-group-item list-group-item-action menu-expand "}>
                      <span className="menu-txt">Payments</span>
                    </a>
                    <div className={"list-group list-group-flush let-sub-menu "+((active_url==='payment')?'active':'')} id="sub-payments">
                    <a className={"list-group-item list-group-item-action sub-menu-parent"}><span className="menu-txt">Payments</span></a>
                     {(this.is_access('payments-in') && this.is_access('payments-out')) &&
                      <a  className={"list-group-item list-group-item-action "+((active_url==='payment' && this.state.sub_menu==='')?'current':'')} href="/payments"><span className="menu-txt">All</span></a>
                     }
                     {(this.is_access('payments-in')) &&
                      <a  className={"list-group-item list-group-item-action "+((this.state.sub_menu==='payments/in')?'current':'')} href="/payments/in"><span className="menu-txt">Payment IN</span></a>
                     }
                     {(this.is_access('payments-out')) &&
                      <a className={"list-group-item list-group-item-action "+((this.state.sub_menu==='payments/out')?'current':'')} href="/payments/out"><span className="menu-txt">Payment OUT</span></a>
                     }
                     {(this.state.contact_menu_lbl==='Accounts') &&
                      <a className={"list-group-item list-group-item-action "+((this.state.sub_menu==='payments/cheques')?'current':'')} href="/payments/cheques/pending/all"><span className="menu-txt">Cheques</span></a>
                     }
                     <a className={"list-group-item list-group-item-action "+(this.state.sub_menu==='adjust_unpaid_bills'?'current':'')} href="/payments/adjust_unpaid_bills"><span className="menu-txt">Adjust Unpaid Bills</span></a>
                    </div>
                  </div>
                  }
                  {(this.is_access('debit_credit_note')) &&
                  <div className="sub-menu-bind">
                    <a onClick={this.handleMenuExpand} id="credit-debit-note" className={"list-group-item list-group-item-action main-menu-item "+((active_url==='credit-debit-note' || active_url==='view-credit-debit')?'active':'')}>

                      <span className="menu-txt-parent"  data-value="Cr/Dr Note">Cr/Dr Note</span>
                      <span className="caret-icon"> 
                        <span className={"menu-direct "+((active_url!=='credit-debit-note' && active_url!=='view-credit-debit')?'active':'')} id="r-credit-debit-note">
                          <FontAwesomeIcon icon={faCaretRight} />
                        </span> 
                        <span className={"menu-direct "+((active_url==='credit-debit-note' || active_url==='view-credit-debit')?'active':'')} id="l-credit-debit-note">
                          <FontAwesomeIcon icon={faCaretDown} />
                        </span>
                      </span>
                    </a>

                    <a href={"/credit-debit-note/credit"} className={"list-group-item list-group-item-action menu-expand "}>
                      <span className="menu-txt">Cr/Dr Note</span>
                    </a>
                    
                    <div className={"list-group list-group-flush let-sub-menu "+((active_url==='credit-debit-note' || active_url==='view-credit-debit')?'active':'')} id="sub-credit-debit-note">
                      <a className={"list-group-item list-group-item-action sub-menu-parent"}><span className="menu-txt">Cr/Dr Note</span></a>
                      <a className={"list-group-item list-group-item-action "+((this.state.sub_menu==='credit-debit-note' || this.state.sub_menu==='view-credit-debit/credit')?'current':'')} href="/credit-debit-note/credit"><span className="menu-txt">Credit Note</span></a>
                     
                      <a className={"list-group-item list-group-item-action "+((this.state.sub_menu==='credit-debit-note/debit' || this.state.sub_menu==='view-credit-debit/debit')?'current':'')} href="/credit-debit-note/debit"><span className="menu-txt">Debit Note</span></a>
                     
                    </div>
                  </div>
                  }
                  {this.is_access('inventory') &&
                  <div className="sub-menu-bind">
                  <a onClick={this.handleMenuExpand} id="products" className={"list-group-item list-group-item-action parent-menu main-menu-item "+((active_url==='product' || active_url==='inventories' || active_url==='variant')?'active':'')}><span className="menu-txt-parent" data-value="Products">Products</span> 
                  <span className="caret-icon"> 
                    <span className={"menu-direct "+((active_url!=='product' && active_url!=='inventories' && active_url!=='variant')?'active':'')} id="r-products">
                      <FontAwesomeIcon icon={faCaretRight} />
                    </span> 
                    <span className={"menu-direct "+((active_url==='product' || active_url==='inventories' || active_url==='variant')?'active':'')} id="l-products">
                      <FontAwesomeIcon icon={faCaretDown} />
                    </span>
                  </span>
                    <ShortcutHelp  arrow="left" target="new" shortcuts={['Ctrl+Alt+Shift+P']}/>
                  </a>
                 
                  <div className={"list-group list-group-flush let-sub-menu "+((active_url==='product' || active_url==='inventories' || active_url==='variant')?'active':'')} id="sub-products">
                    <a className={"list-group-item list-group-item-action sub-menu-parent"}><span className="menu-txt">Products</span></a>
                    <a className={"list-group-item list-group-item-action "+((this.state.sub_menu==='products')?'current':'')} href="/products"><span className="menu-txt">All Products</span></a>
                    
                    <a className={"list-group-item list-group-item-action "+((this.state.sub_menu==='inventories' || active_url==='variant')?'current':'')} href="/inventories"><span className="menu-txt">Inventory</span></a>
                  </div>
                </div>
                }
                 {this.is_access('contacts') &&
                  <>
                  {(this.state.contact_menu_lbl==='Contacts') &&
                    <div className="menu-without-child">
                      <a href={"/contacts/1"} className={"list-group-item list-group-item-action main-menu-item "+((active_url==='contact')?'active':'')}><span className="menu-txt-parent"  data-value='Contacts'>Contacts</span> <ShortcutHelp  arrow="left" target="new" shortcuts={['Ctrl+Alt+Shift+C']}/></a>
                      <a href={"/contacts/1"} className={"list-group-item list-group-item-action menu-expand "}><span className="menu-txt">Contacts</span></a>
                    </div>
                  }
                  {(this.state.contact_menu_lbl==='Accounts') &&
                    <div className="menu-without-child">
                      <a href={"/accounting"} className={"list-group-item list-group-item-action main-menu-item "+((active_url==='contact' || active_url==='view-accounts' || active_url==='accounting')?'active':'')}><span className="menu-txt-parent"  data-value='Accounts'>Accounts</span> <ShortcutHelp  arrow="left" target="new" shortcuts={['Ctrl+Alt+Shift+C']}/></a>
                      <a href={"/accounting"} className={"list-group-item list-group-item-action menu-expand "}><span className="menu-txt">Accounts</span></a>
                    </div>
                  }
                  </>
                  }
                {this.is_access('expense') &&
                <div className="menu-without-child">
                  <a href={"/expenses"} className={"list-group-item list-group-item-action main-menu-item "+(active_url==='expenses'?'active':'')}><span className="menu-txt-parent"  data-value="Expenses">Expenses</span></a>
                  <a href={"/expenses"} className={"list-group-item list-group-item-action menu-expand "}><span className="menu-txt">Expenses</span></a>
                </div>
                } 
                {this.is_access('transport') &&
                <div className="menu-without-child">
                  <a href={"/transports"} className={"list-group-item list-group-item-action main-menu-item "+(active_url==='transports'?'active':'')}><span className="menu-txt-parent"  data-value="transports">Transports</span></a>
                  <a href={"/transports"} className={"list-group-item list-group-item-action menu-expand "}><span className="menu-txt">Transports</span></a>
                </div>
                } 
                  
                 

                  {this.is_access('reports') &&
                  <div className="sub-menu-bind">
                    <a onClick={this.handleMenuExpand} id="mreports" className={"list-group-item list-group-item-action parent-menu main-menu-item "+(active_url==='reports'?'active':'')}><span className="menu-txt-parent"  data-value="Reports">Reports</span> 
                    <span className="caret-icon"> 
                    <span className={"menu-direct "+(active_url!=='reports'?'active':'')} id="r-mreports">
                      <FontAwesomeIcon icon={faCaretRight} />
                    </span> 
                    <span className={"menu-direct "+(active_url==='reports'?'active':'')} id="l-mreports">
                      <FontAwesomeIcon icon={faCaretDown} />
                    </span>
                    </span>
                    <ShortcutHelp  arrow="left" target="new" shortcuts={['Ctrl+Alt+Shift+R']}/>
                    </a>
                    <div className={"list-group list-group-flush let-sub-menu "+((active_url==='reports' || active_url==='dashboard')?'active':'')} id="sub-mreports">
                      <a className={"list-group-item list-group-item-action sub-menu-parent"}><span className="menu-txt">Reports</span></a>
                      {this.is_access('reports-sales-dashboard') &&
                        <a className={"list-group-item list-group-item-action "+((active_url==='dashboard' && this.state.sub_menu==='')?'current':'')} href="/dashboard"><span className="menu-txt">Sales Dasboard</span></a>
                      }
                      {this.is_access('reports-ledger') &&
                      <a className={"list-group-item list-group-item-action "+((active_url==='reports' && this.state.sub_menu==='')?'current':'')} href="/reports"><span className="menu-txt">Ledger</span></a>
                      }
                      {this.is_access('reports-daily-sales') &&
                        <a className={"list-group-item list-group-item-action "+((this.state.sub_menu==='daily-sales-report')?'current':'')} href="/reports/daily-sales-report"><span className="menu-txt">Reports</span></a>
                      }
                      {this.is_access('reports-transactions') &&
                        <a className={"list-group-item list-group-item-action "+((this.state.sub_menu==='transactions')?'current':'')} href="/reports/transactions"><span className="menu-txt">Transactions</span></a>
                      }
                      {this.is_access('reports-inventory') &&
                        <a className={"list-group-item list-group-item-action "+((active_url==='reports' && this.state.sub_menu==='inventories')?'current':'')} href="/reports/inventories"><span className="menu-txt">Inventories</span></a>
                      }
                      {this.is_access('reports-stock-ledger') &&
                      <a className={"list-group-item list-group-item-action "+((active_url==='reports' && this.state.sub_menu==='stock-ledger')?'current':'')} href="/reports/stock-ledger"><span className="menu-txt">Stock Ledger</span></a>
                      }
                      {this.is_access('reports-expenses') &&
                      <a className={"list-group-item list-group-item-action "+((active_url==='reports' && this.state.sub_menu==='expenses')?'current':'')} href="/reports/expenses"><span className="menu-txt">Expenses</span></a>
                    }
                    {this.is_access('reports-profit-loss') &&
                      <a className={"list-group-item list-group-item-action "+((active_url==='reports' && this.state.sub_menu==='profit-loss')?'current':'')} href="/reports/profit-loss"><span className="menu-txt">Profit Loss</span></a>
                  }
                  {this.is_access('reports-tax') &&
                      <a className={"list-group-item list-group-item-action "+((active_url==='reports' && this.state.sub_menu==='tax')?'current':'')} href="/reports/tax"><span className="menu-txt">Taxes</span></a>
                }
                {this.is_access('reports-tcs-tax') &&
                <>
                      {(this.state.enable_tcs===true) &&
                        <a className={"list-group-item list-group-item-action "+((active_url==='reports' && this.state.sub_menu==='tcs-tax')?'current':'')} href="/reports/tcs-tax"><span className="menu-txt">TCS Tax</span></a>
                      }
                      </>
                    }
                    {this.is_access('reports-pending-invoices') &&
                      <a className={"list-group-item list-group-item-action "+((active_url==='reports' && this.state.sub_menu==='pending-invoice')?'current':'')} href="/reports/pending-invoice"><span className="menu-txt">Pending Invoices</span></a>
                    }
                    {this.is_access('reports-salesman') &&
                          <a className={"list-group-item list-group-item-action "+((active_url==='reports' && this.state.sub_menu==='sales-man')?'current':'')} href="/reports/sales-man"><span className="menu-txt">Salesman Report</span></a>
                    }
                    {(this.is_access('reports-audit-trial') && this.state.enable_audit_trial===true) &&
                          <a className={"list-group-item list-group-item-action "+((active_url==='reports' && this.state.sub_menu==='audit-trial')?'current':'')} href="/reports/audit-trial"><span className="menu-txt">Audit Trial</span></a>
                    }
                    {this.is_access('reports-trial-balance') &&
                        <a className={"list-group-item list-group-item-action "+((active_url==='reports' && this.state.sub_menu==='trial-balance')?'current':'')} href="/reports/trial-balance"><span className="menu-txt">Trial Balance</span></a>
                    }
                    
                    <a className={"list-group-item list-group-item-action "+((active_url==='reports' && this.state.sub_menu==='product-report')?'current':'')} href="/reports/product-report/top-selling-product"><span className="menu-txt">Product Report</span></a>

                    <a className={"list-group-item list-group-item-action "+((active_url==='reports' && this.state.sub_menu==='retained-customer')?'current':'')} href="/reports/retained-customer"><span className="menu-txt">Retained Customers</span></a>
                    
                    </div>
                    </div>
                  }
                  
                  {this.is_access('settings') &&
                  <div className="sub-menu-bind">
                  <a onClick={this.handleMenuExpand} id="settings" className={"list-group-item list-group-item-action parent-menu main-menu-item "+(active_url==='setting'?'active':'')}><span className="menu-txt-parent"  data-value="Settings">Settings</span>
                  <span className="caret-icon"> 
                    <span className={"menu-direct "+(active_url!=='setting'?'active':'')} id="r-settings">
                      <FontAwesomeIcon icon={faCaretRight} />
                    </span> 
                    <span className={"menu-direct "+(active_url==='setting'?'active':'')} id="l-settings">
                      <FontAwesomeIcon icon={faCaretDown} />
                    </span>
                  </span>
                    <ShortcutHelp  arrow="left" target="new" shortcuts={['Ctrl+Alt+Shift+P']}/>
                  </a>
                  <div className={"list-group list-group-flush let-sub-menu "+(active_url==='setting'?'active':'')} id="sub-settings">
                    <a className={"list-group-item list-group-item-action sub-menu-parent"}><span className="menu-txt">Settings</span></a>
                    {this.is_access('settings-company-details') &&
                    <a className={"list-group-item list-group-item-action "+(this.state.sub_menu==='company-details'?'current':'')} href="/settings/company-details"><span className="menu-txt">Company Details</span></a>
                    }
                    {this.is_access('settings-bill-payment') &&
                    <a className={"list-group-item list-group-item-action "+(this.state.sub_menu==='billings-and-payments'?'current':'')} href="/settings/billings-and-payments"><span className="menu-txt">Billings and Payments</span></a>
                    }
                    {this.is_access('settings-inventory') &&
                    <a className={"list-group-item list-group-item-action "+(this.state.sub_menu==='inventory'?'current':'')} href="/settings/inventory"><span className="menu-txt">Inventory</span></a>
                    }
                    {this.is_access('settings-contacts') &&
                    <a className={"list-group-item list-group-item-action "+(this.state.sub_menu==='contacts'?'current':'')} href="/settings/contacts"><span className="menu-txt">Contacts</span></a>
                    }
                    {(this.state.is_accounting_module===true) &&
                    <a className={"list-group-item list-group-item-action "+(this.state.sub_menu==='accounts'?'current':'')} href="/settings/accounts"><span className="menu-txt">Accounts</span></a>
                    }
                    {this.is_access('settings-user-management') &&
                    <a className={"list-group-item list-group-item-action "+(this.state.sub_menu==='user-management'?'current':'')} href="/settings/user-management"><span className="menu-txt">User Management</span></a>
                    }
                    {(this.state.modules && this.state.modules.indexOf('link store')>=0) &&
                      <a className={"list-group-item list-group-item-action "+(this.state.sub_menu==='link-store'?'current':'')} href="/settings/link-store"><span className="menu-txt">Link Store</span></a>
                    }
                    {(this.state.modules && this.state.modules.indexOf('stock transfer')>=0) &&
                    <>
                      <a className={"list-group-item list-group-item-action "+(this.state.sub_menu==='stock-transfer'?'current':'')} href="/settings/stock-transfer"><span className="menu-txt">Stock Transfer</span></a>
                      <a className={"list-group-item list-group-item-action "+(this.state.sub_menu==='stock-transfer'?'current':'')} href="/settings/purchase-return-request"><span className="menu-txt">Purchase Return Request</span></a>
                    </>
                    }
                    {this.is_access('settings-barcode') &&
                      <a className={"list-group-item list-group-item-action "+(this.state.sub_menu==='barcode-management'?'current':'')} href="/settings/barcode-management"><span className="menu-txt">Barcode</span></a>
                    }
                    {//this.is_access('settings-expenses') &&
                      <a className={"list-group-item list-group-item-action "+(this.state.sub_menu==='expenses'?'current':'')} href="/settings/expenses"><span className="menu-txt">Expenses</span></a>
                    }
                   
                    {this.is_access('notifications') &&
                      <a className={"list-group-item list-group-item-action "+(this.state.sub_menu==='notifications'?'current':'')} href="/settings/notifications"><span className="menu-txt">Notification</span></a>
                    }
                  </div>
                </div>
                }
                  
                </div>
                <div className="list-group list-group-flush menu-logout main-menu-item " style={{paddingTop:'0px',paddingBottom:'0px'}}>
                  <a href={"/profile"} className={"list-group-item list-group-item-action main-menu-item "+(active_url==='profile'?'active':'')}><FontAwesomeIcon icon={faUser} /> <span className="logout-menu-txt">{user_info.name}</span></a>
                
                </div>
                
                <FinancialYear target="dropdown-menu"/>
                 <ToggleOrderPopup />
                <div className="list-group list-group-flush menu-logout main-menu-item " style={{paddingTop:'0px',paddingBottom:'0px'}}>
                    <a href={"/login"} className="list-group-item list-group-item-action logout-btn"><FontAwesomeIcon icon={faPowerOff} /> <span className="logout-menu-txt">Logout</span></a>
                </div>
                <div className="list-group list-group-flush menu-logout main-menu-item" style={{marginTop:'0px',paddingTop:'0px'}}>
                    <a className="list-group-item list-group-item-action logout-btn" onClick={this.handleMenuCollapse}>
                      <span className="menu-collapse-icon hide" data-target="expand">
                        <FontAwesomeIcon icon={faAngleDoubleRight} /> 
                      </span>
                      <span className="menu-collapse-icon" data-target="collpase" style={{fontSize:'14px'}}>
                        <FontAwesomeIcon icon={faAngleDoubleLeft} /> 
                        <span className="logout-menu-txt">  Collapse</span>
                      </span>
                    </a>
                </div>
            </div>
                
          </>
        );
    }
}