import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import ContactTypes from "./contact-types";
import ServiceLoader from './service-loader.js';
import DrawerLink from './open-drawer-link.js';

import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusSquare, faPencilAlt, faCheckCircle, faTimes,faTrash, faCaretLeft, faCaretRight} from '@fortawesome/free-solid-svg-icons';
import Tooltip from "./tooltip";
import utils from '../utils.js';
import Link from './link.js';
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let db;
if(user_info){
  let version = localStorage.getItem('IdbVersion');
  version = parseInt(version);
  let dbReq = window.indexedDB.open('BaazarERP', version);
  dbReq.onupgradeneeded = function(event) {
    db = event.target.result;
  }
  dbReq.onsuccess = function(event) {
    db = event.target.result;
  }
}
let _this;
export default class ViewContact extends Component {
   constructor() {
      super();
      this.state = {
         show_loader : true,
         contact_list:[],
         agent_contact_list:[],
         current_id:'',
         current_tab:'orders',
         tab_data :'',
         loading_process:'Loading...',
         credit_data : '',
         is_payment : false,
         is_loading : false,
         branches : [],
         active_branch:'',
         page:2,
         show_middle_panel:false,
         scrollPos:0,
         loaded_all:false,
         is_fy_closed : false,
         custom_fields_json : [],
      };
     _this = this;
     this.scrollPos = 0;
     this.handleMultiplePayment = this.handleMultiplePayment.bind(this);
     this.handleMakePayment = this.handleMakePayment.bind(this);
     this.isBottom = this.isBottom.bind(this);
     this.trackScrolling = this.trackScrolling.bind(this);
     this.loadMoreContactBillingData = this.loadMoreContactBillingData.bind(this);
     this.currency_format = utils.currency_format.bind(this);
     
    }

    

    loadMoreContactBillingData(){
        let body = {};
        if(this.state.active_branch!==""){
        this.setState({is_loading:true})
        body = {
              store_name : user_info.store_name,
              branch_id : this.state.active_branch,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, _schema : user_info._schema,
              id : this.state.current_id,
              target : this.state.current_tab,
              page : this.state.page,
           }
        let api = app_api+'/load_more_contact_billing_data';
        axios.post(api, body)
          .then(function (response) {
            let tab_data = this.state.tab_data;
            let billing_data = response.data.contact_billing_data;
            if(billing_data){
              for(let i=0;i<billing_data.length;i++){
                tab_data.push(billing_data[i]);
              }
              this.setState({ 'tab_data': tab_data});
              let page = this.state.page;
              page = page+1;
              this.setState({page:page});
              this.setState({is_loading:false});
              //document.addEventListener('scroll', this.trackScrolling);
            }else{
              this.setState({is_loading:false});
              this.setState({loaded_all:true});
              //document.removeEventListener('scroll', this.trackScrolling);
            }
            
        }.bind(this)).catch(function(res){
            if(res.response){
              
            }
        })
      }
    }

    isBottom(el) {
      if ((document.body.getBoundingClientRect()).top > this.scrollPos){
        return false;
      }else{
        this.scrollPos = (document.body.getBoundingClientRect()).top;
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;
        if (windowBottom >= docHeight) {
          return true;
        } else {
          return false;
        }
      }
    }

    componentWillUnmount() {
      document.removeEventListener('scroll', this.trackScrolling);
    }

    trackScrolling(){
      var st = window.pageYOffset || document.documentElement.scrollTop; 
      if (st > this.state.scrollPos){
        const wrappedElement = document.getElementsByTagName('body')[0];
        if (this.isBottom(wrappedElement)) {
          if(this.state.active_branch!==""){
            document.removeEventListener('scroll', this.trackScrolling);
            this.loadMoreContactBillingData();
          }
        }
      } else {}
     let scrollPos = st <= 0 ? 0 : st;
     this.setState({scrollPos:scrollPos})
    };

    handleMultiplePayment(e){
        let len = document.querySelectorAll(".make-pay-cb:checked").length;
        let target = e.currentTarget.getAttribute('data-target'); 
        if(len>0){
          this.setState({is_payment:true});
        }else{
          this.setState({is_payment:false});
        }
        if(len>0){

          let rows = document.querySelectorAll(".make-pay-cb");
          let items = '';
          for(let i=0;i<rows.length;i++){
           
                rows[i].disabled = true;
                
             
          }
          console.log(target);
          let uncheck = document.querySelectorAll('[data-target='+target+']');
          console.log(uncheck);
          for(let i=0;i<uncheck.length;i++){
              uncheck[i].disabled = false;
          }

          /*let cb = document.querySelectorAll(".make-pay-cb:checked");


          let out = 0;
          let in_ = 0;
           let pattern1 = /SO/g;
           let pattern2 = /PO/g;
           let pattern3 = /SR/g;
           let pattern4 = /PR/g;
          for(let i=0;i<cb.length;i++){
             let val = cb[i].value;
             
             if(val.match(pattern1) || val.match(pattern4)){
                out++;
             }else if(val.match(pattern2) || val.match(pattern3)){
              
                in_++;
             }
          } 

          let rows = document.querySelectorAll(".make-pay-cb");
          let items = '';
         
          if(out>in_){
            for(let i=0;i<rows.length;i++){
               let val = rows[i].value;
               if(val.match(pattern2) || val.match(pattern3)){
                  rows[i].disabled = true;
                  
               }
             }
            
          }else{
            for(let i=0;i<rows.length;i++){
               let val = rows[i].value;
               if(val.match(pattern1) || val.match(pattern4)){
                  rows[i].disabled = true;
                  
               }
            }
            
          }*/

        }else{
          let rows = document.querySelectorAll(".make-pay-cb");
          for(let i=0;i<rows.length;i++){
          
                  rows[i].disabled = false;
                  
               
             }
        }
    }

    handleMakePayment(e){
        let row = document.querySelectorAll(".make-pay-cb:checked");
        let pattern1 = /SO/g;
         let pattern2 = /PO/g;
         let pattern3 = /SRO/g;
         let pattern4 = /PRO/g;

        let pattern11 = /SB/g;
         let pattern21 = /PB/g;
         let pattern31 = /SRB/g;
         let pattern41 = /PRB/g;

        let in_str = ''; 
        let out_str = ''; 
        let _out = 0;
        let _in = 0;
        for(let i=0;i<row.length;i++){
           let val = row[i].value;
           if(val.match(pattern1) || val.match(pattern4)  || val.match(pattern11)  || val.match(pattern41)){
              out_str += "'"+val+"',";
              _out++;
           }else if(val.match(pattern2) || val.match(pattern3) || val.match(pattern21) || val.match(pattern31)){
              in_str += "'"+val+"',";
              _in++;
           }
        }
        let items = '';
        if(_out>_in){
          items = out_str.replace(/,\s*$/, "");
        }else{
          items = in_str.replace(/,\s*$/, "");
        }
        window.open('/new-payment/multiple/'+encodeURIComponent(items),'_blank');
        
    }

    componentDidMount(){
      //document.addEventListener('scroll', this.trackScrolling);
      let curl = window.location.href;
      const get_id = curl.match(/view-contact\/(\d+$)/i);
      const for_branch = curl.match(/view-contact\/(\d+)\/branch\/(\d+$)/i);
      const view_for = curl.match(/view-contact\/([a-z_A-Z]+)\/(\d+)\/branch\/(\d+$)/i);
      let c_id = 0;
      let b_id = user_info.login_id;
      let current_tab = 'orders';
      if(get_id){
        c_id = parseInt(get_id[1]);
      }else if(view_for){
        this.setState({current_tab:view_for[1]});
        current_tab = view_for[1];
        c_id = parseInt(view_for[2]);
        b_id = parseInt(view_for[3]);
      }else if(for_branch){
        c_id = parseInt(for_branch[1]);
        b_id = parseInt(for_branch[2]);
      }

      this.setState({current_id:c_id});

      let bbody = {};
        bbody = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, _schema : user_info._schema,
           }
      let bapi = app_api+'/get_branches';
      axios.post(bapi, bbody)
        .then(function (response) {
          let active_branch = response.data[0].branch_id;
          
          if(b_id!==0){
            active_branch = b_id;
          }
        this.setState({active_branch:active_branch});
        this.setState({ 'branches': response.data});
          let body = {};
          body = {
                store_name : user_info.store_name,
                branch_id : b_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token, _schema : user_info._schema,
                id : c_id,
                target : current_tab,
             }
        let api = app_api+'/get_contact_on_id';
        axios.post(api, body)
          .then(function (response) {
            if(response.data[0]===undefined){
              window.location.href = '/404';
            }
            let res_data = response.data[0];
            let additional_data = res_data.custom_data;
            if(additional_data!==undefined){
              this.setState({custom_fields_json:additional_data});
            }
            this.setState({ 'credit_data': response.data.credit_data});
            this.setState({ 'agent_contact_list': response.data.contact_list});
            this.setState({ 'contact_list': res_data});
            if(res_data.gst_number!=="" || res_data.pan_no!=="" || res_data.city!=="" || res_data.address_1!=="" || res_data.address_2!==""){
              this.setState({show_middle_panel:true});
            }
            if(!response.data.contact_billing_data){
              this.setState({loading_process:"No record(s) found."})
            }
            this.setState({ 'tab_data': response.data.contact_billing_data});
            this.setState({show_loader:false});
            if(response.data.contact_billing_data.length<10){
              this.setState({loaded_all:true});
            }
        }.bind(this)).catch(function(res){
            if(res.response){
              
            }
        })
      }.bind(this)).catch(function(res){
          if(res.response){
            
          }
      })

      let general_contact_bill_settings = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, _schema : user_info._schema,
          keys:"'is_financial_year_closed'"
      }
      let gc_api = app_api+'/get_settings';
      axios.post(gc_api, general_contact_bill_settings)
        .then(function (response) {
          let resp = response.data;
          if(resp.is_fy_closed){
            let is_fy_closed = resp.is_fy_closed;
            this.setState({is_fy_closed:is_fy_closed});
          }
      }.bind(this)).catch(function(res){
          
      }) 
      
    }

    render() {
        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                
                <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-6">
                        <div className="mod-title"><a href="/contacts"> <span>Contacts</span></a> / <b>{this.state.contact_list.name}</b><span className="vchtype"> ({this.state.contact_list.contact_type})</span></div>
                    </div>
                    <div className="col-md-6 text-right mrtop-10">
                        <a href={"/contacts"}><button type="button" className="btn btn-default">Back to list</button></a>
                        
                        <a href={"/edit-contact/"+this.state.current_id}><button type="button" className="btn btn-success">Edit Contact</button></a>
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                    <ServiceLoader show={this.state.show_loader}/>
                    <div className="content-area">
                      <div className="main-content pad-30">
                          <div className="row">
                            <div className={(this.state.show_middle_panel===false)?"col-md-4":"col-md-4 border-right"}>
                                <div className="col-md-12">
                                    <label> Name : </label> <span className="vc-info-spn">{this.state.contact_list.name}</span>
                                </div>
                                {(this.state.contact_list && this.state.contact_list.phone_1!=="") &&
                                <div className="col-md-12 mrtop-5">
                                    <label> Phone 1 : </label> <span className="vc-info-spn">{(this.state.contact_list && this.state.contact_list.phone_1!=="")?this.state.contact_list.phone_1:'-'}</span>
                                </div>
                                }
                                {(this.state.contact_list && this.state.contact_list.phone_2) &&
                                  <div className="col-md-12 mrtop-5">
                                      <label> Phone 2 : </label> <span className="vc-info-spn">{(this.state.contact_list && this.state.contact_list.phone_2)?this.state.contact_list.phone_2:'-'}</span>
                                  </div>
                                }
                                {(this.state.contact_list && this.state.contact_list.email) &&
                                  <div className="col-md-12 mrtop-5">
                                      <label> Email : </label> <span className="vc-info-spn">{(this.state.contact_list && this.state.contact_list.email)?this.state.contact_list.email:'-'}</span>
                                  </div> 
                                }
                                {(this.state.contact_list && this.state.contact_list.contact_type) &&
                                  <div className="col-md-12 mrtop-5">
                                      <label> Type : </label> <span className="vc-info-spn">{(this.state.contact_list && this.state.contact_list.contact_type)?this.state.contact_list.contact_type:'-'}</span>

                                      {(this.state.contact_list && this.state.contact_list.type!==undefined && (this.state.contact_list.type==='1' || this.state.contact_list.type===1)) &&
                                        <span style={{fontSize:'12px',color:'#969090'}}> ({(this.state.contact_list && this.state.contact_list.customer_type===null || this.state.contact_list.customer_type==='retail')?'Retail':this.state.contact_list.customer_type})</span>
                                      }
                                  </div>
                                }
                                {(this.state.contact_list && this.state.contact_list.group_name!="") &&
                                  <div className="col-md-12 mrtop-5">
                                    <label> Group : </label> 
                                    <span className="vc-info-spn">{(this.state.contact_list && this.state.contact_list.group_name!="")?this.state.contact_list.group_name:' -'}</span>
                                  </div>
                                }
                                {(this.state.contact_list && this.state.contact_list.agent_name!=='') &&
                                  <div className="col-md-12 mrtop-5">
                                    <label>Agent Name : </label> <span className="vc-info-spn">{(this.state.contact_list && this.state.contact_list.agent_name)?this.state.contact_list.agent_name:'-'}</span>
                                  </div>
                                }
                                <div className="col-md-12 mrtop-5">
                                  <label>Status : </label> <span className="vc-info-spn">{(this.state.contact_list.status===1)?'Active':'Inactive'}</span>
                                </div>
                              
                            </div>

                            <div className={(this.state.show_middle_panel===false)?"col-md-4":"col-md-4 border-right"}>
                              {(this.state.contact_list && this.state.contact_list.gst_number) &&
                                <div className="col-md-12">
                                    <label> GST Number : </label> <span className="vc-info-spn">{(this.state.contact_list && this.state.contact_list.gst_number)?this.state.contact_list.gst_number:'-'}</span>
                                </div>
                              }
                              {(this.state.contact_list && this.state.contact_list.pan_no) &&
                                <div className="col-md-12 mrtop-5">
                                    <label> PAN Number : </label> <span className="vc-info-spn">{(this.state.contact_list && this.state.contact_list.pan_no)?this.state.contact_list.pan_no:'-'}</span>
                                </div>
                              }
                              {(this.state.contact_list && this.state.contact_list.city)&&
                                <div className="col-md-12 mrtop-5">
                                    <label> City : </label> <span className="vc-info-spn">{(this.state.contact_list && this.state.contact_list.city)?this.state.contact_list.city:'-'}</span>
                                </div> 
                              }
                              {(this.state.contact_list && this.state.contact_list.address_1)&&
                                <div className="col-md-12 mrtop-5">
                                    <label> Address 1 : </label> <span className="vc-info-spn">{(this.state.contact_list && this.state.contact_list.address_1)?this.state.contact_list.address_1:'-'}</span>
                                </div>
                              } 
                              {(this.state.contact_list && this.state.contact_list.address_2)&&
                                <div className="col-md-12 mrtop-5">
                                    <label> Address 2 : </label> <span className="vc-info-spn">{(this.state.contact_list && this.state.contact_list.address_2)?this.state.contact_list.address_2:'-'}</span>
                                </div>
                              }
                            </div>
                            <div className="col-md-4">
                                {(this.state.credit_data.credit_limit!==0) &&
                                  <div className="col-md-12">
                                      <label> Credit Limit : </label> <span className="vc-info-spn">{(this.state.credit_data && this.state.credit_data.credit_limit)?this.currency_format(this.state.credit_data.credit_limit):'-'}</span>
                                  </div>
                                }
                                {(this.state.credit_data.limit_balance!==0) &&
                                <div className="col-md-12 mrtop-5">
                                    <label> Limit Balance : </label> <span className="vc-info-spn">{(this.state.credit_data && this.state.credit_data.limit_balance)?this.currency_format(this.state.credit_data.limit_balance):'0'}</span>
                                </div>
                                }
                                <div className="col-md-12 mrtop-5">
                                    <label> Payment Due : </label> 
                                    <span className="vc-info-spn">
                                      {(this.state.credit_data && this.state.credit_data.previous_balance)?this.currency_format(this.state.credit_data.previous_balance):'-'}

                                      {(this.state.credit_data && this.state.credit_data.previous_balance) &&
                                        <>
                                          {(this.state.contact_list.contact_type!==undefined && this.state.contact_list.contact_type==='Customer') &&
                                            <span className={(this.state.credit_data.previous_balance_type.trim()==='(Advance)')?'pduetype bg-red':'pduetype bg-green'}>{this.state.credit_data.previous_balance_type}</span>
                                          }
                                          {(this.state.contact_list.contact_type!==undefined && this.state.contact_list.contact_type==='Supplier') &&
                                            <span className={(this.state.credit_data.previous_balance_type.trim()==='(Payable)')?'pduetype bg-red':'pduetype bg-green'}>{this.state.credit_data.previous_balance_type}</span>
                                          }
                                        </>
                                      }
                                    </span>
                                </div>
                                {(this.state.contact_list && this.state.contact_list.ltv!==undefined && this.state.contact_list.ltv!=="")&&
                                  <div className="col-md-12 mrtop-5">
                                      <label>LTV : </label> 
                                      <span className="vc-info-spn">
                                        {this.state.contact_list.ltv}
                                      </span>
                                  </div>
                                }
                                {(this.state.contact_list && this.state.contact_list.credit_days) &&
                                  <div className="col-md-12 mrtop-5">
                                      <label> Credit Days : </label> <span className="vc-info-spn">{(this.state.contact_list && this.state.contact_list.credit_days)?this.state.contact_list.credit_days:'-'}</span>
                                  </div> 
                                }
                              {(this.state.contact_list && this.state.contact_list.discount) &&
                                 <div className="col-md-12 mrtop-5">
                                      <label> Discount : </label> <span className="vc-info-spn">{(this.state.contact_list && this.state.contact_list.discount)?this.state.contact_list.discount+"%":'-'}</span>
                                 </div> 
                               }
                               <div className="col-md-12 mrtop-5">
                                    <label> Payment Mode : </label> <span className="vc-info-spn">{(this.state.contact_list && this.state.contact_list.payment_mode_txt)?this.state.contact_list.payment_mode_txt:'-'}</span>
                               </div> 
                               <div className="col-md-12 mrtop-5">
                                    <label> Created By : </label> <span className="vc-info-spn">{(this.state.contact_list && this.state.contact_list.created_by)?this.state.contact_list.created_by:'-'}</span>
                               </div> 
                            </div>
                          </div>
                      </div>
                    </div>
               
                {(this.state.custom_fields_json.length>0) &&
                       <>
                        <div className="content-head border-bottom border-top">
                          <b>Custom Data</b>
                          <p className="info">Custom Data added from settings.</p>
                        </div>
                        <div className="main-content  border-bottom">
                            <div className="inner-content">
                                    <div className="row">
                                        {this.state.custom_fields_json.map((value,index) => {
                                            return (
                                                  <>
                                                    {((index+1)%2!==0) &&
                                                        <div className="col-md-6 mrtop-30"  key={'cfp-'+index}>
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                <label style={{textTransform:'uppercase'}}>{value.name}</label>
                                                                </div>
                                                                <div className="col-md-8">
                                                                <span  className="vc-info-spn">{value.value}</span>
                                                                </div>
                                                                <div className="col-md-1"></div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {((index+1)%2===0) &&
                                                      <div className="col-md-6 mrtop-30"  key={'cfp-'+index}>
                                                          <div className="row">
                                                              <div className="col-md-4">
                                                              <label style={{textTransform:'uppercase'}}>{value.name}</label>
                                                              </div>
                                                              <div className="col-md-8">
                                                              <span  className="vc-info-spn">{value.value}</span>
                                                              </div>
                                                          </div>
                                                      </div>
                                                    }
                                                  </>
                                                )
                                        })}
                                    </div>
                            </div>
                        </div>
                      </>
                    }
                </div>
                <div className="container-fluid">
                  {(this.state.branches.length > 1) &&
                  <div className="nav-container">
                    <ul className="nav nav-pills">

                      {(this.state.branches.length > 1) ?this.state.branches.map( (value, index) => {
                           
                           return (
                            <li className={(this.state.active_branch===value.branch_id)?'active':''}><a href={"/view-contact/"+this.state.current_id+"/branch/"+value.branch_id} style={{textTransform:'capitalize'}}>{value.company_name}</a></li>
                          )
                         }) :''}
                    </ul>
                  </div>
                  }
                  <div className="nav-container">
                    <ul className="nav nav-pills">
                    {(this.state.current_tab!=='notes' && this.state.contact_list.contact_type!==undefined && this.state.contact_list.contact_type!=='Agent') &&
                    <>
                      <li className={(this.state.current_tab==='orders')?'active':''}><a href={"/view-contact/orders/"+this.state.current_id+"/branch/"+this.state.active_branch}>Orders</a></li>
                      <li className={(this.state.current_tab==='pending_bills')?'active':''}><a href={"/view-contact/pending_bills/"+this.state.current_id+"/branch/"+this.state.active_branch}>Pending Bills</a></li>
                      <li className={(this.state.current_tab==='payments')?'active':''}><a href={"/view-contact/payments/"+this.state.current_id+"/branch/"+this.state.active_branch}>Payments</a></li>
                      <li className={(this.state.current_tab==='notes')?'active':''}><a href={"/view-contact/notes/"+this.state.current_id+"/branch/"+this.state.active_branch}>Notes</a></li>
                      <li className={(this.state.current_tab==='ledger')?'active':''}><a href={"/reports/ledger/contact/"+this.state.current_id} target="_blank">Ledger</a></li>
                      </>
                    }
                    {(this.state.current_tab!=='notes' && this.state.contact_list.contact_type!==undefined && this.state.contact_list.contact_type==='Agent') &&
                    <>
                      <li className='active'><a>Associated Contact</a></li>
                    </>
                    }
                    </ul>
                  </div>
                  <div className="content-area">
                     <div className="main-content">

                    {(this.state.contact_list.contact_type!==undefined && this.state.contact_list.contact_type==='Agent') &&
                        <table className="table">
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">NAME</th>
                              <th scope="col">PHONE</th>
                              <th scope="col">PAYMENT DUES</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {(this.state.agent_contact_list && this.state.agent_contact_list.length > 0) ?this.state.agent_contact_list.map( (value, index) => {
                            
                            return (
                              <tr key={ index } id={"billing-row-"+value.id} className="contact-row">
                                <td>
                                  <Link link={'/view-contact/'+value.id} name={value.name} />
                                </td>  
                                <td>
                                  {value.phone}
                                </td>  
                                <td>
                                  {value.payment_dues}
                                </td>  
                                <td>
                                  <Tooltip target="contacts" id={value.id} index={index} view={"/view-contact/"+value.id} edit={"/edit-contact/"+value.id}/>
                                </td>  
                              </tr>
                            )
                          }) : <tr><td colSpan={7} className="text-center">{this.state.loading_process}</td></tr> }
                          {(this.state.is_loading) &&
                            <tr><td colSpan={7} className="text-center">{this.state.loading_process}</td></tr>
                          }
                            {(this.state.is_loading===false && this.state.tab_data.length > 0 && this.state.loaded_all===false) &&
                              <tr>
                                  <th colSpan="7" className="text-center">
                                    <button type="button" className="btn btn-outline-info" onClick={this.loadMoreContactBillingData}>Load More</button>
                                  </th>
                              </tr>
                            }
                          </tbody>
                        </table>
                    }



                     {(this.state.current_tab!=='notes' && this.state.contact_list.contact_type!==undefined && this.state.contact_list.contact_type!=='Agent') &&
                        <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">ORDER #</th>
                            <th scope="col">ORDER DATE</th>
                            {this.state.current_tab!=='payments' &&
                              <th scope="col">ORDER VALUE</th>
                            }
                            {this.state.current_tab==='payments' &&
                            <>
                              <th scope="col">TOTAL AMOUNT</th>
                              <th scope="col">PAID AMOUNT</th>
                              </>
                            }
                            <th scope="col">BALANCE PAYMENT</th>
                            {/* <th scope="col">MODE OF PAYMENT</th> */}
                            <th scope="col">TYPE</th>
                            {this.state.current_tab!=='payments' &&
                            <th scope="col">STATUS</th>
                          }
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                           {(this.state.tab_data.length > 0) ?this.state.tab_data.map( (value, index) => {
                           
                           return (
                            <tr key={ index } id={"billing-row-"+value.id} className={(this.state.current_tab==='payments' && value.status===2)?"strikeout contact-row":"contact-row"} data-id={7}>
                             
                              <td>
                              {this.state.current_tab==='pending_bills' &&
                              <>
                                <input type="checkbox" value={value.number} onChange={this.handleMultiplePayment} className="make-pay-cb" data-target={value.target.replace(/\s/g, '')}/>&nbsp;
                                </>
                              }
                                {(this.state.current_tab==='payments')?
                                  <DrawerLink target="payment" url={'/view-payment/'+value.id} schema={value.schema} name={value.number}/>
                                  :
                                  <DrawerLink  target="billing" url={'/view-billing/'+value.link+'/'+value.id} schema={value.schema} name={value.number} />
                                }

                              </td>
                              <td>{value.date}</td>
                              {this.state.current_tab==='payments' &&
                              <td>{this.currency_format(value.total_amount)}</td>
                              }
                              <td>{this.currency_format(value.order_value)}</td>
                              <td>{this.currency_format(value.balance)}</td>
                              {/* <td>{value.payment_mode}</td>     */}        
                              <td>{value.target}</td>
                              {this.state.current_tab!=='payments' &&
                              <td>{value.status}</td>
                            }
                              <td>

                              {(this.state.current_tab!=='payments' && user_info.login_id===this.state.active_branch) &&
                                  <Tooltip item_count={this.state.tab_data.length} opt={this.state.top_option} id={value.id} index={index} edit={"/new-billing/edit/"+value.link+"/"+value.id} view={"/view-billing/"+value.link+"/"+value.id} is_fy_closed={this.state.is_fy_closed}/>
                              }
                              {(this.state.current_tab==='payments' && user_info.login_id===this.state.active_branch) &&
                                  <Tooltip  item_count={this.state.tab_data.length} opt={this.state.top_option} id={value.id} index={index} view={"/view-payment/"+value.id}  edit={"/new-payment/edit/"+value.id} is_fy_closed={this.state.is_fy_closed}/>
                              }
                              {(this.state.current_tab==='payments' && user_info.login_id!==this.state.active_branch) &&
                                  <Tooltip  item_count={this.state.tab_data.length} opt={this.state.top_option} id={value.id} index={index} view={"/view-payment/"+value.id}  is_fy_closed={this.state.is_fy_closed}/>
                              }
                              </td>
                            </tr>
                          )
                         }) : <tr><td colSpan={6} className="text-center">{this.state.loading_process}</td></tr> }
                         {(this.state.is_loading) &&
                          <tr><td colSpan={6} className="text-center">{this.state.loading_process}</td></tr>
                         }
                          {(this.state.is_loading===false && this.state.tab_data.length > 0 && this.state.loaded_all===false) &&
                            <tr>
                                <th colSpan="7" className="text-center">
                                  <button type="button" className="btn btn-outline-info" onClick={this.loadMoreContactBillingData}>Load More</button>
                                </th>
                            </tr>
                          }
                        </tbody>
                      </table>
                    }
                    {(this.state.is_payment && this.state.current_tab==='pending_bills') &&
                      <button type="button" className="btn btn-success" onClick={this.handleMakePayment}>Make Payments</button>
                    }

                      {this.state.current_tab==='notes' &&
                        <div className="row mrtop-20">
                          <div className="col-md-3"></div>
                          <div className="col-md-6">
                            <p className="contact-notes">{(this.state.contact_list && this.state.contact_list.notes)?this.state.contact_list.notes:''}</p>
                          </div>
                          <div className="col-md-3"></div>
                        </div>
                      }
                     </div>
                  </div>
                </div>
              </div>
            </div>
        );
    }
}