import React, {Component} from "react";
import axios from 'axios';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey,faSpinner  } from '@fortawesome/free-solid-svg-icons';
import encrypt_decrypt from './encrypt-decrypt.js';
import BarcodeTemplate from "./settings/barcode-template";
import utils from "../utils";
let {enc_key,app_api,user_info,gst_options} = require('../library.js');
export default class PrintBarcode extends Component {
    constructor() {
        super();
        this.state = {  
            barcode_template_data : [],
            barcode_template:[],
            is_quantity_entered : false,
            barcode_quantity : 0,
        } 
    }
    
    handlePrintBarcode = (event)  => {
       
        let barcode_template_data = this.state.barcode_template_data;
  
        if(barcode_template_data[0].height!==undefined){
          let height = barcode_template_data[0].height;
          let width = barcode_template_data[0].width;
          let values = barcode_template_data[0].value;
  
          let name = this.state.barcode_template_data[0].name;
  
          
          let template = '';
          this.barcode_template = [];
          
            let id = this.state.update_id;
            
            let billing_data = this.props.barcode_data_info;
            let quantity = document.getElementById("barcode-print-quantity").value;
            if(quantity!==""){
              quantity = parseInt(quantity);
              for(let q=0;q<quantity;q++){
                this.barcode_template.push(<BarcodeTemplate id={q} key={q} billing_data={billing_data} name={name} tamplate_data={values} height={height} width={width} barcode_template_data={barcode_template_data}/>);
              } 
              this.setState({barcode_template:this.barcode_template});
            }
            this.handleCloseBarcodeQuantityPopup();
          var element = document.getElementById('barcode-print-preview');
          element.classList.remove("fade");
          element.style.display="block"; 
        }
      }
      handlePrint = (e) =>{
     
                    let type = e.currentTarget.id;
                    var divContents = document.getElementById("barcode-print-container").innerHTML; 
                    var a = window.open('', '', 'height=1000,width=700,location=no,left=250px'); 
                    a.document.write('<html><head>'); 
                    a.document.write('<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" type="text/css" />'); 
                    a.document.write('<style type="text/css">@page {margin: 0;}.bcode-temp{text-transform:uppercase}.react-draggable {position: absolute !important;line-height: 0;white-space: nowrap;}.react-draggable.center{position: relative !important;}.react-draggable.free{left: 0;}.react-draggable.free-left{left: 0;}.react-draggable.free-right{right: 0;}.react-draggable.left{position: relative !important;}.react-draggable.right{position: relative !important;}.drag-inner.center{text-align:center;}.drag-inner.right{text-align: right;}.drag-inner.left{text-align: left;}.temp-elem-name{margin: 0;}</style>'); 
                    a.document.write('</head>'); 
                    
                    a.document.write('<body onload="window.print();" style="background:rgb(222, 222, 222)">'); 
                    
                    a.document.write(divContents); 
                   
                     a.document.write('</body></html>');
                     a.document.close();
                     a.focus();
            } 
            closeModel = (event) => {
              var element = document.getElementById('barcode-print-preview');
              element.classList.add("fade");
              element.style.display="none"; 
            }
    componentDidMount(){
        let barcode_settings = {};
          barcode_settings = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
                target : 'barcode',
             }
          let bc_api = app_api+'/get_default_barcode_template';
          axios.post(bc_api, barcode_settings)
            .then(function (response) {
              let cursor = response.data;
              
              this.setState({ 'barcode_template_data': cursor}); 
          }.bind(this)).catch(function(res){
              if(res.response){
                if(res.response.data){
                    
                }
              }
          })

    }
    handleCloseBarcodeQuantityPopup = () =>{
        var element = document.getElementById('barcode-print-quantity-pop');
        element.classList.add("fade");
        element.style.display="none"; 
    }
    render() {
        return (
            <>
                
                <div id="barcode-print-preview" className="modal fade"  style={{overflowY:'scroll'}}>
                    <div className="modal-dialog" style={{textAlign:'center'}} >
                        <div className="modal-content">
                        <div className="modal-header bg-none">
                            <div className="row">
                            <div className="col-md-4 mrtop-5">
                                <h4 style={{color:'black',textTransform:'uppercase'}}>
                                {(this.state.barcode_template_data && this.state.barcode_template_data[0] && this.state.barcode_template_data[0].name) &&
                                this.state.barcode_template_data[0].name
                                }
                                </h4>
                            </div>
                            
                            <div className="col-md-8">
                                <button type="button" onClick={this.handlePrint} className="btn btn-success pull-right mrleft-5">Print</button>
                                <button type="button" onClick={this.closeModel} className="btn btn-secondary pull-right">Cancel</button>
                            </div>
                            </div>
                        </div>
                        <div className="modal-body" style={{padding:'15px',background:'#dedede'}} id="barcode-print-container" >
                            {this.barcode_template}
                        </div>
                        </div>
                    </div>
                </div>
                
                
                <div id="barcode-print-quantity-pop" className="modal fade">
                    <div className="modal-dialog modal-sm" style={{textAlign:'center'}} >
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Barcode Print</h5>
                        </div>
                            <div className="modal-body">
                                
                                <input type="text" className="form-control" id="barcode-print-quantity" placeholder="Enter Barcode Print Quantity"/>
                            </div>
                            <div className="modal-footer">
                                <div className="row">
                                    <div className="col-md-6 text-left">
                                        <button type="button" className="btn btn-secondary" onClick={this.handleCloseBarcodeQuantityPopup}>Close</button>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <button type="button" className="btn btn-success" onClick={this.handlePrintBarcode}>Print</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </>
        );
    }
}