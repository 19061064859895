import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import utils from "../utils";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
let {enc_key,app_api,user_info, gst_options} = require('../library.js');
export default class BillingPOSOpenCounter extends Component {
    constructor() {
        super();
        this.state = {
            opening_balance_amount : 0,
            is_opening_account : false,
            is_openend_today : '',
            is_open_billing_counter : false,
        };
       
    }
    componentDidMount(){
        this.handleGetIsOpened();
        this.handleGetSettings();
    }
    handleGetSettings = () =>{
        let ssend_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            access_level : user_info.access_level,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            keys:"'is_open_billing_counter'",
        }
        let sapi = app_api+'/get_settings';
        axios.post(sapi, ssend_data)
          .then(function (response) {
            let resp = response.data;
            if(resp.is_open_billing_counter){
                let ps = resp.is_open_billing_counter;
                this.setState({is_open_billing_counter:ps});
              }
        }.bind(this)).catch(function(res){
            
        })
      }
    handleGetIsOpened = () =>{
        let ssend_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            access_level : user_info.access_level,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema
        }
        let sapi = app_api+'/get_today_counter_status';
        axios.post(sapi, ssend_data)
          .then(function (response) {
            let resp = response.data;
            this.setState({is_openend_today:resp.is_open});
        }.bind(this)).catch(function(res){
            
        })
      }
    handleChangeOpeningBalanceAmount = (value) =>{
        this.setState({opening_balance_amount:value});
    }
    handleOpenAccount=()=>{
        if(this.state.opening_balance_amount===""){
            return false;
        }
        this.setState({is_opening_account:true});
        let pmbody = {
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            opening_balance_amount : this.state.opening_balance_amount,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
        }
        let pmapi = app_api+'/open_account_for_today';
        axios.post(pmapi, pmbody)
        .then(function (response) {
            this.setState({is_openend_today:true});
            this.setState({is_opening_account:false});
        }.bind(this)).catch(function(res){
           
        })
    }
    render() {
        return (
            <>
            {(this.state.is_open_billing_counter===true && this.state.is_openend_today===false) &&
                <div className="modal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:'block'}}>
                
                    <div className="modal-dialog modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title hbtitle">Open Account for Today</h5>
                            </div>
                            <div className="modal-body" style={{overflow: 'auto',maxHeight: '500px'}}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <input type="text" className="form-control" placeholder="Enter Opening Balance Amount" onChange={(e)=>this.handleChangeOpeningBalanceAmount(e.target.value)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer text-right">
                                {(this.state.is_opening_account===false) &&
                                    <button type="button" className="btn btn-success" onClick={this.handleOpenAccount}>Open</button>
                                }
                                {(this.state.is_opening_account===true) &&
                                    <button type="button" className="btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                }
                            </div>
                        </div>
                    </div>
                </div> 
            }
            </>
        );
    }
}