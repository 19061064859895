import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Autocomplete from "./autocomplete";
import utils from "../utils";
import {faPlusSquare, faPencilAlt, faCheckCircle, faTimes,faTrash} from '@fortawesome/free-solid-svg-icons';
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");

let _this;
let db;
export default class ContactsDropdown extends Component {
    constructor() {
      super();
      this.state = {
         contact_list:[],
         contact_options:[],
         ssr_contact_option:[],
         ppr_contact_option:[],
         relevant_contact_type_ssr : 1,
         relevant_contact_type_ppr : 2,
      };
      _this = this;
      this.handleRefreshContactList = this.handleRefreshContactList.bind(this);
    }
    componentDidMount(){
      if(this.props.contact_type && this.props.target==='pending-invoice'){
        let ContactList = [];  
        let ContactOptions = [];
        let body = {
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
            contact_type : this.props.contact_type,
        }
        let api = app_api+'/get_contact';
        axios.post(api, body)
            .then(function (response) {
                let resp_data = response.data;
                resp_data.map( (value, key) => {
                  let id = value.contact_id;
                  let name = value.name;
                  let mobile = value.phone_1;
                  ContactOptions[id]={value1:mobile,value2:name};
                  ContactList[id] = value;
              });
              this.setState({ 'contact_list': ContactList }); 
              this.setState({ 'contact_options': ContactOptions }); 
        }.bind(this)).catch(function(res){
            if(res.response){
                //this.setState({loading_process:'Record(s) not found'});
            }
        })
        return false;
      }
      if(this.props.target!=='pending-invoice'){
        if(user_info)  { 
          let version = localStorage.getItem('IdbVersion');
          version = parseInt(version);
          let dbReq = window.indexedDB.open('BaazarERP', version);
          dbReq.onupgradeneeded = function(event) {
            db = event.target.result;
          }
          
          dbReq.onsuccess = function(event) {
            db = event.target.result;
            try{
              let ContactList = [];  
              let ContactOptions = []; 

              let ssr_contact_option = [];
              let ppr_contact_option = [];
              let transaction = db.transaction("contacts", "readwrite");
              let contacts = transaction.objectStore("contacts");
              let request = contacts.getAll();
              request.onsuccess = function(e) {
                let cursor =  e.target.result;
                cursor.map( (value, key) => {
                    let id = value.id;
                    let type = value.type;

                    

                    let data = value.data;
                    let bytes = CryptoJS.AES.decrypt(data, enc_key);
                    let cval = bytes.toString(CryptoJS.enc.Utf8);
                    let con =   JSON.parse(cval);
                    let mobile = con.phone_1.toString();
                    let name = con.name;
                    let relevant_contact_type_ssr = _this.state.relevant_contact_type_ssr;
                    if(localStorage.getItem('relevant_contact_type_ssr')){
                      relevant_contact_type_ssr = localStorage.getItem('relevant_contact_type_ssr');
                    }
                    let relevant_contact_type_ppr = _this.state.relevant_contact_type_ppr;
                    if(localStorage.getItem('relevant_contact_type_ppr')){
                      relevant_contact_type_ppr = localStorage.getItem('relevant_contact_type_ppr');
                    }

                    let cname = name.toLowerCase();

                    if(relevant_contact_type_ssr!=='all'){
                      if(parseInt(relevant_contact_type_ssr)===parseInt(type) || cname==='general' || cname==='general sale'){
                        ssr_contact_option[id]={value1:mobile,value2:name};
                      }
                    }else{
                      ssr_contact_option[id]={value1:mobile,value2:name};
                    }
                    if(relevant_contact_type_ppr!=='all'){
                      if(parseInt(relevant_contact_type_ppr)===parseInt(type) || cname==='general' || cname==='general sale'){
                        ppr_contact_option[id]={value1:mobile,value2:name};
                      }
                    }else{
                      ppr_contact_option[id]={value1:mobile,value2:name};
                    }
                    if(_this.props.contact_type && _this.props.contact_type===type){
                      ContactOptions[id]={value1:mobile,value2:name};
                      ContactList[id] = cval;
                    }else if(_this.props.contact_type===undefined){
                      ContactOptions[id]={value1:mobile,value2:name};
                      ContactList[id] = cval;
                    }
                });
                db.close();
                _this.setState({ 'contact_list': ContactList }); 
                _this.setState({ 'contact_options': ContactOptions }); 
                
                if(_this.props.target==="New Sale" || _this.props.target==="New Sales Return" || _this.props.target==="Customer"){
                  _this.setState({ 'contact_options': ssr_contact_option }); 
                }else if(_this.props.target==="New Purchase" || _this.props.target==="New Purchase Return" || _this.props.target==="Supplier"){
                  _this.setState({ 'contact_options': ppr_contact_option });
                }
                _this.setState({ 'ssr_contact_option': ssr_contact_option });
                _this.setState({ 'ppr_contact_option': ppr_contact_option });
              }; 
            }catch{}
          }
        }
      }
    }
    

    handleRefreshContactList(){
      if(this.props.target==="New Sale" || this.props.target==="New Sales Return" || this.props.target==="Supplier"){
        this.setState({ 'contact_options': this.state.ssr_contact_option });
      }else if(this.props.target==="New Purchase" || this.props.target==="New Purchase Return" || this.props.target==="Customer"){
        this.setState({ 'contact_options': this.state.ppr_contact_option });
      }
    }

    render() {
        return (
            <div>
            <>
              {(this.props.target==="New Sale" || this.props.target==="New Purchase" || this.props.target==="New Sales Return" || this.props.target==="New Purchase Return" || this.props.target==="Customer" || this.props.target==="Supplier") &&
                <span id="handleRefreshContactList" onClick={this.handleRefreshContactList}></span>
              }
              <Autocomplete
                options={this.state.contact_options}
                title={this.props.label}
                data_target="contact"
                id="customer-dropdown"
                className="auto-comp-opt"
                data_id={this.props.index}
                input_id={this.props.input_id}
                item_info={this.props.contact_info}
                contact_id={this.props.contact_info.item_id}
                contact_name={this.props.contact_info.item_name}
                target="contact_box"
                tabindex = {(this.props.tabindex)?this.props.tabindex:1}
                position = {this.props.position}
                is_enable_tag = {this.props.is_enable_tag}
                icon_label = {this.props.icon_label}
                is_pos = {this.props.is_pos}
                handleSelectCustomer = {this.props.handleSelectCustomer}
              />
              </>
            </div>
        );
    }
}