import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import Tooltip from "./tooltip";
import axios from 'axios';
import utils from '../utils.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowLeft,faSearch,faCaretLeft,faCaretRight,faSpinner, faSort} from '@fortawesome/free-solid-svg-icons';
import Notification from "./notification.js";
import check_access from './access-level.js';
import Link from './link.js';
import DrawerLink from "./open-drawer-link";
import SearchInventoryItems from './search-inventory-items.js';
import BarcodeTemplate from "./settings/barcode-template";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ListCount from "./list-count";
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let db;
let _this;
let productList = [];
export default class Inventories extends Component {
    constructor() {
      super();
      this.state = {
          loading_process :'Loading...',
          number_of_row :2,
          prev_link :'',
          next_link: '',
          current_page : '',
          total_page : '',
          page_msg : '',
          current_url : '',
          prev_url : '',
          inventory_list : [],
          export_list : [],
          is_barcode_tab : false,
          item_options:[],
          barcode_template_data:[],
          barcode_template:[],
          active_tab:'active',
          inventory_search_items : [],
          search_count:0,
          active_count : 0,
          draft_count : 0,
          is_category : false,
          inventory_sort_type : 'ASC',
          progress : 0,
          is_download_inv : false,
          total_record : 0,
      };
      _this = this;
      this.timeout = null;
      this.inventory_search_items = [];
      this.searched_ids=[];
      this.barcode_template = [];
      this.is_access = check_access.is_access.bind(this);
      this.closeModel = this.closeModel.bind(this);
      this.handleInventorySearch = this.handleInventorySearch.bind(this);
      this.openPrintPreview = this.openPrintPreview.bind(this);
      this.handlePrint = this.handlePrint.bind(this);
    }

    handleInventorySearch(e){
      let item_name = document.getElementById("inventory-search-box").value;
      this.inventory_search_items = [];
      this.setState({inventory_search_items:this.inventory_search_items});
      this.setState({search_count:1});
      this.setState({loading_process:'Loading...'});
      this.searched_ids = [];
      if(item_name!==""){
        clearTimeout(this.timeout);
        let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              item_name : item_name,
              target : 'inventory',
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/inventory/search_item';
        this.timeout = setTimeout(function () {
        axios.post(api, body)
          .then(function (response) {
            let cursor = response.data;
            
            if(cursor.length>0){
              for(let i=0;i<cursor.length;i++){
                  let item = cursor[i];
                  
                  if(_this.searched_ids.indexOf(item.inventory_id)<0){
                    _this.searched_ids.push(item.inventory_id);
                    _this.inventory_search_items.push(<SearchInventoryItems target="inventory" inventory={item} count={cursor.length} index={i}/>);
                  }
              }
            }else{
              _this.setState({loading_process:'No Inventory found.'});
            }
            _this.setState({inventory_search_items:_this.inventory_search_items});
        }.bind(_this)).catch(function(res){
            if(res.response){
              if(res.response.data){
                  
              }
            }
        })
        }, 1500);
      }else{
        this.setState({search_count:0});
        clearTimeout(this.timeout);
      }
    }


    handlePrint(e){
            let type = e.currentTarget.id;
            var divContents = document.getElementById("main-container").innerHTML; 
            var a = window.open('', '', 'height=1000,width=700,location=no,left=250px'); 
            a.document.write('<html><head>'); 
            a.document.write('<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" type="text/css" />'); 
            a.document.write('<style type="text/css">@page {margin: 0;}.blbl{text-transform: uppercase;font-size: 11px;}.blbl.company_name{font-size:12px;font-weight:bold}</style>'); 
            a.document.write('</head>'); 
            
            a.document.write('<body onload="window.print();" style="background:rgb(222, 222, 222)">'); 
            
            a.document.write(divContents); 
           
             a.document.write('</body></html>');
             a.document.close();
             a.focus();
    } 
    closeModel(event) {
      var element = document.getElementById('barcode-print-preview');
      element.classList.add("fade");
      element.style.display="none"; 
    }
    openPrintPreview(event) {
      let thisid = event.currentTarget.id;
      /*document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-spinner').classList.remove("hide");
      */
      let barcode_template_data = this.state.barcode_template_data;
      let height = barcode_template_data[0].height;
      let width = barcode_template_data[0].width;
      let values = barcode_template_data[0].value;

      let name = this.state.barcode_template_data[0].name;

      let prows = document.querySelectorAll(".barcode-list");
      let template = '';
      this.barcode_template = [];
      for(let r=0 ; r<prows.length; r++){
        let id = prows[r].getAttribute("data-id");
        let billing_data = this.state.item_options[id];
        let quantity = document.getElementById("no-of-print-"+id).value;
        if(quantity!==""){
          quantity = parseInt(quantity);
          for(let q=0;q<quantity;q++){
            this.barcode_template.push(<BarcodeTemplate id={q} barcode_template_data={barcode_template_data} key={q} billing_data={billing_data} name={name} tamplate_data={values} height={height} width={width}/>);
          } 
          this.setState({barcode_template:this.barcode_template});
        }
      }

      var element = document.getElementById('barcode-print-preview');
      element.classList.remove("fade");
      element.style.display="block"; 
      
     /* document.getElementById(thisid+'-spinner').classList.hide("hide");
      document.getElementById(thisid).classList.remove("hide");*/
    }
    handlePaginationShortcut = (e)=>{
      
      // 33 - page up
      // 34 - page down
      // 35 - end
      // 36 - home
      if(e.which===33){
        let prev_link = this.state.prev_link;
        
        var page = prev_link.match(/\d+$/g);
        if(page && page[0]){
          let comming_page = parseInt(page[0]);
          if(comming_page>0){
            e.preventDefault();
            window.location.href = prev_link;
          }
        }else{
          window.location.href = '/inventories';
        }
      }else if(e.which===34){
        let next_link = this.state.next_link;
        let total_page = this.state.total_page;
        var page = next_link.match(/\d+$/g);
        if(page){
          let comming_page = parseInt(page[0]);
          if(comming_page<=total_page){
            e.preventDefault();
            window.location.href = this.state.next_link;
          }
        }
      }else if(e.which===35){
        e.preventDefault();
        let last_page = this.state.next_link.replace(/\d+$/g, this.state.total_page);
        window.location.href = last_page;
      }else if(e.which===36){
        e.preventDefault();
        window.location.href = '/inventories';
      }
    }
    componentDidMount(){
      document.addEventListener("keydown", this.handlePaginationShortcut);
      let curl = window.location.href;
      let url = curl.split("/"); 
      let current_url = url[url.length - 1];
      let prev_url = url[url.length - 2];
      this.setState({current_url:current_url});
      this.setState({prev_url:prev_url});
      let cpage;
      let current_page;
      let body = {};
      const inventory = curl.match(/inventories$/i);
      const inventory_page = curl.match(/inventories\/page\/(\d+$)/i);
      
      let act_tab = 'active';
      
      if(inventory){
        this.setState({current_page:1});
        this.setState({prev_link:'#'});
        this.setState({next_link:'/inventories/page/2'});
        cpage = 1;
        body = {
            page : cpage,
            target:'inventory',
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            type : act_tab,
            uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
         }
      }else if(inventory_page){
        
        cpage = parseInt(inventory_page[1]);
        let next = cpage+1;
        let prev = cpage-1;
        let next_link = '/inventories/page/'+next;
        let prev_link = '/inventories/page/'+prev;
        if(prev===1){
          prev_link = '/inventories';
        }
        this.setState({current_page:cpage});
        this.setState({prev_link:prev_link});
        this.setState({next_link:next_link});
        body = {
            page : cpage,
            target:'inventory',
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            type : act_tab,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
         }
      }

      if(inventory!==null || inventory_page!==null){
        let api = app_api+'/inventory/page';
        axios.post(api, body)
          .then(function (response) {
              let page_count = response.data.number_of_page;
              let total_record = response.data.total_record;
            if(response.data.is_empty){
              this.setState({loading_process:'Record(s) not found'});
              this.setState({current_page:0});
              this.setState({total_page:0});
            }else{
              this.setState({total_page:page_count});
              this.setState({total_record:total_record});
              this.setState({active_count:response.data.active_count});
              this.setState({draft_count:response.data.draft_count});
              this.setState({ 'inventory_list': response.data.data });
              if(cpage===page_count){
                this.setState({next_link:'#'});
              }
            }
        }.bind(this)).catch(function(res){
            if(res.response){
               this.setState({loading_process:'Record(s) not found'});
            }
        })
      }
      const product_category = curl.match(/inventories\/category\/(\d+$)/i);
      const product_category_page = curl.match(/inventories\/category\/(\d+)\/page\/(\d+$)/i);
      let cat_id = 0 ;
      if(product_category){
        cat_id = product_category[1];
        this.setState({current_page:1});
        this.setState({prev_link:'#'});
        this.setState({next_link:'/inventories/category/'+cat_id+'/page/2'});
        cpage = 1;
        body = {
            page : cpage,
            target:'var_category',
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            category_id : cat_id,
            uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
         }
      }else if(product_category_page){
        cat_id = parseInt(product_category_page[1]);
        cpage = parseInt(product_category_page[2]);
        let next = cpage+1;
        let prev = cpage-1;
        let next_link = '/inventories/category/'+cat_id+'/page/'+next;
        let prev_link = '/inventories/category/'+cat_id+'/page/'+prev;
        if(prev===1){
          prev_link = '/inventories/category/'+cat_id;
        }
        this.setState({current_page:cpage});
        this.setState({prev_link:prev_link});
        this.setState({next_link:next_link});
        body = {
            page : cpage,
            target:'var_category',
            store_name : user_info.store_name,
            branch_id : user_info.login_id,
            user_id : user_info.user_id,
            category_id : cat_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token,
            _schema : user_info._schema,
         }
      }

      if(product_category!==null || product_category_page!==null){
        this.setState({is_category:true});
        let api = app_api+'/products/category';
        axios.post(api, body)
          .then(function (response) {
              let page_count = response.data.number_of_page;
            if(response.data.is_empty){
              this.setState({loading_process:'Record(s) not found'});
              this.setState({current_page:0});
              this.setState({total_page:0});
            }else{
              this.setState({total_page:page_count});
              this.setState({active_count:response.data.active_count});
              this.setState({draft_count:response.data.draft_count});
              this.setState({ 'inventory_list': response.data.data });
              if(cpage===page_count){
                this.setState({next_link:'#'});
              }
            }
        }.bind(this)).catch(function(res){
            if(res.response){
               this.setState({loading_process:'Record(s) not found'});
            }
        })
      }

      let keys = "'inventory_sort_type'";
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          keys:keys
      }
      let api = app_api+'/get_settings';
      axios.post(api, send_data)
        .then(function (response) {
          let resp = response.data;
          if(resp.inventory_sort_type!==undefined){
            let inventory_sort_type = resp.inventory_sort_type;
            this.setState({inventory_sort_type:inventory_sort_type});
          }
      }.bind(this)).catch(function(res){
          
      })
    } 

    handleSortList = (e)=>{
      e.currentTarget.style.border = '2px solid #d7d7d7';
      let inventory_sort_type = this.state.inventory_sort_type;
      if(inventory_sort_type==='ASC'){
        inventory_sort_type = 'DESC';
      }else if(inventory_sort_type==='DESC'){
        inventory_sort_type = 'ASC';
      }
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          name : 'inventory_sort_type',
          value : inventory_sort_type,
      }
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
        window.location.reload();
      }.bind(this)).catch(function(res){
          
      })
    }

    handleLoadInventoryOnPage = (page) =>{
      let export_list = this.state.export_list;
      let next_page = page+1;
      
      let body = {
          page : page,
          target:'inventory',
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          user_id : user_info.user_id,
          type : 'active',
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          action:'download'
      }
  
      let api = app_api+'/inventory/page';
      axios.post(api, body)
        .then(function (response) {
          if(response.data.is_empty){
            this.setState({progress:100});
            document.getElementById("donwload-inventory-xls-button").click();
            let _this = this;
            setTimeout(() => {
              _this.setState({is_download_inv:false});
              _this.setState({progress:0});
            }, 100);
          }else{
            let res = response.data.data;
            let total_record = this.state.total_record;
            for (let index = 0; index < res.length; index++) {
              const element = res[index];
              export_list.push(element);
            }
            this.setState({export_list:export_list});
            let loaded_record = export_list.length;
            let cal_per = (loaded_record/total_record)*100;
          
            this.setState({progress:Math.round(cal_per)});
            this.handleLoadInventoryOnPage(next_page);

          }
      }.bind(this)).catch(function(res){
          if(res.response){
            this.setState({loading_process:'Record(s) not found'});
          }
      })
    }

    handleOpenDownloadProgress = () =>{
      this.setState({is_download_inv:true});
      let _this = this;
      
      this.handleLoadInventoryOnPage(1);

     /*  setInterval(() => {
        let progress = _this.state.progress;
        console.log(progress);
        _this.setState({progress:progress+1});
        if(progress>=100){
          clearInterval();
        }
      }, 500); */
    }

    render() {
        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="inv-opt-block bl1">
                      {(this.state.is_category) &&
                       <div className="mod-title">
                        <a href="/inventories">
                          <FontAwesomeIcon icon={faArrowLeft} style={{color: '#808080',fontSize: '30px'}}/>  <span style={{fontSize: '30px'}}>Back</span>
                        </a>
                      </div>
                      }
                      {(this.state.is_category===false) &&
                       <div className="mod-title"><span>Inventories</span></div>
                      }
                    </div>
                   
                    <div className="inv-opt-block bl2 inventory-search">
                      {(this.state.is_barcode_tab===false && this.state.is_category===false) && 
                        <div className="mrtop-10">
                          <input type="text" onChange={this.handleInventorySearch} id="inventory-search-box" className="form-control" placeholder="Type to search..."/>
                        
                        </div>
                      }
                    
                    </div>
                    <div className="inv-opt-block bl3">
                      <div className="pull-right inven-btn">
                          <Notification />
                      </div>
                    </div>
                    <div className="inv-opt-block bl5 inv-bl5">
                      <div className="inven-btn">
                        {(this.state.is_category===false) &&
                          <>
                        <a href={"/products"}><button type="button" className="btn btn-success pull-right nav-btn">View Products</button></a>
                        <div className="pull-right">
                          <button type="button" className="btn btn-success pull-right nav-btn" onClick={this.handleOpenDownloadProgress}>Download all Inventories</button>
                        </div>
                        </>
                        }
                      </div>
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                {(this.state.search_count===0) && 
                  <div className="content-area">
                     <div className="main-content">
                        
                        <table className="table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col"></th>
                            <th scope="col"  style={{cursor:'pointer'}} onClick={this.handleSortList}>PRODUCT <FontAwesomeIcon icon={faSort} className='sort-on-id'/></th>
                            <th scope="col">SELLING PRICE</th>
                            <th scope="col">AVAILABLE</th>
                            <th scope="col">CATEGORY</th>
                            <th scope="col" className="text-center">BARCODE</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                           {(this.state.inventory_list.length > 0) ?this.state.inventory_list.map( (value, index) => {
                          
                           return (
                            <tr key={ index } className="contact-row product-row" id={"contact-row-"+value.inventory_id}>
                              <td width="70">
                                <div  style={{marginTop:'4px'}}>
                                  <div style={{backgroundImage:'url('+value.image+')',backgroundRepeat:'no-repeat',backgroundSize:'100% 100%',height: '40px',width: '40px',border:'1px solid #d7d7d7'}}></div>
                                </div>
                              </td>
                              {(value.parent_id===0) &&
                                <td><Link link={'/products/view/'+value.inventory_id} name={value.item_name} style={{fontSize:'14px',fontWeight:'bold'}}/></td>
                              }
                              {(value.parent_id!==0) &&
                                <td>
                                  <Link link={'/products/view/'+value.parent_id} name={value.parent_name} style={{fontSize:'14px',fontWeight:'bold'}}/><br/>
                                  <Link link={'/variant/view/'+value.parent_id+'/'+value.inventory_id} name={value.item_name} style={{fontSize:'13px',fontWeight:'bold'}}/>
                                </td>
                              }
                              <td>{value.selling_price}</td>
                              <td>
                                <p style={{margin:'0px'}}>
                                  {value.availability}
                                </p>
                                <span className="inv-dzn-lbl">{value.dozen_quantity}</span>
                                <span className="inv-dzn-lbl sep">{value.seperator}</span>
                                <span className="inv-dzn-lbl">{value.bundle_text}</span>
                              </td>
                             
                              <td id={value.category_id} data-id={value.inventory_id} data-value={value.category} style={{cursor:'pointer'}}  dangerouslySetInnerHTML={{ __html: value.category}}>
                                
                              </td>
                              <td className="text-center">
                                <span style={{fontSize:'14px'}}>{value.barcode_name}</span>
                                {/* {(value.barcode_name!=="" && value.barcode_name!==null && value.sku!=="" && value.sku!==null) &&
                                  <span style={{fontSize:'16px',color:'#acacac'}}>  |  </span>
                                }
                                <span style={{fontSize:'14px'}}>{value.sku}</span> */}
                              </td>
                              
                              {(value.parent_id===0) &&
                              <td>
                                  <Tooltip target="inventory" id={value.inventory_id} index={index} view={"/products/view/"+value.inventory_id} edit={"/products/edit/"+value.inventory_id}  delete={{active_tab:'inventory',id:value.inventory_id,data_value:'inventory'}}  item_count={this.state.inventory_list.length}/>
                              </td>
                              }
                              {(value.parent_id!==0) &&
                              <td>
                                  <Tooltip target="inventory" id={value.inventory_id} index={index} view={"/variant/view/"+value.parent_id+"/"+value.inventory_id} edit={"/variant/edit/"+value.parent_id+"/"+value.inventory_id}  delete={{active_tab:'inventory',id:value.inventory_id,data_value:'variation'}}  item_count={this.state.inventory_list.length}/>
                              </td>
                              }
                            </tr>
                          )
                         }) : <tr><td colSpan="7" className="text-center">{this.state.loading_process}</td></tr> }
                        </tbody>
                      </table>
                      {(this.state.inventory_list && this.state.inventory_list.length > 0) &&
                        <ListCount list={this.state.inventory_list}/>
                      }
                      <div className="page-nav">
                        <div className="navd">
                          <a href={this.state.prev_link}>
                            <div className="navd">
                              <FontAwesomeIcon className="cont" icon={faCaretLeft}/>
                            </div>
                          </a>
                        </div>
                        <div className="navd center"><p className="msg">{this.state.current_page} of {this.state.total_page}</p></div>
                        <div className="navd">
                          <a href={this.state.next_link}>
                            <div className="navd">
                              <FontAwesomeIcon  className="cont" icon={faCaretRight}/>
                            </div>
                          </a>
                        </div>
                      </div>
                     </div>
                  </div>
                }
                {(this.state.search_count>0) &&
                  <table className="table">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">PRODUCT</th>
                      <th scope="col">SELLING PRICE</th>
                      <th scope="col">AVAILABLE</th>
                      <th scope="col">CATEGORY</th>
                      <th scope="col" className="text-center">BARCODE</th>
                      <th scope="col">STATUS</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                      {(this.state.inventory_search_items.length>0) &&
                        <>
                          {this.state.inventory_search_items}
                        </>
                      }
                      {(this.state.inventory_search_items.length===0) &&
                        <tr>
                          <td className="text-center" colSpan="7">{this.state.loading_process}</td>
                        </tr>
                      }

                  </tbody>
                </table>
              }
                </div>
              </div>
              {(this.state.is_download_inv===true) &&
                <div id="process-csv-download" className="modal" style={{display:'block'}}>
                  <div className="modal-dialog">
                    <div  style={{display:'none'}}>
                      <ReactHTMLTableToExcel
                        id="donwload-inventory-xls-button"
                        className="btn btn-success pull-right"
                        table="inventory-export-table"
                        filename="inventory-export"
                        sheet="inventory-export"
                        buttonText="Download as XLS"/>

                      <table className="table" id="inventory-export-table">
                        <thead className="thead-light">
                          <tr>
                            <th scope="col"></th>
                            <th scope="col"  style={{cursor:'pointer'}}>PRODUCT</th>
                            <th scope="col">SELLING PRICE</th>
                            <th scope="col">AVAILABLE</th>
                            <th scope="col">CATEGORY</th>
                            <th scope="col" className="text-center">BARCODE</th>
                          </tr>
                        </thead>
                        <tbody>
                           {(this.state.export_list.length > 0) ?this.state.export_list.map( (value, index) => {
                          
                           return (
                            <tr key={ index } className="contact-row product-row" id={"contact-row-"+value.inventory_id}>
                              <td width="70">
                               {index+1}
                              </td>
                              {(value.parent_id===0) &&
                                <td><Link link={'/products/view/'+value.inventory_id} name={value.item_name} style={{fontSize:'14px',fontWeight:'bold'}}/></td>
                              }
                              {(value.parent_id!==0) &&
                                <td>
                                  <Link link={'/products/view/'+value.parent_id} name={value.parent_name} style={{fontSize:'14px',fontWeight:'bold'}}/><br/>
                                  <Link link={'/variant/view/'+value.parent_id+'/'+value.inventory_id} name={value.item_name} style={{fontSize:'13px',fontWeight:'bold'}}/>
                                </td>
                              }
                              <td>{value.selling_price}</td>
                              <td>
                                <p style={{margin:'0px'}}>
                                  {value.availability}
                                </p>
                              </td>
                              <td id={value.category_id} data-id={value.inventory_id} data-value={value.category} style={{cursor:'pointer'}}  dangerouslySetInnerHTML={{ __html: value.category}}>
                              </td>
                              <td className="text-center">
                                {value.barcode_name}
                              </td>
                            </tr>
                          )
                         }) : <tr><td colSpan="7" className="text-center"></td></tr> }
                        </tbody>
                      </table>



                    </div>
                    <div className="modal-content csv-load-blk">
                      <div className="parent-bar">
                        <div className="child-bar" style={{width:this.state.progress+'%'}}></div>
                      </div>
                      <p>Please wait while donload complete ({this.state.progress}%)</p>
                    </div>
                  </div>
                </div>
              }
            </div>
        );
    }
}