import React, { Component } from "react";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../../admin.css';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Leftnav from "../leftnav";
import ModalBox from "../modal-box";
import ProductCategory from "../product-category";
import Autocomplete from "../autocomplete";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheckCircle,faPencilAlt,faPlus,faPlusSquare,faSpinner,faTimes,faTrash  } from '@fortawesome/free-solid-svg-icons';
import Notification from "../notification.js";
import check_access from '../access-level.js';
import axios from 'axios';
import utils from '../../utils.js';
import SelectDropdown from "../select-dropdown";
import Reports from "../reports.js";
let {enc_key,app_api,user_info} = require('../../library.js');
export default class SettingsInventory extends Component {
     constructor() {
      super();
      this.state = {
        exported_inventory_list : [],
        total_inventory_page : 0,
        settings : '',
        show_import:false,
        otp_while_update_product:false,
        is_exporting_inventory:false,
        show_bulk_edit:false,
        show_custom_headers:false,
        default_inventory_status : 'Draft',
        show_inventory_unit : false,
        show_inventory_dozen_quantity : false,
        show_inventory_bundle_quantity : false,
        on_off_msp : false,
        alias_barcode_while_billing : false,
        regenerate_barcode_opt : {},
        is_reg_gen_bcode : false,
        import_data : [],
        import_file : [],
        csv_headers : [],
        comission : '',
        comission_type : 'rs',
        custom_fields_json : [],
        contact_import_headers : [],
        show_inventory_custom_field : false,
        is_create_hsn : false,
        is_update_hsn : false,
        is_delete_hsn : false,
        update_hsn_id : '',
        hsn_list : [],
        inventory_pagination_count : 25
      }
      this.openModal = this.openModal.bind(this);
      this.handleOpenImport = this.handleOpenImport.bind(this);
      this.handleImportChange = this.handleImportChange.bind(this);
      this.handleUploadInventory = this.handleUploadInventory.bind(this);
      this.handleSaveOption = this.handleSaveOption.bind(this);
      this.handleSaveRegenerateBarcodeOpt = this.handleSaveRegenerateBarcodeOpt.bind(this);
      this.handleRegenerateBarcode = this.handleRegenerateBarcode.bind(this);
      this.is_access = check_access.is_access.bind(this);
      this.floating_point = utils.floating_point.bind(this);
    }

    handleAddMoreCustomField = (e) =>{
        let randval = Math.floor(Math.random() * 9000) + 1000;
        let custom_fields_json =  this.state.custom_fields_json;
        let new_field = {'id':randval,'is_purchase_active':true,'is_sale_active':true,'is_barcode_active':true,'name':'','value':''};
        custom_fields_json.push(new_field);
        this.setState({custom_fields_json:custom_fields_json});
    }

    handleCustomFieldKeydown = (e) =>{
      let key_code = e.which;
      if(key_code===13){
        this.handleAddMoreCustomField();
      }
    }

    handleSaveCustomField = e =>{
      let thisid = e.currentTarget.id;
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
    
      let cfields = this.state.custom_fields_json;

      let custom_fields = [];
      for (let index = 0; index < cfields.length; index++) {
        const element = cfields[index];
        if(element.name!==''){
          custom_fields.push(element);
        }
      }

      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'inventory_custom_fields',
          value : custom_fields
      }
      
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
          document.getElementById(thisid).classList.remove("hide");
          document.getElementById(thisid+'-btn-spinner').classList.add("hide");
      }.bind(this)).catch(function(res){
          
      })
    
      let scfsend_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'show_inventory_custom_field',
          value : this.state.show_inventory_custom_field
      }
      let scapi = app_api+'/save_option';
      axios.post(scapi, scfsend_data)
        .then(function (response) {
      }.bind(this)).catch(function(res){
          
      })
    }

    handleSetCustomField = (e) =>{
        let thisvalue = e.currentTarget.value;
        let target = e.currentTarget.getAttribute('data-target');
        let idx = e.currentTarget.getAttribute('data-id');
        let custom_fields_json =  this.state.custom_fields_json;
        custom_fields_json[idx][target] = thisvalue;
        this.setState({custom_fields_json:custom_fields_json});
    }
    

    handleRemoveCustomField = (e) =>{
        let idx = e.currentTarget.getAttribute('data-id');
        let custom_fields_json =  this.state.custom_fields_json;
        custom_fields_json.splice(idx,1);
        this.setState({custom_fields_json:custom_fields_json});
    }


    handleRegenerateBarcode(e){
      
      this.setState({is_reg_gen_bcode:e.currentTarget.checked});
    }

    handleSaveRegenerateBarcodeOpt(e){
      let re_gen_bc_opt = document.querySelectorAll(".re-gen-bc-check");
      let regenerate_barcode_opt = {};
      for(let i=0;i<re_gen_bc_opt.length;i++){
        let is_checked = re_gen_bc_opt[i].checked;
        let this_id = re_gen_bc_opt[i].id;
        if(this_id==='on_stock_age'){
          is_checked = document.getElementById("on_stock_days").value;
        }
        regenerate_barcode_opt[this_id] = is_checked;
      }
      let thisid = e.currentTarget.id;
      document.getElementById(thisid).classList.add("hide");
      document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
    
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, _schema : user_info._schema,
          name : 'regenerate_barcode_opt',
          value : regenerate_barcode_opt
      }
      
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
          document.getElementById(thisid).classList.remove("hide");
          document.getElementById(thisid+'-btn-spinner').classList.add("hide");
      }.bind(this)).catch(function(res){
          
      })
    }

    handleUploadInventory(e){
      document.getElementById('up-con').classList.add("hide");
      document.getElementById('up-con-btn-spinner').classList.remove("hide");
   

      let inventory = document.querySelectorAll(".contact-row");
      let inventory_row = [];
      for(let i=0;i<inventory.length;i++){
         let id = inventory[i].id;
         let name = document.getElementById("name-"+id).value;
         let category = document.getElementById("category-"+id).value;
         let hsn = document.getElementById("hsn-"+id).value;
         let barcode = document.getElementById("barcode-"+id).value;
         let alias_barcode = document.getElementById("alias-barcode-"+id).value;
         let custom_text = document.getElementById("custom_text-"+id).value;
         let quantity = document.getElementById("quantity-"+id).value;
         let purchase_price = document.getElementById("purchase_price-"+id).value;
         let selling_price = document.getElementById("selling_price-"+id).value;
         let product_unit = document.getElementById("product_unit-"+id).value;
         let cgst = document.getElementById("cgst-"+id).value;
         let sgst = document.getElementById("sgst-"+id).value;
         let igst = document.getElementById("igst-"+id).value;
         let discount = document.getElementById("discount-"+id).value;
         let bundle_quantity = document.getElementById("bundle_quantity-"+id).value;
         let status = document.getElementById("status-"+id).value;
         let description = document.getElementById("description-"+id).value;

         let data = {name:name,category:category,hsn:hsn,alias_barcode:alias_barcode,barcode:barcode,custom_text:custom_text,quantity:quantity,purchase_price:purchase_price,selling_price:selling_price,product_unit:product_unit,cgst:cgst,sgst:sgst,igst:igst,discount:discount,bundle_quantity:bundle_quantity,status:status,description:description}
         inventory_row.push(data);
      }


      let body = {
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, _schema : user_info._schema,
          target : 'inventory',
          inventory : inventory_row,
       }
     
      let api = app_api+'/upload_import_csv';
      axios.post(api, body)
        .then(function (response) {
          document.getElementById('up-con').classList.remove("hide");
          document.getElementById('up-con-btn-spinner').classList.add("hide");
       
          window.location.href = '/products';
      }.bind(this)).catch(function(res){
          
      })
    }

    handleOpenImport(e){
      document.getElementById("import-inventory").click();
    }

    handleImportChange(e){
      document.getElementById('imp-con').classList.add("hide");
      document.getElementById('imp-con-btn-spinner').classList.remove("hide");
      const files = e.target.files
      const formData = new FormData()
      let csv = files[0];


      this.setState({import_file:csv});
      formData.append('importFile', csv);
      formData.append('store_name', user_info.store_name);
      formData.append('branch_id', user_info.login_id);
      formData.append('user_id', user_info.user_id);
      formData.append('uuid', utils.uuid());
      formData.append('role_id', user_info.access_level);
      formData.append('token', user_info.token);
      formData.append('_schema', user_info._schema);
      formData.append('type', 'process_data');
      formData.append('target', 'inventory');
      let api = app_api+'/process_import_csv';
      axios.post(api, formData)
        .then(function (response) {
          let resp = response.data;
          document.getElementById('imp-con').classList.remove("hide");
          document.getElementById('imp-con-btn-spinner').classList.add("hide");
          this.setState({show_custom_headers:true,csv_headers:resp.headers,import_data:resp.import_data});
      }.bind(this)).catch(function(res){
          
      })
    }

    openModal(event) {
      let thisid = event.currentTarget.getAttribute('data_target');
      if(thisid==='add_hsn_name'){
        this.setState({is_create_hsn:true});
        return false;
      }
      if(thisid!==null){
        var element = document.getElementById(thisid);
        element.classList.remove("fade");
        element.style.display="block";

        document.getElementById("add-category-title").innerHTML = 'ADD CATEGORY';
        document.getElementById("update_category_id").value = "";
        document.getElementById("category").value = "";
        document.getElementById("parent_category").value = 0;
        document.getElementById("category_sequence").value = '';
        document.getElementById("category_short_name").value = '';
      }
    }

    componentDidMount(){
      let curl = window.location.href;
      const update_bulk = curl.match(/settings\/inventory\/update-bulk$/i);
      const import_headers = curl.match(/settings\/inventory\/import-headers$/i);

      if(update_bulk){
        this.setState({show_bulk_edit:true});
       
      }

      if(import_headers){
        this.setState({show_custom_headers:true});
      }
      let keys = "'default_inventory_status','allow_negative_inventory','show_inventory_unit','show_inventory_dozen_quantity','show_inventory_bundle_quantity','minimum_selling_price_margin','bundle_text','bundle_per_quantity','regenerate_barcode_opt','comission','inventory_custom_fields','show_inventory_custom_field','on_off_msp','alias_barcode_while_billing','hsn_list','inventory_pagination_count','otp_while_update_product'";
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          keys:keys
      }
      let api = app_api+'/get_settings';
      axios.post(api, send_data)
        .then(function (response) {
          let resp = response.data;
          let is_allowed_negative_inventory = false;
          if(resp.allow_negative_inventory!==undefined){
            is_allowed_negative_inventory = resp.allow_negative_inventory;
          }
          if(resp.inventory_custom_fields!==undefined){
            let inventory_custom_fields = resp.inventory_custom_fields;
            this.setState({custom_fields_json:inventory_custom_fields});
          }
          if(resp.inventory_pagination_count!==undefined){
            let inventory_pagination_count = resp.inventory_pagination_count;
            this.setState({inventory_pagination_count:inventory_pagination_count});
          }
          if(resp.show_inventory_custom_field!==undefined){
            let show_inventory_custom_field = resp.show_inventory_custom_field;
            this.setState({show_inventory_custom_field:show_inventory_custom_field});
          }
          if(resp.show_inventory_unit!==undefined){
            let show_inventory_unit = resp.show_inventory_unit;
            this.setState({show_inventory_unit:show_inventory_unit});
          }
          if(resp.show_inventory_dozen_quantity!==undefined){
            let show_inventory_dozen_quantity = resp.show_inventory_dozen_quantity;
            this.setState({show_inventory_dozen_quantity:show_inventory_dozen_quantity});
          }
          if(resp.show_inventory_bundle_quantity!==undefined){
            let show_inventory_bundle_quantity = resp.show_inventory_bundle_quantity;
            this.setState({show_inventory_bundle_quantity:show_inventory_bundle_quantity});
          }
          if(resp.on_off_msp!==undefined){
            let on_off_msp = resp.on_off_msp;
            this.setState({on_off_msp:on_off_msp});
          }
          if(resp.alias_barcode_while_billing!==undefined){
            let alias_barcode_while_billing = resp.alias_barcode_while_billing;
            this.setState({alias_barcode_while_billing:alias_barcode_while_billing});
          }
          if(resp.otp_while_update_product!==undefined){
            let otp_while_update_product = resp.otp_while_update_product;
            this.setState({otp_while_update_product:otp_while_update_product});
          }
          if(resp.regenerate_barcode_opt!==undefined){
            let regenerate_barcode_opt = resp.regenerate_barcode_opt;
            this.setState({regenerate_barcode_opt:regenerate_barcode_opt});
            if(regenerate_barcode_opt.regenerate_barcode===true){
              this.setState({is_reg_gen_bcode:true})
            }
          }
          let default_inventory_status = 'Draft';
          if(resp.default_inventory_status!==undefined){
            default_inventory_status = resp.default_inventory_status;
          }
          if(resp.comission!==undefined){
            let com = resp.comission;
            this.setState({comission:com.comission,comission_type:com.comission_type});
          }
          if(resp.hsn_list!==undefined){
            let hsn_list = resp.hsn_list;
            this.setState({hsn_list:hsn_list});
          }
          this.setState({allow_negative_inventory:is_allowed_negative_inventory,default_inventory_status:default_inventory_status});
          this.setState({settings:resp});
      }.bind(this)).catch(function(res){
          
      })

      this.handleGetHeaders();
    }

    handleSaveOption(e){
      let thisid = e.currentTarget.id;
      let key = e.currentTarget.getAttribute('data-id');
      let msp='';
      let target = e.currentTarget.getAttribute('data-target');
      if(key!=='allow_negative_inventory' && key!=='default_inventory_status' && key!=='show_inventory_dozen_quantity' && key!=='show_inventory_unit' && key!=='show_inventory_bundle_quantity' && key!=='on_off_msp' && key!=='alias_barcode_while_billing' && key!=='otp_while_update_product'){
        msp = document.getElementById(target).value;

        if(key==='comission'){
          msp +=this.state.comission_type;
        }


        document.getElementById(thisid).classList.add("hide");
        document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
        if(key==='minimum_selling_price_margin'){
          if(msp===""){ 
            msp = 0;
          }
        }
      }else if(key==='allow_negative_inventory'){
        msp = e.currentTarget.checked;
        this.setState({allow_negative_inventory:e.currentTarget.checked});
      }else if(key==='default_inventory_status'){
        msp = e.currentTarget.value;
        this.setState({default_inventory_status:msp});
      }else if(key==='show_inventory_dozen_quantity'){
        msp = e.currentTarget.checked;
        this.setState({show_inventory_dozen_quantity:msp});
      }else if(key==='show_inventory_unit'){
        msp = e.currentTarget.checked;
        this.setState({show_inventory_unit:msp});
      }else if(key==='show_inventory_bundle_quantity'){
        msp = e.currentTarget.checked;
        this.setState({show_inventory_bundle_quantity:msp});
      }else if(key==='on_off_msp'){
        msp = e.currentTarget.checked;
        this.setState({on_off_msp:e.currentTarget.checked});
      }else if(key==='alias_barcode_while_billing'){
        msp = e.currentTarget.checked;
        this.setState({alias_barcode_while_billing:e.currentTarget.checked});
      }else if(key==='otp_while_update_product'){
        msp = e.currentTarget.checked;
        this.setState({otp_while_update_product:e.currentTarget.checked});
      }
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : key,
          value : msp,
      }
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
        if(key!=='allow-negative-inventory' && key!=='default_inventory_status'){
          document.getElementById(thisid).classList.remove("hide");
          document.getElementById(thisid+'-btn-spinner').classList.add("hide");
        }
      }.bind(this)).catch(function(res){
          
      })
    }

    setupImportHeader = e =>{
      this.setState({show_custom_headers:true});
    }

    hideImportHeader = e =>{
      this.setState({show_custom_headers:false});
    }

    handleChangeCommissionType = e =>{
      let thisval = e.currentTarget.value;
      this.setState({comission_type:thisval})
    }

    handleShowCustomField = e =>{
      this.setState({show_inventory_custom_field:e.currentTarget.checked});
    }
    
    handleChangeCustomFieldVisibility = e =>{
      let is_checked = e.currentTarget.checked;
      let dataid = e.currentTarget.getAttribute('data-id');
      let target = e.currentTarget.getAttribute('data-target');
    
      let idx = dataid;
      let custom_fields_json =  this.state.custom_fields_json;
      custom_fields_json[idx][target] = is_checked;
      this.setState({custom_fields_json:custom_fields_json});
    }

    handleGetHeaders = ()=>{
      let keys = "'import_inventory_headers_new'";
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          keys:keys
      }
      let api = app_api+'/get_settings';
      axios.post(api, send_data)
        .then(function (response) {
          let resp = response.data;
          if(resp.import_inventory_headers_new!==undefined){
            let import_inventory_headers_new = resp.import_inventory_headers_new;
            this.setState({contact_import_headers:import_inventory_headers_new});
          }
      }.bind(this)).catch(function(res){
          
      })
    }
    handleChangeInventoryPaginationCount = (e) =>{
      let thisval = e.currentTarget.value;
    
      this.setState({inventory_pagination_count:thisval});
    }
    handleExportInventory = () =>{
      this.setState({is_exporting_inventory:true});
      this.handleProcessExportInventory(1);
    }
    handleProcessExportInventory = (page) =>{
      
      let exported_inventory_list = this.state.exported_inventory_list;
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, 
          _schema : user_info._schema,
          total_inventory_page : this.state.total_inventory_page,
          page : page
      }
      
      let api = app_api+'/export_inventory';
      axios.post(api, send_data)
        .then(function (response) {
          let resp = response.data.response;
          let is_empty = response.data.is_empty;
          let total_inventory_page = response.data.total_inventory_page;
          if(this.state.total_inventory_page===0){
            this.setState({total_inventory_page:total_inventory_page});
          }
          let progress = (page/total_inventory_page)*100;
          if(progress>100){
            progress = 100;
          }
          this.setState({progress:Math.round(progress)});
          for (let index = 0; index < resp.length; index++) {
            const element = resp[index];
            exported_inventory_list.push(element);
          }
          this.setState({exported_inventory_list});
          if(is_empty===false){
            let next_page = page+1;
            this.handleProcessExportInventory(next_page);
          }else if(is_empty===true){
            document.getElementById('donwload-inventory-xls-button').click();
            this.setState({is_exporting_inventory:false});
          }
      }.bind(this)).catch(function(res){
          
      })
    }
    render() {
        let minimum_selling_price_margin = (this.state.settings.minimum_selling_price_margin!==undefined && this.state.settings.minimum_selling_price_margin!==null)?this.state.settings.minimum_selling_price_margin:'';
        let bundle_text = (this.state.settings.bundle_text)?this.state.settings.bundle_text:'';
        let bundle_per_quantity = (this.state.settings.bundle_per_quantity)?this.state.settings.bundle_per_quantity:'';
        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-8">
                        <div className="mod-title"><span>Inventory</span></div>
                    </div>
                    <div className="col-md-4">
                        <div className="pull-right"><Notification /></div>
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                  <div className="content-area">
                     <div className="main-content">
                       <span id="hideImportHeader" onClick={this.hideImportHeader}></span>
                      {(this.state.show_import===false && this.state.show_bulk_edit===false && this.state.show_custom_headers===false) &&
                        <>

                        <div className="row">
                          
                          <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="box-container">
                                  <div className="box-head">
                                    <label>PRODUCT CATEGORY</label>
                                    <label style={{marginLeft:'86px'}}>BARCODE SEQUENCE</label>
                                    {this.is_access('settings-inventory-create') && 
                                    <FontAwesomeIcon onClick={this.openModal} data_target="add_category" icon={faPlusSquare} className="pull-right margin-5 fa-icon"/>
                                    }
                                  </div>
                                  <div className="box-body">
                                      <ProductCategory show="list" />
                                  </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="box-container">
                                  <div className="box-head">
                                    <label>INVENTORY IMPORT/EXPORT</label>
                                  </div>
                                  <div className="box-body pad-15">
                                
                                      <div className="row">
                                        <div className="col-md-12 text-center">
                                            <a href="/inventory-import.csv" download>Download inventory import demo file</a>
                                        </div>
                                      </div>
                                      <div className="row mrtop-10">
                                        <div className="col-md-3"></div>
                                        <div className="col-md-6 text-center" style={{display:'grid'}}>
                                          
                                        <input type="file" onChange={this.handleImportChange} id="import-inventory" accept=".csv" style={{display:'none'}}/>
                                          <button type="button" className="btn btn-danger" id="imp-con" onClick={this.handleOpenImport}>IMPORT</button>
                                          <button type="button" id="imp-con-btn-spinner" className="hide btn btn-danger"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                        </div>
                                        <div className="col-md-3"></div>
                                      </div>
                                      <div className="row mrtop-20">
                                      <div className="col-md-3"></div>
                                        <div className="col-md-6 text-center"  style={{display:'grid'}}>
                                         {/*  <form method="POST" action={app_api+"/export_csv"}>
                                            <input type="hidden" value={user_info.login_id} name="branch_id" />
                                            <input type="hidden" value={user_info.store_name} name="store_name" />
                                            <input type="hidden" value="inventory" name="target" />
                                            <button type="submit" className="btn btn-success">
                                              Export
                                            </button>
                                          </form> */}
                                          {(this.state.is_exporting_inventory===false) &&
                                            <button type="button" className="btn btn-success" id="exp-inv" onClick={this.handleExportInventory}>EXPORT</button>
                                          }
                                          {(this.state.is_exporting_inventory===true) &&
                                            <button type="button" id="exp-inv-btn-spinner" className="btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                          }
                                        </div>
                                        <div className="col-md-3"></div>
                                      </div>
                                      {(this.state.is_exporting_inventory===true && this.state.progress>=0) &&
                                      <div className="row mrtop-20">
                                        <div className="col-md-12">
                                        <div className="csv-load-blk" style={{padding:'0px'}}>
                                          <div className="parent-bar">
                                            <div className="child-bar" style={{width:this.state.progress+'%'}}></div>
                                          </div>
                                          <p>Please wait while export complete ({this.state.progress}%)</p>
                                        </div>
                                        </div>
                                        <div className="col-md-12 hide">
                                        <ReactHTMLTableToExcel
                                          id="donwload-inventory-xls-button"
                                          className="btn btn-success pull-right"
                                          table="inventory-export-table"
                                          filename="inventory_export"
                                          sheet="inventory_export"
                                          buttonText="Download as XLS"/>
                                          <table id="inventory-export-table">
                                            <thead>
                                              <tr>
                                                <th>ITEM NAME</th>
                                                <th>ITEM DESCRIPTION</th>
                                                <th>PARTY NAME</th>
                                                <th>CATEGORY</th>
                                                <th>HSN</th>
                                                <th>BARCODE</th>
                                                <th>ALIAS BARCODE</th>
                                                <th>PART NAME</th>
                                                <th>PURCHASE PRICE</th>
                                                <th>ENC.PURCHASE PRICE</th>
                                                <th>ENC.PURCHASE DATE</th>
                                                <th>SELLING PRICE</th>
                                                <th>TAX</th>
                                                <th>TAX TYPE</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {(this.state.exported_inventory_list.map((value)=>{
                                                return(
                                                        <tr>
                                                          <td>{value.item_name}</td>
                                                          <td>{value.item_desc}</td>
                                                          <td>{value.party_name}</td>
                                                          <td>{value.category}</td>
                                                          <td>{value.hsn}</td>
                                                          <td>{value.barcode_name}</td>
                                                          <td>{value.alias_barcode}</td>
                                                          <td>{value.part_name}</td>
                                                          <td>{value.purchase_price}</td>
                                                          <td>{value.enc_purchase_price}</td>
                                                          <td>{value.enc_purchase_date}</td>
                                                          <td>{value.selling_price}</td>
                                                          <td>{value.tax}</td>
                                                          <td>{value.is_tax_inc}</td>
                                                        </tr>
                                                      )
                                              }))}
                                              
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                      }
                                      <div className="row mrtop-20">
                                      <div className="col-md-3"></div>
                                        <div className="col-md-6 text-center"  style={{display:'grid'}}>
                                            <a href="/settings/inventory/update-bulk">
                                              <button type="button" className="btn btn-success" style={{width:'100%'}}>
                                                Update Bulk Inventory
                                              </button>
                                            </a>
                                        </div>
                                        <div className="col-md-3"></div>
                                      </div>
                                  
                                  </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                            <div className="box-container">
                              <div className="box-head">
                                <label>REGENERATE BARCODE</label>
                              </div>
                              <div className="box-body" style={{padding:'10px 20px'}}>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div>
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input re-gen-bc-check" type="checkbox" id="regenerate_barcode" value="regenerate_barcode" checked={this.state.is_reg_gen_bcode} onChange={this.handleRegenerateBarcode}/>
                                      <label className="form-check-label" style={{fontWeight:'bold'}} htmlFor="regenerate_barcode" id="lbl-regenerate_barcode">REGENERATE BARCODE</label>
                                      </div>
                                    </div>
                                    {(this.state.is_reg_gen_bcode===true) &&
                                      <div style={{marginLeft:'10px', width:'100%'}}>
                                    <div>
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input re-gen-bc-check" type="checkbox" id="change_supplier" value="change_supplier" defaultChecked={this.state.regenerate_barcode_opt.change_supplier}/>
                                      <label className="form-check-label chklbl" htmlFor="change_supplier" id="lbl-change_supplier">ON SUPPLIER CHANGE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input re-gen-bc-check" type="checkbox" id="change_pp" value="change_pp"   defaultChecked={this.state.regenerate_barcode_opt.change_pp}/>
                                      <label className="form-check-label chklbl" htmlFor="change_pp" id="lbl-change_pp">ON PURCHASE PRICE CHANGE</label>
                                      </div>
                                    </div>
                                  
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input re-gen-bc-check" type="checkbox" id="change_sp" value="change_sp"  defaultChecked={this.state.regenerate_barcode_opt.change_sp}/>
                                      <label className="form-check-label chklbl" htmlFor="change_sp" id="lbl-change_sp">ON SELLING PRICE CHANGE</label>
                                      </div>
                                    </div>
                                  
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input re-gen-bc-check" type="checkbox" id="change_tax" value="change_tax" defaultChecked={this.state.regenerate_barcode_opt.change_tax}/>
                                      <label className="form-check-label chklbl" htmlFor="change_tax" id="lbl-change_tax">ON TAX CHANGE</label>
                                      </div>
                                    </div>
                                    <div className="mrtop-5">
                                      <div className="form-check form-check-inline" style={{width:'257px'}}>
                                      <input className="form-check-input re-gen-bc-check" type="checkbox" id="on_stock_age" value="on_stock_age" defaultChecked={this.state.regenerate_barcode_opt.on_stock_age}/>
                                      <label className="form-check-label chklbl" htmlFor="on_stock_age" id="lbl-on_stock_age">
                                        IF STOCK IS OLDER THAN <input type="text" className="" id="on_stock_days" style={{width:'40px'}}  defaultValue={this.state.regenerate_barcode_opt.on_stock_age}/> Days
                                      </label>
                                      </div>
                                    </div>
                                    </div>
                                  }
                                  </div>
                                </div>
                              </div>
                              <div className="text-right" style={{borderRight:'1px solid #d7d7d7',borderBottom:'1px solid #d7d7d7',borderLeft:'1px solid #d7d7d7',padding:'10px'}}>
                                <button type="button" className="btn btn-success" id="save-regenerate-bc" onClick={this.handleSaveRegenerateBarcodeOpt}>Save Settings</button>
                                <button type="button" id="save-regenerate-bc-btn-spinner" className="hide btn btn-success btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                              </div>
                            </div>
                          </div>


                          <div className="col-md-12">
                            <div className="box-container">
                              <div className="box-head">
                                <label>CUSTOM FIELDS</label>
                              </div>
                              <div className="box-body" style={{padding:'10px 20px'}}>
                                <div className="row">
                                  <div className="col-md-12">
                                    <div>
                                      <div className="form-check form-check-inline">
                                      <input className="form-check-input re-gen-bc-check" type="checkbox" id="show_inventory_custom_field" value="show_inventory_custom_field" checked={this.state.show_inventory_custom_field} onChange={this.handleShowCustomField}/>
                                      <label className="form-check-label" style={{fontWeight:'bold'}} htmlFor="show_inventory_custom_field" id="lbl-show_inventory_custom_field">SHOW CUSTOM FIELDS</label>
                                      </div>
                                    </div>
                                    {(this.state.show_inventory_custom_field) &&
                                      <div style={{marginLeft:'10px', width:'100%'}}>
                                        <table className="table cftbl" style={{width:'100%'}}>
                                          {(this.state.custom_fields_json.length>0) &&
                                              <thead>
                                                <tr>  
                                                  <th>Name</th>
                                                  <th>Purchase</th>
                                                  <th>Sale</th>
                                                  <th>Barcode</th>
                                                  <th></th>
                                                </tr>
                                              </thead>
                                          }
                                          <tbody>
                                          {(this.state.custom_fields_json.length>0) &&
                                            <>
                                            {this.state.custom_fields_json.map((value,index) => {
                                              return (
                                                <tr key={'cfp-'+index}>
                                                  <td><input type="text" className="form-control" value={value.name} data-id={index} data-target="name" onChange={this.handleSetCustomField} onKeyDown={this.handleCustomFieldKeydown} id={"cfj-name-"+index}/></td>
                                                  <td style={{textAlign:'center'}}>
                                                    <div className="form-check form-switch" style={{marginTop:'10px'}}>
                                                    <input className="form-check-input" type="checkbox" role="switch" data-id={index} id={"cfjswitch"+index} data-target="is_purchase_active" defaultChecked={value.is_purchase_active} onChange={this.handleChangeCustomFieldVisibility}/>
                                                    
                                                  </div>
                                                  </td>
                                                  <td style={{textAlign:'center'}}>
                                                    <div className="form-check form-switch" style={{marginTop:'10px'}}>
                                                    <input className="form-check-input" type="checkbox" role="switch" data-id={index} id={"cfjswitch"+index} data-target="is_sale_active"  defaultChecked={value.is_sale_active} onChange={this.handleChangeCustomFieldVisibility}/>
                                                    
                                                  </div>
                                                  </td>
                                                  <td style={{textAlign:'center'}}>
                                                    <div className="form-check form-switch" style={{marginTop:'10px'}}>
                                                    <input className="form-check-input" type="checkbox" role="switch" data-id={index} id={"cfjswitch"+index} data-target="is_barcode_active"  defaultChecked={value.is_barcode_active} onChange={this.handleChangeCustomFieldVisibility}/>
                                                    
                                                  </div>
                                                  </td>
                                                  <td>
                                                    <button type="button" className="btn" data-id={index} onClick={this.handleRemoveCustomField} style={{marginTop:'3px'}}>
                                                      <FontAwesomeIcon icon={faTimes}  style={{marginRight:'0px'}}></FontAwesomeIcon>
                                                    </button>
                                                  </td>
                                                </tr>
                                              )
                                            })}
                                            <tr>
                                              <td colSpan={4} style={{textAlign:'center'}}>
                                              <button type="button" className="btn btn-outline-info btn-block" onClick={this.handleAddMoreCustomField} style={{marginTop:'3px',marginLeft:'3px'}}>
                                                        <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                                                      </button>
                                              </td>
                                            </tr>
                                            </>
                                          }
                                          {(this.state.custom_fields_json.length===0) &&
                                            <tr>
                                            <td colSpan={3} className="text-center">
                                              <button type="button" className="btn btn-outline-info" onClick={this.handleAddMoreCustomField} style={{marginTop:'3px'}}>
                                                Add Custom Fields
                                              </button>
                                            </td>
                                          </tr>
                                          }
                                          </tbody>
                                        </table>
                                      </div>
                                    }
                                  </div>
                                </div>
                              </div>
                              <div className="text-right" style={{borderRight:'1px solid #d7d7d7',borderBottom:'1px solid #d7d7d7',borderLeft:'1px solid #d7d7d7',padding:'10px'}}>
                                <button type="button" className="btn btn-success" id="save-custom-field" onClick={this.handleSaveCustomField}>Save Settings</button>
                                <button type="button" id="save-custom-field-btn-spinner" className="hide btn btn-success btn-save-action"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                              </div>
                            </div>
                          </div>

                        </div>

                          </div>
                          <div className="col-md-6">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="box-container">
                                    <div className="box-head">
                                      <label>PRODUCT HSN</label>
                                      <FontAwesomeIcon onClick={this.openModal} data_target="add_hsn_name" icon={faPlusSquare} className="pull-right margin-5 fa-icon"/>
                                    </div>
                                    <div className="box-body">
                                    <table className="table">
                                      <tbody>
                                          {(this.state.hsn_list.length > 0) ?this.state.hsn_list.map( (value, index) => {
                                          return (
                                            <tr key={ index }>
                                              <td>
                                                <span className="show l-span" style={{marginLeft:value.pixel}}>
                                                  { value.hsn } ({value.tax_rate}%)
                                                </span>
                                              </td>
                                             
                                              <td>
                                                <div id={'pc-normal-mode-'+index} className="show">
                                                  <FontAwesomeIcon data-id={index} onClick={this.handleEditHSN} icon={faPencilAlt} className="pull-right fa-icon"/>
                                                  <FontAwesomeIcon onClick={this.handleDeleteHSN} data_target="delete_confirm" data_value={value} data_id={index} icon={faTrash} className="pull-right fa-icon"/>
                                                </div>
                                              </td>
                                            </tr>
                                          )
                                        }) : <tr><td className="text-center">No record(s) found</td></tr> }
                                      </tbody> 
                                    </table>
                                    </div>
                                </div>
                              </div>
                              <div className="col-md-12">
                              <div className="box-container">
                                <div className="box-head">
                                  <label>GENERAL SETTINGS</label>
                                </div>
                                <div className="box-body">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="box-container border-bottom">
                                          <a href="/setting/inventory/stock-calculator"><button type="button" className="btn btn-success btn-block">
                                              Stock Calculator
                                          </button></a>
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="box-container">
                                          <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" onChange={this.handleSaveOption} id="ask-for-otp-while-update-product" value="1" data-id="otp_while_update_product" checked={this.state.otp_while_update_product}/>
                                            <label className="form-check-label" htmlFor="ask-for-otp-while-update-product">Ask for OTP validation while update product</label>
                                          </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="box-container">
                                          <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox" onChange={this.handleSaveOption} id="alias-barcode-while-billing" value="1" data-id="alias_barcode_while_billing" checked={this.state.alias_barcode_while_billing}/>
                                            <label className="form-check-label" htmlFor="alias-barcode-while-billing">Allow Alias Barcode visibility in inventory</label>
                                          </div>
                                        </div>
                                    </div>
                                  <div className="col-md-12">
                                  
                                    <div className="box-container">
                                    
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" onChange={this.handleSaveOption} id="on-off-msp" value="1" data-id="on_off_msp" checked={this.state.on_off_msp}/>
                                        <label className="form-check-label" htmlFor="on-off-msp">Allow MSP check while billing</label>
                                      </div>
                                      {(this.state.on_off_msp===true) &&
                                        <div>
                                          <label className="lable-for" for="min-selling-price">MSP margin to Selling Price</label>
                                          <div className="input-group">
                                            <input id="min-selling-price" type="text" className="form-control no-border-right" defaultValue={minimum_selling_price_margin}/>
                                            <div className="input-group-append">
                                              <span className="input-group-text pad-left-none">%</span>
                                            </div>
                                            <button type="button" id="msp-btn-btn-spinner" className="hide btn btn-primary mrleft-10"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                            <button type="button" id="msp-btn" onClick={this.handleSaveOption} data-target="min-selling-price" data-id="minimum_selling_price_margin" className="btn btn-primary mrleft-10">Save</button>
                                          </div>
                                        </div>
                                      }
                                    </div>
                                  </div> 
                                  <div className="col-md-12">
                                    <div className="box-container">
                                      <label className="lable-for" for="bundle-text">Inventory Per Page Count</label>
                                      <div className="input-group">
                                        <input id="inv-page-count" type="text" className="form-control" value={this.state.inventory_pagination_count} onChange={this.handleChangeInventoryPaginationCount}/>
                                      
                                        <button type="button" id="inv-page-count-btn-spinner" className="hide btn btn-primary mrleft-10"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                        <button type="button" id="inv-page-count" onClick={this.handleSaveOption} data-target="inv-page-count" data-id="inventory_pagination_count" className="btn btn-primary mrleft-10">Save</button>
                                      </div>
                                    </div>
                                  </div> 
                                  <div className="col-md-12">
                                    <div className="box-container">
                                      <label className="lable-for" for="bundle-text">Bundle Dispaly Text</label>
                                      <div className="input-group">
                                        <input id="bundle-text" type="text" className="form-control" defaultValue={bundle_text}/>
                                      
                                        <button type="button" id="bun-txt-btn-spinner" className="hide btn btn-primary mrleft-10"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                        <button type="button" id="bun-txt" onClick={this.handleSaveOption} data-target="bundle-text" data-id="bundle_text" className="btn btn-primary mrleft-10">Save</button>
                                      </div>
                                    </div>
                                  </div> 
                                  <div className="col-md-12">
                                    <div className="box-container">
                                      <label className="lable-for" for="default_inventory_status">Inventory After Adding</label>
                                      <select className="form-control" value={this.state.default_inventory_status}  onChange={this.handleSaveOption} data-target="default_inventory_status" data-id="default_inventory_status">
                                        <option value="Draft">Inactive</option>
                                        <option value="Active">Active</option>
                                      </select>
                                    </div>
                                  </div> 
                                  <div className="col-md-12">
                                    <div className="box-container">
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" onChange={this.handleSaveOption} id="allow-negative-inventory" value="1" data-id="allow_negative_inventory" checked={this.state.allow_negative_inventory}/>
                                        <label className="form-check-label" htmlFor="allow-negative-inventory">Allow Negative Inventory Selling</label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="box-container">
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" onChange={this.handleSaveOption} id="show_inventory_unit" value="1" data-id="show_inventory_unit" checked={this.state.show_inventory_unit}/>
                                        <label className="form-check-label" htmlFor="show_inventory_unit">Show Unit in Inventory List</label>
                                      </div>
                                    </div>
                                  </div> 
                                  <div className="col-md-12">
                                    <div className="box-container">
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" onChange={this.handleSaveOption} id="show_inventory_dozen_quantity" value="1" data-id="show_inventory_dozen_quantity" checked={this.state.show_inventory_dozen_quantity}/>
                                        <label className="form-check-label" htmlFor="show_inventory_dozen_quantity">Show Dozen Quantity in Inventory List</label>
                                      </div>
                                    </div>
                                  </div> 
                                  <div className="col-md-12">
                                    <div className="box-container">
                                      <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox" onChange={this.handleSaveOption} id="show_inventory_bundle_quantity" value="1" data-id="show_inventory_bundle_quantity" checked={this.state.show_inventory_bundle_quantity}/>
                                        <label className="form-check-label" htmlFor="show_inventory_bundle_quantity">Show Bundle Quantity in Inventory List</label>
                                      </div>
                                    </div>
                                  </div> 
                                  <div className="col-md-12">
                                    <div className="box-container">
                                      <label className="" for="comission">Inventory Comission to Staff: </label>
                                      <button type="button" id="com-btn-spinner" className="hide btn btn-primary mrleft-10 pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                        <button type="button" id="com" onClick={this.handleSaveOption} data-target="comission" data-id="comission" className="btn btn-primary mrleft-10 pull-right">Save</button>
                                      <input type="text" className="form-control pull-right w-100px" defaultValue={this.state.comission} id="comission"/>
                                        <div className="form-group round-blk">
                                        <div className="form-check">
                                          <input className="form-check-input" type="radio" name="comission" id="comission-rs" value="rs" checked={(this.state.comission_type==='rs')?true:false} onClick={this.handleChangeCommissionType}/>
                                          <label className="form-check-label rs-sym" htmlFor="comission-rs">
                                            ₹
                                          </label>
                                        </div>
                                        <div className="form-check">
                                          <input className="form-check-input" type="radio" name="comission" id="comission-per" value="per"  onClick={this.handleChangeCommissionType} checked={(this.state.comission_type==='per')?true:false} />
                                          <label className="form-check-label" htmlFor="comission-per">
                                            %
                                          </label>
                                        </div>
                                          
                                        
                                        </div>
                                    </div>
                                  </div> 
                                  </div>
                                </div>
                              </div>
                            </div>
                            </div>
                          </div>
                        </div>

                       
                       

                        </>
                      }
                    
                        {(this.state.show_bulk_edit===true) &&
                          <UpdateBulkInventory/>
                        }
                        {(this.state.show_custom_headers===true) &&
                          <ImportHeaderSettings  contact_import_headers={this.state.contact_import_headers}  headers={this.state.csv_headers} import_data={this.state.import_data} import_file={this.state.import_file}/>
                        }
                     </div>
                  </div>
                </div>
              </div>
              <ModalBox id="add_contact_type" title="ADD CONTACT TYPE" data_id="Contact Type"/>
              <ModalBox id="add_payment_mode" title="ADD PAYMENT MODE"  data_id="Payment Mode"/>
              <ModalBox id="add_unit" title="ADD UNIT"  data_id="Unit"/>
              <ModalBox id="delete_confirm" title="Delete"  data_id="Delete"/>
              <ModalBox id="add_category" title="ADD CATEGORY"  data_id="Category"/>

              {(this.state.is_create_hsn===true) &&
                <div className="modal" id="add_hsn_name"  role="dialog" aria-labelledby="add-hsn-title" aria-hidden="true" style={{display:'block'}}>
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="add-hsn-title">ADD HSN</h5>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-6">
                              <label htmlFor="hsn_name">HSN</label>
                              <input type="text" name="hsn_name" className="form-control" placeholder="Please enter HSN" id="hsn_name"/>
                          </div>
                          <div className="col-md-6">
                              <label htmlFor="hsn_name">TAX RATE %</label>
                              <input type="text" name="hsn_tax_rate" className="form-control" placeholder="Please enter HSN tax rate" id="hsn_tax_rate"/>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" id="close-save-hsn-but" onClick={this.closeHSNModel}>Close</button>
                        <button type="button" id="save-hsn-btn" className="btn btn-success pull-right" onClick={this.handleSaveHSN}>Save HSN</button>
                        <button type="button" id="save-hsn-btn-spinner" className="hide btn btn-success pull-right"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                      </div>
                    </div>
                  </div>
                </div>
              }
              {(this.state.is_delete_hsn===true) &&
                  <div className="modal" style={{display:'block'}}>
                  <div className="modal-dialog modal-confirm">
                    <div className="modal-content">
                      <div className="modal-header flex-column">
                        <div className="icon-box">
                          <span className="material-icons">&times;</span>
                        </div>            
                        <h4 className="modal-title w-100">Are you sure?</h4>  
                      </div>
                      <div className="modal-body">
                        <p style={{whiteSpace: 'break-spaces'}} id="delete_message">{(this.props.delete_message)?this.props.delete_message:'Do you really want to delete these records? This process cannot be undone.'}</p>
                      </div>
                      <div className="modal-footer justify-content-center">
                        <button type="button" className="btn btn-secondary" onClick={this.handleCloseHSNDeleteList}>No</button>
                        <button type="button" className="btn btn-danger" onClick={this.handleDeleteHSNList}>Yes</button>
                      </div>
                    </div>
                  </div>
                </div>  
              }
            </div>
        );
    }

    closeHSNModel = () =>{
      this.setState({is_create_hsn:false});
      this.setState({update_hsn_id:''});
    }
    handleCloseHSNDeleteList = () =>{
      this.setState({is_delete_hsn:false});
      this.setState({update_hsn_id:''});
    }
    handleDeleteHSNList = () =>{
      let hsn_list = this.state.hsn_list;
      let id = this.state.update_hsn_id;
      hsn_list.splice(id,1);
      this.setState({hsn_list:hsn_list});
      this.saveHSNList(hsn_list);
      this.handleCloseHSNDeleteList();
    }

    saveHSNList = (hsn_list) =>{
      this.setState({hsn_list:hsn_list});
      let send_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, _schema : user_info._schema,
          name : 'hsn_list',
          value : hsn_list
      }
      
      let api = app_api+'/save_option';
      axios.post(api, send_data)
        .then(function (response) {
        
      }.bind(this)).catch(function(res){
          
      })
    }

    handleSaveHSN = () =>{
      let hsn_name = document.getElementById('hsn_name').value;
      let hsn_tax_rate = document.getElementById('hsn_tax_rate').value;
      if(hsn_name!=="" && hsn_tax_rate!==""){
        let hsn_list = this.state.hsn_list;
        let hsn_json = {hsn:hsn_name, tax_rate:hsn_tax_rate};
        if(this.state.update_hsn_id===''){
          hsn_tax_rate = hsn_tax_rate.replaceAll('%','');
          hsn_list.push(hsn_json);
        }else{
          hsn_list[this.state.update_hsn_id] = hsn_json;
        }
        
        this.saveHSNList(hsn_list);
        this.closeHSNModel();
      }else{
        document.getElementById('hsn_name').focus();
      }
    }
    handleEditHSN = (e) =>{
      this.setState({is_create_hsn:true});
      this.setState({is_update_hsn:true});
      let id = e.currentTarget.getAttribute('data-id');
      this.setState({update_hsn_id:id});
      let hsn = this.state.hsn_list[id].hsn;
      let tax_rate = this.state.hsn_list[id].tax_rate;
      setTimeout(() => {
        if(document.getElementById('hsn_name')){
          document.getElementById('hsn_name').value = hsn;
        }
        if(document.getElementById('hsn_tax_rate')){
          document.getElementById('hsn_tax_rate').value = tax_rate;
        }
      }, 100);
    }
    handleDeleteHSN = (e) =>{
      this.setState({is_delete_hsn:true});
      let id = e.currentTarget.getAttribute('data-id');
      this.setState({update_hsn_id:id});
    }
}

class ImportHeaderSettings extends Component{
  constructor(props) {
    super(props)
    this.state = {
      headers : [],
      headers_list : [],
      current_screen : 'setup_headers',
      unmapped_headers : [],
      offset : 0,
      progress : 0
    }
  }

  handleChangeHeader = e =>{
    let key = e.currentTarget.id;
    let value = e.currentTarget.value;
    let headers = this.state.headers;
    headers[key].default = value;
    this.setState({headers:headers});
  }

  closeHeaderSetting = e =>{
    if(document.getElementById("hideImportHeader")){
      document.getElementById("hideImportHeader").click();
    }
  }

  

  handleSaveHeaders = e =>{
    let thisid = e.currentTarget.id;
    /* document.getElementById(thisid).classList.add("hide");
    document.getElementById(thisid+'-btn-spinner').classList.remove("hide"); */
    let qs = document.querySelectorAll(".custom-header");
    let unmapped_headers = [];
    for (let index = 0; index < qs.length; index++) {
      const thisvalue = qs[index].value;
      const data_value = qs[index].getAttribute('data_value');
      if(thisvalue===""){
        unmapped_headers.push(data_value);
      }
    }

    this.setState({current_screen:'preview_headers',unmapped_headers:unmapped_headers});
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    let send_data = {
        store_name:user_info.store_name,
        branch_id:user_info.login_id,
        user_id : user_info.user_id,
        uuid : utils.uuid(),
        role_id : user_info.access_level,
        token : user_info.token, _schema : user_info._schema,
        name : 'import_inventory_headers_new',
        value : this.state.headers
    }
    
    let api = app_api+'/save_option';
    axios.post(api, send_data)
      .then(function (response) {
        /* document.getElementById(thisid).classList.remove("hide");
        document.getElementById(thisid+'-btn-spinner').classList.add("hide"); */
    }.bind(this)).catch(function(res){
        
    })
  }

  componentDidMount(){
    this.setState({headers:this.props.contact_import_headers});
    let header = this.props.contact_import_headers;
    let headers_list = [];
    Object.keys(header).forEach(function(key) {
      headers_list.push(key);
    });
    this.setState({headers_list:headers_list})
  }
  moveBack = () =>{
    this.setState({current_screen:'setup_headers'});
  }
  handleImportData = (e) =>{
    let thisid = e.currentTarget.id;
    document.getElementById(thisid).classList.add("hide");
    document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
    var element = document.getElementById('process-csv-upload');
    element.classList.remove("fade");
    element.style.display="block"; 
    this.handleProcessUpload();
  }

  handleProcessUpload = () =>{
    let count = this.props.import_data.length;
    const formData = new FormData();

    formData.append('importFile', this.props.import_file);
    formData.append('store_name', user_info.store_name);
    formData.append('branch_id', user_info.login_id);
    formData.append('user_id', user_info.user_id);
    formData.append('uuid', utils.uuid());
    formData.append('role_id', user_info.access_level);
    formData.append('token', user_info.token);
    formData.append('_schema', user_info._schema);
    formData.append('count', this.props.import_data.length);
    formData.append('offset', this.state.offset);
    formData.append('type', 'upload_data');
    formData.append('target', 'inventory');
    let api = app_api+'/process_import_csv';
    axios.post(api, formData)
      .then(function (response) {
        let data = response.data;
        let offset = data.offset;
        let progress = (offset/count)*100;
        if(count<100){
          progress = 100;
        }
        if(progress>100){
          progress = 100;
        }
        this.setState({progress:Math.round(progress)});
        this.setState({offset:offset});
        if(data.is_over===false){
          this.handleProcessUpload();
        }else{
          setTimeout(() => {
            var element = document.getElementById('process-csv-upload');
            element.classList.add("fade");
            element.style.display="none"; 
            window.location.href = '/products';
          }, 500);
        }
    }.bind(this)).catch(function(res){
        
    })
  }

 

  render() {
    return    <div className="row">
                <div className="col-md-12">
                  <div className="box-container" style={{padding:'0px'}}>
                    <div className="box-head">
                      <label>MAP IMPORT FILE HEADERS</label>
                    </div>
                    {(this.state.current_screen==='setup_headers') &&
                      <>
                    <div className="box-body"> 
                      <div className="row">
                          
                          <div className="col-md-12">
                          <ul className="list-group">
                            {this.state.headers_list.map(items => (
                              <li className="list-group-item">
                                <div className="row">
                                    <div className="col-md-6">{this.state.headers[items].label} : </div>
                                    <div className="col-md-6">
                                      <SelectDropdown options={this.props.headers} data_id={this.state.headers[items].data_id} default={this.state.headers[items].default} onChange={this.handleChangeHeader} data_value={this.state.headers[items].data_value}/>
                                    </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                          </div>
                       
                      </div>
                    </div>
          
                    <div className="row"  style={{padding:'10px'}}>
                      
                      <div className="col-md-6">
                        <button type="button" className="btn btn-secondary" onClick={this.closeHeaderSetting}>Close</button>
                      </div>
                      <div className="col-md-6 text-right">
                      
                          <button type="button" className="btn btn-success" id="set-custom-header" onClick={this.handleSaveHeaders}>Next</button>
                          <button type="button" id="set-custom-header-btn-spinner" className="hide btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                    
                      </div>
           
                    </div>
                    </>
                    }

                    {(this.state.current_screen==='preview_headers') &&
                      <>
                      <div className="box-body"> 
                        <div className="row">
                      
                          <div className="col-md-12">
                            <ul className="list-group">
                              <li className="list-group-item">
                              </li>
                                  <h5>Total imported record(s) : <span style={{color:'#27b327'}}>{this.props.import_data.length}</span></h5>
                              <li className="list-group-item">
                                  <h5>Unmapped headers</h5>
                                  {(this.state.unmapped_headers.length>0) &&
                                    <ul>
                                    {this.state.unmapped_headers.map((v) =>(
                                      <li>{v}</li>
                                    ))
                                    }
                                  </ul>
                                  }
                                  {(this.state.unmapped_headers.length===0) &&
                                    <>
                                    <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon> 
                                    All headers mapped
                                    </>
                                  }
                              </li>
                            </ul>
                          </div>
                      
                        </div>
                      </div>
                      <div className="row"  style={{padding:'10px'}}>
                    
                        <div className="col-md-6">
                          <button type="button" className="btn btn-success" onClick={this.moveBack}>Previous</button>
                        </div>
                        <div className="col-md-6 text-right">
                            <button type="button" className="btn btn-danger" id="import-data" onClick={this.handleImportData}>Import</button>
                            <button type="button" id="import-data-btn-spinner" className="hide btn btn-danger"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                      
                        </div>
                    
                      </div>
                      </>
                    }
                  </div>
                </div>
                <div id="process-csv-upload" className="modal fade">
                  <div className="modal-dialog">
                    <div className="modal-content csv-load-blk">
                      <div className="parent-bar">
                        <div className="child-bar" style={{width:this.state.progress+'%'}}></div>
                      </div>
                      <p>Please wait while import complete ({this.state.progress}%)</p>
                    </div>
                  </div>
                </div>
              </div>
         
  }
}

class UpdateBulkInventory extends Component {
  constructor(props) {
      super(props)
      this.state = {
          inventory_items:[],
          inventory_data : [],
          inventory_list : [],
          action_message : '',
      }
      this.category_inventory_list = [];
      this.inventory_list = [];
      this.inventory_data = [];
      this.handleInventoryList = this.handleInventoryList.bind(this);
      this.handleUpdateBulkInventory = this.handleUpdateBulkInventory.bind(this);
      this.handleDeleteItem = this.handleDeleteItem.bind(this);
      this.handleChangeItemValue = this.handleChangeItemValue.bind(this);
      this.updateInventoryData = this.updateInventoryData.bind(this);
      this.handleInventoryPMChange = this.handleInventoryPMChange.bind(this);
      this.loadItemOnCategory = this.loadItemOnCategory.bind(this);
      this.handleAutoMSPCalculate = this.handleAutoMSPCalculate.bind(this);
      this.floating_point = utils.floating_point.bind(this);
  }

  handleAutoMSPCalculate(e){
    let index = e.currentTarget.getAttribute('data-id');
    let auto = document.getElementById("auto-"+index);
    let lowest_sp = document.getElementById("hid_lowest_selling_price-"+index).value;
    if(auto.checked===true){
      document.getElementById("lowest_selling_price-"+index).setAttribute('disabled','true');
      let purchase_price = document.getElementById("purchase_price-"+index).value;
      let selling_price = document.getElementById("selling_price-"+index).value;
      let lspm =  document.getElementById("lspm-"+index).value;

      if(selling_price===""){
        selling_price = 0;
      }

      if(purchase_price===""){
        purchase_price = 0;
      }else{
        purchase_price = parseFloat(purchase_price);
      }

      let cal = purchase_price*lspm/100;
      lowest_sp = purchase_price+cal;

      if(selling_price<lowest_sp){
        lowest_sp = selling_price;
      }
    }else{
      document.getElementById("lowest_selling_price-"+index).removeAttribute('disabled');
    }
    document.getElementById("lowest_selling_price-"+index).value = this.floating_point(lowest_sp);
    this.inventory_list[index]['lowest_selling_price'] = this.floating_point(lowest_sp);
  }

  loadItemOnCategory(e){
      let category = localStorage.getItem('selected_category');
      this.inventory_list = [];
      this.setState({inventory_list:this.inventory_list});
      if(category!=="" && category!==null){
        if(this.category_inventory_list[category]===undefined){
          let body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token, _schema : user_info._schema,

                target : 'category',
                category : category,
             }
          let api = app_api+'/inventory/get_inventory_items';
          axios.post(api, body)
            .then(function (response) {
              let cursor = response.data;
              if(cursor.length>0){
                this.category_inventory_list[category] = cursor;
                this.updateInventoryData(cursor,'category');
              }
          }.bind(this)).catch(function(res){
              if(res.response){
                if(res.response.data){
                    
                }
              }
          })
        }else{
          let cursor = this.category_inventory_list[category];
          console.log(cursor);
          this.updateInventoryData(cursor,'category');
        }
      }else{
        let inventory_data = localStorage.getItem('inventory_data');
        if(inventory_data){
          inventory_data = JSON.parse(inventory_data);
        }
        let inv_data = inventory_data;
        this.updateInventoryData(inv_data,'inventory');
      }
  }

  handleInventoryPMChange(e){
      let operation_type = e.currentTarget.value;
      let index = parseInt(e.currentTarget.getAttribute('data-id'));
      
      this.inventory_list[index]['operation_type'] = operation_type;
      let new_quantity = this.inventory_list[index]['new_quantity'];
      let total_quantity = this.inventory_list[index]['availability'];
      let total_available_html = '';
      let tq = total_quantity;

      if(operation_type==='+'){
        total_available_html = tq+" + "+new_quantity+' = '+total_quantity;
        total_quantity = parseInt(total_quantity)+parseInt(new_quantity);
      }else if(operation_type==='-'){
        total_available_html = tq+" - "+new_quantity+' = '+total_quantity;
        total_quantity = parseInt(total_quantity)-parseInt(new_quantity);
      }
      document.getElementById("total_quantity-"+index).innerHTML = total_available_html;
      this.inventory_list[index]['total_quantity'] = total_quantity;
  }
  handleChangeItemValue(e){
    let thisvalue = e.currentTarget.value;
    let data_target = e.currentTarget.getAttribute('data-target');
    let index = parseInt(e.currentTarget.getAttribute('data-id'));
    let operation_type = this.inventory_list[index]['operation_type'];
    if(data_target==='new_quantity'){
        if(thisvalue===''){
          thisvalue = 0;
        }
        let tq = total_quantity;
        let total_quantity = this.inventory_list[index]['availability'];
        let total_available_html = '';
        if(operation_type==='+'){
          total_available_html = tq+" + "+thisvalue+' = '+total_quantity;
          total_quantity = parseInt(total_quantity)+parseInt(thisvalue);
        }else if(operation_type==='-'){
          total_available_html = tq+" - "+thisvalue+' = '+total_quantity;
          total_quantity = parseInt(total_quantity)-parseInt(thisvalue);
        }

        document.getElementById("total_quantity-"+index).innerHTML = total_available_html;
        this.inventory_list[index]['total_quantity'] = total_quantity;
    }
    this.inventory_list[index][data_target] = thisvalue;
   
    if(data_target==='purchase_price'){
      this.handleAutoMSPCalculate(e);
    }

  }
  handleDeleteItem(e){
    let _t = this;
    let index = parseInt(e.currentTarget.getAttribute('data-id'));
    let state_inventory = this.state.inventory_items;
    let removed_item = this.inventory_list[index];
    let removed_id = this.inventory_list[index].inventory_id;
    if(state_inventory[removed_id]===undefined){
      state_inventory[removed_id] = [];
    }
    state_inventory[removed_id] = removed_item;
    this.setState({inventory_items:state_inventory});
    this.inventory_list.splice(index,1);
    this.setState({inventory_list:[]});
    setTimeout(function(){
      _t.setState({inventory_list:_t.inventory_list});
    },10);
   
  }
  handleInventoryList(){
    let id = parseInt(localStorage.getItem("selected_inventory_id"));
    let inv_data = [];
    if(this.state.inventory_items.length>0){

      let inv_items = this.state.inventory_items[id];
      
      this.inventory_list.push(inv_items);
      this.setState({inventory_list:this.inventory_list});
      localStorage.removeItem("selected_inventory_id");
      let inventory_data = localStorage.getItem('inventory_data');
      if(inventory_data){
        inventory_data = JSON.parse(inventory_data);
      }
      let inv_data = inventory_data;
      
      for(let i=0;i<this.inventory_list.length;i++){
      
        let inv_id = this.inventory_list[i].inventory_id;
        for(let j=inventory_data.length-1;j>=0;j--){
          let iid = inventory_data[j].inventory_id;
          if(iid===inv_id){
            inv_data.splice(j,1);
          }
        }
      }
      this.setState({ inventory_items: []}); 
      this.updateInventoryData(inv_data,'inventory');
      document.getElementById("item-box").value = '';
    
      setTimeout(function(){
        document.getElementById("item-box").focus();
      },50)
    }else{
      let inventory_data = localStorage.getItem('inventory_data');
      if(inventory_data){
        inventory_data = JSON.parse(inventory_data);
      }
      let inv_data = inventory_data;
      this.updateInventoryData(inv_data,'inventory');
      let _this = this;
      setTimeout(() => {
        let inv_items = _this.state.inventory_items[id];
        _this.inventory_list.push(inv_items);
        _this.setState({inventory_list:_this.inventory_list});
        localStorage.removeItem("selected_inventory_id");
        
        for(let i=0;i<_this.inventory_list.length;i++){
          let inv_id = _this.inventory_list[i].inventory_id;
          for(let j=inventory_data.length-1;j>=0;j--){
            let iid = inventory_data[j].inventory_id;
            if(iid===inv_id){
              inv_data.splice(j,1);
            }
          }
        }
        _this.setState({ inventory_items: []}); 
        _this.updateInventoryData(inv_data,'inventory');
        document.getElementById("item-box").value = '';
        document.getElementById("item-box").focus();
      }, 100);
    }
    
    
  }
  handleUpdateBulkInventory(e){
    document.getElementById('up-bulk-inv').classList.add("hide");
    document.getElementById('up-bulk-inv-btn-spinner').classList.remove("hide");
    let message = document.getElementById("stock_adjust_message").value;
    let body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token, _schema : user_info._schema,
              inventory_list : this.inventory_list,
              message : message,
           }
    let api = app_api+'/inventory/update_bulk_inventory';
    axios.post(api, body)
      .then(function (response) {
        let cursor = response.data;
        document.getElementById('up-bulk-inv').classList.remove("hide");
        document.getElementById('up-bulk-inv-btn-spinner').classList.add("hide");
        this.setState({action_message:'Inventory updated successfuly'});
        let _t = this;
            window.location.reload();
        setTimeout(function(){
            _t.setState({action_message:''});
        },1500);
    }.bind(this)).catch(function(res){
        if(res.response){
          if(res.response.data){
              
          }
        }
    })
  }

  updateInventoryData(cursor,target){
    let inv_items = [];
    
    cursor.map( (value, key) => {
        let id = value.inventory_id;
        let stat_lbl = '';
        if(value.inventory_status==='Draft'){
          stat_lbl=' (Inactive)';
        }
        let item_name = value.item_name+" - Qty:"+value.availability+stat_lbl;
        let static_item_name = value.item_name;

        let sku   = value.sku; 
        let bundle_text= value.bundle_text;
        let total = value.total;
        let barcode_name= value.barcode_name;
        let btext = this.state.bundle_text;
        if(btext===''){
          this.setState({bundle_text:bundle_text});
        }
        if(sku===null){
          sku = "none";
        }
        if(barcode_name===null){
          barcode_name = "none";
        }

        
        let inventory_list = {inventory_id:value.inventory_id,value1:sku, value2:item_name,value3:barcode_name,static_item_name:static_item_name,item_desc:value.item_desc,hsn:value.hsn,product_unit_id:value.product_unit_id,discount:value.discount,tax:value.tax,sp_tax:value.sp_tax,lowest_selling_price:value.lowest_selling_price,selling_price:value.selling_price,purchase_price:value.purchase_price,quantity:value.quantity,availability:value.availability,total:total,bundle_quantity:value.bundle_quantity,bundle_text:bundle_text,cgst:value.cgst,sgst:value.sgst,igst:value.igst,unit_name:value.unit_name,old_available:value.availability,inventory_status:value.inventory_status,sp_discount:value.sp_discount,sp_tax_type:value.sp_tax_type,pp_tax_type:value.pp_tax_type,operation_type:'+',new_quantity:0,total_quantity:value.availability,lspm:value.lspm};
        inv_items[id] = inventory_list;

        if(target==='category'){
          this.inventory_list.push(inventory_list);
        }
    });
    
    if(target==='category'){
      this.setState({inventory_list:this.inventory_list});
      let inventory_data = localStorage.getItem('inventory_data');
      if(inventory_data){
        inventory_data = JSON.parse(inventory_data);
      }
      let inv_data = inventory_data;
      for(let i=0;i<this.inventory_list.length;i++){
          let inv_id = this.inventory_list[i].inventory_id;
          for(let j=inventory_data.length-1;j>=0;j--){
            let iid = inventory_data[j].inventory_id;
            if(iid===inv_id){
              inv_data.splice(j,1);
            }
          }
      }
      let _t = this;
      setTimeout(function(){
      _t.updateInventoryData(inv_data,'inventory');
      },100);
    }
    this.setState({ inventory_items: inv_items}); 
  }

  componentDidMount(){
    let body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token, _schema : user_info._schema,

           }
    let api = app_api+'/inventory/get_inventory_items';
    axios.post(api, body)
      .then(function (response) {
        let cursor = response.data;
        this.setState({inventory_data:cursor});
        localStorage.setItem('inventory_data',JSON.stringify(cursor));
        this.inventory_data = cursor;
        this.updateInventoryData(cursor,'inventory');
    }.bind(this)).catch(function(res){
        if(res.response){
          if(res.response.data){
              
          }
        }
    })
  }

  render() {
    return    <div className="row">
                <div className="col-md-12">
                  <div className="box-container" style={{padding:'0px'}}>
                    <div className="box-head">
                      <label>UPDATE BULK INVENTORY</label>
                    </div>
                    <div className="box-body" style={{padding:'15px'}}>  
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor="item-name">Select an item from the list to update</label>
                          <Autocomplete
                            options={this.state.inventory_items}
                            data_target="inventory-bulk-edit"
                            title="Start typing SKU or name..."  
                            id={"item-name"}
                            className="auto-comp-opt"
                            data_id={0}
                            active_tab="inventory"
                            input_id={"item-box"}
                            target="item-box"
                            clsName = ""
                          />
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="item-name">Select a category to edit its associated product</label>
                          <ProductCategory show="dropdown" target="inventory-bulk-edit"/>
                          <span onClick={this.loadItemOnCategory} id="loadItemOnCategory"></span>
                          <span onClick={this.handleInventoryList} id="handleInventoryList"></span>
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="stock_adjust_message">Stock adjust note</label>
                          <input type="text" className="form-control" id="stock_adjust_message" defaultValue="Stock Re-Adjusted" />
                        </div>
                      </div>
                      <div className="row mrtop-30">
                        <div className="col-md-12">
                            <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Item Name</th>
                                  <th>Purchase Price</th>
                                  <th>Selling Price</th>
                                  <th>MSP</th>
                                  <th>Bundle QTY</th>
                                  <th>Availability</th>
                                  <th>Quantity</th>
                                  <th></th>
                                  <th>Total Avail.</th>
                                </tr>
                              </thead>
                              <tbody>
                                  {(this.state.inventory_list.length > 0) ?this.state.inventory_list.map( (value, index) => {
                                   return (
                                    <tr key={ index }>
                                      <td>
                                        <span className="btempname">{value.static_item_name}</span>
                                      </td>
                                      <td>
                                        <input type="text" className="form-control w-100px" defaultValue={value.purchase_price} onChange={this.handleChangeItemValue} data-id={index} data-target='purchase_price' id={"purchase_price-"+index}/>
                                      </td>
                                      <td>
                                        <input type="text" className="form-control w-100px" defaultValue={value.selling_price} onChange={this.handleChangeItemValue} data-id={index} data-target='selling_price' id={"selling_price-"+index}/>
                                      </td>
                                      <td>
                                        <div style={{display:'flex'}}>
                                          <input type="text" className="form-control" defaultValue={value.lowest_selling_price} onChange={this.handleChangeItemValue} data-id={index} data-target='lowest_selling_price' id={"lowest_selling_price-"+index} style={{width:'80px'}}/>

                                          <input type="hidden" className="form-control" defaultValue={value.lspm} data-id={index} data-target='lspm' id={"lspm-"+index}/>
                                          <input type="hidden" className="form-control" defaultValue={value.lowest_selling_price} data-id={index} data-target='hid_lowest_selling_price' id={"hid_lowest_selling_price-"+index}/>
                                          <div className="form-group" style={{margin:'5px 5px'}}>
                                            <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name={"auto-"+index} id={"auto-"+index} value="auto"  onChange={this.handleAutoMSPCalculate} defaultChecked={false} data-id={index}/>
                                            <label className="form-check-label" htmlFor={"auto-"+index}>
                                            Auto Cal.
                                            </label>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <input type="text" className="form-control w-100px" defaultValue={value.bundle_quantity} onChange={this.handleChangeItemValue} data-id={index} data-target='bundle_quantity' id={"bundle_quantity-"+index}/>
                                      </td>
                                      <td>
                                        <span className="btempname">{value.availability}</span>
                                      </td>
                                      <td>
                                        <div style={{display:'flex'}}>
                                         <div className="form-group round-blk">
                                           <div className="form-check">
                                              <input className="form-check-input" type="radio" name={"action-"+index} id={"plus-"+index} value="+"  onChange={this.handleInventoryPMChange} defaultChecked={true} data-id={index}/>
                                              <label className="form-check-label plus" htmlFor={"plus-"+index}>
                                                +
                                              </label>
                                            </div>
                                            <div className="form-check">
                                              <input className="form-check-input" type="radio" name={"action-"+index} id={"minus-"+index} value="-" onChange={this.handleInventoryPMChange} data-id={index}/>
                                              <label className="form-check-label minus" htmlFor={"minus-"+index}>
                                                -
                                              </label>
                                            </div>
                                           </div>
                                           <div>
                                            <input type="text" className="form-control" defaultValue={value.new_quantity} onChange={this.handleChangeItemValue} data-id={index} data-target='new_quantity' style={{width:'60px'}}/>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                      </td>
                                        <span className="btempname" id={"total_quantity-"+index}>{parseInt(value.total_quantity)}</span>
                                      <td>
                                          <FontAwesomeIcon onClick={this.handleDeleteItem} data-id={index} data_target="delete_confirm" icon={faTrash} className="pull-right margin-5 fa-icon"/>
                                      </td>
                                    </tr>
                                  )
                                 }) : <tr><td colSpan={7} className="text-center">Select an item from dropdown</td></tr> }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    {(this.state.inventory_list.length > 0) &&
                    <div className="row text-right">
                      <div className="col-md-12">
                        <div style={{padding:'10px'}}>
                          <span style={{color:'#059b05', fontSize:'14px',marginRight:'5px'}}>{this.state.action_message}</span>
                          <button type="button" className="btn btn-danger" id="up-bulk-inv" onClick={this.handleUpdateBulkInventory}>Update Bulk</button>
                          <button type="button" id="up-bulk-inv-btn-spinner" className="hide btn btn-danger"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                        </div>
                      </div>
                    </div>
                    }
                  </div>
                </div>
              </div>
         
  }
}