import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import Breadcrumbs from "./breadcrumbs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faSearch, faTrash,faCaretRight,faCaretDown, faShoppingCart,faBell, faSort, faUpload, faCloud, faCloudUploadAlt, faSpinner  } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import ClosedFYButton from "./closed-fy-button";
import Pager from "./pager";
import Tooltip from "./tooltip";
import BulkAction from "./bulk-action";
import ShortcutHelp from "./shortcut-help.js";
import Notification from "./notification.js";
import check_access from './access-level.js';
import HoldBillPopup from './hold-bill-list-popup.js';
import {QRCodeSVG} from 'qrcode.react';
import Link from './link.js';
import utils from '../utils.js';
import ModalBox from "./modal-box";
import ListCount from "./list-count";
import DownloadUnpaidBills from "./download-unpaid-bills";
import DrawerLink from "./open-drawer-link.js";

let {enc_key,app_api,user_info} = require('../library.js');
let _this;

export default class Billings extends Component {
    constructor() {
        super();
        this.state = {
          FromDate:  new Date(),
          ToDate: new Date(),
          active_tab:'sales',
          loading_process :'Loading...',
          billing_list : [],
          billing_list_temp : [],
          total_page : '',
          trash_count : 0,
          hold_count : 0,
          total_records : 0,
          total_pending_amount : 0,
          is_loading_unpaid_amount : true,
          top_option:'',
          create_button : 'New Sale',
          button_ref : 1,
          is_search : false,
          sort_type : '',
          is_fy_closed : false,
          is_create_quotation : false,
          delete_cancelled_bill : false,
          send_e_invoice : false,
          is_download_unpaid_bills : false,
          not_env_count :0,
          progress : 0,
          total_records : 0,
          total_loaded : 0,
          advance_sale_data : [
                                {key:'slinvdate',value:'INVOICE DATE'},
                                {key:'slagent',value:'AGENT NAME'},
                                {key:'sltransport',value:'TRANSPORT'},
                                {key:'sllrno',value:'LR NO'},
                                {key:'slsuprefno',value:'SUPPLIER\'S REFRENCE NO'},
                                {key:'sldelivery-note',value:'DELIVERY NOTE'},
                                {key:'sloth-note-from-sup',value:'OTHER NOTE FROM SUPPLIER'},
                                {key:'slother-ref',value:'OTHER REFERENCE'},
                                {key:'slterms-of-delivery',value:'TERMS OF DELIVERY'},
                                {key:'slpieces-in-parcel',value:'PIECES IN PARCEL'},
                                {key:'slno-of-bales',value:'NO. OF PARCELS/BALES'},
                                {key:'slpacked-by',value:'PACKED BY'},
                                {key:'slchecked-by',value:'CHECKED BY'},
                              ]
        }
        _this = this;
        this.timeout = null;
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleBillSearch = this.handleBillSearch.bind(this);
        this.handleShowPayment = this.handleShowPayment.bind(this);
        this.handleSortList = this.handleSortList.bind(this);
        this.refreshBillList = this.refreshBillList.bind(this);
        this.is_access = check_access.is_access.bind(this);
        this.currency_format = utils.currency_format.bind(this);
    }
  FromDate = date => {
    this.setState({
      FromDate: date
    });
  };
  ToDate = date => {
    this.setState({
      ToDate: date
    });
  };
  openSendEInvoicePopup = ()=>{
    var element = document.getElementById('generate_e_invoice_popup');
    element.classList.remove("fade");
    element.style.display="block"; 
}
  handleSortList(e){
    let sort_on = e.currentTarget.getAttribute("data-id");
    this.setState({sort_type:sort_on});
    let curl = window.location.href;
    const billings = curl.match(/billings$/i);
    const active_tab = curl.match(/billings\/([a-z_A-Z]+$)/i);
    const page_tab = curl.match(/billings\/([a-z_A-Z]+)\/(\d+$)/i);

    let current_page = 1;
    let current_tab = 'sales';
    if(active_tab && active_tab[1]){
      current_tab = active_tab[1];
    }else if(page_tab && page_tab[1]){
      current_page = page_tab[2];
      current_tab = page_tab[1];
    }
    const trash_hold = curl.match(/billings\/([a-z_A-Z]+)\/([hold|trash]+$)/i);
    const trash_hold_page = curl.match(/billings\/([a-z_A-Z]+)\/([hold|trash]+)\/(\d+$)/i);
   
    let top_option = 'regular';
    if(trash_hold && (trash_hold[2]==='hold' || trash_hold[2]==='trash')){
      let a_tab = trash_hold[1];
      current_tab = a_tab;
      let aa_tab = a_tab.replace("_"," ");
      top_option = trash_hold[2];
    }
   if(trash_hold_page && (trash_hold_page[2]==='hold' || trash_hold_page[2]==='trash')){
      let a_tab = trash_hold_page[1];
      current_tab = a_tab;
      let aa_tab = a_tab.replace("_"," ");
      top_option = trash_hold_page[2];
      current_page = trash_hold_page[3];
    }
    this.setState({loading_process:'Loading...'});
    this.setState({ billing_list: [] });
    this.setState({ billing_list_temp: [] });
    let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              page : current_page,
              show : top_option,
              sort_on : sort_on,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/billings/'+current_tab;
        axios.post(api, body)
        .then(function (response) {
            this.setState({ billing_list: response.data.data });
            this.setState({ billing_list_temp: response.data.data });
            
      }.bind(this)).catch(function(res){
          if(res.response){
             this.setState({loading_process:'Record(s) not found'});
          }
      })
  }


  handleGeteInvoiceCheck = (current_tab) =>{
    
    let body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              target : current_tab,
              action : 'check',
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/check_bulk_e_invoice';
        axios.post(api, body)
        .then(function (response) {
            this.setState({ not_env_count: response.data.count });
      }.bind(this)).catch(function(res){
          if(res.response){
             this.setState({loading_process:'Record(s) not found'});
          }
      })
  }

  handleBillSearch(e){
      let item_name = e.currentTarget.value;
      this.setState({loading_process:'Loading...'});
      if(item_name!==""){
        let amount_item = "";
        if(parseFloat(item_name)>0){
          amount_item = this.currency_format(item_name);
        }
        this.setState({ billing_list: [],is_search:true });
        clearTimeout(this.timeout);
        let api = app_api+'/search_bill';
        _this.timeout = setTimeout(function () {
        let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              search : item_name,
              search_item : amount_item,
              active_tab : _this.state.active_tab,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        axios.post(api, body)
          .then(function (response) {
              let page_count = response.data.number_of_page;
              let trash_count = response.data.trash_count;
              let hold_count = response.data.hold_count;
            
            if(response.data.is_empty){
              _this.setState({loading_process:'Record(s) not found'});
            }else{
              _this.setState({total_page:page_count});
              _this.setState({ billing_list: response.data.data });
            }
        }.bind(_this)).catch(function(res){
            if(res.response){
               _this.setState({loading_process:'Record(s) not found'});
            }
        })
        }, 500);
      }else{
        clearTimeout(this.timeout);
        if(this.state.is_search===true){
          this.refreshBillList();
          this.setState({is_search:false });
        }
      }
  }

  handleShowPayment(e){
    let id = e.currentTarget.id;
    if(document.querySelectorAll(".payment-row-"+id)){
      let elem = document.querySelectorAll(".payment-row-"+id);
      for(let i=0;i<elem.length;i++){
        elem[i].classList.toggle("hide");
      }
    }
    if(document.getElementById("caret-right-"+id)){
      document.getElementById("caret-right-"+id).classList.toggle("hide");
      document.getElementById("caret-down-"+id).classList.toggle("hide");
    }
  }

  handleCheckAll(e){
      let checked = e.currentTarget.checked;
      var cb = document.querySelectorAll('.sub-checkbox'); 
      for (var i = 0; i < cb.length; i++) { 
          cb[i].checked = checked; 
      }
  }

  componentDidMount(){
    /* const is_open = document.getElementById('page-header');
    if(is_open){
      is_open.oncontextmenu = function() {
        
      };
    } */
    localStorage.removeItem('response_id');
    localStorage.removeItem('open_list_view');
    localStorage.removeItem('save_print');
    localStorage.removeItem('open_new_bill');
    let curl = window.location.href;
    const billings = curl.match(/billings$/i);
    const active_tab = curl.match(/billings\/([a-z_A-Z]+$)/i);
    const page_tab = curl.match(/billings\/([a-z_A-Z]+)\/(\d+$)/i);

    let current_page = 1;
    let current_tab = 'sales';
    if(billings){
     this.setState({active_tab:'sales'});
    }else if(active_tab && active_tab[1]){
      this.setState({active_tab:active_tab[1]});
      current_tab = active_tab[1];
    }else if(page_tab && page_tab[1]){
      this.setState({active_tab:page_tab[1]});
      current_page = page_tab[2];
      current_tab = page_tab[1];
    }else{
      this.setState({active_tab:'sales'});
    }
    const trash_hold = curl.match(/billings\/([a-z_A-Z]+)\/([hold|trash]+$)/i);
    const trash_hold_page = curl.match(/billings\/([a-z_A-Z]+)\/([hold|trash]+)\/(\d+$)/i);
   
    let top_option = 'regular';
    if(trash_hold && (trash_hold[2]==='hold' || trash_hold[2]==='trash')){
      let a_tab = trash_hold[1];
      this.setState({active_tab:a_tab});
      current_tab = a_tab;
      let aa_tab = a_tab.replace("_"," ");
       top_option = trash_hold[2];
      this.setState({top_option:top_option});
      let msg = '';
      if(top_option==='trash'){
        msg = aa_tab+" Trashed";
      }else if(top_option==='hold'){
        msg = aa_tab+" on Hold";
      }
      document.getElementById("breadcrumbs_subtitle").innerHTML = msg;
    }
   if(trash_hold_page && (trash_hold_page[2]==='hold' || trash_hold_page[2]==='trash')){
        let a_tab = trash_hold_page[1];
        this.setState({active_tab:a_tab});
        current_tab = a_tab;
        let aa_tab = a_tab.replace("_"," ");
        top_option = trash_hold_page[2];
        this.setState({top_option:top_option});
        current_page = trash_hold_page[3];
        let msg = '';
        if(top_option==='trash'){
          msg = aa_tab+" Trashed";
        }else if(top_option==='hold'){
          msg = aa_tab+" on Hold";
        }
        document.getElementById("breadcrumbs_subtitle").innerHTML = msg;
      }

      let button = 'New Sale';
      let button_ref = 1;
      if(current_tab==='purchase'){
          button ='New Purchase';
          button_ref = 2;
      }else if(current_tab==='purchase_return'){
        button = 'New Purchase Return';
        button_ref = 4;
      }else if(current_tab==='sales_return'){
        button = 'New Sales Return';
        button_ref = 3;
      }
      this.setState({create_button:button});
      this.setState({button_ref:button_ref});
      
    let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              page : current_page,
              show : top_option,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/billings/'+current_tab;
        axios.post(api, body)
        .then(function (response) {
            let page_count = response.data.number_of_page;
            let trash_count = response.data.trash_count;
            let hold_count = response.data.hold_count;

            if(response.data.total_amount){
              //this.setState({total_pending_amount:response.data.total_amount})
            }

            this.setState({trash_count:trash_count});
            this.setState({hold_count:hold_count});
            this.setState({sort_type:response.data.sort_on_type});
            if(response.data.is_empty){
              this.setState({loading_process:'Record(s) not found'});
              this.setState({total_page:0});
            }else{
              this.setState({total_page:page_count});
              this.setState({ billing_list: response.data.data });
              this.setState({ billing_list_temp: response.data.data });
            }
            this.setState({ is_fy_closed: response.data.is_fy_closed });
      }.bind(this)).catch(function(res){
          if(res.response){
             //this.setState({loading_process:'Record(s) not found'});
          }
      })

      setTimeout(() => {
        
        if(current_tab==='sales' || current_tab==='sales_return' || current_tab==='purchase' || current_tab==='purchase_return'){
          let qs = document.querySelectorAll(".default-hide");
          
          for (let index = 0; index < qs.length; index++) {
            const element = qs[index];
            element.classList.add('hide');
          }
        }
      }, 100);

      let bc_send_data = {
        store_name:user_info.store_name,
        branch_id:user_info.login_id,
        user_id : user_info.user_id,
        keys:"'is_create_quotation','delete_cancelled_bill','send_e_invoice','sales_list_opt_visibility','sales_return_list_opt_visibility','purchase_list_opt_visibility','purchase_return_list_opt_visibility'",
        uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
    }
    let bc_api = app_api+'/get_settings';
    axios.post(bc_api, bc_send_data)
      .then(function (response) {
        let resp = response.data;
        
        if(resp.is_create_quotation){
          let ps = resp.is_create_quotation;
          this.setState({is_create_quotation:ps});
        }
        if(resp.send_e_invoice){
          let ps = resp.send_e_invoice;
          this.setState({send_e_invoice:ps});
        }
        if(resp.delete_cancelled_bill){
          let ps = resp.delete_cancelled_bill;
          this.setState({delete_cancelled_bill:ps});
        }
       
        if(current_tab==='sales'){
          if(resp.sales_list_opt_visibility){
            let ps = resp.sales_list_opt_visibility;
            let qs = document.querySelectorAll(".default-hide");
            for (let index = 0; index < qs.length; index++) {
              const tid = qs[index].id;
              if(ps.indexOf(tid)<0){
                if(document.getElementById(tid)){
                  document.getElementById(tid).classList.remove('hide');
                }
              }
            }
          }else{
            let qs = document.querySelectorAll(".default-hide");
            for (let index = 0; index < qs.length; index++) {
              const element = qs[index];
              element.classList.remove('hide');
            }
          }
        }else if(current_tab==='sales_return'){
          if(resp.sales_return_list_opt_visibility){
            let ps = resp.sales_return_list_opt_visibility;
            let qs = document.querySelectorAll(".default-hide");
            for (let index = 0; index < qs.length; index++) {
              let tid = qs[index].id;
              tid = tid.replace('sl','srl');
              if(ps.indexOf(tid)<0){
                tid = tid.replace('srl','sl');
                if(document.getElementById(tid)){
                  document.getElementById(tid).classList.remove('hide');
                }
              }
            }
          }else{
            let qs = document.querySelectorAll(".default-hide");
            for (let index = 0; index < qs.length; index++) {
              const element = qs[index];
              element.classList.remove('hide');
            }
          }
        }else if(current_tab==='purchase'){
          if(resp.purchase_list_opt_visibility){
            let ps = resp.purchase_list_opt_visibility;
            
            let qs = document.querySelectorAll(".default-hide");
            for (let index = 0; index < qs.length; index++) {
              let tid = qs[index].id;
              tid = tid.replace('sl','pl');
              if(ps.indexOf(tid)<0){
                if(document.getElementById(tid)){
                  document.getElementById(tid).classList.remove('hide');
                }else{
                  tid = tid.replace('pl','sl');
                  if(document.getElementById(tid)){
                    document.getElementById(tid).classList.remove('hide');
                  }
                }
              }
            }
          }else{
            let qs = document.querySelectorAll(".default-hide");
            for (let index = 0; index < qs.length; index++) {
              const element = qs[index];
              element.classList.remove('hide');
            }
          }
        }else if(current_tab==='purchase_return'){
          if(resp.purchase_return_list_opt_visibility){
            let ps = resp.purchase_return_list_opt_visibility;
            let qs = document.querySelectorAll(".default-hide");
            for (let index = 0; index < qs.length; index++) {
              let tid = qs[index].id;
              
              tid = tid.replace('sl','prl');
              
              if(ps.indexOf(tid)<0){
                tid = tid.replace('prl','sl');
                if(document.getElementById(tid)){
                  document.getElementById(tid).classList.remove('hide');
                }
              }
            }
          }else{
            let qs = document.querySelectorAll(".default-hide");
            for (let index = 0; index < qs.length; index++) {
              const element = qs[index];
              element.classList.remove('hide');
            }
          }
        }
    }.bind(this)).catch(function(res){
        if(res.response){
          if(res.response.data){
              
          }
        }
    })
    if(current_tab==='sales' || current_tab==='sales_return'){
      this.handleGeteInvoiceCheck(current_tab);
    }
    if(current_tab==='unpaid_bills' && current_page===1){
      this.handleGetTotalUnpaidAmount();
    }else if(current_tab==='unpaid_bills' && current_page>1){
      this.setState({is_loading_unpaid_amount:false});
      let total_pending_amount = localStorage.getItem('total_pending_amount');
      this.setState({total_pending_amount:total_pending_amount});
    }
  }

  handleProcessDownloadUnpaidBills = (page) =>{
    
    let total_loaded = this.state.total_loaded;
    let limit = 50;
    total_loaded = total_loaded+limit;
    
    let body = {
        page : page,
        store_name : user_info.store_name,
        branch_id : user_info.login_id,
        user_id : user_info.user_id,
        uuid : utils.uuid(),
        role_id : user_info.access_level,
        token : user_info.token,
        _schema : user_info._schema,
        total_record:this.state.total_records,
        limit : limit
    }
    
    let api = app_api+'/download_unpaid_bills';
    axios.post(api, body)
      .then(function (response) {
        let data = response.data.data;
        let number_of_page = response.data.number_of_page;
        let total_record = response.data.total_record;
        this.setState({total_records:total_record});
        let total_pending_amount = this.state.total_pending_amount;
        if(page<=number_of_page){
          let progress = (total_loaded / total_record) * 100;
          progress = utils.currency_format(progress);
          progress = Math.round(progress);
          if(progress>100){
            progress = 100;
          }
          this.setState({progress:progress});
          this.setState({total_loaded:total_loaded});
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            total_pending_amount = total_pending_amount + element.pending_amount;
          }
          this.setState({total_pending_amount:total_pending_amount})
          localStorage.setItem('total_pending_amount',total_pending_amount);
          page = page+1;
          this.handleProcessDownloadUnpaidBills(page);
        }else{
          this.setState({progress:0});
          this.setState({is_loading_unpaid_amount:false});
        }
    }.bind(this)).catch(function(res){
        
    })
  }
  handleGetTotalUnpaidAmount = () =>{
    
    let body = {
       
        store_name : user_info.store_name,
        branch_id : user_info.login_id,
        user_id : user_info.user_id,
        uuid : utils.uuid(),
        role_id : user_info.access_level,
        token : user_info.token,
        _schema : user_info._schema,
    }
    
    let api = app_api+'/get_total_unpaid_amount';
    axios.post(api, body)
      .then(function (response) {
        let data = response.data.data;
        let total_pending_amount = response.data.response;
        this.setState({total_pending_amount:total_pending_amount})
        localStorage.setItem('total_pending_amount',total_pending_amount);
    }.bind(this)).catch(function(res){
        
    })
  }

  refreshBillList(){
    let curl = window.location.href;
    const billings = curl.match(/billings$/i);
    const active_tab = curl.match(/billings\/([a-z_A-Z]+$)/i);
    const page_tab = curl.match(/billings\/([a-z_A-Z]+)\/(\d+$)/i);

    let current_page = 1;
    let current_tab = 'sales';
    if(billings){
     this.setState({active_tab:'sales'});
    }else if(active_tab && active_tab[1]){
      this.setState({active_tab:active_tab[1]});
      current_tab = active_tab[1];
    }else if(page_tab && page_tab[1]){
      this.setState({active_tab:page_tab[1]});
      current_page = page_tab[2];
      current_tab = page_tab[1];
    }else{
      this.setState({active_tab:'sales'});
    }
    const trash_hold = curl.match(/billings\/([a-z_A-Z]+)\/([hold|trash]+$)/i);
    const trash_hold_page = curl.match(/billings\/([a-z_A-Z]+)\/([hold|trash]+)\/(\d+$)/i);
   
    let top_option = 'regular';
    if(trash_hold && (trash_hold[2]==='hold' || trash_hold[2]==='trash')){
      let a_tab = trash_hold[1];
      this.setState({active_tab:a_tab});
      current_tab = a_tab;
      let aa_tab = a_tab.replace("_"," ");
       top_option = trash_hold[2];
      this.setState({top_option:top_option});
      let msg = '';
      if(top_option==='trash'){
        msg = aa_tab+" Trashed";
      }else if(top_option==='hold'){
        msg = aa_tab+" on Hold";
      }
      document.getElementById("breadcrumbs_subtitle").innerHTML = msg;
    }
   if(trash_hold_page && (trash_hold_page[2]==='hold' || trash_hold_page[2]==='trash')){
        let a_tab = trash_hold_page[1];
        this.setState({active_tab:a_tab});
        current_tab = a_tab;
        let aa_tab = a_tab.replace("_"," ");
        top_option = trash_hold_page[2];
        this.setState({top_option:top_option});
        current_page = trash_hold_page[3];
        let msg = '';
        if(top_option==='trash'){
          msg = aa_tab+" Trashed";
        }else if(top_option==='hold'){
          msg = aa_tab+" on Hold";
        }
        document.getElementById("breadcrumbs_subtitle").innerHTML = msg;
      }

      let button = 'New Sale';
      let button_ref = 1;
      if(current_tab==='purchase'){
          button ='New Purchase';
          button_ref = 2;
      }else if(current_tab==='purchase_return'){
        button = 'New Purchase Return';
        button_ref = 4;
      }else if(current_tab==='sales_return'){
        button = 'New Sales Return';
        button_ref = 3;
      }
      this.setState({create_button:button});
      this.setState({button_ref:button_ref});
      let _this = this;
    let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              page : current_page,
              show : top_option,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/billings/'+current_tab;
        axios.post(api, body)
        .then(function (response) {
            let page_count = response.data.number_of_page;
            let trash_count = response.data.trash_count;
            let hold_count = response.data.hold_count;
            this.setState({trash_count:trash_count});
            this.setState({hold_count:hold_count});
            this.setState({sort_type:response.data.sort_on_type});
          if(response.data.is_empty){
            this.setState({loading_process:'Record(s) not found'});
            this.setState({total_page:0});
          }else{
            this.setState({total_page:page_count});
            this.setState({ billing_list: response.data.data });
            this.setState({ billing_list_temp: response.data.data });
          }
      }.bind(this)).catch(function(res){
          if(res.response){
            _this.setState({loading_process:'Record(s) not found'});
          }
      })
  }
  handleShowHoldBill = (e) =>{
      if(document.getElementById("handleShowHoldBill")){
        document.getElementById("handleShowHoldBill").click();
      }
  }
    render() {
        let data_value = 'billing';
        if(this.state.top_option==='hold'){
          data_value = 'hold_bill';
        }
        return (
            <div className="d-flex" id="wrapper">
              <Leftnav />
              <div id="page-content-wrapper">
                 <nav id="page-header" className="navbar border-bottom">
                  <div className="row">
                    <div className="col-md-4">
                        <div className="mod-title">
                          <b id="breadcrumbs_subtitle">{this.state.active_tab.replace('_',' ')} List</b>
                        </div>
                    </div>
                    
                    <div className="col-md-8">
                        <div className="inv-opt-block bl2 bill-search">
                          <div className="mrtop-10">
                            <input type="text" onChange={this.handleBillSearch} id="bill-search-box" className="form-control" placeholder="Type to search..."/>
                          </div>
                        </div>
                        {(this.state.active_tab!=='unpaid_bills') &&
                          <>
                          {(this.is_access('billing-sale-create') || this.is_access('billing-purchase-create') || this.is_access('billing-sales-return-create') || this.is_access('billing-purchase-return-create')) &&
                            <div className="pull-right">

                              {(this.state.is_fy_closed===false) &&
                              <>
                                <a href={"/new-billing/"+this.state.button_ref}><button type="button" className="btn btn-success pull-right nav-btn">{this.state.create_button}</button></a>
                                <ShortcutHelp  arrow="top" target="new" shortcuts={['Ctrl+Alt+N']}/>
                              </>
                              }
                              {(this.state.is_fy_closed===true) &&
                                <ClosedFYButton className="pull-right nav-btn" label={this.state.create_button} type="button"/>
                              }
                            </div>
                          }
                          </>
                        }
                        {(this.state.active_tab==='unpaid_bills') &&
                         <div className="pull-right">

                            <a href={"/payments/adjust_unpaid_bills"} target="_blank"><button type="button" className="btn btn-success pull-right nav-btn">Adjust Unpaid Bills</button></a>
                         
                         
                        </div>
                        }
                        <div className="pull-right" style={{marginTop:'15px'}}>
                          
                         <Notification /> 

                        {this.state.trash_count>0 &&
                          <a href={"/billings/"+this.state.active_tab+"/trash"} className="btrash">
                            <FontAwesomeIcon  icon={faTrash}  className=""/><span className="notif-count">{this.state.trash_count}</span>
                          </a>
                        }
                        {(this.state.hold_count>0 && utils.isMobile()===true) &&
                          <a href={"/billings/"+this.state.active_tab+'/hold'} className="bcart">
                            <FontAwesomeIcon  icon={faShoppingCart} className=""/><span className="notif-count">{this.state.hold_count}</span>
                          </a>
                        }
                        
                        </div>
                    </div>
                  </div>
                </nav>
                <div className="container-fluid">
                  {(this.state.not_env_count>0 && this.state.send_e_invoice===true) &&
                    <div className="alert alert-warning text-center" role="alert" style={{paddingTop: '0px',paddingBottom: '3px'}}>
                      <p style={{margin:'0px'}}>You have {this.state.not_env_count} UnSynced <span style={{textTransform:'capitalize'}}>{this.state.active_tab.replace("_"," ")}</span> e-Invoice. Please <button className="btn btn-warning btn-sm" onClick={this.openSendEInvoicePopup}  style={{paddingBottom:'0px',paddingTop:'0px'}}>Click Here</button> to Sync it.</p>
                    </div>
                  }
                  {this.state.is_search===false && this.state.top_option==='' &&
                  <div className="nav-container">
                    <ul className="nav nav-pills">
                      {this.is_access('billing-sale') &&
                        <li className={this.state.active_tab==='sales'?'active':''}><a href="/billings">Sales</a></li>
                      }
                      {this.is_access('billing-purchase') &&
                        <li className={this.state.active_tab==='purchase'?'active':''}><a href="/billings/purchase">Purchase</a></li>
                      }
                      {this.is_access('billing-sales-return') &&
                        <li className={this.state.active_tab==='sales_return'?'active':''}><a href="/billings/sales_return">Sales Return</a></li>
                      }
                      {this.is_access('billing-purchase-return') &&
                        <li className={this.state.active_tab==='purchase_return'?'active':''}><a href="/billings/purchase_return">Purchase Return</a></li>
                      }
                      {(this.is_access('billing-quotation') && this.state.is_create_quotation===true) &&
                        <li className={this.state.active_tab==='quotation'?'active':''}>
                          <a href="/quotation">Quotation</a>
                        </li>
                      }
                      
                      <li className={this.state.active_tab==='unpaid_bills'?'active':''}><a href="/billings/unpaid_bills">Unpaid Sale Bills</a></li>
                    
                      {(this.state.active_tab==='unpaid_bills') &&
                        <li className={'hold_title'}>
                          <span style={{fontWeight:'bold'}}>
                           
                           
                              ₹{utils.currency_format(this.state.total_pending_amount)}
                          
                          </span>
                          <button type="button" className="btn btn-success" onClick={this.handleDownloadUnpaidBills} style={{marginLeft:'10px'}}>Download Xls</button>
                        </li>
                      }
                      {(this.state.hold_count>0 && utils.isMobile()===false) &&
                        <li title="Alt + H" className={this.state.active_tab==='hold'?'active hold_title':'hold_title'}>
                          <a href={"/billings/"+this.state.active_tab+'/hold'}><FontAwesomeIcon  icon={faShoppingCart} className=""/> On Hold {this.state.active_tab.replace("_"," ")}({this.state.hold_count})</a>
                        </li>
                      }
                    </ul>
                  <ShortcutHelp target="tabs"  arrow="top" shortcuts={['Ctrl+Alt+1','Ctrl+Alt+2','Ctrl+Alt+3','Ctrl+Alt+4']}/>

                  </div>
                  }
                  <div className="content-area">
                   {this.state.top_option==='' &&
                     <div className="content-filter hide">
                        <div className="row">
                          <div className="col-md-4">
                              <label className="lbl">SHOW SALES</label>
                              <select className="form-control">
                                  <option value="">All</option>
                              </select>
                          </div>
                          <div className="col-md-4">
                            <div className="input-group mrtop-32">
                              <input type="text" className="form-control no-border-right" placeholder="Enter Sales #, Amount #, Balance #, Status #" />
                              <div className="input-group-append">
                                <span className="input-group-text"><FontAwesomeIcon icon={faSearch} /></span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <label className="lbl">FROM</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text no-border-right">
                                  <FontAwesomeIcon icon={faCalendarAlt} />
                                </span>
                                <DatePicker  className="form-control  no-border-left" selected={this.state.FromDate} onChange={this.FromDate} dateFormat="dd/MM/yyyy"/>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <label className="lbl">TO</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text  no-border-right"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                <DatePicker className="form-control  no-border-left" selected={this.state.ToDate} onChange={this.ToDate} dateFormat="dd/MM/yyyy"/>
                              </div>
                            </div>
                          </div>
                        </div>
                     </div>
                   }
                     <div className="main-content" style={{overflow:'auto'}}>
                        <table className="table">
                        <thead className="thead-light">
                          <tr>
                          {this.state.top_option==='trash' &&
                            <th scope="col" width="54">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" onChange={this.handleCheckAll}/>
                              </div>
                            </th>
                          } 
                          {((this.state.active_tab==='sales' || this.state.active_tab==='unpaid_bills') && this.state.is_search===false) &&
                            <th scope="col" onClick={this.handleSortList} data-id="id" className="sort-head">SALES # <span><FontAwesomeIcon icon={faSort} className={(this.state.sort_type==='id')?'sort-on-id':''}/></span></th>
                          }
                          {(this.state.active_tab==='sales_return' && this.state.is_search===false) &&
                            <th scope="col" onClick={this.handleSortList} data-id="id" className="sort-head">SALES RETURN # <span><FontAwesomeIcon icon={faSort}  className={(this.state.sort_type==='id')?'sort-on-id':''}/></span></th>
                          }
                          {(this.state.active_tab==='purchase' && this.state.is_search===false) &&
                            <th scope="col" onClick={this.handleSortList} data-id="id" className="sort-head">PURCHASE # <span><FontAwesomeIcon icon={faSort}  className={(this.state.sort_type==='id')?'sort-on-id':''}/></span></th>
                          }
                          {(this.state.active_tab==='purchase_return' && this.state.is_search===false) &&
                            <th scope="col" onClick={this.handleSortList} data-id="id" className="sort-head">PURCHASE RETURN # <span><FontAwesomeIcon icon={faSort}  className={(this.state.sort_type==='id')?'sort-on-id':''}/></span></th>
                          }
                          {(this.state.is_search===true) &&
                            <th>INVOICE #</th>
                          }
                            <th scope="col" onClick={this.handleSortList} data-id="date" className="sort-head default-hide" id="sldate">DATE <span><FontAwesomeIcon icon={faSort}  className={(this.state.sort_type==='date')?'sort-on-date':''}/></span></th>
                            <th scope="col" className="default-hide" id="slname">NAME</th>
                            {(this.state.top_option==='hold') &&
                              <th scope="col">STAFF</th>
                            }
                            <th scope="col" className="default-hide" id="slamount">AMOUNT</th>
                            {(this.state.active_tab==='sales' && this.state.is_search===false) &&
                              <th scope="col" className="default-hide" id="slpending-amt">PENDING AMOUNT</th>
                            }
                            {(this.state.active_tab==='unpaid_bills') &&
                              <th scope="col">PENDING AMOUNT</th>
                            }
                            {(this.state.active_tab==='purchase' && this.state.is_search===false) &&
                              <th scope="col"  className="default-hide" id="plpayment-mode">MODE OF PAYMENT</th>
                            }
                            {(this.state.active_tab==='sales' || this.state.active_tab==='sales_return' || this.state.active_tab==='purchase' || this.state.active_tab==='purchase_return') &&
                            <>
                              {(this.state.advance_sale_data.map((value)=>{
                                return(
                                  <th scope="col" className="default-hide" id={value.key}>{value.value}</th>
                                )
                              }))}
                            </>
                            }
                            {(this.state.active_tab==='sales') &&
                              <th scope="col">DISC</th>
                            }
                            <th scope="col" className="default-hide" id="slstatus">STATUS</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                         <tbody>
                           {(this.state.billing_list && this.state.billing_list.length > 0) ?this.state.billing_list.map( (value, index) => {
                           
                            
                           return (
                            <>
                            <tr key={ index } id={"billing-row-"+value.id} className="contact-row" data-id={this.state.top_option==='trash'?10:9}>
                              {this.state.top_option==='trash' &&
                                 <td>
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input sub-checkbox" type="checkbox" value={value.id}/>
                                  </div>
                                </td>
                              }
                              <td onClick={this.handleShowPayment} id={value.id}>
                              {(this.state.active_tab!=='unpaid_bills') &&
                                <Link link={"/view-billing/"+value.data_target+"/"+value.id} name={value.number} />
                              }
                              {(this.state.active_tab==='unpaid_bills') &&
                                <DrawerLink target="billing" url={'/view-billing/'+value.data_target+'/'+value.id} name={value.number}  schema={value.schema}/>
                              }
{/* 
                              {(this.state.send_e_invoice===true && value.is_e_invoice_generated===true && value.irn_data && value.irn_data.all && value.irn_data && value.irn_data.all.response_data && value.irn_data.all.response_data.Irn) &&
                                <p className="list-irn">{value.irn_data.all.response_data.Irn}</p>
                              } */}
                              {(value.is_online===true) &&
                                <span className="online-strip">Online</span>
                              }
                              {(this.state.send_e_invoice===true && value.is_e_invoice_generated===false) &&
                                <FontAwesomeIcon icon={faCloudUploadAlt} style={{color:'#b3b3b3',marginLeft:'5px'}} title="Generate eInvoice"/>
                              }
                              
                              </td>
                              <td className={(value.sales_list_opt_visibility && (value.sales_list_opt_visibility.indexOf('sldate')>=0 || value.sales_list_opt_visibility.indexOf('srldate')>=0 || value.sales_list_opt_visibility.indexOf('pldate')>=0 || value.sales_list_opt_visibility.indexOf('prldate')>=0))?'default-hide hide':''}>{value.created_date}</td>
                              <td className={(value.sales_list_opt_visibility && (value.sales_list_opt_visibility.indexOf('slname')>=0 || value.sales_list_opt_visibility.indexOf('srlname')>=0 || value.sales_list_opt_visibility.indexOf('plname')>=0 || value.sales_list_opt_visibility.indexOf('prlname')>=0))?'default-hide hide':''}><Link link={'/view-contact/'+value.contact_id} name={value.contact_name} /></td>
                              {(this.state.top_option==='hold') &&
                                <td>{value.staff_name}</td>
                              }
                              <td className={(value.sales_list_opt_visibility && (value.sales_list_opt_visibility.indexOf('slamount')>=0 || value.sales_list_opt_visibility.indexOf('srlamount')>=0 || value.sales_list_opt_visibility.indexOf('plamount')>=0 || value.sales_list_opt_visibility.indexOf('prlamount')>=0))?'default-hide hide':''}>{value.total}</td>
                              {(this.state.active_tab==='sales' && this.state.is_search===false) &&
                              <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('slpending-amt')>=0)?'default-hide hide':''}>{this.currency_format(value.pending_amount)}</td>
                              }
                              {(this.state.active_tab==='unpaid_bills') &&
                              <td>{this.currency_format(value.pending_amount)}</td>
                              }
                              {(this.state.active_tab==='purchase'  && this.state.is_search===false) &&
                                <td  className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('plpayment-mode')>=0)?'default-hide hide':''}>{value.payment_mode}</td>
                              }
                              {(this.state.active_tab==='sales' && value.data_target==='sales') &&
                                <>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('slagent')>=0)?'default-hide hide':''}>{value.dispatched_info.agent_name}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('sltransport')>=0)?'default-hide hide':''}>{value.dispatched_info.dispatched_through}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('sllrno')>=0)?'default-hide hide':''}>{value.dispatched_info.dispatched_document}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('slsuprefno')>=0)?'default-hide hide':''}>{value.dispatched_info.supplier_ref_no}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('sldelivery-note')>=0)?'default-hide hide':''}>{value.dispatched_info.delivery_note}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('sloth-note-from-sup')>=0)?'default-hide hide':''}>{value.dispatched_info.other_note}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('slother-ref')>=0)?'default-hide hide':''}>{value.dispatched_info.other_refrence}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('slterms-of-delivery')>=0)?'default-hide hide':''}>{value.dispatched_info.terms_of_delivery}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('slpieces-in-parcel')>=0)?'default-hide hide':''}>{value.dispatched_info.pieces_in_parcel}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('slno-of-bales')>=0)?'default-hide hide':''}>{value.dispatched_info.no_of_parcels}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('slpacked-by')>=0)?'default-hide hide':''}>{value.dispatched_info.packed_by}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('slchecked-by')>=0)?'default-hide hide':''}>{value.dispatched_info.checked_by}</td>
                                </>
                              }
                              {(this.state.active_tab==='purchase' && value.data_target==='purchase') &&
                                <>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('plinvdate')>=0)?'default-hide hide':''}>{value.dispatched_info.invoice_date}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('plagent')>=0)?'default-hide hide':''}>{value.dispatched_info.agent_name}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('pltransport')>=0)?'default-hide hide':''}>{value.dispatched_info.dispatched_through}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('pllrno')>=0)?'default-hide hide':''}>{value.dispatched_info.dispatched_document}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('plsuprefno')>=0)?'default-hide hide':''}>{value.dispatched_info.supplier_ref_no}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('pldelivery-note')>=0)?'default-hide hide':''}>{value.dispatched_info.delivery_note}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('ploth-note-from-sup')>=0)?'default-hide hide':''}>{value.dispatched_info.other_note}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('plother-ref')>=0)?'default-hide hide':''}>{value.dispatched_info.other_refrence}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('plterms-of-delivery')>=0)?'default-hide hide':''}>{value.dispatched_info.terms_of_delivery}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('plpieces-in-parcel')>=0)?'default-hide hide':''}>{value.dispatched_info.pieces_in_parcel}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('plno-of-bales')>=0)?'default-hide hide':''}>{value.dispatched_info.no_of_parcels}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('plpacked-by')>=0)?'default-hide hide':''}>{value.dispatched_info.packed_by}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('plchecked-by')>=0)?'default-hide hide':''}>{value.dispatched_info.checked_by}</td>
                                </>
                              }
                              {(this.state.active_tab==='sales_return' && value.data_target==='sales_return') &&
                                <>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('srlagent')>=0)?'default-hide hide':''}>{value.dispatched_info.agent_name}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('srltransport')>=0)?'default-hide hide':''}>{value.dispatched_info.dispatched_through}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('srllrno')>=0)?'default-hide hide':''}>{value.dispatched_info.dispatched_document}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('srlsuprefno')>=0)?'default-hide hide':''}>{value.dispatched_info.supplier_ref_no}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('srldelivery-note')>=0)?'default-hide hide':''}>{value.dispatched_info.delivery_note}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('srloth-note-from-sup')>=0)?'default-hide hide':''}>{value.dispatched_info.other_note}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('srlother-ref')>=0)?'default-hide hide':''}>{value.dispatched_info.other_refrence}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('srlterms-of-delivery')>=0)?'default-hide hide':''}>{value.dispatched_info.terms_of_delivery}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('srlpieces-in-parcel')>=0)?'default-hide hide':''}>{value.dispatched_info.pieces_in_parcel}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('srlno-of-bales')>=0)?'default-hide hide':''}>{value.dispatched_info.no_of_parcels}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('srlpacked-by')>=0)?'default-hide hide':''}>{value.dispatched_info.packed_by}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('srlchecked-by')>=0)?'default-hide hide':''}>{value.dispatched_info.checked_by}</td>
                                </>
                              }
                              {(this.state.active_tab==='purchase_return' && value.data_target==='purchase_return') &&
                                <>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('prlinvdate')>=0)?'default-hide hide':''}>{value.dispatched_info.invoice_date}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('prlagent')>=0)?'default-hide hide':''}>{value.dispatched_info.agent_name}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('prltransport')>=0)?'default-hide hide':''}>{value.dispatched_info.dispatched_through}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('prllrno')>=0)?'default-hide hide':''}>{value.dispatched_info.dispatched_document}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('prlsuprefno')>=0)?'default-hide hide':''}>{value.dispatched_info.supplier_ref_no}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('prldelivery-note')>=0)?'default-hide hide':''}>{value.dispatched_info.delivery_note}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('prloth-note-from-sup')>=0)?'default-hide hide':''}>{value.dispatched_info.other_note}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('prlother-ref')>=0)?'default-hide hide':''}>{value.dispatched_info.other_refrence}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('prlterms-of-delivery')>=0)?'default-hide hide':''}>{value.dispatched_info.terms_of_delivery}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('prlpieces-in-parcel')>=0)?'default-hide hide':''}>{value.dispatched_info.pieces_in_parcel}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('prlno-of-bales')>=0)?'default-hide hide':''}>{value.dispatched_info.no_of_parcels}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('prlpacked-by')>=0)?'default-hide hide':''}>{value.dispatched_info.packed_by}</td>
                                  <td className={(value.sales_list_opt_visibility && value.sales_list_opt_visibility.indexOf('prlchecked-by')>=0)?'default-hide hide':''}>{value.dispatched_info.checked_by}</td>
                                </>
                              }
                               {(this.state.active_tab==='sales' && value.data_target==='sales' && value.disc_percent!==undefined) &&
                               <td><span style={{color:value.disc_percent_color,fontWeight:(value.disc_percent>5)?'bold':'normal'}}>{utils.currency_format(value.disc_percent)}%</span></td>
                              }
                              <td className={(value.sales_list_opt_visibility && (value.sales_list_opt_visibility.indexOf('slstatus')>=0 || value.sales_list_opt_visibility.indexOf('srlstatus')>=0 || value.sales_list_opt_visibility.indexOf('plstatus')>=0 || value.sales_list_opt_visibility.indexOf('prlstatus')>=0))?'hide default-hide':''}>{value.bill_status}</td>
                             
                              {(this.state.send_e_invoice===true && value.is_e_invoice_generated===true) &&
                                  <td>
                                    {(value.is_irn_cancel===true) &&
                                      <Tooltip opt={this.state.top_option} id={value.id} index={index} view={"/view-billing/"+this.state.active_tab+"/"+value.id} target={this.state.active_tab} item_count={_this.state.billing_list.length} is_fy_closed={this.state.is_fy_closed} delete={{active_tab:this.state.active_tab,id:value.id,data_value:data_value,is_e_invoice:true,delete_label:(value.is_current?'Delete':'Cancel')}}/>
                                    }
                                    {(value.is_irn_cancel===false) &&
                                      <Tooltip opt={this.state.top_option} id={value.id} index={index} view={"/view-billing/"+this.state.active_tab+"/"+value.id} target={this.state.active_tab} item_count={_this.state.billing_list.length} is_fy_closed={this.state.is_fy_closed}/>
                                    }

                                    {(value.is_irn_cancel!==false && value.is_irn_cancel!==true) &&
                                    <>
                                      {(value.bill_status==='Cancelled')?
                                        <Tooltip opt={this.state.top_option} id={value.id} index={index} view={"/view-billing/"+value.data_target+"/"+value.id} resume={"/new-billing/resume/"+value.data_target+"/"+value.id} target={value.data_target} item_count={_this.state.billing_list.length} is_fy_closed={this.state.is_fy_closed} is_delete_cancelled_bill={{is_delete_cancelled_bill:this.state.delete_cancelled_bill,active_tab:value.data_target,id:value.id,data_value:data_value,delete_label:'Delete'}} />
                                        :
                                        <Tooltip opt={this.state.top_option} id={value.id} index={index} view={"/view-billing/"+value.data_target+"/"+value.id} edit={"/new-billing/edit/"+value.data_target+"/"+value.id} delete={{active_tab:value.data_target,id:value.id,data_value:data_value,delete_label:(value.is_current?'Delete':'Cancel')}} target={value.data_target} item_count={_this.state.billing_list.length} is_fy_closed={this.state.is_fy_closed}/>
                                      }
                                    </>
                                    }

                                  </td>
                              }

                              {(this.state.send_e_invoice===true && value.is_e_invoice_generated!==true) &&
                                <td>
                                    {(value.bill_status==='Cancelled')?
                                      <Tooltip opt={this.state.top_option} id={value.id} index={index} view={"/view-billing/"+value.data_target+"/"+value.id} resume={"/new-billing/resume/"+value.data_target+"/"+value.id} target={value.data_target} item_count={_this.state.billing_list.length} is_fy_closed={this.state.is_fy_closed} is_delete_cancelled_bill={{is_delete_cancelled_bill:this.state.delete_cancelled_bill,active_tab:value.data_target,id:value.id,data_value:data_value,delete_label:'Delete'}} />
                                      :
                                      <Tooltip opt={this.state.top_option} id={value.id} index={index} view={"/view-billing/"+value.data_target+"/"+value.id} edit={"/new-billing/edit/"+value.data_target+"/"+value.id} delete={{active_tab:value.data_target,id:value.id,data_value:data_value,delete_label:(value.is_current?'Delete':'Cancel')}} target={value.data_target} item_count={_this.state.billing_list.length} is_fy_closed={this.state.is_fy_closed}/>
                                    }
                                </td>
                              }
                              {(this.state.send_e_invoice===false) &&
                                <td>
                                    {(value.bill_status==='Cancelled')?
                                      <Tooltip opt={this.state.top_option} id={value.id} index={index} view={"/view-billing/"+value.data_target+"/"+value.id} resume={"/new-billing/resume/"+value.data_target+"/"+value.id} target={value.data_target} item_count={_this.state.billing_list.length} is_fy_closed={this.state.is_fy_closed} is_delete_cancelled_bill={{is_delete_cancelled_bill:this.state.delete_cancelled_bill,active_tab:value.data_target,id:value.id,data_value:data_value,delete_label:'Delete'}} />
                                      :
                                      <Tooltip opt={this.state.top_option} id={value.id} index={index} view={"/view-billing/"+value.data_target+"/"+value.id} edit={"/new-billing/edit/"+value.data_target+"/"+value.id} delete={{active_tab:value.data_target,id:value.id,data_value:data_value,delete_label:(value.is_current?'Delete':'Cancel')}} target={value.data_target} item_count={_this.state.billing_list.length} is_fy_closed={this.state.is_fy_closed}/>
                                    }
                                </td>
                              }
                            </tr>
                          </>
                          )
                         }) : <tr><td colSpan={this.state.top_option==='trash'?9:8} className="text-center">{_this.state.loading_process}</td></tr> }
                        </tbody>
                      </table>
                      {this.state.top_option==='trash' &&
                        <BulkAction data_target={this.state.active_tab} />
                      }
                      {(this.state.billing_list && this.state.billing_list.length > 0) &&
                        <ListCount list={this.state.billing_list}/>
                      }
                      {(this.state.is_search===false) &&
                        <Pager total_page={this.state.total_page}/>
                      }
                     </div>
                  </div>
                </div>
              </div>
              <HoldBillPopup data={{screen:'billing',billing_type:this.state.active_tab,update_id:0}}/>
              {(this.state.send_e_invoice===true) &&
                <ModalBox id="generate_e_invoice_popup" title="Generate e-Invoice" target={this.state.active_tab} />
              }
              {(this.state.active_tab==='unpaid_bills' && this.state.is_download_unpaid_bills===true) &&
              <>
                <span id="handleCloseDownloadUnpaidBills" onClick={this.handleCloseDownloadUnpaidBills}></span>
                <DownloadUnpaidBills/>
              </>
              }
            </div>
        );
    }
    handleDownloadUnpaidBills = () =>{
      this.setState({is_download_unpaid_bills:true})
    }
    handleCloseDownloadUnpaidBills = () =>{
      this.setState({is_download_unpaid_bills:false})
    }
}