import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faRupeeSign, faTimesCircle, faArrowAltCircleRight, faInfoCircle, faInfo, faExclamation, faAngleDoubleRight, faSpinner} from '@fortawesome/free-solid-svg-icons';
import utils from "../utils";
import check_access from './access-level.js';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import '../../node_modules/react-toastify/dist/ReactToastify.css';
import CreditDebitNoteLi from "./credit-debit-note-li";
import ChequeInputInfo from "./cheque-input-info";
let {enc_key,app_api,user_info} = require('../library.js');
function redirectToSettings(){
  window.location.href = '/settings/billings-and-payments';
}
export default class BillingPOSPaymentPopup extends Component {
    constructor() {
      super();
      this.state = {
        payment_mode : 1,
        payment_mode_fixed : '',
        is_direct_pay : false,
        enable_tcs : false,
        enable_contact_tcs : false,
        current_focused : 0,
        bill_popup_data : [],
        pending_return_opt : '',
        bill_data : {},
        selected_customer : {},
        payments_data : {bill_types:[],data:{bill_amount:0,paid_amount:0,return_amount:0}},
        is_saving_pos : false,
      };
      this.is_access = check_access.is_access.bind(this);
      this.currency_format = utils.currency_format.bind(this);
      this.floating_point = utils.floating_point.bind(this);
    }
    componentDidMount(){
      this.setState({bill_data:this.props.bill_data,selected_customer:this.props.bill_data.contact_info});
      let pd = {'bill_amount':this.props.bill_data.grand_total,'paid_amount':0,'return_amount':-this.props.bill_data.grand_total};
      let payments_data = {bill_types:[],data:pd};
      this.setState({payments_data:payments_data});
      document.addEventListener("keydown", this.handleBillPaymentShortcut);
    }
    componentWillUnmount(){
      document.removeEventListener("keydown", this.handleBillPaymentShortcut);
    }
    handleClosePaymentPopup = ()=>{
      if(this.props.handleClosePaymentPopup){
        this.props.handleClosePaymentPopup();
      }
    }
    handleChangeDiscountAmount = (value) =>{
      let bill_data = this.state.bill_data;
      bill_data.global_discount = value;
      this.handleFinalCalculationTemp(bill_data);
    }
    handleChangeDiscountOption = (type)=>{
      let bdata = this.state.bill_data;
      bdata.global_discount_type = type;
      this.handleFinalCalculationTemp(bdata);
    }
    handleChangeTCSPercent = (value) =>{
      let bill_data = this.state.bill_data;
      if(value==""){
        value = 0;
      }
      bill_data.tcs_percent = value;
      this.handleFinalCalculationTemp(bill_data);
    }
    handleChangeRoundOffAmount = (value) =>{
      let bill_data = this.state.bill_data;
      bill_data.round_off = value;
      this.handleFinalCalculationTemp(bill_data);
    }
    handleChangeRoundOffOption = (value)=>{
      let bill_data = this.state.bill_data;
      bill_data.round_off_opt = value;
      this.handleFinalCalculationTemp(bill_data,true);
    }
    hanldeExchangeFinalCalculationTemp = (list_items)=>{
      let total_exchange_quantity = 0;
      let total_exchange_amount = 0;
      let exchange_sub_total = 0;
      let total_exchange_discount = 0;
      let exchange_gst_amount = 0;
      let exchange_igst_amount = 0;
      let exchange_sgst_amount = 0;
      let exchange_cgst_amount = 0;
      for (let index = 0; index < list_items.length; index++) {
        const element = list_items[index];
        let quantity = element.quantity;
        total_exchange_quantity = total_exchange_quantity + parseFloat(quantity);
        let total = element.total;
        let price = element.price;
        let discount_amount = element.discount_amount;
        total_exchange_amount = total_exchange_amount + parseFloat(total);
        exchange_sub_total = exchange_sub_total + parseFloat(price);
        total_exchange_discount = total_exchange_discount + parseFloat(discount_amount);
        
        exchange_igst_amount = exchange_igst_amount + parseFloat(element.exchange_igst_amount);
        exchange_cgst_amount = exchange_cgst_amount + parseFloat(element.exchange_cgst_amount);
        exchange_sgst_amount = exchange_sgst_amount + parseFloat(element.exchange_sgst_amount);
        exchange_gst_amount = exchange_gst_amount + parseFloat(element.gst_amount);
      }
      return {total_exchange_amount:total_exchange_amount,total_exchange_quantity:total_exchange_quantity};
    }
    handleFinalCalculationTemp = (bill_data,round_off_change=false)=>{
      let list_items = bill_data.list_items;
      let exchange_items = bill_data.exchange_items;
      let total_quantity = 0;
      let total_amount = 0;
      let sub_total = 0;
      let total_discount = 0;
      let commission = 0;
      let gst_amount = 0;
      let igst_amount = 0;
      let sgst_amount = 0;
      let cgst_amount = 0;
      for (let index = 0; index < list_items.length; index++) {
        const element = list_items[index];
        let quantity = element.quantity;
        total_quantity = total_quantity + parseFloat(quantity);
        let total = element.total;
        let price = element.price;
        let item_commission = element.item_commission;
        let discount_amount = element.discount_amount;
        total_amount = total_amount + parseFloat(total);
        sub_total = sub_total + parseFloat(price);
        total_discount = total_discount + parseFloat(discount_amount);
        commission = commission + parseFloat(item_commission);

        igst_amount = igst_amount + parseFloat(element.igst_amount);
        cgst_amount = cgst_amount + parseFloat(element.cgst_amount);
        sgst_amount = sgst_amount + parseFloat(element.sgst_amount);

        gst_amount = gst_amount + parseFloat(element.gst_amount);
      }
      let global_discount = this.state.bill_data.global_discount;
      let discount_opt = this.state.bill_data.global_discount_type;
      let total_global_discount = 0;
      if(discount_opt!=='' && discount_opt!==null && global_discount!=='' && global_discount!==null && parseFloat(global_discount)>0){
        for (let index = 0; index < list_items.length; index++) {
          let item = list_items[index];
          let quantity = item.quantity;
          if(quantity===""){
            quantity = 0;
          }
          
          quantity = parseFloat(quantity);
          
          let discount = item.discount;
          let discount_type = item.discount_type;
          let sp_tax = item.sp_tax;
          let sp_tax_type = item.sp_tax_type;
          let selling_price = item.selling_price;
          selling_price = parseFloat(selling_price);

          let item_discount = 0; 
          if(discount!=='' && discount!==null && parseFloat(discount)){
            if(discount_type==='per'){
              let cal_disc = (selling_price * discount)/100;
              item_discount = utils.floating_point(cal_disc);
            }else if(discount_type==='rs'){
              item_discount = discount;
            }
          }
          let glob_discount = 0;
          if(discount_opt==="per"){
            let dis = ((selling_price * quantity)*global_discount)/100;
            glob_discount = dis;
          }else if(discount_opt==="rs"){
              let gd = global_discount/sub_total*100;
              let dis = ((selling_price * quantity)*gd)/100;
              glob_discount = dis;
          }
          total_global_discount = total_global_discount + glob_discount;
          let total_discount = item_discount + glob_discount;
          let gst_amount = 0 ;
          let sp_amount = selling_price;
          
          item.price = sp_amount;
          
          if(sp_tax!=="" && sp_tax!==null && parseFloat(sp_tax)>0){
            sp_tax = parseFloat(sp_tax);
            if(sp_tax_type==='including'){
              let GST = parseFloat(selling_price) - (parseFloat(selling_price) * (100 / (100 + sp_tax ) ) );
              GST = selling_price-GST;
              GST = parseFloat(utils.floating_point(GST));
              let gross_amount = GST-total_discount;
              let TOTAL_GST = selling_price-GST;
              gst_amount = TOTAL_GST;
              gst_amount = (quantity*gst_amount);
              gst_amount = (gross_amount*sp_tax/100)*quantity;
              item.price = utils.floating_point(gross_amount);
              sp_amount = (quantity*gross_amount)+gst_amount;
            }else if(sp_tax_type==='excluding'){
              gst_amount = (sp_amount*sp_tax)/100;
              sp_amount   = sp_amount * quantity;
              gst_amount = gst_amount*quantity;
            }
          }else{
            sp_amount = sp_amount - total_discount;
            sp_amount   = sp_amount * quantity;
          }
          
          let company_state = user_info.state;
          let customer_state = (this.state.selected_customer.state!==undefined)?this.state.selected_customer.state:company_state;
          item.cgst_amount = 0;
          item.sgst_amount = 0;
          item.igst_amount = 0;
          
          if(company_state===customer_state){
            if(gst_amount>0){
              let cal_gst = gst_amount/2;
              item.cgst_amount = utils.floating_point(cal_gst);
              item.sgst_amount = utils.floating_point(cal_gst);
            }
          }else{
            if(gst_amount>0){
              item.igst_amount = utils.floating_point(gst_amount);
            }
          }
          
          item.discount_amount = item_discount;
          item.gst_amount = utils.floating_point(gst_amount);
          let item_total = sp_amount;
          item.total = item_total;
          if(this.state.is_item_commission===true && this.state.item_commission>0){
            let item_commission = (item_total * this.state.item_commission)/100;
            item.item_commission = utils.floating_point(item_commission);
          }
          list_items[index] = item;
        }
        bill_data.list_items = list_items;
        total_quantity = 0;
        total_amount = 0;
        sub_total = 0;
        total_discount = 0;
        commission = 0;
        gst_amount = 0;
        igst_amount = 0;
        sgst_amount = 0;
        cgst_amount = 0;
        for (let index = 0; index < list_items.length; index++) {
          const element = list_items[index];
          let quantity = element.quantity;
          total_quantity = total_quantity + parseFloat(quantity);
          let total = element.total;
          let price = element.price;
          let item_commission = element.item_commission;
          let discount_amount = element.discount_amount;
          total_amount = total_amount + parseFloat(total);
          sub_total = sub_total + parseFloat(price);
          total_discount = total_discount + parseFloat(discount_amount);
          commission = commission + parseFloat(item_commission);

          igst_amount = igst_amount + parseFloat(element.igst_amount);
          cgst_amount = cgst_amount + parseFloat(element.cgst_amount);
          sgst_amount = sgst_amount + parseFloat(element.sgst_amount);

          gst_amount = gst_amount + parseFloat(element.gst_amount);
        }
      }
      bill_data.global_discount_type = discount_opt;
      bill_data.global_discount = global_discount;
      bill_data.global_discount_amount = total_global_discount;
      let exchange_final_data = this.hanldeExchangeFinalCalculationTemp(exchange_items);
      let exchange_amount = exchange_final_data.total_exchange_amount;
      let total_exchange_quantity = exchange_final_data.total_exchange_quantity;
      let sale_amount = total_amount;
      total_amount = total_amount - exchange_amount;
      total_amount = total_amount + this.state.bill_data.other_charge_amount;
      if(this.state.bill_data.contact_info.is_tcs_enabled===true){
        let tcs_percent = bill_data.tcs_percent;
        tcs_percent = parseFloat(tcs_percent);
        let cal_tcs = (total_amount*tcs_percent)/100;
        bill_data.tcs_amount = utils.floating_point(cal_tcs);
        total_amount = total_amount + cal_tcs;
      }
      let round_off = Math.round(total_amount)-total_amount;
      let round_off_opt = bill_data.round_off_opt;
      if(round_off>=0){
        round_off = Math.ceil(total_amount)-total_amount;
        round_off = utils.currency_format(round_off);
        if(round_off_change===false){
          round_off_opt = '+';
        }
      }else{
        round_off = total_amount-Math.floor(total_amount);
        round_off = utils.currency_format(round_off);
        if(round_off_change===false){
          round_off_opt = '-';
        }
      }
      let grand_total = total_amount;
      if(round_off_opt==='-'){
        grand_total = parseFloat(grand_total)-parseFloat(round_off);
      }else if(round_off_opt==='+'){
        grand_total = parseFloat(grand_total)+parseFloat(round_off);
      }
      bill_data.total_amount = sale_amount;
      bill_data.exchange_amount = exchange_amount;
      bill_data.total_exchange_quantity = total_exchange_quantity;
      bill_data.grand_total = utils.currency_format(grand_total);
      bill_data.round_off = round_off;
      bill_data.round_off_opt = round_off_opt;
      bill_data.total_unit = list_items.length;
      bill_data.total_quantity = total_quantity;
      bill_data.sub_total = sub_total;
      bill_data.total_discount = total_discount;
      bill_data.commission = commission;
      bill_data.gst_amount = gst_amount;
      bill_data.igst_amount = igst_amount;
      bill_data.cgst_amount = cgst_amount;
      bill_data.sgst_amount = sgst_amount;
      this.setState({bill_data:bill_data});
    }
    handleAddPayment = (e) =>{
      let data_id = e.currentTarget.getAttribute('data-id');
      let bill_amount = this.state.bill_data.grand_total;
      bill_amount = bill_amount.replaceAll(',','');
      if(document.getElementById(data_id)){
        let qs = document.querySelectorAll('.payment-mode-data');
      
        for (let index = 0; index < qs.length; index++) {
          const element = qs[index];
          element.value = '';
        }
        document.getElementById(data_id).value = bill_amount;
      
        document.getElementById(data_id).focus();
        document.getElementById(data_id).select();
      }
      this.handleCalculatePaymentData();
    }
    handleCalculatePaymentData = () =>{
      let t_p_amts = document.querySelectorAll(".payment-mode-data");

      let pay_data = [];
      let tpa = 0;
      
      for(let i=0;i<t_p_amts.length;i++){
        let amt = t_p_amts[i].value;
        let id = t_p_amts[i].id;
        let data_id = t_p_amts[i].getAttribute('data-id');
        let chq_bank_name = '';
        let chq_no = '';
        let chq_date = '';
        let chq_is_recon = '';
        if(data_id){
          chq_bank_name = 'chq-bank-name-'+data_id.replaceAll(' ','-').toLowerCase();
          chq_no = 'chq-no-'+data_id.replaceAll(' ','-').toLowerCase();
          chq_date = 'chq-date-'+data_id.replaceAll(' ','-').toLowerCase();
          chq_is_recon = 'send-for-recon-'+data_id.replaceAll(' ','-').toLowerCase();
        }
        let pay_chq_no = '';
        let pay_chq_bank_name = '';
        let pay_chq_date = '';
        let pay_is_recon = '';
        if(chq_no!=='' && document.getElementById(chq_no)){
          pay_chq_bank_name = document.getElementById(chq_bank_name).value;
          pay_chq_no = document.getElementById(chq_no).value;
          pay_chq_date = document.getElementById(chq_date).value;
        }
        if(chq_is_recon!=='' && document.getElementById(chq_is_recon)){
          pay_is_recon = document.getElementById(chq_is_recon).checked;
        }
        let pdata = {'id':id,value:amt,'cheque_bank_name':pay_chq_bank_name,'cheque_no':pay_chq_no,'cheque_date':pay_chq_date,'is_reconciliation':pay_is_recon};
        pay_data.push(pdata);
        if(amt!=="" && parseFloat(amt)>0){
          tpa  = tpa + parseFloat(amt);
        }
      }
      let tba = this.state.bill_data.grand_total;
      let tra = tpa - tba;
      let pd = {'bill_amount':this.state.bill_data.grand_total,'paid_amount':tpa,'return_amount':tra};
      let payments_data = {bill_types:pay_data,data:pd};
      this.setState({payments_data:payments_data});
    }
    handleAmountChange = (e)=>{
      this.handleCalculatePaymentData();
    }
    handleChangePaymentMode = (value) =>{
      this.setState({payment_mode:parseInt(value)});
      let pay_data = [];
      let pdata = {'id':'total-credit-amount',value:this.state.bill_data.grand_total,'cheque_bank_name':'','cheque_no':'','cheque_date':'','is_reconciliation':''};
      pay_data.push(pdata);
      let pd = {'bill_amount':this.state.bill_data.grand_total,'paid_amount':0,'return_amount':0};
      let payments_data = {bill_types:pay_data,data:pd};
      this.setState({payments_data:payments_data});
    }
    handleBillPaymentShortcut = (e) =>{
        let fkey_arr = [112,113,114,115,116,117,118,119,120,121,122,123];
        let key_pressed = e.which;
        let keyIdx = fkey_arr.indexOf(key_pressed);
        if(keyIdx>=0){
          e.preventDefault();
          keyIdx = keyIdx+1;
          if(document.getElementById("handlePayModeBtnClick-"+keyIdx)){
            document.getElementById("handlePayModeBtnClick-"+keyIdx).click();
          }
        }
        if(e.altKey && key_pressed===68){
          e.preventDefault();
          //discount
          document.getElementById("global_discount_cb").focus();
          document.getElementById("global_discount_cb").select();
        }else if(e.altKey && key_pressed===82){
          e.preventDefault();
          //round off
          document.getElementById("round_off_cb").focus();
          document.getElementById("round_off_cb").select();
        }else if(e.altKey && key_pressed===67){
          e.preventDefault();
          //credit note
          document.getElementById("ptype_note").focus();
          document.getElementById("ptype_note").select();
        }else if(e.altKey && key_pressed===80){
          e.preventDefault();
          //print
          document.getElementById("handlePrintFromPopup").click();
        }else if(e.altKey && key_pressed===78){
          e.preventDefault();
          //save print new
          document.getElementById("handleSavePrint").click();
        }else if(e.altKey && key_pressed===83){
          e.preventDefault();
          document.getElementById("handleSavePayment").click();
        }
    }

    handleCreateBillingPOS = () =>{
      let payments_data = this.state.payments_data;
      let g_total = this.state.bill_data.grand_total;
      if(this.state.payment_mode===1){
        let tra = payments_data.data.return_amount;
        let tpa = payments_data.data.paid_amount;
        if(tra!=="" && parseFloat(tra)<0){
          if(document.getElementById("total-cash-amount")){	
            document.getElementById("total-cash-amount").focus();	
          }else if(document.getElementById("total-online-payment-amount")){	
            document.getElementById("total-online-payment-amount").focus();	
          }
          if(document.getElementById("bill-save-error")){
            document.getElementById("bill-save-error").innerHTML = 'Please adjust balance amount.';
            return false;
          }
        }else if(tpa==="" || (tpa==="0" && g_total!=="0")){	
          if(document.getElementById("total-cash-amount")){	
            if(document.getElementById("total-cash-amount")){	
              document.getElementById("total-cash-amount").focus();	
            }else if(document.getElementById("total-online-payment-amount")){	
              document.getElementById("total-online-payment-amount").focus();	
            }	
            if(document.getElementById("bill-save-error")){	
              document.getElementById("bill-save-error").innerHTML = 'Please enter amount to pay.';	
              return false;	
            }	
          }	
        }
      }
      //this.setState({is_saving_pos:true});
      let send_data = this.state.bill_data;
      send_data.store_name = user_info.store_name;
      send_data.user_id = user_info.user_id;
      send_data.uuid = utils.uuid();
      send_data.role_id = user_info.access_level;
      send_data.token = user_info.token;
      send_data._schema = user_info._schema;
      send_data.branch_id  = user_info.login_id;
      send_data.bill_status  = 1;
      send_data.payment_mode  = this.state.payment_mode;
      send_data.payments_data  = payments_data;
      send_data.send_type  = 'save';
      let api = app_api+'/billing_pos';
        axios.post(api, send_data)
          .then(function (response) {
              this.setState({is_saving_pos:false});
             let resp = response.data;
             let id = resp.id;
        }.bind(this)).catch(function(res){
         
        })
    }

    render() {
      let pmindx = 0;
        return (
            <div id='billing-pos-payment-popup' className="modal" style={{overflowY:'scroll',display:'block'}}>
                <div className="modal-dialog modal-confirm modal-lg" style={{width:'unset',paddingBottom:'0px'}}>
                    <div className="modal-content" style={{paddingTop:'5px'}}>
                    
                        <div className="modal-body border-bottom" style={{padding:'15px 15px 0px 15px'}}>
                            <div className="row">
                                <div className={"mrbtm-10 col-md-6 text-left mrtop-20"}>
                                    <div className="row">
                                      <div className="col-md-4">
                                      <label htmlFor="mode_of_payment">BILL TYPE :</label>
                                      </div>
                                      <div className="col-md-8">
                                        <select target="New Sale" className="form-control form-field" id="mode_of_payment" defaultValue={this.state.payment_mode} onChange={(e)=>this.handleChangePaymentMode(e.target.value)}>
                                            <option value="">Select Pay Mode</option>
                                            <option value="1">Payment</option>
                                            <option value="2">Credit</option>
                                        </select>
                                      </div>
                                    </div>
                                </div>
                                <div className="col-md-6 text-right">
                                    <FontAwesomeIcon icon={faRupeeSign} style={{fontSize:'45px'}}/>
                                    <span id="bill-pay-pop-title" style={{color: '#333',fontWeight: 'bold',fontSize:'50px'}}>{utils.currency_format(this.state.bill_data.grand_total)}</span>
                                </div>
                              
                              
                               
                                
                             
                              <div style={{position: 'absolute',right: '-10px', top: '0',fontSize: '20px'}}>
                                <FontAwesomeIcon icon={faTimesCircle} style={{cursor:'pointer'}} onClick={this.handleClosePaymentPopup}/>
                              </div>
                            </div>
                            <hr style={{marginTop:'0px'}}/>
                          
                            <div className="row">
                           
                                <div className={"col-md-6 text-left border-right "}>
                                   
                                        <table width="100%" className="table inline-table bill-type-table">
                                        <tbody>
                                         
                                            {(this.state.payment_mode===1) &&
                                            <>
                                                {(this.props.data.payment_modes.length > 0) ?this.props.data.payment_modes.map( (value, index) => {
                                                pmindx = pmindx+1;
                                                    return (
                                                    <>
                                                    {(value.status===1) &&
                                                        <>
                                                       
                                                        <tr key={ index }>
                                                        <td>
                                                        <span style={{margin:'0px'}}>{value.payment_mode}:</span>
                                                        <span style={{fontSize:'10px'}}> (F{pmindx})</span>
                                                        </td>
                                                        <td style={{width:'40px',textAlign:'right'}}>
                                                        <button className="btn btn-success btn-sm" style={{minHeight:'35px',minWidth:'35px',backgroundColor: '#218838',borderColor: '#1e7e34'}}  data-id={"total-"+value.payment_mode.replaceAll(' ','-').toLowerCase()+"-amount"} onClick={(e)=>this.handleAddPayment(e)} id={"handlePayModeBtnClick-"+pmindx}>Add <FontAwesomeIcon icon={faAngleDoubleRight} /></button>
                                                        </td>
                                                        <td className="bttitem">
                                                        <input type="number" className="form-control payment-mode-data" placeholder="0" data-index={index} data-id={value.payment_mode} data-target="pay-box" id={"total-"+value.payment_mode.replaceAll(' ','-').toLowerCase()+"-amount"} onChange={(e)=>this.handleAmountChange(e)} defaultValue={(this.state.payments_data && this.state.payments_data.bill_types)?this.state.payments_data.bill_types['total-'+value.payment_mode.replaceAll(' ','-').toLowerCase()+'-amount']:''}/>
                                                        </td>
                                                        </tr>
                                                        {(value.is_cheque===true || value.is_cheque===1 || value.is_cheque==='1') &&
                                                        <ChequeInputInfo  type="td" id={value.payment_mode}/>
                                                        }
                                                        
                                                    </>
                                                    }
                                                    </>
                                                    )
                                                }) : '' }
                                                {(this.state.selected_customer.advance_payment!==undefined && this.state.selected_customer.advance_payment>0) &&
                                                <tr>
                                                  <td>Advance <span>(₹{utils.currency_format(this.state.selected_customer.advance_payment)})</span></td>
                                                  <td></td>
                                                  <td className="bttitem text-right">
                                                      <input type="number" className="form-control payment-mode-data" data-target="pay-box" placeholder="" id={"customer-advance-amount"}   onChange={(e)=>this.handleAmountChange(e)} defaultValue={(this.state.payments_data && this.state.payments_data.bill_types)?this.state.payments_data.bill_types['customer_advance_amount']:''}/>
                                                  </td>
                                                </tr>
                                                }
                                            <tr>
                                                <td colSpan={2}>
                                                    <p className="error" style={{margin:'0px'}} id="bill-save-error"></p>
                                                </td>
                                            </tr>
                                            </>
                                            }
                                            {(this.state.payment_mode===2) &&
                                            <>
                                              <tr key={ "cr-0"}>
                                                <td>Credit:</td>
                                                <td className="bttitem">
                                                <input type="number" className="form-control payment-mode-data" placeholder="0" data-index={0} data-id={'Credit'} data-target="pay-box" id={"total-credit-amount"}  readOnly={true} defaultValue={(this.state.payments_data && this.state.payments_data.bill_types)?this.state.payments_data.bill_types['total-credit-amount']:''}/>
                                                </td>
                                              </tr>
                                               <tr>
                                                  <td colSpan={2}>
                                                      <p className="error" style={{margin:'0px'}} id="bill-save-error"></p>
                                                  </td>
                                              </tr>
                                              </>
                                          }
                                        </tbody>
                                        </table>
                                    
                                </div>
                               
                                <div className="col-md-6">
                                
                                <table className="table inline-table bill-type-table text-left">
                                  <tbody>
                                  {(this.is_access('billing-bgdiscount')===true && this.state.bill_data.global_discount_type!==undefined) &&
                                      <tr>
                                          <td><span className="sp-normal">Discount on Bill <span style={{fontSize:'9px'}}>(Alt+D)</span><span id="disc_on_bill">
                                              {(this.state.bill_data.global_discount_type==='per') &&
                                                <span className="rs-sym" style={{fontWeight:'bold'}}> :{(this.state.bill_data.global_discount_amount>0)?'₹'+this.state.bill_data.global_discount_amount:''}</span>
                                              }
                                             </span></span></td>
                                          <td className="tbl-in-bx">
                                            <>
                                            <input type="text" className="form-control w-100px pull-right" defaultValue={this.state.bill_data.global_discount} id="global_discount_cb" onChange={(e)=>this.handleChangeDiscountAmount(e.target.value)}/>
                                            <div className="form-group round-blk">
                                              <div className="form-check">
                                                <input className="form-check-input" type="radio" name="global_discount_cb" id="discount-rs" value="rs" defaultChecked={(this.state.bill_data.global_discount_type==='rs')?true:false} onChange={()=>this.handleChangeDiscountOption('rs')}/>
                                                <label className="form-check-label rs-sym" htmlFor="discount-rs">
                                                  ₹
                                                </label>
                                              </div>
                                              <div className="form-check">
                                                <input className="form-check-input" type="radio" name="global_discount_cb" id="discount-per" value="per" defaultChecked={(this.state.bill_data.global_discount_type==='per')?true:false} onChange={()=>this.handleChangeDiscountOption('per')}/>
                                                <label className="form-check-label" htmlFor="discount-per">
                                                  %
                                                </label>
                                              </div>
                                              
                                            </div>
                                            </>
                                          </td>
                                      </tr>
                                  }
                                 
                                  {(this.is_access('billing-broundoff')===true && this.state.bill_data.round_off_opt!==undefined && this.state.bill_data.round_off_opt!=='') &&
                                      <tr>
                                          <td><span className="sp-normal">Round Off </span> <span style={{fontSize:'9px'}}>(Alt+R)</span></td>
                                          <td className="tbl-in-bx">
                                            <>
                                            <input type="text" className="form-control w-100px pull-right" defaultValue= {this.state.bill_data.round_off}  onChange={(e)=>this.handleChangeRoundOffAmount(e.target.value)} id="round_off_cb"/>
                                            <div className="form-group round-blk">
                                              <div className="form-check">
                                                <input className="form-check-input" type="radio" name="round_off" id="minus" value="-" defaultChecked={(this.state.bill_data.round_off_opt==='-')?true:false}  onChange={()=>this.handleChangeRoundOffOption('-')}/>
                                                <label className="form-check-label minus" htmlFor="minus">
                                                  -
                                                </label>
                                              </div>
                                              <div className="form-check">
                                                <input className="form-check-input" type="radio" name="round_off" id="plus" value="+" defaultChecked={(this.state.bill_data.round_off_opt==='+')?true:false} onChange={()=>this.handleChangeRoundOffOption('+')}/>
                                                <label className="form-check-label plus" htmlFor="plus">
                                                  +
                                                </label>
                                              </div>
                                              
                                            </div>
                                            </>
                                          </td>
                                      </tr>
                                  }
                                  
                                  {(this.state.bill_data.contact_info && this.state.bill_data.contact_info.is_tcs_enabled!==undefined && this.state.bill_data.contact_info.is_tcs_enabled===true) &&
                                      <tr>
                                          <td><span className="sp-normal">TCS</span>
                                          {(this.state.bill_data.tcs_amount>0) &&
                                              <span className="rs-sym" style={{fontWeight:'bold'}}> :{(this.state.bill_data.tcs_amount>0)?'₹'+this.state.bill_data.tcs_amount:''}</span>
                                          }
                                          </td>
                                          <td className="tbl-in-bx">
                                            <div className="input-group w-100px pull-right">
                                                <input type="text" className="form-control no-border-right" defaultValue={(this.state.bill_data.tcs_percent!==undefined && this.state.bill_data.tcs_percent>0)?this.state.bill_data.tcs_percent:0} onChange={(e)=>this.handleChangeTCSPercent(e.target.value)}/>
                                                <div className="input-group-append">
                                                  <span className="input-group-text pad-left-none">%</span>
                                                </div>
                                            </div>
                                          </td>
                                      </tr>
                                   }
                                    
                                   {/*    <tr>
                                        <td>
                                          <span className="sp-normal">Credit Note (if any) </span><span style={{fontSize:'9px'}}>(Alt+C)</span>
                                          <CreditDebitNoteLi data={{contact_id:1,contact_name:'General',billing_type:'New Sale'}}/>
                                          <br/>
                                          <span className="error" style={{fontSize: '12px'}}></span>
                                        </td>
                                        <td className="text-right tbl-in-bx b">
                                          <input type="text" className="form-control pull-right w-100px" data-target="redeem" id="ptype_note"/>
                                          <span style={{fontSize: '18px',
    float: 'right',
    marginTop: '6px',
    marginLeft: '10px',
    marginRight: '10px',cursor:'pointer'}}><FontAwesomeIcon icon={faArrowAltCircleRight} /></span>
                                          <input type="text" className="form-control hide" id="redeem_original_amount"/>
                                        </td>
                                      </tr> */}
                                  </tbody>
                                </table>
                                
                              
                                        <table width="100%" className="table inline-table bill-type-table">
                                            <tbody>
                                                <tr style={{lineHeight:'.5'}}>
                                                    <td className="text-left"><label className="btype-lbl">Bill Amount:</label></td>
                                                    <td className="bttitem text-right">
                                                    <label id="payment-mode-data" className="btype-lbl">₹{utils.currency_format(this.state.payments_data.data.bill_amount)}</label>
                                                    </td>
                                                </tr>
                                                {(this.state.payment_mode===1) &&
                                                <>
                                                  <tr style={{lineHeight:'.5'}}>
                                                      <td className="text-left">
                                                        <label className="btype-lbl">Amount Paid:</label>
                                                        <span className="error" style={{fontSize:'12px'}} id="amount-paid-desc"></span>
                                                      </td>
                                                      <td className="bttitem text-right">
                                                        <label id="total-paid-amount" className="btype-lbl">₹{utils.currency_format(this.state.payments_data.data.paid_amount)}</label>
                                                      </td>
                                                  </tr>
                                                  <tr style={{lineHeight:'.5'}}>
                                                      <td className="text-left"><label className="btype-lbl" id="return-lbl">Return:</label></td>
                                                      <td className="bttitem text-right">
                                                      <label id="total-return-amount" className="btype-lbl" style={{color:(this.state.payments_data.data.return_amount<0)?'red':(this.state.payments_data.data.return_amount>0)?'green':''}}>₹{utils.currency_format(this.state.payments_data.data.return_amount)}</label>
                                                      </td>
                                                  </tr>
                                                </>
                                                }
                                                {(this.state.payment_mode===2) &&
                                                <tr style={{lineHeight:'.5'}}>
                                                    <td className="text-left"><label className="btype-lbl" id="return-lbl">Bill Type:</label></td>
                                                    <td className="bttitem text-right">
                                                    <label id="total-return-amount" className="btype-lbl">Credit Sale</label>
                                                    </td>
                                                </tr>
                                                }
                                            </tbody>
                                        </table>
                                   
                                </div>
                               
                            </div>
                          
                        </div>
                        <div className="modal-footer justify-content-center" style={{padding:'10px 0px 0px'}}>
                            <div className="row">
                                
                                <div className="col-md-3 text-center" style={{paddingLeft:'5px'}}>
                                     
                                     <button type="button" onClick={this.handleClosePaymentPopup} className="btn btn-secondary"  style={{cursor:'pointer'}}>
                                      <p style={{padding:'0px',margin:'0px',marginTop:'-5px'}}>Close</p>
                                      <p style={{fontSize:'9px',padding:'0px',margin:'0px'}}>(Esc)</p>
                                    </button>
                                </div>
                                <div className="col-md-9">
                                  <div style={{display:'flex',float:'right'}}>
                                    <div>
                                        
                                        <button type="button" id="handlePrintFromPopup" className="btn btn-success"  style={{backgroundColor: '#218838',borderColor: '#1e7e34'}}>
                                          <p style={{padding:'0px',margin:'0px',marginTop:'-5px'}}>Print</p>
                                          <p style={{fontSize:'9px',padding:'0px',margin:'0px'}}>(Alt+P)</p>
                                        </button>
                                    </div>
                                    <div>
                            
                                    <button type="button" className="btn btn-success" style={{backgroundColor: '#218838',borderColor: '#1e7e34'}} id="handleSavePrint">
                                        <p style={{padding:'0px',margin:'0px',marginTop:'-5px'}}>Save + Print + New</p>
                                        <p style={{fontSize:'9px',padding:'0px',margin:'0px'}}>(Alt+N)</p>
                                    </button>
                                          
                                    </div>
                                    <div>
                                        {(this.state.is_saving_pos===false) &&
                                          <button type="button" className="btn btn-success"  style={{backgroundColor: '#218838',borderColor: '#1e7e34'}} id="handleSavePayment" onClick={()=>this.handleCreateBillingPOS('save')}>
                                            <p style={{padding:'0px',margin:'0px',marginTop:'-5px'}}>Save</p>
                                            <p style={{fontSize:'9px',padding:'0px',margin:'0px'}}>(Alt+S)</p>
                                          </button>
                                        }
                                        {(this.state.is_saving_pos===true) &&
                                          <button type="button" className="btn btn-success" style={{backgroundColor: '#218838',borderColor: '#1e7e34'}}><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                        }
                                    </div>
                                  </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        );
    }
}