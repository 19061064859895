import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav.js";
import Breadcrumbs from "./breadcrumbs.js";
import ContactsDropdown from "./contacts-dropdown.js";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faSearch,faPlus,faSpinner,faCalculator,faShoppingCart, faPlusCircle,faCheckCircle, faTimesCircle, faLeaf, faTimes, faLock, faCog, faMinus, faInfo, faInfoCircle, faMale, faUser, faCartPlus, faRemoveFormat, faEraser, faEnvelopeOpen, faFolderOpen, faListAlt, faExchangeAlt, faSave, faAngleDoubleRight  } from '@fortawesome/free-solid-svg-icons';
import 'animate.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ClosedFYButton from "./closed-fy-button.js";
import Autocomplete from "./autocomplete.js";
import ServiceLoader from './service-loader.js';
import PrintBill from './print-bill.js';
import HoldBillPopup from './hold-bill-list-popup.js';
import utils from '../utils.js';
import { ToastContainer, toast } from 'react-toastify';
import '../../node_modules/react-toastify/dist/ReactToastify.css';
import DrawerLink from "./open-drawer-link.js";
import ProductUnits from "./product-units.js";
import BillingPOSPaymentPopup from "./billing-pos-payment-popup.js";
import BillingPOSOpenCounter from "./billing-pos-open-counter.js";
let {enc_key,app_api,user_info, gst_options} = require('../library.js');
var CryptoJS = require("crypto-js");
let db;
if(user_info){
  let version = localStorage.getItem('IdbVersion');
  version = parseInt(version);
  let dbReq = window.indexedDB.open('BaazarERP', version);
  dbReq.onupgradeneeded = function(event) {
    db = event.target.result;
  }
  dbReq.onsuccess = function(event) {
    db = event.target.result;
  }
}
let _this;
export default class BillingPOS extends Component {
    constructor() {
        super();
        this.state = {
          bill_data :{
            assisted_by : '',  
            walkin_delivery : 'walkin', 
            delivery_man : '', 
            billing_type : 'sale',
            bill_sequence : '',
            contact_info : '',
            billingDate:  new Date(),
            list_items : [],
            exchange_items : [],
            other_charges : [],
            total_quantity : 0,
            total_amount : 0,
            exchange_amount : 0,
            other_charge_amount : 0,
            total_unit : 0,
            total_discount : 0,
            total_exchange_quantity : 0,
            round_off : 0,
            round_off_opt : '+',
            global_discount_type : '',
            global_discount : 0,
            global_discount_type : '',
            tcs_amount : 0,
            tcs_percent : 0,
            payments_data : {bill_types:[],data:{bill_amount:0,paid_amount:0,return_amount:0}},
          },
          payment_modes:[],
          staff_options:[],
          is_item_selected : true,
          selected_customer : {},
          child_inventory_items : [],
          is_show_child_popup : false,
          is_show_new_inventory : false,
          is_show_exchange_item : false,
          is_show_other_charges : false,
          is_show_pay_popup : false,
          is_saving_pos : false,
          is_show_contact : true,
          default_payment_mode_for_sale : 1,
          tcs_percent : 0,
          global_discount_type : '',
          global_discount : 0,
          current_sequence : '',
          customer_last_purchase_info : {}
        };
        this.sequence = [];
    }
    billingDate = date => {
      let bill_data = this.state.bill_data;
      bill_data.billingDate = date;
      this.setState({bill_data:bill_data});
      this.setState({
        billingDate: date
      });
    }; 
    handleChangeStaff = (e) =>{
      let assisted_by = e.currentTarget.value;
      let bill_data = this.state.bill_data;
      bill_data.assisted_by = assisted_by;
      this.setState({bill_data:bill_data});
    }
    componentDidMount = () =>{
      this.handleManageFirstLoad();
      document.addEventListener("keydown", this.handleBillingPOSShortCut);
    }
    componentWillUnmount(){
      document.removeEventListener("keydown", this.handleBillingPOSShortCut);
    }
    handleBillingPOSShortCut = (e) =>{
      if(this.state.is_show_pay_popup===true){
        return false;
      }
      let fkey_arr = [112,113];//,114,115,116,117,118,119,120,121,122,123 - more f keys
      let key_pressed = e.which;
      let keyIdx = fkey_arr.indexOf(key_pressed);
      if(keyIdx>=0){
        e.preventDefault();
        if(keyIdx===0){
          if(document.getElementById('iitem-box')){
            document.getElementById('iitem-box').focus();
          }
        }else if(keyIdx===1){
          if(document.getElementById('contact_box')){
            document.getElementById('contact_box').focus();
          }
        }
      }
    }
    handleManageFirstLoad = ()=>{
      this.handleGetBillingSequence();
      this.handleGetStaffs();
      this.handleGetSettings();
      this.handleGetPaymentModes();
    }
    handleGetBillingSequence = ()=>{
      let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/get_sequence_number';
        axios.post(api, body)
          .then(function (response) {
              let resp = response.data;
              let sales_sequence = resp.sales_sequence;
              let sales_order_sequence = resp.sales_order_sequence;
              
              sales_sequence = "#SB"+sales_sequence;
              this.sequence['sale'] = sales_sequence;
              sales_order_sequence = "#SO"+sales_order_sequence;
              this.sequence['order'] = sales_order_sequence;
              let bill_data = this.state.bill_data;
              bill_data.bill_sequence = sales_sequence;
              this.setState({bill_data:bill_data});
              this.setState({current_sequence:sales_sequence});
              
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                }
            }
        })
    }
    handleGetPaymentModes = () =>{
      let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
           }
        let api = app_api+'/get_payment_mode';
        axios.post(api, body)
          .then(function (response) {
            let cursor = response.data;
            let PaymentModes = [];
            cursor.map( (value, key) => {
                let id = value.payment_mode_id;
                let payment_mode = value.payment_mode;
                if(payment_mode!=='Credit'){
                  let pmmode = {id:id,payment_mode:payment_mode,status:value.status,direct_pay:value.direct_pay,is_cheque:value.is_cheque,account_type:value.account_type};
                  PaymentModes.push(pmmode)
                }
            });
            this.setState({ 'payment_modes': PaymentModes }); 
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                    
                }
            }
        })
    }
    handleGetStaffs = () =>{
      let pmbody = {
        store_name : user_info.store_name,
        branch_id : user_info.login_id,
        user_id : user_info.user_id,
        contact_type : 4,
        uuid : utils.uuid(),
        role_id : user_info.access_level,
        token : user_info.token,
        _schema : user_info._schema,
        }
      let pmapi = app_api+'/get_contact';
      axios.post(pmapi, pmbody)
        .then(function (response) {
          let cursor = response.data;
          let ContactOptions = [];  
          cursor.map( (value, key) => {
            let id = value.contact_id;
            ContactOptions[id]={id:id,name:value.name};
          });
          this.setState({ 'staff_options': ContactOptions }); 
      }.bind(this)).catch(function(res){
          if(res.response){
              if(res.response.data){
                  
              }
          }
      })
    }
    handleGetSettings = () =>{
      let ssend_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          access_level : user_info.access_level,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
          keys:"'allow_negative_inventory','sale_field_hide','on_off_msp','alias_barcode_while_billing','sales_sp_disc_type','is_item_commission','item_commission','other_charges','default_payment_mode_for_sale','tcs_percent','global_discount_type'",
      }
      let sapi = app_api+'/get_settings';
      axios.post(sapi, ssend_data)
        .then(function (response) {
          let resp = response.data;
          let bill_data = this.state.bill_data;
          if(resp.default_payment_mode_for_sale){
            let pm = resp.default_payment_mode_for_sale;
            this.setState({default_payment_mode_for_sale:pm});
          }
          if(resp.tcs_percent){
            let pm = resp.tcs_percent;
            this.setState({tcs_percent:pm});
          }
          if(resp.global_discount_type){
            let pm = resp.global_discount_type;
            this.setState({global_discount_type:pm});
            bill_data.global_discount_type = pm;
          }
          if(resp.is_item_commission!==undefined){
            let is_item_commission = resp.is_item_commission;
            this.setState({is_item_commission:is_item_commission});
            bill_data.is_item_commission = is_item_commission;
          }
          if(resp.other_charges!==undefined){
            let other_charges = resp.other_charges;
            bill_data.other_charges = other_charges;
            this.setState({bill_data:bill_data});
          }
          
          if(resp.item_commission!==undefined){
            let item_commission = resp.item_commission;
            this.setState({item_commission:item_commission});
          }
          if(resp.allow_negative_inventory!==undefined){
            let ani = resp.allow_negative_inventory;
            this.setState({allow_negative_inventory:ani});
          }
          
          if(resp.on_off_msp!==undefined){
            let on_off_msp = resp.on_off_msp;
            this.setState({on_off_msp:on_off_msp});
          }
          if(resp.alias_barcode_while_billing!==undefined){
            let alias_barcode_while_billing = resp.alias_barcode_while_billing;
            this.setState({alias_barcode_while_billing:alias_barcode_while_billing});
          }
          if(resp.sale_field_hide){
            let ps = resp.sale_field_hide;
            this.setState({sale_field_hide:ps});
            bill_data.sale_field_hide = ps;
          }
          this.setState({bill_data:bill_data});
          if(resp.sales_sp_disc_type){
            let ps = resp.sales_sp_disc_type;
            this.setState({discount_type:ps});
          }
        
      }.bind(this)).catch(function(res){
          
      })
    }
    handleChangeBillingType = (bill_type) =>{
      let bill_data = this.state.bill_data;
      bill_data.billing_type = bill_type;
      let seq_number = this.sequence[bill_type];
      this.setState({current_sequence:seq_number});
      bill_data.bill_sequence = seq_number;
      this.setState({bill_data:bill_data});
    }

    handleSelectItem = (item) =>{
      this.setState({is_item_selected:false});
      let _this = this;
      setTimeout(() => {
        this.setState({is_item_selected:true});
      }, 10);
      console.log(item);
      
      if(item.is_more_barcode>0){
        let _this = this;
        setTimeout(() => {
          _this.setState({child_inventory_items:item.child_barcode_info,is_show_child_popup:true});
        }, 100);
        return false;
      }
      if(item.is_new_item===undefined){
        item.quantity = 1;
      }
      let dis = item.sp_discount;
      if(dis===''){
        dis = 0;
      }
      item.item_id = 0;
      item.discount = dis;
      item.discount_type = this.state.discount_type;
      item.item_commission = 0;
      let bill_data = this.state.bill_data;
      let list_items = bill_data.list_items;
      let inventory_id = item.inventory_id;
      let is_exist = false;
      let item_index_tmp = '';
      for (let index = 0; index < list_items.length; index++) {
        const element = list_items[index];
        if(parseInt(element.inventory_id)===parseInt(inventory_id)){
          is_exist = true;
          item_index_tmp = index;
          break;
        }
      }
      
      if(is_exist===true){
        list_items[item_index_tmp].quantity = parseFloat(list_items[item_index_tmp].quantity)+1;
        list_items[item_index_tmp] = this.handleItemCalculation(list_items[item_index_tmp]);
      }else{
        item = this.handleItemCalculation(item);
        list_items.push(item);
      }
      bill_data.list_items = list_items;
      this.handleFinalCalculation(list_items);
      this.setState({bill_data:bill_data});
    }
    handleChangeDiscountType = (type,index,target='sale') =>{
      let bill_data = this.state.bill_data;
      if(target==='sale'){
        let list_items = bill_data.list_items;
        list_items[index].discount_type = type;
        list_items[index] = this.handleItemCalculation(list_items[index]);
        this.handleFinalCalculation(list_items);
        bill_data.list_items = list_items;
        this.setState({bill_data:bill_data});
      }else if(target==='exchange'){
        let exchange_items = bill_data.exchange_items;
        exchange_items[index].discount_type = type;
        exchange_items[index] = this.handleItemCalculation(exchange_items[index],'exchange');
        this.handleFinalCalculation(exchange_items);
        bill_data.exchange_items = exchange_items;
        this.setState({bill_data:bill_data});
      }
    }
    handleItemCalculation = (item,cal_type='sale') =>{
      
      let quantity = item.quantity;
      if(quantity===""){
        quantity = 0;
      }
      
      quantity = parseFloat(quantity);
      
      let discount = item.discount;
      let discount_type = item.discount_type;
      let sp_tax = item.sp_tax;
      let sp_tax_type = item.sp_tax_type;
      let selling_price = item.selling_price;
      selling_price = parseFloat(selling_price);

      let item_discount = 0; 
      if(discount!=='' && discount!==null && parseFloat(discount)){
        if(discount_type==='per'){
          let cal_disc = (selling_price * discount)/100;
          item_discount = utils.floating_point(cal_disc);
        }else if(discount_type==='rs'){
          item_discount = discount;
        }
      }

      let gst_amount = 0 ;
      let sp_amount = selling_price;
      
      item.price = sp_amount;
      
      if(sp_tax!=="" && sp_tax!==null && parseFloat(sp_tax)>0){
        sp_tax = parseFloat(sp_tax);
        if(sp_tax_type==='including'){
          let GST = parseFloat(selling_price) - (parseFloat(selling_price) * (100 / (100 + sp_tax ) ) );
          GST = selling_price-GST;
          GST = parseFloat(utils.floating_point(GST));
          let gross_amount = GST-item_discount;
          let TOTAL_GST = selling_price-GST;
          gst_amount = TOTAL_GST;
          gst_amount = (quantity*gst_amount);
          gst_amount = (gross_amount*sp_tax/100)*quantity;
          item.price = utils.floating_point(gross_amount);
          sp_amount = (quantity*gross_amount)+gst_amount;
        }else if(sp_tax_type==='excluding'){
          gst_amount = (sp_amount*sp_tax)/100;
          sp_amount   = sp_amount * quantity;
          gst_amount = gst_amount*quantity;
        }
      }else{
        sp_amount = sp_amount - item_discount;
        sp_amount   = sp_amount * quantity;
      }
      
      let company_state = user_info.state;
      let customer_state = (this.state.selected_customer.state!==undefined)?this.state.selected_customer.state:company_state;
      item.cgst_amount = 0;
      item.sgst_amount = 0;
      item.igst_amount = 0;
      
      if(company_state===customer_state){
        if(gst_amount>0){
          let cal_gst = gst_amount/2;
          item.cgst_amount = utils.floating_point(cal_gst);
          item.sgst_amount = utils.floating_point(cal_gst);
        }
      }else{
        if(gst_amount>0){
          item.igst_amount = utils.floating_point(gst_amount);
        }
      }
      
      item.discount_amount = item_discount;
      item.gst_amount = utils.floating_point(gst_amount);
      let item_total = sp_amount;
      item.total = item_total;
      if(this.state.is_item_commission===true && this.state.item_commission>0){
        let item_commission = (item_total * this.state.item_commission)/100;
        item.item_commission = utils.floating_point(item_commission);
      }
      return item;
    }
    hanldeExchangeFinalCalculation = (list_items)=>{
      let total_exchange_quantity = 0;
      let total_exchange_amount = 0;
      let exchange_sub_total = 0;
      let total_exchange_discount = 0;
      let exchange_gst_amount = 0;
      let exchange_igst_amount = 0;
      let exchange_sgst_amount = 0;
      let exchange_cgst_amount = 0;
      for (let index = 0; index < list_items.length; index++) {
        const element = list_items[index];
        let quantity = element.quantity;
        total_exchange_quantity = total_exchange_quantity + parseFloat(quantity);
        let total = element.total;
        let price = element.price;
        let discount_amount = element.discount_amount;
        total_exchange_amount = total_exchange_amount + parseFloat(total);
        exchange_sub_total = exchange_sub_total + parseFloat(price);
        total_exchange_discount = total_exchange_discount + parseFloat(discount_amount);
        
        exchange_igst_amount = exchange_igst_amount + parseFloat(element.exchange_igst_amount);
        exchange_cgst_amount = exchange_cgst_amount + parseFloat(element.exchange_cgst_amount);
        exchange_sgst_amount = exchange_sgst_amount + parseFloat(element.exchange_sgst_amount);
        exchange_gst_amount = exchange_gst_amount + parseFloat(element.gst_amount);
      }
      return {total_exchange_amount:total_exchange_amount,total_exchange_quantity:total_exchange_quantity};
    }
    handleFinalCalculation = (items)=>{
      let bill_data = this.state.bill_data;
      let list_items = bill_data.list_items;
      let exchange_items = bill_data.exchange_items;
      let total_quantity = 0;
      let total_amount = 0;
      let sub_total = 0;
      let total_discount = 0;
      let commission = 0;
      let gst_amount = 0;
      let igst_amount = 0;
      let sgst_amount = 0;
      let cgst_amount = 0;
      for (let index = 0; index < list_items.length; index++) {
        const element = list_items[index];
        let quantity = element.quantity;
        total_quantity = total_quantity + parseFloat(quantity);
        let total = element.total;
        let price = element.price;
        let item_commission = element.item_commission;
        let discount_amount = element.discount_amount;
        total_amount = total_amount + parseFloat(total);
        sub_total = sub_total + parseFloat(price);
        total_discount = total_discount + parseFloat(discount_amount);
        commission = commission + parseFloat(item_commission);

        igst_amount = igst_amount + parseFloat(element.igst_amount);
        cgst_amount = cgst_amount + parseFloat(element.cgst_amount);
        sgst_amount = sgst_amount + parseFloat(element.sgst_amount);

        gst_amount = gst_amount + parseFloat(element.gst_amount);
      }
      let global_discount = this.state.global_discount;
      let discount_opt = this.state.global_discount_type;
      let tcs_percent = 0;
      if(this.state.bill_data.contact_info.tcs_percent!==undefined && this.state.bill_data.contact_info.tcs_percent!=='' && this.state.bill_data.contact_info.tcs_percent>0){
        tcs_percent = this.state.bill_data.contact_info.tcs_percent;
      }
      let total_global_discount = 0;
      if(discount_opt!=='' && discount_opt!==null && global_discount!=='' && global_discount!==null && parseFloat(global_discount)>0){
        for (let index = 0; index < list_items.length; index++) {
          let item = list_items[index];
          let quantity = item.quantity;
          if(quantity===""){
            quantity = 0;
          }
          
          quantity = parseFloat(quantity);
          
          let discount = item.discount;
          let discount_type = item.discount_type;
          let sp_tax = item.sp_tax;
          let sp_tax_type = item.sp_tax_type;
          let selling_price = item.selling_price;
          selling_price = parseFloat(selling_price);

          let item_discount = 0; 
          if(discount!=='' && discount!==null && parseFloat(discount)){
            if(discount_type==='per'){
              let cal_disc = (selling_price * discount)/100;
              item_discount = utils.floating_point(cal_disc);
            }else if(discount_type==='rs'){
              item_discount = discount;
            }
          }
          let glob_discount = 0;
          if(discount_opt==="per"){
            let dis = ((selling_price * quantity)*global_discount)/100;
            glob_discount = dis;
          }else if(discount_opt==="rs"){
              let gd = global_discount/sub_total*100;
              let dis = ((selling_price * quantity)*gd)/100;
              glob_discount = dis;
          }
          total_global_discount = total_global_discount + glob_discount;
          let total_discount = item_discount + glob_discount;
          let gst_amount = 0 ;
          let sp_amount = selling_price;
          
          item.price = sp_amount;
          
          if(sp_tax!=="" && sp_tax!==null && parseFloat(sp_tax)>0){
            sp_tax = parseFloat(sp_tax);
            if(sp_tax_type==='including'){
              let GST = parseFloat(selling_price) - (parseFloat(selling_price) * (100 / (100 + sp_tax ) ) );
              GST = selling_price-GST;
              GST = parseFloat(utils.floating_point(GST));
              let gross_amount = GST-total_discount;
              let TOTAL_GST = selling_price-GST;
              gst_amount = TOTAL_GST;
              gst_amount = (quantity*gst_amount);
              gst_amount = (gross_amount*sp_tax/100)*quantity;
              item.price = utils.floating_point(gross_amount);
              sp_amount = (quantity*gross_amount)+gst_amount;
            }else if(sp_tax_type==='excluding'){
              gst_amount = (sp_amount*sp_tax)/100;
              sp_amount   = sp_amount * quantity;
              gst_amount = gst_amount*quantity;
            }
          }else{
            sp_amount = sp_amount - total_discount;
            sp_amount   = sp_amount * quantity;
          }
          
          let company_state = user_info.state;
          let customer_state = (this.state.selected_customer.state!==undefined)?this.state.selected_customer.state:company_state;
          item.cgst_amount = 0;
          item.sgst_amount = 0;
          item.igst_amount = 0;
          
          if(company_state===customer_state){
            if(gst_amount>0){
              let cal_gst = gst_amount/2;
              item.cgst_amount = utils.floating_point(cal_gst);
              item.sgst_amount = utils.floating_point(cal_gst);
            }
          }else{
            if(gst_amount>0){
              item.igst_amount = utils.floating_point(gst_amount);
            }
          }
          
          item.discount_amount = item_discount;
          item.gst_amount = utils.floating_point(gst_amount);
          let item_total = sp_amount;
          item.total = item_total;
          if(this.state.is_item_commission===true && this.state.item_commission>0){
            let item_commission = (item_total * this.state.item_commission)/100;
            item.item_commission = utils.floating_point(item_commission);
          }
          list_items[index] = item;
        }
        bill_data.global_discount_type = discount_opt;
        bill_data.global_discount_amount = total_global_discount;
        
        bill_data.list_items = list_items;
        total_quantity = 0;
        total_amount = 0;
        sub_total = 0;
        total_discount = 0;
        commission = 0;
        gst_amount = 0;
        igst_amount = 0;
        sgst_amount = 0;
        cgst_amount = 0;
        for (let index = 0; index < list_items.length; index++) {
          const element = list_items[index];
          let quantity = element.quantity;
          total_quantity = total_quantity + parseFloat(quantity);
          let total = element.total;
          let price = element.price;
          let item_commission = element.item_commission;
          let discount_amount = element.discount_amount;
          total_amount = total_amount + parseFloat(total);
          sub_total = sub_total + parseFloat(price);
          total_discount = total_discount + parseFloat(discount_amount);
          commission = commission + parseFloat(item_commission);

          igst_amount = igst_amount + parseFloat(element.igst_amount);
          cgst_amount = cgst_amount + parseFloat(element.cgst_amount);
          sgst_amount = sgst_amount + parseFloat(element.sgst_amount);

          gst_amount = gst_amount + parseFloat(element.gst_amount);
        }
      }
      bill_data.global_discount_type = discount_opt;
      bill_data.global_discount = global_discount;
      bill_data.global_discount_amount = total_global_discount;
      let exchange_final_data = this.hanldeExchangeFinalCalculation(exchange_items);
      let exchange_amount = exchange_final_data.total_exchange_amount;
      let total_exchange_quantity = exchange_final_data.total_exchange_quantity;
      let sale_amount = total_amount;
      total_amount = total_amount - exchange_amount;
      total_amount = total_amount + this.state.bill_data.other_charge_amount;
      if(this.state.bill_data.contact_info.is_tcs_enabled===true){
        let tcs_percent = this.state.bill_data.tcs_percent;
        if(tcs_percent>0){
          tcs_percent = parseFloat(tcs_percent);
          let cal_tcs = (total_amount*tcs_percent)/100;
          bill_data.tcs_amount = utils.floating_point(cal_tcs);
          total_amount = total_amount + cal_tcs;
        }
      }
    
      let round_off = Math.round(total_amount)-total_amount;
      let round_off_opt = this.state.bill_data.round_off_opt;
      if(round_off>=0){
        round_off = Math.ceil(total_amount)-total_amount;
        round_off = utils.currency_format(round_off);
        round_off_opt = '+';
      }else{
        round_off = total_amount-Math.floor(total_amount);
        round_off = utils.currency_format(round_off);
        round_off_opt = '-';
      }
      let grand_total = total_amount;
      
      if(round_off_opt==='-'){
        grand_total = parseFloat(grand_total)-parseFloat(round_off);
      }else if(round_off_opt==='+'){
        grand_total = parseFloat(grand_total)+parseFloat(round_off);
      }
      
      bill_data.total_amount = sale_amount;
      bill_data.exchange_amount = exchange_amount;
      bill_data.total_exchange_quantity = total_exchange_quantity;
      bill_data.grand_total = utils.currency_format(grand_total);
      bill_data.round_off = round_off;
      bill_data.round_off_opt = round_off_opt;
      bill_data.total_unit = list_items.length;
      bill_data.total_quantity = total_quantity;
      bill_data.sub_total = sub_total;
      bill_data.total_discount = total_discount;
      bill_data.commission = commission;
      bill_data.gst_amount = gst_amount;
      bill_data.igst_amount = igst_amount;
      bill_data.cgst_amount = cgst_amount;
      bill_data.sgst_amount = sgst_amount;
      this.setState({bill_data:bill_data});
    }
    handleChangeQuantityOnClick = (type,index,target='sale')=>{
      let bill_data = this.state.bill_data;
      if (target==='exchange') {
        let exchange_items = bill_data.exchange_items;
        let quantity = parseFloat(exchange_items[index].quantity);
        if(type==='+'){
          quantity = quantity+1;
        }else{
          quantity = quantity-1;
        }
        if(quantity>0){
          exchange_items[index].quantity = quantity;
          exchange_items[index] = this.handleItemCalculation(exchange_items[index],'exchange');
          this.handleFinalCalculation(exchange_items);
          bill_data.exchange_items = exchange_items;
          
          this.setState({bill_data:bill_data});
        }
      }else{
        let list_items = bill_data.list_items;
        let quantity = parseFloat(list_items[index].quantity);
        if(type==='+'){
          quantity = quantity+1;
        }else{
          quantity = quantity-1;
        }
        if(quantity>0){
          list_items[index].quantity = quantity;
          list_items[index] = this.handleItemCalculation(list_items[index]);
          this.handleFinalCalculation(list_items);
          bill_data.list_items = list_items;
          
          this.setState({bill_data:bill_data});
        }
      }
    }
    handleChangeItemValue = (type,value,index,target='sale') =>{
      let bill_data = this.state.bill_data;
      if(target==='sale'){
        let list_items = bill_data.list_items;
        let item  = list_items[index];
        
        if(type==='quantity'){
          item.quantity = value;
        }else if(type==='discount'){
          item.discount = value;
        }else if(type==='commission'){
          item.item_commission = value;
        }
        if(value!==''){
          list_items[index] = this.handleItemCalculation(item);
        }
        this.handleFinalCalculation(list_items);
        bill_data.list_items = list_items;
        
        this.setState({bill_data:bill_data});
      }else if(target==='exchange'){
        let exchange_items = bill_data.exchange_items;
        let item  = exchange_items[index];
        
        if(type==='quantity'){
          item.quantity = value;
        }else if(type==='discount'){
          item.discount = value;
        }else if(type==='commission'){
          item.item_commission = value;
        }
        if(value!==''){
          exchange_items[index] = this.handleItemCalculation(item,'exchange');
        }
        this.handleFinalCalculation(exchange_items);
        bill_data.exchange_items = exchange_items;
        
        this.setState({bill_data:bill_data});
      }
    }

    handleRemoveItem = (index,target='sale') =>{
      let bill_data = this.state.bill_data;
      if(target==='sale'){
        let list_items = bill_data.list_items;
        list_items.splice(index,1);
        this.handleFinalCalculation(list_items);
        bill_data.list_items = list_items;
        this.setState({bill_data:bill_data});
      }else if(target==='exchange'){
        let exchange_items = bill_data.exchange_items;
        exchange_items.splice(index,1);
        this.handleFinalCalculation(exchange_items);
        bill_data.exchange_items = exchange_items;
        this.setState({bill_data:bill_data});
      }
    }
    handleSelectCustomer = (customer_info) =>{
      let bill_data = this.state.bill_data;
      bill_data.contact_info = customer_info;
      
      if(customer_info.discount!==undefined && customer_info.discount!==''){
        this.setState({global_discount:customer_info.discount});
      }
      if(customer_info.tcs_percent!==undefined && customer_info.tcs_percent!==''){
        bill_data.tcs_percent = customer_info.tcs_percent;
      }
      this.handleFinalCalculation([]);
      this.setState({bill_data:bill_data});
      
      let body = {};
        body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
              contact_id : customer_info.contact_id,
           }
        let api = app_api+'/get_contact_billing_pos_info';
        axios.post(api, body)
          .then(function (response) {
              let resp = response.data.response;
              this.setState({customer_last_purchase_info:resp});
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                }
            }
        })
    }
    handleCloseInvetoryListPopup = () =>{
      this.setState({is_show_child_popup:false});
    }
    handleNewInventoryPopup = () =>{
      this.setState({is_show_new_inventory:true});
    }
    handleCloseNewInventoryItem = () =>{
      this.setState({is_show_new_inventory:false});
    }
    handleResetBill = () =>{
      
      let bill_data_tmp ={
        bill_data :{
          assisted_by : '',  
          walkin_delivery : 'walkin', 
          delivery_man : '', 
          billing_type : 'sale',
          bill_sequence : '',
          contact_info : '',
          billingDate:  new Date(),
          list_items : [],
          exchange_items : [],
          other_charges : [],
          total_quantity : 0,
          total_amount : 0,
          exchange_amount : 0,
          other_charge_amount : 0,
          total_unit : 0,
          total_discount : 0,
          total_exchange_quantity : 0,
          round_off : 0,
          round_off_opt : '+',
          global_discount_type : '',
          global_discount : 0,
          global_discount_type : '',
          tcs_amount : 0,
          tcs_percent : 0,
        },
        payment_modes:[],
        staff_options:[],
        is_item_selected : true,
        selected_customer : {},
        child_inventory_items : [],
        is_show_child_popup : false,
        is_show_new_inventory : false,
        is_show_exchange_item : false,
        is_show_other_charges : false,
        is_show_pay_popup : false,
        is_show_contact : true,
        default_payment_mode_for_sale : 1,
        tcs_percent : 0,
        global_discount_type : '',
        global_discount : 0,
        current_sequence : '',
      };
      this.setState(bill_data_tmp);
      let _this = this;
      this.setState({is_show_contact:false});
      setTimeout(() => {
        _this.setState({is_show_contact:true});
      }, 50);
      this.handleManageFirstLoad();
    }
    handleSelectExchangeItem = (item) =>{
    
      this.setState({is_select_exchange_item:false});
      let _this = this;
      setTimeout(() => {
        this.setState({is_select_exchange_item:true});
      }, 10);
      console.log(item);
      
      if(item.is_more_barcode>0){
        let _this = this;
        setTimeout(() => {
          _this.setState({child_inventory_items:item.child_barcode_info,is_show_child_popup:true});
        }, 100);
        return false;
      }
      if(item.is_new_item===undefined){
        item.quantity = 1;
      }
      let dis = item.sp_discount;
      if(dis===''){
        dis = 0;
      }
      item.discount = dis;
      item.discount_type = this.state.discount_type;
      item.item_commission = 0;
      let bill_data = this.state.bill_data;
      let exchange_items = bill_data.exchange_items;
      let inventory_id = item.inventory_id;
      let is_exist = false;
      let item_index_tmp = '';
      for (let index = 0; index < exchange_items.length; index++) {
        const element = exchange_items[index];
        if(parseInt(element.inventory_id)===parseInt(inventory_id)){
          is_exist = true;
          item_index_tmp = index;
          break;
        }
      }
      
      if(is_exist===true){
        exchange_items[item_index_tmp].quantity = parseFloat(exchange_items[item_index_tmp].quantity)+1;
        exchange_items[item_index_tmp] = this.handleItemCalculation(exchange_items[item_index_tmp],'exchange');
      }else{
        item = this.handleItemCalculation(item,'exchange');
        exchange_items.push(item);
      }
      bill_data.exchange_items = exchange_items;
      this.handleFinalCalculation(exchange_items);
      this.setState({bill_data:bill_data});
    
    }
    handleExchangeItem = ()=>{
      this.setState({is_show_exchange_item:true});
      this.setState({is_item_selected:false});
    }
    handleCloseExchangePopup = ()=>{
      this.setState({is_show_exchange_item:false});
      this.setState({is_item_selected:true});
    }
    handleOpenOtherCharges = ()=>{
      this.setState({is_show_other_charges:true});
    }
    handleCloseOtherCharges = ()=>{
      this.setState({is_show_other_charges:false});
    }
    hanldeSetOtherCharges = (oc)=>{
      let bill_data = this.state.bill_data;
      let tot_oc =0;
      for (let index = 0; index < oc.length; index++) {
        const element = oc[index];
        let total_amount = element.total_amount;
        let is_selected = element.is_selected;
        if(is_selected===true){
          tot_oc = tot_oc+parseFloat(total_amount);
        }
      }
      bill_data.other_charge_amount = tot_oc;
      bill_data.other_charges = oc;
      this.setState({bill_data:bill_data});
      this.handleFinalCalculation([]);
    }
    handleOpenPaymentPopup = ()=>{
      this.setState({is_show_pay_popup:true});
    }
    handleClosePaymentPopup = ()=>{
      this.setState({is_show_pay_popup:false});
    }
    handleReadjustBillingData = (bill_data)=>{
      this.setState({bill_data:bill_data});
      this.handleFinalCalculation([]);
    }
    handleHoldBill = () =>{
      this.setState({is_saving_pos:true});
      let payments_data = this.state.payments_data;
      let send_data = this.state.bill_data;
      send_data.store_name = user_info.store_name;
      send_data.user_id = user_info.user_id;
      send_data.uuid = utils.uuid();
      send_data.role_id = user_info.access_level;
      send_data.token = user_info.token;
      send_data._schema = user_info._schema;
      send_data.branch_id  = user_info.login_id;
      send_data.bill_status  = 2;
      send_data.payment_mode  = 2;
      send_data.payments_data  = payments_data;
      send_data.send_type  = 'save';
      let api = app_api+'/billing_pos';
        axios.post(api, send_data)
          .then(function (response) {
            this.setState({is_saving_pos:false});
             let resp = response.data;
             let id = resp.id;
        }.bind(this)).catch(function(res){
         
        })
    }
    handleShowHoldBill = () =>{
      if(document.getElementById('handleShowHoldBill')){
        document.getElementById('handleShowHoldBill').click();
      }
    }
    render() {

        return (
            <div className="d-flex billing-pos" id="wrapper">
              <Leftnav />
              
              <div id="page-content-wrapper">
                 <nav className="navbar border-bottom">
                  <div className="row" style={{marginTop:'10px'}}>
                    <div className="col-md-1 col-sm-1" style={{textAlign:'center',marginTop:'5px'}}>	
                       <FontAwesomeIcon icon={faLock}/>
                    </div>	
                    <div className="col-md-2">	
                      {(this.state.staff_options && this.state.staff_options.length>0) &&
                    <select className="form-control" tabindex={2} id="staff-dropdown" value={this.state.bill_data.assisted_by} onChange={this.handleChangeStaff} target="staff">
                              <option value="">Salesman</option>
                              {(this.state.staff_options.length > 0) ?this.state.staff_options.map( (value, index) => {
                               return (
                                  <option value={value.id} key={index}>{value.name}</option>
                                )
                              }) : ''}
                          </select>
                      }
                    </div>	
                    <div className="col-md-6">	
                       
                    </div>	
                    <div className="col-md-1">	
                       <select className="form-control" value={this.state.bill_data.billing_type} onChange={(e)=>this.handleChangeBillingType(e.target.value)}>
                        <option value={'sale'}>S</option>
                        <option value={'order'}>O</option>
                       </select>
                    </div>	
                    <div className="col-md-1">	
                      {(this.state.bill_data.billing_type==='sale') &&
                        <>
                        {(this.state.current_sequence!=='') &&
                          <h4 style={{background: '#0cbc0c', color: '#fff', textAlign: 'center',paddingTop: '9px',borderRadius:'5px',fontSize:'16px',height:'100%'}}>{this.state.current_sequence}</h4>
                        }
                        {(this.state.current_sequence==='') &&
                          <h4 style={{background: '#0cbc0c', color: '#fff', textAlign: 'center',paddingTop: '9px',borderRadius:'5px',fontSize:'16px',height:'100%'}}><FontAwesomeIcon icon={faSpinner} pulse/></h4>
                        }
                        </>
                      }
                      {(this.state.bill_data.billing_type==='order') &&
                      <>
                      {(this.state.current_sequence!=='') &&
                       <h4 style={{background: 'red', color: '#fff', textAlign: 'center',paddingTop: '9px',borderRadius:'5px',fontSize:'16px',height:'100%'}}>{this.state.current_sequence}</h4>
                      }
                      {(this.state.current_sequence==='') &&
                          <h4 style={{background: 'red', color: '#fff', textAlign: 'center',paddingTop: '9px',borderRadius:'5px',fontSize:'16px',height:'100%'}}><FontAwesomeIcon icon={faSpinner} pulse/></h4>
                        }
                      </>
                      }
                    </div>
                    <div className="col-md-1" style={{textAlign:'center',cursor:'pointer',marginTop:'5px'}}>
                      <DrawerLink target="pos_settings" name={<FontAwesomeIcon icon={faCog} style={{color:'#686666'}}/>} page_title='Settings' width='95%' info={''}/>
                    </div>
                  </div>
                </nav>
                <div className="container-fluid mobile-view" style={{padding:'15px'}}>
                <ServiceLoader show={this.state.show_loader}/>
                  <div className="content-area">
                     <div className="row">
                      <div className="col-md-3">
                        {(this.state.is_item_selected===true)  &&
                        <Autocomplete
                            options={[]}
                            data_target="billing"
                            title="Start typing barcode or name..."  
                            id={"iitem-name"}
                            className="auto-comp-opt"
                            data_id={0}
                            active_tab={'New Sale'}
                            input_id={"iitem-box"}
                            item_info={[]}
                            target="iitem-box"
                            tabindex={1}
                            icon_label="F1"
                            is_auto_focus = {true}
                            handleSelectItem = {this.handleSelectItem}
                        />
                        }
                        {(this.state.is_item_selected===false) &&
                          <div className="search">
                            <div className="input-group">
                              <input type="text" className="search-box form-control" placeholder="Start typing barcode or name..." />
                                <div className="input-group-append">
                                    <span className="input-group-text" style={{"background": "rgb(255, 255, 255)"}}>F1</span>
                                </div>
                            </div>
                        </div>
                        }
                      </div>
                      <div className="col-md-3">
                        {(this.state.is_show_contact===true) &&
                          <ContactsDropdown show="dropdown" label="Type to select contact" input_id="contact_box" contact_info={this.state.bill_data.contact_info} handleSelectCustomer={this.handleSelectCustomer} target={'New Sale'} is_pos={true} icon_label="F2"/>
                        }
                      </div>
                      <div className="col-md-2">
                        <button className="btn btn-success" onClick={this.handleNewInventoryPopup}><FontAwesomeIcon icon={faPlus}/> New Inventory</button>
                      </div>
                      <div className="col-md-2"></div>
                      <div className="col-md-2">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text no-border-right">
                              <FontAwesomeIcon icon={faCalendarAlt} />
                            </span>
                            
                            <DatePicker className="form-control  no-border-left" selected={this.state.bill_data.billingDate} onChange={this.billingDate} dateFormat="dd/MM/yyyy"/>
                            
                          </div>
                        </div>
                      </div>
                     </div>
                    
                     <div className="row">
                      <div className="col-md-12"><hr /></div>
                     </div>
                     <div className="row">
                        <div className="col-md-9">
                        <table className="table" style={{border:'1px solid #dee2e6'}}>
                          <thead className="thead-light">
                            <tr>
                              <th style={{background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>SNo</th>
                              <th style={{background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>Item Name</th>
                              <th style={{textAlign:'center',background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>Qty</th>
                              {(this.state.sale_field_hide!==undefined && this.state.sale_field_hide.indexOf('sdisc')<0) &&
                                <th style={{textAlign:'center',background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>Discount</th>
                              }
                              {(this.state.is_item_commission===true) &&
                                <th style={{textAlign:'center',background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>Commission</th>
                              }
                              <th style={{textAlign:'right',background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>Total</th>
                              <th style={{textAlign:'center',background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>Full Info</th>
                              <th style={{textAlign:'center',background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>Remove</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(this.state.bill_data.list_items.map((value,index)=>{
                                return(
                                  <tr>
                                    <td>{index+1}</td>
                                    <td>{value.static_item_name}</td>
                                    <td style={{textAlign:'center'}}>
                                    <div>
                                      <button type="button" className="btn btn-light" style={{marginRight:'5px',padding:'0px 7px'}} onClick={()=>this.handleChangeQuantityOnClick('-',index)}>
                                        <FontAwesomeIcon icon={faMinus} style={{fontSize:'12px',color:'#686666'}}/>
                                      </button>
                                      <input type="text" value={value.quantity} style={{width:'28px',border:'none',textAlign:'center'}} onChange={(e)=>this.handleChangeItemValue('quantity',e.target.value,index)}/>
                                      <button type="button" className="btn btn-light" style={{marginLeft:'5px',padding:'0px 7px'}} onClick={()=>this.handleChangeQuantityOnClick('+',index)}>
                                        <FontAwesomeIcon icon={faPlus} style={{fontSize:'12px',color:'#686666'}}/>
                                      </button>
                                    </div>
                                    </td>
                                    {(this.state.sale_field_hide.indexOf('sdisc')<0) &&
                                    <td style={{textAlign:'center'}}>
                                      <div style={{display:'flex',justifyContent:'center'}}>
                                      <input type="text" value={value.discount} style={{width:'35px',textAlign:'center',border:'none'}} onChange={(e)=>this.handleChangeItemValue('discount',e.target.value,index)}/>
                                      <div className="form-group" style={{margin:'2px 0px 0px 10px',textAlign:'left'}}>
                                        <div className="form-check form-check-inline">
                                          <input className="form-check-input" type="radio" name={"item-discount-"+index} value="rs" checked={(value.discount_type==='rs')?true:false} onChange={(e)=>this.handleChangeDiscountType('rs',index)}/>
                                          <label className="form-check-label rs-sym" htmlFor="discount-rs">
                                            ₹
                                          </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                          <input className="form-check-input" type="radio" name={"item-discount-"+index} value="per"  checked={(value.discount_type==='per')?true:false}  onChange={(e)=>this.handleChangeDiscountType('per',index)}/>
                                          <label className="form-check-label" htmlFor="discount-per">
                                            %
                                          </label>
                                        </div>
                                      </div>
                                      </div>
                                    </td>
                                    }
                                    {(this.state.is_item_commission===true) &&
                                      <td style={{textAlign:'center'}}>
                                        ₹<input type="text" value={value.item_commission} style={{width:'35px',border:'none'}} onChange={(e)=>this.handleChangeItemValue('commission',e.target.value,index)}/>
                                      </td>
                                    }
                                    <td style={{textAlign:'right'}}>₹{utils.currency_format(value.total)}</td>
                                    <td style={{textAlign:'center'}}>
                                        <DrawerLink target="product_list_info" name={<FontAwesomeIcon icon={faInfoCircle} style={{color:'#686666',cursor:'pointer'}}/>} info={value} page_title={value.total} width='30%'/>
                                    </td>
                                    <td style={{textAlign:'center'}}>
                                      <FontAwesomeIcon icon={faTimes} style={{color:'#686666',cursor:'pointer'}} onClick={()=>this.handleRemoveItem(index)}/>
                                    </td>
                                  </tr>
                                )
                            }))}
                           {(this.state.bill_data.list_items.length===0) &&
                            <tr>
                              <td style={{textAlign:'center'}} colSpan={8}>
                                
                                  <FontAwesomeIcon icon={faCartPlus} style={{fontSize:'50px'}}></FontAwesomeIcon>
                                
                              </td>
                            </tr>
                           }
                          </tbody>
                          {(this.state.bill_data.list_items.length>0) &&
                          <tfoot>
                            <tr>
                              <th></th>
                              <th></th>
                              <th style={{textAlign:'center'}}><h4>{this.state.bill_data.total_quantity} item(s)</h4></th>
                              
                              {(this.state.sale_field_hide!==undefined && this.state.sale_field_hide.indexOf('sdisc')<0) &&
                                <th></th>
                              }
                              {(this.state.is_item_commission===true) &&
                                <th></th>
                              }
                             <th style={{textAlign:'right'}}><h4>₹{utils.currency_format(this.state.bill_data.total_amount)}</h4></th>
                             <th style={{textAlign:'center'}}>
                                 <DrawerLink target="product_list_full_info" name={<button className="btn btn-info btn-sm" style={{padding:'0px 5px'}}><FontAwesomeIcon icon={faInfoCircle}/> Full Bill</button>} info={this.state.bill_data} page_title='Full Bill' width='95%'/>
                             </th>
                             <th></th>
                            </tr>
                          </tfoot>
                          }
                        </table>
                        {(this.state.bill_data.exchange_items.length>0) &&
                          <>
                            <h4>Exchange Item(s)</h4>
                            <table className="table" style={{borderBottom:'1px solid #dee2e6'}}>
                              <thead className="thead-light">
                                <tr>
                                  <th style={{background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>SNo</th>
                                  <th style={{background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>Item Name</th>
                                  <th style={{textAlign:'center',background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>Qty</th>
                                  {(this.state.sale_field_hide!==undefined && this.state.sale_field_hide.indexOf('sdisc')<0) &&
                                    <th style={{textAlign:'center',background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>Discount</th>
                                  }
                                 
                                  <th style={{textAlign:'right',background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>Total</th>
                                  <th style={{textAlign:'center',background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>Full Info</th>
                                  <th style={{textAlign:'center',background:'#779ec8',borderColor:'#779ec8',color:'#fff'}}>Remove</th>
                                </tr>
                              </thead>
                              <tbody>
                                {(this.state.bill_data.exchange_items.map((value,index)=>{
                                    return(
                                      <tr>
                                        <td>{index+1}</td>
                                        <td>{value.static_item_name}</td>
                                        <td style={{textAlign:'center'}}>
                                        <div>
                                          <button type="button" className="btn btn-light" style={{marginRight:'5px',padding:'0px 7px'}} onClick={()=>this.handleChangeQuantityOnClick('-',index,'exchange')}>
                                            <FontAwesomeIcon icon={faMinus} style={{fontSize:'12px',color:'#686666'}}/>
                                          </button>
                                          <input type="text" value={value.quantity} style={{width:'28px',border:'none',textAlign:'center'}} onChange={(e)=>this.handleChangeItemValue('quantity',e.target.value,index,'exchange')}/>
                                          <button type="button" className="btn btn-light" style={{marginLeft:'5px',padding:'0px 7px'}} onClick={()=>this.handleChangeQuantityOnClick('+',index,'exchange')}>
                                            <FontAwesomeIcon icon={faPlus} style={{fontSize:'12px',color:'#686666'}}/>
                                          </button>
                                        </div>
                                        </td>
                                        {(this.state.sale_field_hide.indexOf('sdisc')<0) &&
                                        <td style={{textAlign:'center'}}>
                                          <div style={{display:'flex',justifyContent:'center'}}>
                                          <input type="text" value={value.discount} style={{width:'35px',textAlign:'center',border:'none'}} onChange={(e)=>this.handleChangeItemValue('discount',e.target.value,index,'exchange')}/>
                                          <div className="form-group" style={{margin:'2px 0px 0px 10px',textAlign:'left'}}>
                                            <div className="form-check form-check-inline">
                                              <input className="form-check-input" type="radio" name={"exchange-discount-"+index} value="rs" checked={(value.discount_type==='rs')?true:false} onChange={(e)=>this.handleChangeDiscountType('rs',index,'exchange')}/>
                                              <label className="form-check-label rs-sym" htmlFor="discount-rs">
                                                ₹
                                              </label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                              <input className="form-check-input" type="radio" name={"exchange-discount-"+index} value="per"  checked={(value.discount_type==='per')?true:false}  onChange={(e)=>this.handleChangeDiscountType('per',index,'exchange')}/>
                                              <label className="form-check-label" htmlFor="discount-per">
                                                %
                                              </label>
                                            </div>
                                          </div>
                                          </div>
                                        </td>
                                        }
                                       
                                        <td style={{textAlign:'right'}}>₹{utils.currency_format(value.total)}</td>
                                        <td style={{textAlign:'center'}}>
                                            <DrawerLink target="product_list_info" name={<FontAwesomeIcon icon={faInfoCircle} style={{color:'#686666',cursor:'pointer'}}/>} info={value} page_title={value.total} width='30%'/>
                                        </td>
                                        <td style={{textAlign:'center'}}>
                                          <FontAwesomeIcon icon={faTimes} style={{color:'#686666',cursor:'pointer'}} onClick={()=>this.handleRemoveItem(index,'exchange')}/>
                                        </td>
                                      </tr>
                                    )
                                }))}
                              {(this.state.bill_data.exchange_items.length===0) &&
                                <tr>
                                  <td style={{textAlign:'center'}} colSpan={7}>
                                    
                                      <FontAwesomeIcon icon={faCartPlus} style={{fontSize:'50px'}}></FontAwesomeIcon>
                                    
                                  </td>
                                </tr>
                              }
                              </tbody>
                              {(this.state.bill_data.exchange_items.length>0) &&
                              <tfoot>
                                <tr>
                                  <th></th>
                                  <th></th>
                                  <th style={{textAlign:'center'}}><h4>{this.state.bill_data.total_exchange_quantity} item(s)</h4></th>
                                  
                                  {(this.state.sale_field_hide!==undefined && this.state.sale_field_hide.indexOf('sdisc')<0) &&
                                    <th></th>
                                  }
                                  
                                <th style={{textAlign:'right'}}><h4>₹{utils.currency_format(this.state.bill_data.exchange_amount)}</h4></th>
                                <th style={{textAlign:'center'}}>
                                    <DrawerLink target="exchange_list_full_info" name={<button className="btn btn-info btn-sm" style={{padding:'0px 5px'}}><FontAwesomeIcon icon={faInfoCircle}/> Full Bill</button>} info={this.state.bill_data} page_title='Full Bill' width='95%'/>
                                </th>
                                <th></th>
                                </tr>
                              </tfoot>
                              }
                            </table>
                          </>
                        }
                        </div>
                        <div className="col-md-3">
                        <nav>
                          <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <a className="nav-item nav-link active" id="nav-account-tab" data-toggle="tab" href="#nav-account" role="tab" aria-controls="nav-account" aria-selected="true">
                              <FontAwesomeIcon icon={faUser}/>
                            </a>
                            {/* <a className="nav-item nav-link" id="nav-offers-tab" data-toggle="tab" href="#nav-offers" role="tab" aria-controls="nav-offers" aria-selected="false">Offers</a> */}
                          </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent" style={{border:'1px solid #dee2e6',borderTop:'none',height:'300px'}}>
                          <div className="tab-pane fade show active" id="nav-account" role="tabpanel" aria-labelledby="nav-account-tab" style={{padding:'5px'}}>
                            <div className="row">
                              <div className="col-md-12">
                                {(this.state.bill_data.contact_info.contact_id!==undefined) &&
                                  <>
                                    <h5 style={{background: '#779ec8', color: '#ffff', padding: '5px'}}>Last Purchase Info</h5>
                                    {(this.state.customer_last_purchase_info.sale_id!==undefined) &&
                                      <div className="row">
                                        <div className="col-md-6">
                                          <p style={{margin:'0px', fontSize:'12px',color:'gray'}}>Bill Number</p>
                                          <DrawerLink target="billing" url={'/view-billing/sales/'+this.state.customer_last_purchase_info.sale_id} name={this.state.customer_last_purchase_info.sales_number} schema={this.state.customer_last_purchase_info.schema}/>
                                        </div>
                                        <div className="col-md-6">
                                          <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Amount</p>
                                          <h5>₹{utils.currency_format(this.state.customer_last_purchase_info.sale_id)}</h5>
                                        </div>
                                        <div className="col-md-6">
                                          <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Purchase Date</p>
                                          <h5>{this.state.customer_last_purchase_info.date_display}</h5>
                                        </div>
                                        <div className="col-md-6">
                                          <p style={{margin:'0px',fontSize:'12px',color:'gray'}}>Status</p>
                                          <h5>{this.state.customer_last_purchase_info.status}</h5>
                                        </div>
                                      </div>
                                    }
                                  </>
                                }
                                {(this.state.bill_data.contact_info.contact_id===undefined) &&
                                  <div style={{textAlign:'center',marginTop:'40px'}}>
                                    <FontAwesomeIcon icon={faSearch} style={{fontSize:'40px'}}/>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                          <div className="tab-pane fade" id="nav-offers" role="tabpanel" aria-labelledby="nav-offers-tab">offers</div>
                          
                        </div>
                        </div>
                     </div>
                    
                  </div>
                </div>
                {(this.state.bill_data.list_items.length>0) &&
                    
                    <div  className="container-fluid mobile-view" style={{padding:'15px',position:'fixed',bottom:'0px',left:'13px'}}>
                      <button className="btn btn-danger btn-sm" style={{marginRight:'10px'}} onClick={this.handleResetBill}>
                        <FontAwesomeIcon icon={faEraser}/> Reset Bill
                      </button>
                      {(this.state.is_saving_pos===false) &&
                        <button className="btn btn-success btn-sm" style={{marginRight:'10px'}} onClick={this.handleHoldBill}>
                          <FontAwesomeIcon icon={faFolderOpen}/> Hold Bill
                        </button>
                      }
                      {(this.state.is_saving_pos===true) &&
                        <button className="btn btn-success btn-sm" style={{marginRight:'10px'}}>
                        <FontAwesomeIcon icon={faSpinner} pulse/>
                        </button>
                      }
                      <button className="btn btn-success btn-sm" style={{marginRight:'10px'}} onClick={this.handleShowHoldBill}>
                        <FontAwesomeIcon icon={faListAlt}/> Hold List
                      </button>
                      <button className="btn btn-success btn-sm" style={{marginRight:'10px'}} onClick={this.handleExchangeItem}>
                        <FontAwesomeIcon icon={faExchangeAlt}/> {(this.state.bill_data.exchange_amount===0)?'Exchange':'Exchange ₹'+utils.currency_format(this.state.bill_data.exchange_amount)}
                      </button>
                      <button className="btn btn-success btn-sm" style={{marginRight:'10px'}} onClick={this.handleOpenOtherCharges}>
                        <b>₹</b> {(this.state.bill_data.other_charge_amount===0)?'Other Charges':'Other Charges ₹'+utils.currency_format(this.state.bill_data.other_charge_amount)}
                      </button>
                      <button className="btn btn-success pull-right" onClick={this.handleOpenPaymentPopup}>
                        <FontAwesomeIcon icon={faSave}/> Pay ₹{utils.currency_format(this.state.bill_data.grand_total)}
                      </button>
                      <DrawerLink target="bill_summary" name={<button className="btn btn-info pull-right" style={{marginRight:'10px'}}><FontAwesomeIcon icon={faInfoCircle} style={{color:'#fff'}}/></button>} info={this.state.bill_data} page_title='Bill Summary' width='40%'/>
                    </div>
                  
                  }
              </div>
              {(this.state.is_show_child_popup===true) &&
                <ChildInventoryItems child_inventory_items={this.state.child_inventory_items} handleCloseInvetoryListPopup={this.handleCloseInvetoryListPopup} handleSelectItem={this.handleSelectItem}/>
              }
              {(this.state.is_show_new_inventory===true) &&
                <NewInventroyItem handleCloseNewInventoryItem={this.handleCloseNewInventoryItem} handleSelectItem={this.handleSelectItem}/>
              }
              {(this.state.is_show_exchange_item===true) &&
                <ExchangeItem handleCloseExchangePopup={this.handleCloseExchangePopup} handleSelectExchangeItem={this.handleSelectExchangeItem}/>
              }
              {(this.state.is_show_other_charges===true) &&
                <OtherCharges handleCloseOtherCharges={this.handleCloseOtherCharges} other_charges={this.state.bill_data.other_charges} hanldeSetOtherCharges={this.hanldeSetOtherCharges} total_oc={this.state.bill_data.other_charge_amount}/>
              }
              {(this.state.is_show_pay_popup===true) &&
                <BillingPOSPaymentPopup data={{payment_modes:this.state.payment_modes}} handleClosePaymentPopup={this.handleClosePaymentPopup} bill_data={this.state.bill_data} handleReadjustBillingData={this.handleReadjustBillingData} selected_customer={this.state.selected_customer}/>
              }
              <BillingPOSOpenCounter />
              <HoldBillPopup data={{screen:(this.state.bill_data.billing_type==='sale')?'billing':'order',billing_type:'sales',update_id:0}}/>
            </div>
        );
    }
}

class ChildInventoryItems extends Component {
    constructor() {
        super();
        this.state = {
            barcode_inventory_items : [],
            child_inventory_items : [],
            active_item :0,
            show_loader : true,
            search_text : '',
        };
        
    }
   
    componentDidMount(){
        this.setState({barcode_inventory_items:this.props.child_inventory_items});
        document.addEventListener("keydown", this.handleBarcodeInventoryListShortcut);
    }
    handleBarcodeInventoryListShortcut = (e)=>{
        let key_code = e.which;
     
        if(key_code===27){
         
            this.handleCloseInvetoryListPopup();
           /*  document.getElementById("iitem-box").focus();
            document.getElementById("open_popup_on_focus").click(); */
          
        }
        
        if(key_code===40){
          
          var inv_list = document.querySelectorAll(".bc-inv-list");
          
            let active_item = this.state.active_item;
            active_item++;
            if(inv_list.length>=(active_item+1)){
              this.setState({active_item:active_item});
            }
          
        }
        
        if(key_code===38){
          
          var inv_list = document.querySelectorAll(".bc-inv-list");
          
            let active_item = this.state.active_item;
            active_item--;
            if(active_item>=0){
              this.setState({active_item:active_item});
            }
          
        }
        
        if(key_code===13){
          
          var inv_list = document.querySelectorAll(".bc-inv-list");
          
          let active_item = this.state.active_item;
          if(inv_list[active_item]!==undefined){
              inv_list[active_item].click();
          }
        
        }
    }
      handleSelectInventoryItem = () => {
        let index = this.state.active_item;
        console.log(index);
        let value = this.state.barcode_inventory_items[index];             
        let barcode_name= value.barcode_name;
        if(barcode_name===null){
          barcode_name = "";
        }
        let bi_quan = 0;
        
        if(barcode_name!==""){
          let barcode_rep = barcode_name.replaceAll("/",'');
          let bi_qs = document.querySelectorAll('.birow-barcode-'+barcode_rep);
          for (let bi = 0; bi < bi_qs.length; bi++) {
            const telem = bi_qs[bi];
            let ti_did = telem.getAttribute('data-id');
            let bq = document.getElementById("item-quantity-"+ti_did).value;
            bi_quan = bi_quan+parseFloat(bq);
          } 
        }

        let id = value.inventory_id;
       
        let stat_lbl = '';
      
        let item_name = value.item_name+" - Qty:"+value.availability+stat_lbl;
        let static_item_name = value.item_name;

        let sku   = value.sku; 
        let total = value.total;
        let bundle_text= value.bundle_text;
        
        
        if(sku===null){
          sku = "";
        }
        
        let alias_barcode= value.alias_barcode;
        if(alias_barcode===null){
          alias_barcode = "";
        }
        let custom_data = value.custom_fields_json;
        
       
        let temp_opt = {value1:sku, value2:item_name,value3:barcode_name,value4:alias_barcode,alias_barcode:alias_barcode,static_item_name:static_item_name,item_desc:value.item_desc,hsn:value.hsn,product_unit_id:value.product_unit_id,discount:value.discount,tax:value.tax,sp_tax:value.sp_tax,lowest_selling_price:value.lowest_selling_price,selling_price:value.selling_price,purchase_price:value.purchase_price,price_hidden:value.price_hidden,quantity:value.quantity,availability:value.availability,total:total,bundle_quantity:value.bundle_quantity,bundle_text:bundle_text,cgst:value.cgst,sgst:value.sgst,igst:value.igst,unit_name:value.unit_name,old_available:value.availability,inventory_status:value.inventory_status,sp_discount:value.sp_discount,sp_tax_type:value.sp_tax_type,pp_tax_type:value.pp_tax_type,barcode:barcode_name,category_id:value.category_id,category_name:value.category_name,custom_data:custom_data,is_more_barcode:0,custom_text:value.custom_text,barcode_name:barcode_name,reorder_quantity:value.reorder_quantity,opening_stock:value.opening_stock,part_name:value.part_name,inventory_id:id};

        if(value.custom_fields_json){
          let custom_field_json = value.custom_fields_json;
          for (let cfi = 0; cfi < custom_field_json.length; cfi++) {
            const cf_element = custom_field_json[cfi];
            if(cf_element.is_barcode_active!==undefined && cf_element.is_barcode_active===true){
              let cfid = 'cf'+cf_element.id;
              let cfvalue = cf_element.value;
              temp_opt[cfid] = cfvalue;
            }
          }
        }
        
        this.props.handleSelectItem(temp_opt);
        this.handleCloseInvetoryListPopup();
      }
  
      handleCloseInvetoryListPopup = ()  =>{
        this.props.handleCloseInvetoryListPopup();
      }
      handleSearch = (e) =>{
          let userInput = e.currentTarget.value;
          this.setState({search_text:userInput});
          let barcode_inventory_items = this.props.child_inventory_items;
          let hblist = [];
          barcode_inventory_items.map( (value, index) => { 
            let value1 = value.barcode_name;
            if(value1.toLowerCase().indexOf(userInput.toLowerCase()) > -1){
                hblist.push(value);
            }
        });
        this.setState({barcode_inventory_items:hblist})
      }
    render() {
        return (
            <div className="modal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:'block'}}>
            
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title hbtitle">INVENTORY ITEMS</h5>
                  </div>
                  <div className="modal-body" style={{overflow: 'auto',maxHeight: '500px'}}>
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <input type="hidden" value={'New Sale'} id="current_active_tab"/>
                     
                      <input type="text" className="form-control" placeholder="Type to search" id="dif-bc-invi-search" onChange={this.handleSearch}/>
                     
                         <table className="table">
                            <thead>
                              <tr>
                                <th className="text-left">Item</th>
                                <th className="text-left">Barcode</th>
                                <th className="text-left">Availability</th>
                                <th className="text-left">Price</th>
                              </tr>
                            </thead>
                            <tbody>
                            {(this.state.barcode_inventory_items.length > 0) ?this.state.barcode_inventory_items.map( (value, index) => {
                               return (
                                  <tr key={index} className={(this.state.active_item===index)?'active bc-inv-list':'bc-inv-list'} onClick={this.handleSelectInventoryItem} id={value.inventory_id} style={{cursor:'pointer'}}>
                                    <td className="text-left">{value.item_name}</td>
                                    <td className="text-left">{value.barcode_name} ({value.item_code})</td>
                                    <td className="text-left">
                                      {value.availability}
                                      <input type="hidden" value={value.availability} className="bc-inv-li-avail"/>
                                    </td>
                                    <td className="text-left">{value.selling_price}</td>
                                  </tr>
                                )
                              }) :  <tr><td colSpan={3} style={{textTransform:'capitalize'}}>Oops ! No item found</td></tr>
                            }
                            </tbody>
                         </table>
                        
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={this.handleCloseInvetoryListPopup}>Close</button>
                  </div>
                </div>
              </div>
            </div> 
        );
    }
}
class NewInventroyItem extends Component {
  constructor() {
      super();
      this.state = {
          inventory_item :{
            "value1": "",
            "value2": "",
            "value3": "",
            "value4": "",
            "alias_barcode": "",
            "static_item_name": "",
            "item_desc": "",
            "hsn": "",
            "product_unit_id": 1,
            "discount": "0",
            "tax": 0,
            "sp_tax": 0,
            "lowest_selling_price": "",
            "selling_price": "",
            "purchase_price": "",
            "price_hidden": "",
            "quantity": 1,
            "availability": 1,
            "total": 0,
            "bundle_quantity": "0",
            "bundle_text": "Bundle",
            "cgst": "0",
            "sgst": "0",
            "igst": "0",
            "unit_name": "Pcs",
            "old_available": 9,
            "inventory_status": "Active",
            "sp_discount": "0",
            "sp_tax_type": "including",
            "pp_tax_type": "excluding",
            "barcode": "",
            "category_id": "2",
            "category_name": "",
            "custom_data": [
                
            ],
            "is_more_barcode": 0,
            "custom_text": "",
            "barcode_name": "",
            "reorder_quantity": null,
            "opening_stock": null,
            "part_name": null,
            "inventory_id": 0,
            "child_barcode_info": [
            ],
           
            "discount_type": "per",
            "item_commission": 0,
            "price": 0,
            "cgst_amount": 0,
            "sgst_amount": 0,
            "igst_amount": 0,
            "discount_amount": 0,
            "gst_amount": 0,
            "is_new_item":true
        }
      };
      
  }
 
 
  handleAddToList = () => {
      this.props.handleSelectItem(this.state.inventory_item);
      this.handleCloseNewInventoryItem();
    }

    handleChangeItem = (value,type) =>{
      let inventory_item = this.state.inventory_item;
      if(type==='sp_tax'){
        value = value.replace(/[^0-9]/g, '');
        console.log(value);
      }
      if(type==='item_name'){
        inventory_item['static_item_name'] = value;
      }
      inventory_item[type] = value;
      this.setState({inventory_item:inventory_item});
    }
    handleChangeUnit = (id,name) =>{
      let inventory_item = this.state.inventory_item;
      inventory_item['product_unit_id'] = id;
      inventory_item['unit_name'] = name;
      this.setState({inventory_item:inventory_item});
    }

    handleCloseNewInventoryItem = ()  =>{
      this.props.handleCloseNewInventoryItem();
    }
    
  render() {
      return (
          <div className="modal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:'block'}}>
          
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title hbtitle">NEW INVENTORY</h5>
                </div>
                <div className="modal-body" style={{overflow: 'auto',maxHeight: '500px'}}>
                  <div className="row">
                    <div className="col-md-12 text-center">
                       <table className="table">
                          <thead>
                            <tr>
                              <th className="text-left">Item</th>
                              <th className="text-left">Quantity</th>
                              <th className="text-left">Unit</th>
                              <th className="text-left">Price</th>
                              <th className="text-left">Tax</th>
                              <th className="text-left">Tax Type</th>
                            </tr>
                          </thead>
                          <tbody>
                              <td  style={{width:'200px'}}>
                                <input type="text" className="form-control" placeholder="Enter Item Name" onChange={(e)=>this.handleChangeItem(e.target.value,'item_name')} value={this.state.inventory_item.item_name} autoFocus={true}/>
                              </td>
                              <td>
                                <input type="text" className="form-control" placeholder="Enter Quantity" onChange={(e)=>this.handleChangeItem(e.target.value,'quantity')}  value={this.state.inventory_item.quantity}/>
                              </td>
                              <td style={{width:'100px'}}>
                                <ProductUnits show='dropdown' handleChangeUnit={this.handleChangeUnit}  data_id={0} id={'iitem-unit'} default={this.state.inventory_item.product_unit_id} target="iitem-unit"/>
                              </td>
                              <td>
                                <input type="text" className="form-control" placeholder="Price" onChange={(e)=>this.handleChangeItem(e.target.value,'selling_price')}  value={this.state.inventory_item.selling_price}/>
                              </td>
                              <td style={{width:'130px'}}>
                                <input list={"tax-list"} className="form-control" placeholder="%" onChange={(e)=>this.handleChangeItem(e.target.value,'sp_tax')} value={this.state.inventory_item.sp_tax}/>
                                <datalist id={"tax-list"}>
                                  {(gst_options && gst_options.length > 0) ?gst_options.map( (value, index) => {
                                    return (
                                      <option>{value.value}</option>
                                    )
                                  }) : '' }
                                </datalist>
                              </td>
                              <td style={{width:'130px'}}>
                                <select className="form-control"  onChange={(e)=>this.handleChangeItem(e.target.value,'sp_tax_type')} value={this.state.inventory_item.sp_tax_type}>
                                  <option value="excluding">Exc GST</option>
                                  <option value="including">Inc GST</option>
                                </select>
                              </td>
                          </tbody>
                       </table>
                      
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={this.handleCloseNewInventoryItem}>Close</button>
                  <button type="button" className="btn btn-success pull-right" onClick={this.handleAddToList}><FontAwesomeIcon icon={faPlus}/> Add to List</button>
                </div>
              </div>
            </div>
          </div> 
      );
  }
}

class ExchangeItem extends Component {
  constructor() {
      super();
      this.state = {
        is_select_exchange_item : true
      };
      
  }
 
    handleCloseExchangePopup = ()  =>{
      this.props.handleCloseExchangePopup();
    }
    handleSelectExchangeItem = (item) =>{
      this.setState({is_select_exchange_item:false});
      let _this = this;
      setTimeout(() => {
        _this.setState({is_select_exchange_item:true});
      }, 10);
      this.props.handleSelectExchangeItem(item);
    }
  render() {
      return (
          <div className="modal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:'block'}}>
          
            <div className="modal-dialog modal-sm" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <FontAwesomeIcon icon={faTimesCircle} onClick={this.handleCloseExchangePopup} style={{position: 'absolute', top: '-25px',  right: '-10px', fontSize: '20px',cursor:'pointer'}}/>
                    {(this.state.is_select_exchange_item===true)  &&
                        <Autocomplete
                            options={[]}
                            data_target="billing"
                            title="Start typing barcode or name..."  
                            id={"iitem-name"}
                            className="auto-comp-opt"
                            data_id={0}
                            active_tab={'New Sale'}
                            input_id={"iitem-box"}
                            item_info={[]}
                            target="iitem-box"
                            tabindex={1}
                            icon_label="F1"
                            is_auto_focus = {true}
                            handleSelectItem = {this.handleSelectExchangeItem}
                        />
                    }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
      );
  }
}
class OtherCharges extends Component {
  constructor(props) {
      super();
      this.state = {
          other_charges_list : props.other_charges,
          oc_list : props.other_charges,
          total_oc :props.total_oc,
      };
  }

  componentDidMount = ()=>{
   /*  this.setState({other_charges_list:this.props.other_charges});
    this.setState({total_oc:this.props.total_oc}); */
  }
    handleCloseOtherCharges = ()  =>{
      console.log(this.state.oc_list);
      this.props.handleCloseOtherCharges();
    }
    handleSelectOCItem = (is_selected, index)=>{
      if(is_selected===undefined || is_selected===false){
        is_selected = true;
      }else{
        is_selected = false;
      }
      let other_charges_list = this.state.other_charges_list;
      let oc = other_charges_list[index];
      let price = oc.price;
      let qty = oc.qty;
      if(price===""){
        price = 0;
      }
      if(qty===""){
        qty=0;
      }
      let total = parseFloat(price) * parseFloat(qty);
      other_charges_list[index].is_selected = is_selected;
      if(is_selected===false){
        other_charges_list[index].total_amount = '';
      }else{
        other_charges_list[index].total_amount = total;
      }
      this.setState({other_charges_list:other_charges_list})
      this.hanldeCalculateOtherCharge(other_charges_list);
    }
    handleChangeItemValue = (value,index,key)=>{
      let other_charges_list = this.state.other_charges_list;
      other_charges_list[index][key] = value;
      let oc = other_charges_list[index];
      let price = oc.price;
      let qty = oc.qty;
      if(price===""){
        price = 0;
      }
      if(qty===""){
        qty=0;
      }
      let total = parseFloat(price) * parseFloat(qty);
      other_charges_list[index].total_amount = total;
      this.setState({other_charges_list:other_charges_list})
      this.hanldeCalculateOtherCharge(other_charges_list);
    }
    hanldeCalculateOtherCharge = (other_charges_list) =>{
      let tot_oc =0;
      for (let index = 0; index < other_charges_list.length; index++) {
        const element = other_charges_list[index];
        let total_amount = element.total_amount;
        let is_selected = element.is_selected;
        if(is_selected===true){
          tot_oc = tot_oc+parseFloat(total_amount);
        }
      }
      this.setState({total_oc:tot_oc});
    }

    handleChangeOCQuantityOnClick = (type,index)=>{
        let other_charges_list = this.state.other_charges_list;
        let quantity = parseFloat(other_charges_list[index].qty);
        if(type==='+'){
          quantity = quantity+1;
        }else{
          quantity = quantity-1;
        }
        if(quantity>0){
          other_charges_list[index].qty = quantity;
          let oc = other_charges_list[index];
          let price = oc.price;
          let qty = oc.qty;
          if(price===""){
            price = 0;
          }
          if(qty===""){
            qty=0;
          }
          let total = parseFloat(price) * parseFloat(qty);
          other_charges_list[index].total_amount = total;
          this.setState({other_charges_list:other_charges_list});
          this.hanldeCalculateOtherCharge(other_charges_list);
        }
      
    }
    handleSaveOC = () =>{
      if(this.props.hanldeSetOtherCharges){
        this.props.hanldeSetOtherCharges(this.state.other_charges_list);
        this.handleCloseOtherCharges();
      }
    }
  render() {
      return (
          <div className="modal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:'block'}}>
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      
                      <h4>Other Charges List</h4>
                      
                      <table className="table">
                        <thead>
                          <tr>
                            <th style={{width:'50px'}}>SNo</th>
                            <th style={{width:'10px'}}>Name</th>
                            <th style={{width:'100px'}}>Price</th>
                            <th style={{width:'80px',textAlign:'center'}}>Qty</th>
                            <th></th>
                            <th style={{textAlign:'right'}}></th>
                          </tr>
                        </thead>
                        <tbody>
                        {(this.state.other_charges_list.map((value,index)=>{
                            return(
                                <tr key={'ocl-'+index}>
                                    <td style={{width:'50px'}}>
                                      {index+1}
                                    </td>
                                    <td style={{width:'150px'}}>
                                        {value.name}
                                    </td>
                                    <td style={{width:'100px'}}>
                                        <input type="text" className="form-control  form-control-sm" placeholder="Enter Price" value={value.price}  onChange={(e)=>this.handleChangeItemValue(e.target.value,index,'price')}/>
                                    </td>
                                    <td style={{width:'80px',textAlign:"center"}}>
                                      
                                      <div style={{display:'flex',justifyContent:'center'}}>
                                      <button type="button" className="btn btn-light" style={{marginRight:'5px',padding:'0px 7px'}} onClick={()=>this.handleChangeOCQuantityOnClick('-',index)}>
                                        <FontAwesomeIcon icon={faMinus} style={{fontSize:'12px',color:'#686666'}}/>
                                      </button>
                                      <input type="text" className="form-control  form-control-sm" placeholder="Enter Qty" style={{width:'28px',border:'none',textAlign:'center'}} value={value.qty} onChange={(e)=>this.handleChangeItemValue(e.target.value,index,'qty')}/>
                                      <button type="button" className="btn btn-light" style={{marginLeft:'5px',padding:'0px 7px'}} onClick={()=>this.handleChangeOCQuantityOnClick('+',index)}>
                                        <FontAwesomeIcon icon={faPlus} style={{fontSize:'12px',color:'#686666'}}/>
                                      </button>
                                    </div>
                                    </td>
                                    <td style={{width:'100px'}}>
                                      {(value.is_selected!==true) &&
                                        <button className="btn btn-outline-success btn-sm"  onClick={(e)=>this.handleSelectOCItem(value.is_selected,index)}>Add <FontAwesomeIcon icon={faAngleDoubleRight}/> </button>
                                      }
                                      {(value.is_selected===true) &&
                                        <button className="btn btn-outline-danger btn-sm"  onClick={(e)=>this.handleSelectOCItem(value.is_selected,index)}>Remove</button>
                                      }
                                    </td>
                                    <td style={{width:'100px',textAlign:'right'}}>
                                        {(value.is_selected) &&
                                          <b>₹{value.price} X {value.qty}=₹{value.total_amount}</b>
                                        }
                                    </td>
                                </tr>
                            )
                        }))}
                        {(this.state.total_oc>0) &&
                        <tr>
                          <th colSpan={6} style={{textAlign:'right'}}><h2>₹{utils.currency_format(this.state.total_oc)}</h2></th>
                        </tr>
                        }
                        </tbody>
                    </table>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary btn-sm" onClick={this.handleCloseOtherCharges}>Close</button>
                  <button type="button" className="btn btn-success btn-sm pull-right" onClick={this.handleSaveOC}>Done</button>
                </div>
              </div>
            </div>
          </div> 
      );
  }
}