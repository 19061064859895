import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../admin.css';
import Leftnav from "./leftnav";
import Breadcrumbs from "./breadcrumbs";
import axios from 'axios';
import ModalBox from "./modal-box";
import ProductUnits from "./product-units";
import BarcodeTemplate from "./settings/barcode-template";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner,faPencilAlt  } from '@fortawesome/free-solid-svg-icons';
import ReactWhatsapp from 'react-whatsapp';
import ClosedFYButton from "./closed-fy-button";
import ServiceLoader from './service-loader.js';
import PrintBill from './print-bill.js';
import DrawerLink from './open-drawer-link.js';
import utils from '../utils.js';
import check_access from './access-level.js';
import ShareOptions from "./share-opt";
import ParcelSticker from "./parcel-sticker";
import {QRCodeSVG} from 'qrcode.react';
let {enc_key,app_api,user_info} = require('../library.js');
var CryptoJS = require("crypto-js");
let db;
if(user_info){
  let version = localStorage.getItem('IdbVersion');
  version = parseInt(version);
  let dbReq = window.indexedDB.open('BaazarERP', version);
  dbReq.onupgradeneeded = function(event) {
    db = event.target.result;
  }
  dbReq.onsuccess = function(event) {
    db = event.target.result;
  }
}
export default class ViewOrder extends Component {
    constructor() {
        super();
        this.state = {
            is_on_fly :false,
            print_target:'print',
            show_loader : true,
            is_reedeem : false,
            is_pending : false,
            is_payment : false,
            payment_status : '',
            update_id : 0,
            response_id : 0,
            referer : 0,
            more_rows : '',
            return_more_rows : '',
            billing_type : 'New Sale',
            currrent_sequence : '',
            currrent_sequence_no : '',
            billingDate:  '',
            invoiceDate:  '',
            contact_info : '',
            billing_data : '',
            target : 'sale',
            issued_by : '',
            agent_name : '',
            assisted_by : '',
            round_off_opt : '',
            round_off_opt2 : '',
            round_off : '',
            round_off_sym :'',
            global_discount : '',
            global_discount_amount : '',
            discount_sym :'',
            payment_info : [],
            total_quantity:'',
            view_branch_id:'',
            print_settings:[],
        
            invoice_title:'',
            terms_and_conditions : [],
            bill_status : '',
            payment_modes:[],
            barcode_name_on_the_bill_view:false,
            item_name_on_the_bill_view:false,
            is_print : false,
            is_on_account_return : false,
            return_goods_bills : [],
            barcode_template_data:[],
            barcode_template:[],
            barcode_print_data:[],
            is_direct_pay : false,
            bill_view_response:[],
            whatsapp_msg : '',
            whatsapp_phone : '',
            dispatched_info : '',
            advance_sale_data : false,
            is_fy_closed : false,
            is_items_visible : true,
            show_inventory_custom_field : false,
            next_id : false,
            previous_id : false,
            is_print_cicked : false,
            send_e_invoice : false,
            is_e_invoice_generated : false,
            is_modify_advance_sale_data : false,
        };
        this.barcode_template  = [];
        this.tax_slab  = [];
        this.csgsttaxable =[];
        this.igsttaxable =[];

        this.csgsttaxnet  = [];
        this.igsttaxnet  = [];

        this.igst_slab_key  = [];
        this.sgst_slab_key  = [];
        this.cgst_slab_key  = [];
     
        this.more_rows = [];
        this.return_more_rows = [];
        this.types = {sales:"New Sale",purchase:"New Purchase",sales_return:"New Sales Return",purchase_return:"New Purchase Return"};
        this.referer = {sales:1,purchase:2,sales_return:3,purchase_return:4};
        this.sequence = [];
        this.send_data_items = [];
        this.currency_format = utils.currency_format.bind(this);
        this.floating_point = utils.floating_point.bind(this);
        this.convert_number_to_words = utils.convert_number_to_words.bind(this);
        this.delteBilling = this.delteBilling.bind(this);
        this.handlePrint = this.handlePrint.bind(this);
        this.closeBarcodeModel = this.closeBarcodeModel.bind(this);
        this.openBarcodePrintPreview = this.openBarcodePrintPreview.bind(this);
        this.handleBarcodePrint = this.handleBarcodePrint.bind(this);
        this.handleShare = this.handleShare.bind(this);
        this.shareMessage = this.shareMessage.bind(this);
        this.is_access = check_access.is_access.bind(this);
        this.isMobile = utils.isMobile.bind(this);
    }

    shareMessage(){
      let data = {
                    target:'view',
                    title:this.state.billing_type,
                    contact_name:this.state.contact_info.item_name,
                    contact_phone:this.state.contact_info.phone_1,
                    currrent_sequence:this.state.currrent_sequence,
                    bill_date:this.state.billing_data.bill_date,
                    bill_time:this.state.billing_data.bill_time,
                    sharable_link:encodeURI(this.state.billing_data.sharable_link),
                    bill_total:this.state.billing_data.total,
                }
      let txt_message =  utils.shareBillMessage(data);
      this.setState({whatsapp_msg:txt_message});
      let mobile = this.state.contact_info.phone_1;
      mobile = utils.check_mobile_number(mobile);
      this.setState({whatsapp_phone:mobile});
    }

    handleShare(e){
      
    //console.log(txt_message);
    
      /*let data = {
        title: title,
        text: txt_message
      }*/
      //utils.handleShare(data);
    }
    closeBarcodeModel(event) {
      var element = document.getElementById('barcode-print-preview');
      element.classList.add("fade");
      element.style.display="none"; 
    }
    openBarcodePrintPreview(event) {
      this.setState({response_id:0});
      let barcode_template_data = this.state.barcode_template_data;
      let height = barcode_template_data[0].height;
      let width = barcode_template_data[0].width;
      let values = barcode_template_data[0].value;

      let name = this.state.barcode_template_data[0].name;

      let prows = this.state.barcode_print_data;
      let template = '';
      this.barcode_template = [];
      for(let r=0 ; r<prows.length; r++){
        let billing_data = prows[r];
        let quantity = prows[r].availability;
        if(quantity!==""){
          quantity = parseInt(quantity);
          for(let q=0;q<quantity;q++){
            this.barcode_template.push(<BarcodeTemplate barcode_template_data={barcode_template_data} id={q} key={q} billing_data={billing_data} name={name} tamplate_data={values} height={height} width={width}/>);
          } 
          this.setState({barcode_template:this.barcode_template});
        }
      }

      var element = document.getElementById('barcode-print-preview');
      element.classList.remove("fade");
      element.style.display="block"; 
      
    }

     handleBarcodePrint(e){
            let type = e.currentTarget.id;
            var divContents = document.getElementById("main-barcode-container").innerHTML; 
            var a = window.open('', '', 'height=1000,width=700,location=no,left=250px'); 
            a.document.write('<html><head>'); 
            a.document.write('<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" type="text/css" />'); 
            a.document.write('<style type="text/css">@page {margin: 0;}.bcode-temp{text-transform:uppercase;}.react-draggable {position: absolute !important;line-height: 0;white-space: nowrap;}.react-draggable.center{position: relative !important;}.react-draggable.free{left: 0;}.react-draggable.free-left{left: 0;}.react-draggable.free-right{right: 0;}.react-draggable.left{position: relative !important;}.react-draggable.right{position: relative !important;}.drag-inner.center{text-align:center;}.drag-inner.right{text-align: right;}.drag-inner.left{text-align: left;}.temp-elem-name{margin: 0;}</style>'); 
            
            a.document.write('<body onload="window.print();" style="background:rgb(222, 222, 222)">'); 
            
            a.document.write(divContents); 
           
             a.document.write('</body></html>');
             a.document.close();
             a.focus();
    } 

    handlePrint(e){ 
      let type = e.currentTarget.id;
/*       document.getElementById(type+'-lbl').classList.add("hide");
      document.getElementById(type+'-btn-spinner').classList.remove("hide"); */
      this.setState({print_target:type,response_id:this.state.update_id});
     /*  setTimeout(function(){
        if(document.getElementById(type+'-lbl')){

          document.getElementById(type+'-lbl').classList.remove("hide");
          document.getElementById(type+'-btn-spinner').classList.add("hide");
        }
      },3000); */
    } 
    delteBilling(event){
      let del_option = (this.state.billing_data && this.state.billing_data.is_current===true)?'Delete':'Cancel';
      let id = event.currentTarget.id;
      let data_value = event.currentTarget.getAttribute('data_value');
      let data_id = event.currentTarget.getAttribute('data_id');
      var element = document.getElementById('delete_confirm');
      element.classList.remove("fade");
      element.style.display="block";
      let delete_value = 'billing_view';
      if(this.state.bill_status==="On Hold"){
        delete_value = 'hold_view';
      }
      document.getElementById("deleteValue").value=delete_value;
      document.getElementById("deleteIndex").value=id;
      document.getElementById("deleteTarget").value=data_id;
      del_option = del_option.toLowerCase();
      document.getElementById("delete_message").innerHTML =  'Do you really want to '+del_option+' this bill?';   
    }

    handleGetContactOnId = (type,id) =>{
      let body = {};
            body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token, _schema : user_info._schema,
                id : id
             }
          let api = app_api+'/get_contact_on_id';
          axios.post(api, body)
            .then(function (response) {
                let data = response.data;
                let contact_info = data[0];
                let name = contact_info.name;
                if(type==='assisted_by'){
                  this.setState({ 'assisted_by': name }); 
                }
                if(type==='agent_name'){
                  this.setState({ 'agent_name': name }); 
                }
          }.bind(this)).catch(function(res){
              if(res.response){
                  if(res.response.data){
                  }
              }
          })
    }

    componentDidMount(){

      let curl = window.location.href;
      let schema =user_info._schema; 
      if(this.props.url){
        curl = this.props.url;
        this.setState({is_on_fly:true})
        schema =this.props.schema;
      }
      let view_type = '';
      const view_billing = curl.match(/view-order\/([a-z_A-Z]+)\/(\d+$)/i);
      const print_bill = curl.match(/view-order\/([a-z_A-Z]+)\/print\/(\d+$)/i);

      if(print_bill){
        let save_print = localStorage.getItem("save_print");
        if(save_print=="yes"){
          localStorage.removeItem("save_print");
          setInterval(function(){
            let open_new_bill = localStorage.getItem("open_new_bill");
            if(open_new_bill=="yes"){
              localStorage.removeItem("open_new_bill");
              window.location.href = "/new-order/1";
            }
          },5);
        }
      }

      let print_settings = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, _schema : user_info._schema,
          keys:"'barcode_name_on_the_bill_view','item_name_on_the_bill_view','advance_sale_data','is_financial_year_closed','show_inventory_custom_field','send_e_invoice'"
      }
      let set_api = app_api+'/get_settings';
      axios.post(set_api, print_settings)
        .then(function (response) {
          let resp = response.data;
          if(resp.show_inventory_custom_field!==undefined){
            let show_inventory_custom_field = resp.show_inventory_custom_field;
            this.setState({show_inventory_custom_field:show_inventory_custom_field});
          }
        
          if(resp.barcode_name_on_the_bill_view){
            let ps = resp.barcode_name_on_the_bill_view;
            this.setState({barcode_name_on_the_bill_view:ps});
          }
          if(resp.item_name_on_the_bill_view){
            let ps = resp.item_name_on_the_bill_view;
            this.setState({item_name_on_the_bill_view:ps});
          }
          if(resp.advance_sale_data){
            let ps = resp.advance_sale_data;
            this.setState({advance_sale_data:ps});
          }
          if(resp.send_e_invoice){
            let ps = resp.send_e_invoice;
            this.setState({send_e_invoice:ps});
          }
          if(resp.is_fy_closed){
            let is_fy_closed = resp.is_fy_closed;
            this.setState({is_fy_closed:is_fy_closed});
          }
      }.bind(this)).catch(function(res){
          
      })


      if(view_billing || print_bill){ // EDIT MODE
          let id ='';
          let type = '';
          if(print_bill){
             id = print_bill[2];
             type = print_bill[1];
             view_type = 'print';
          }else{
             id = view_billing[2];
             type = view_billing[1];
          }

          let t = this.types[type];
          let referer = this.referer[type];
          this.setState({referer:referer});

          this.setState({update_id:id});
          this.setState({billing_type:t});
          this.setState({target:type});
         
         
          let body = {};
          body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              store_state : user_info.state,
              type : t,
              id : parseInt(id),
              action : 'view',
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token, 
              _schema : user_info._schema,
              schema : schema
           }
       
        let api = app_api+'/orders/get_billing';
        axios.post(api,body)
          .then(function (response) {
              let resp = response.data;
              
              let data = resp.data;
              
              if(data.total===undefined){
                window.location.href = '/';
              }

              this.setState({next_id:data.next_prev_data.next});
              this.setState({previous_id:data.next_prev_data.previous});

              this.setState({bill_view_response:resp})
              let assisted_by = data.assisted_by;
              let agent_name = data.agent_name;
              this.setState({payment_info:data.payment_info});
              this.setState({view_branch_id:data.branch_id});

              if(t==='New Purchase'){
                this.setState({barcode_print_data:resp.barcode_print_data});
              }

              if(data.total_unit<data.total_unit_count){
                this.setState({is_items_visible:false});
              }

              this.setState({billing_data:data,dispatched_info:resp.dispatched_info,bill_status:data.bill_status});
              
              let is_direct_pay = data.is_direct_pay;
              this.setState({is_direct_pay:is_direct_pay});
              this.setState({show_loader:false});

              let payment_note_number  = data.payment_note_number;
              let payment_note_redeem_total  = data.payment_note_redeem_total;
              if(payment_note_number!=="" && payment_note_number!==null && payment_note_redeem_total!==null && payment_note_redeem_total!==""){
                this.setState({is_reedeem:true});
              }

              if(type==="sales_return" || type==="purchase_return"){
                this.setState({is_on_account_return:data.is_on_account});
                if(data.is_on_account===true){
                  this.setState({return_goods_bills:data.return_goods});
                }
              } 


              if(data.user_info && data.user_info[0]){
                this.setState({issued_by:data.user_info[0].username});
              }
              if(assisted_by>0 && assisted_by!==""){
                this.handleGetContactOnId('assisted_by',assisted_by);
              }
             
              if(agent_name!==""){
                agent_name = parseInt(agent_name);
              }

              if(agent_name>0 && agent_name!==""){
                this.handleGetContactOnId('agent_name',agent_name);
              }
              
              let rooff = data.round_off;
              let rooff_sym = data.round_off_sym;
              this.setState({round_off:rooff});
              this.setState({round_off_sym:rooff_sym});
             
              let gdisc = data.global_discount;
              let gdisc_sym = data.global_discount_sym;
              this.setState({global_discount:gdisc});
              this.setState({discount_sym:gdisc_sym});
              
              if(gdisc_sym==='per'){
                let sub_total = parseFloat(data.sub_total.replace(/,/g , ""));
                let disc = 0;
                if(data.discount!==undefined){
                  if(isNaN(data.discount)){
                    disc =parseFloat(data.discount.replace(/,/g , ""));
                  }else{
                    disc =parseFloat(data.discount);
                  }
                }
                let stotal = sub_total-disc;
                let gamount = stotal*gdisc/100;
                this.setState({global_discount_amount:gamount});
              }
              

              let billing_date = data.created_date;
              let invoice_date = data.invoice_date;
              this.setState({billingDate:billing_date});
              this.setState({invoiceDate:invoice_date});
              let contact_info = data.contact_info;
              let contact_name = '';
              let contact_id = '';
              let address_1 =  '';
              let address_2 = '';
              let city = '';
              let credit_limit = '';
              let email = '';
              let phone_1 = '';
              let phone_2 = '';
              let state = '';
              let display_state = '';
              let gst_number = '';
              let pin = '';
              if(Object.keys(contact_info).length > 0) {
                 contact_name = contact_info[0].name;
                 contact_id = contact_info[0].contact_id;
                 address_1 = contact_info[0].address_1;
                 address_2 = contact_info[0].address_2;
                 city = contact_info[0].city;
                 credit_limit = contact_info[0].credit_limit;
                 email = contact_info[0].email;
                 phone_1 = contact_info[0].phone_1;
                 phone_2 = contact_info[0].phone_2;
                 state = contact_info[0].state;
                 display_state = contact_info[0].display_state;
                 gst_number = contact_info[0].gst_number;
                 pin = contact_info[0].pin;
              }
              let c_info = {item_name:contact_name, item_id:contact_id,address_1:address_1,address_2:address_2,city:city,credit_limit:credit_limit,email:email,phone_1:phone_1,phone_2:phone_2,state:state,display_state:display_state,gst_number:gst_number,pin:pin};
              this.setState({contact_info:c_info});

              let pending_amount = parseInt(data.pending_amount);
              if(pending_amount>0){
                this.setState({is_pending:true});
              }
              let sequence = data.number;
              let sequence_no = data.number_sequence;
              this.sequence[t] = sequence;
              this.sequence[t+" No"] = sequence_no;
              this.setState({currrent_sequence:sequence});
              this.setState({currrent_sequence_no:sequence_no});

              
              let list_items = data.list_items;

              if(data.is_e_invoice_generated!==undefined){
                this.setState({is_e_invoice_generated:data.is_e_invoice_generated});
              }
              

              let total_quantity = 0;
              let slab_igst = [];
              let slab_cgst = [];
              let slab_sgst = [];


              let slab_igst_kp = [];
              let slab_cgst_kp = [];
              let slab_sgst_kp = [];

              if(data.return_data!==undefined){
                let return_data = data.return_data;
                for(let i=0;i<return_data.length;i++){
                  let items = return_data[i];
                  this.return_more_rows.push(items);
                }
                this.setState({return_more_rows:this.return_more_rows});
              }
           
              for(let i=0;i<list_items.length;i++){

                let quantity = list_items[i].quantity;

                let price = parseFloat(list_items[i].price.replace(/,/g , ""));
                let total = parseFloat(list_items[i].total.replace(/,/g , ""));
                let ssgst = list_items[i].sgst;

                let ssgst_total = list_items[i].total_sgst;
               
                let scgst = list_items[i].cgst;
                let scgst_total = list_items[i].total_cgst;
                let sigst = list_items[i].igst;
                let sigst_total = list_items[i].total_igst;
                

                if(slab_igst[sigst]===undefined){
                  slab_igst[sigst] = [];
                  this.igsttaxable[sigst]=[];
                  this.igsttaxnet[sigst]=[];
                  if(sigst>0){
                    this.igst_slab_key.push(sigst);
                  }
                }

                if(slab_igst[sigst]!==undefined){
                  let tot_tax = (price*quantity)*parseFloat(sigst)/100;
                  let total_sigst = (slab_igst[sigst].length===undefined)?parseFloat(slab_igst[sigst])+tot_tax:tot_tax;

                  slab_igst[sigst]=total_sigst//total_sigst;

                  let total_taxable = (this.igsttaxable[sigst].length===undefined)?parseFloat(this.igsttaxable[sigst])+(quantity*price):(quantity*parseFloat(price));
                  this.igsttaxable[sigst] = total_taxable;

                  let total_net = (this.igsttaxnet[sigst].length===undefined)?parseFloat(this.igsttaxnet[sigst])+total:parseFloat(total);
                  this.igsttaxnet[sigst] = total_net;
                }

                if(slab_cgst[scgst]===undefined){
                  slab_cgst[scgst] = [];
                  this.csgsttaxable[scgst]=[];
                  this.csgsttaxnet[scgst]=[];
                  if(scgst>0){
                    this.cgst_slab_key.push(scgst);
                  }
                }
                if(slab_cgst[scgst]!==undefined){
                  let tot_tax = (price*quantity)*parseFloat(scgst)/100;
                  let total_scgst = (slab_cgst[scgst].length===undefined)?parseFloat(slab_cgst[scgst])+tot_tax:tot_tax;

                  slab_cgst[scgst] = total_scgst;


                  let tot_taxable = (this.csgsttaxable[scgst].length===undefined)?parseFloat(this.csgsttaxable[scgst])+(quantity*price):(quantity*price);
                  this.csgsttaxable[scgst] = tot_taxable;

                  let total_net = (this.csgsttaxnet[scgst].length===undefined)?parseFloat(this.csgsttaxnet[scgst])+total:total;
                  this.csgsttaxnet[scgst] = total_net;
                }

                if(slab_sgst[ssgst]===undefined){
                  slab_sgst[ssgst] = [];
                  if(ssgst>0){
                    this.sgst_slab_key.push(ssgst);
                  }
                }
                if(slab_sgst[ssgst]!==undefined){
                  let tot_tax = (price*quantity)*parseFloat(scgst)/100;
                  let total_ssgst = (slab_sgst[ssgst].length===undefined)?parseFloat(slab_sgst[ssgst])+tot_tax:tot_tax;
                
                  slab_sgst[ssgst] = total_ssgst;;
                }

                total_quantity = total_quantity+parseInt(list_items[i].quantity);
                let items = list_items[i];
                let key = i+1;
                this.more_rows.push(items);
              }
              

              this.tax_slab['sgst'] = slab_sgst;
              this.tax_slab['cgst'] = slab_cgst;
              this.tax_slab['igst'] = slab_igst;

              

              this.setState({more_rows:this.more_rows,total_quantity:total_quantity});

              let _this = this;
              setTimeout(function(){
                _this.shareMessage();
              },100);
              if(view_type==='print'){
                this.setState({is_print_cicked:true});
                setTimeout(function(){
                  if(document.getElementById("print")){
                    document.getElementById("print").click();
                  }
                },1000);
              }
              let is_payment = false;
              if(this.state.target){
                if(this.state.target==='sales'){
                  if(this.is_access('payments-in-create')){
                   this.setState({is_payment:true})
                   is_payment = true;
                  }
                }else if(this.state.target==='purchase'){
                  if(this.is_access('payments-out-create')){
                   this.setState({is_payment:true})
                   is_payment = true;
                  }
                }else if(this.state.target==='sales_return'){
                  
                  if(this.is_access('payments-out-create')){
                   this.setState({is_payment:true})
                   is_payment = true;
                  }
                }else if(this.state.target==='purchase_return'){
                  if(this.is_access('payments-in-create')){
                   this.setState({is_payment:true})
                   is_payment = true;
                  }
                }
              }

              if(is_payment===true){
                this.setState({payment_status:'Pending'});
                this.setState({is_pending:true});
              }else if(is_payment===false){
                this.setState({payment_status:'Paid'})
              }
              if(data.bill_status==="Paid"){
                this.setState({is_pending:false})
                this.setState({payment_status:'Paid'})
              }
              if(data.bill_status==="On Hold"){
                this.setState({is_pending:false})
                this.setState({payment_status:'On Hold'})
              }

              if(this.state.target==='sales_return' || this.state.target==='purchase_return'){
                this.setState({is_pending:false})
                this.setState({payment_status:data.bill_status});
              }

              if(data.bill_status==="Trashed"){
                  this.setState({is_pending:false})
                  this.setState({payment_status:'Trashed'});
              }
              if(data.bill_status==="Cancelled"){
                this.setState({is_pending:false})
                this.setState({payment_status:'Cancelled'});
              }
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                }
            }
        })

        if(t==='New Purchase'){
          let body = {};
          body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token, _schema : user_info._schema,
             }
       
          api = app_api+'/get_default_barcode_template';
          axios.post(api, body)
            .then(function (response) {
              let cursor = response.data;
              
              this.setState({ 'barcode_template_data': cursor}); 
          }.bind(this)).catch(function(res){
              if(res.response){
                if(res.response.data){
                    
                }
              }
          })
        }


      }

        
        let send_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, _schema : user_info._schema,
            key:'terms_and_conditions'
        }
        let api = app_api+'/get_settings';
        axios.post(api, send_data)
          .then(function (response) {
            let resp = response.data;
            if(resp.terms_and_conditions){
              let terms = resp.terms_and_conditions;
              
              this.setState({terms_and_conditions:terms});
            }
        }.bind(this)).catch(function(res){
            
        })

        let PaymentMode = [];
      let pmbody = {};
        pmbody = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, _schema : user_info._schema,
           }
        let pmapi = app_api+'/get_payment_mode';
        axios.post(pmapi, pmbody)
          .then(function (response) {
            let cursor = response.data;
            cursor.map( (value, key) => {
                let id = value.payment_mode_id;
                let payment_mode = value.payment_mode;
                PaymentMode[key] = {payment_mode:payment_mode,status:value.status,direct_pay:value.direct_pay};
            });
            this.setState({ 'payment_modes': PaymentMode }); 
        }.bind(this)).catch(function(res){
            if(res.response){
                if(res.response.data){
                    
                }
            }
        })
    }

    handleLoadData = (e) =>{
      let clicked_on = e.currentTarget.id;
      document.getElementById(clicked_on).classList.add("hide");	
      document.getElementById(clicked_on+'-btn-spinner').classList.remove("hide");
      let send_data = {
        store_name:user_info.store_name,
        branch_id:user_info.login_id,
        user_id : user_info.user_id,
        id : this.state.update_id,
        type:this.state.target,
        pwd:'process_bill_item',
        uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, _schema : user_info._schema,
      } 
      let set_api = app_api+'/orders/process_order_missing_items';
      axios.post(set_api, send_data)
        .then(function (response) {
          window.location.reload();
      }.bind(this)).catch(function(res){
          
      })
    }
    handlePrintDDToggle = (e) =>{
      let id = e.currentTarget.id;
      let is_print_cicked = this.state.is_print_cicked;
      this.setState({is_print_cicked:!is_print_cicked});
      if(id==='view-print' || id==='close-print-overlay'){   
        this.setState({response_id:0});
      }
    }

    openSendEInvoicePopup = ()=>{
        var element = document.getElementById('generate_e_invoice_popup');
        element.classList.remove("fade");
        element.style.display="block"; 
    }
    handleShowModifyAdvSaleData = () =>{
      this.setState({is_modify_advance_sale_data : true});
    }
    handleCloseAdvSaleData = () =>{
      this.setState({is_modify_advance_sale_data : false});
    }
    handleSaveAdvanceSaleData = (e) =>{
      let thisid = e.currentTarget.id;
      document.getElementById(thisid).classList.add("hide");	
      document.getElementById(thisid+'-btn-spinner').classList.remove("hide");
      let adv_data_1 = {};
      let adv_data_2 = {};

      let qs1 = document.querySelectorAll('.adv_data_1');
      let qs2 = document.querySelectorAll('.adv_data_2');
      for (let index = 0; index < qs1.length; index++) {
        const element = qs1[index];
        let id = element.id;
        let value = element.value;
        adv_data_1[id] = value;
      }
      for (let index = 0; index < qs2.length; index++) {
        const element = qs2[index];
        let id = element.id;
        let value = element.value;
        adv_data_2[id] = value;
      }
      
      let body = {};
            body = {
                store_name : user_info.store_name,
                branch_id : user_info.login_id,
                user_id : user_info.user_id,
                uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token, _schema : user_info._schema,
                id : this.state.update_id,
                target : this.state.target,
                adv_data_1 : adv_data_1,
                adv_data_2 : adv_data_2,
             }
          let api = app_api+'/save_advance_sale_data';
          axios.post(api, body)
            .then(function (response) {
              window.location.reload();
          }.bind(this)).catch(function(res){ 
            document.getElementById(thisid).classList.remove("hide");	
            document.getElementById(thisid+'-btn-spinner').classList.add("hide");
          })
    }
   
    render() {
        let view = true;
        let edit = true;
        let del = true;
        let is_payment = false;
        let target = this.state.target;
        let is_cd_note = false;
        let cd_type = 'Credit';
        if(this.state.target){
            if(target==='sales'){
              if(!this.is_access('billing-sale-view')){
                view = false;
              }
              if(!this.is_access('billing-sale-modify')){
                edit = false;
              }
              if(!this.is_access('billing-sale-delete')){
                del = false;
              }
              if(this.is_access('payments-in-create')){

                is_payment = true;
              }
            }else if(target==='purchase'){
              if(!this.is_access('billing-purchase-view')){
                view = false;
              }
              if(!this.is_access('billing-purchase-modify')){
                edit = false;
              }
              if(!this.is_access('billing-purchase-delete')){
                del = false;
              }
              if(this.is_access('payments-out-create')){
                is_payment = true;
              }
            }else if(target==='sales_return'){
              if(!this.is_access('billing-sales-return-view')){
                view = false;
              }
              if(!this.is_access('billing-sales-return-modify')){
                edit = false;
              }
              if(!this.is_access('billing-sales-return-delete')){
                del = false;
              }
              if(this.is_access('payments-out-create')){
                is_payment = true;
              }
            }else if(target==='purchase_return'){
              if(!this.is_access('billing-purchase-return-view')){
                view = false;
              }
              if(!this.is_access('billing-purchase-return-modify')){
                edit = false;
              }
              if(!this.is_access('billing-purchase-return-delete')){
                del = false;
              }
              if(this.is_access('payments-in-create')){
                is_payment = true;
              }
            }
            
        }

        if(this.state.is_e_invoice_generated===true){
          del = false;
          edit = false;
        }
      
        if(this.state.billing_type==='New Sale'){
          is_cd_note = true;
          cd_type = 'Credit';
        }
        if(this.state.billing_type==='New Purchase'){
          is_cd_note = true;
          cd_type = 'Debit';
        }
      
        let total_payment_paid = 0;
        let total_payment_balance = 0;
        let total_amount = 0;
        let is_variant = '';
        let s_no = 0;

        let total_colspan = 4;
        let total_colspan1 = 7;

        if((this.state.billing_type==='New Purchase' || this.state.billing_type==='New Sale') && this.state.show_inventory_custom_field===true && this.state.billing_data.custom_data!==undefined && this.state.billing_data.custom_data.length>0){
          total_colspan = total_colspan+this.state.billing_data.custom_data.length;
          total_colspan1 = total_colspan1+this.state.billing_data.custom_data.length;
        }
        return (
            <div className="d-flex billing-details" id="wrapper" onClick={this.handleDivClick}>
              {(this.state.is_on_fly===false) &&
              <Leftnav />
              }
              <div   className="view-on-fly" id="page-content-wrapper">
              <nav className="navbar border-bottom">
                  <div className="row">
                    {(utils.isMobile()===false) &&
                    <div className="col-md-3">
                        <div className="mod-title">
                          <a href="/billings"><span>Billing</span></a> / <a href={"/billings/"+this.state.target}>
                            <span>{this.state.billing_type}</span>
                          </a> / <b id="breadcrumbs_subtitle">View Billing</b>
                        </div>
                    </div>
                    }
                    {(this.state.payment_status==='Cancelled'  && utils.isMobile()===false) &&
                      <div className="col-md-7 text-right mrtop-15">
                        <div>
                        {(this.state.is_fy_closed===false) &&
                          <>
                            <a href={"/new-order/resume/"+this.state.target+"/"+this.state.update_id} className="div-display"><button type="button" className="btn btn-success btn-sm">Restart Billing</button></a>
                          </>
                        }
                        {(this.state.is_fy_closed===true) &&
                          <ClosedFYButton className="btn-sm" label="Restart Billing" type="button"/>
                        }
                        </div>
                      </div>
                    }


                    {(this.state.payment_status!=='Cancelled') &&
                    <>
                    {(utils.isMobile()===false) &&
                     <div className="col-md-3  mrtop-15 text-right">
                       {(this.state.is_pending && this.state.is_payment && parseInt(user_info.login_id)===parseInt(this.state.view_branch_id)) &&
                        <div>
                          {(this.state.is_fy_closed===false) &&
                            <>
                              <a href={"/new-payment/"+this.state.target+"/"+this.state.update_id} className="div-display"><button type="button" className="btn btn-success btn-sm">Add Payment</button></a>
                            </>
                          }
                          {(this.state.is_fy_closed===true) &&
                            <ClosedFYButton className="btn-sm" label="Add Payment" type="button"/>
                          }
                        </div>
                        }
                        {(this.state.bill_status==="On Hold") &&
                        <div>
                           {(this.state.is_fy_closed===false) &&
                            <>
                              <a href={"/new-order/edit/"+this.state.target+"/"+this.state.update_id} className="div-display"><button type="button" className="btn btn-success btn-sm">Resume Billing</button></a>
                            </>
                          }
                          {(this.state.is_fy_closed===true) &&
                            <ClosedFYButton className="btn-sm" label="Resume Billing" type="button"/>
                          }
                        </div>
                        }
                    
                    </div>
                  }
                    <div className={(utils.isMobile()===false)?"col-md-5 mrtop-15 action-area text-right":"col-md-12 mrtop-15 action-area text-right"}>
                      {(parseInt(user_info.login_id)===parseInt(this.state.view_branch_id)) &&
                      <div className="e-p-p-opt div-display">
                          {(this.state.payment_status==='Cancelled' && utils.isMobile()===true) &&
                            <>
                              {(this.state.is_fy_closed===false) &&
                              <div className="eppopt">
                                  <a href={"/new-order/resume/"+this.state.target+"/"+this.state.update_id}>Restart Billing</a>
                              </div>
                              }
                              {(this.state.is_fy_closed===true) &&
                                <ClosedFYButton className="eppopt" label='Restart Billing' type="div"/>
                              }
                            </>
                          }
                          {(this.state.is_pending && this.state.is_payment && utils.isMobile()===true && parseInt(user_info.login_id)===parseInt(this.state.view_branch_id)) &&
                            <>
                               {(this.state.is_fy_closed===false) &&
                                  <div className="eppopt">
                                      <a href={"/new-payment/"+this.state.target+"/"+this.state.update_id}>Add Payment</a>
                                  </div>
                                }
                                {(this.state.is_fy_closed===true) &&
                                  <ClosedFYButton className="eppopt" label='Add Payment' type="div"/>
                                }
                            </>
                          }
                          {(this.state.bill_status==="On Hold"  && utils.isMobile()===true) &&
                          <>
                            {(this.state.is_fy_closed===false) &&
                              <div className="eppopt">
                                  <a href={"/new-order/edit/"+this.state.target+"/"+this.state.update_id} >Resume Billing</a>
                              </div>
                            }
                            {(this.state.is_fy_closed===true) &&
                              <ClosedFYButton className="eppopt" label='Resume Billing' type="div"/>
                            }
                            </>
                          }
                        {(this.state.whatsapp_phone!==false && this.state.bill_status!=="On Hold") &&
                          <div className="eppopt"><ShareOptions target="dropdown" share={{id:this.state.update_id,sharable_link:this.state.billing_data.sharable_link,whatsapp_phone:this.state.whatsapp_phone,whatsapp_msg:this.state.whatsapp_msg,label:'Share'}} id="view-share"></ShareOptions></div>
                        }
                        {(this.state.bill_status!=="On Hold") &&
                        <>
                          <div className="eppopt">
                              <div className="share-opt-blk">
                                <div className="share-dd">
                                    <div className="share-dd-clk" onClick={this.handlePrintDDToggle} id='view-print'>Print</div>
                                    {(this.state.is_print_cicked===true) &&
                                    <>
                                    <div className="close-print-overlay" onClick={this.handlePrintDDToggle} id="close-print-overlay"></div>
                                    <ul className="list-group" id={'printDDToggle'}   >
                                        <li  className="list-group-item" onClick={this.handlePrint} id="print">
                                           <span>Bill</span>
                                        </li>
                                        {(this.state.billing_type==="New Purchase Return" || this.state.billing_type==="New Sale") &&
                                          <>
                                            <li id="generate-waybill" className="list-group-item">
                                              <DrawerLink  target="waybill" url={"/view-order/"+this.state.target+"/"+this.state.update_id} schema={this.state.billing_data.schema} name={"Waybill"} info={this.state.bill_view_response}/>
                                            </li>
                                           
                                            <ParcelSticker bill_view_response={this.state.bill_view_response}/>
                                           
                                          </>
                                        }
                                    </ul>
                                    </>
                                    }
                                </div>
                                
                            </div>
                          </div>
                          </>
                        }
                          {(this.state.billing_type==="New Purchase") &&
                            <div  onClick={this.openBarcodePrintPreview} id="preview" className="eppopt"><a>Generate Barcode</a></div>
                          }
                          {/* {((this.state.billing_type==="New Purchase" || this.state.billing_type==="New Sale") && this.state.send_e_invoice===true) &&
                          <>
                            {(user_info.gst_type==='regular' && user_info.gst_number!=="" && this.state.contact_info.gst_number!=='' && this.state.is_e_invoice_generated===false) &&
                              <div onClick={this.openSendEInvoicePopup} id="send-e-invoice" className="eppopt"><a>Send e-Invoice</a></div>
                            }
                          </>
                          } */}
                          
                          {(this.state.billing_type!=="New Purchase" && utils.isMobile()===false && this.state.bill_status!=="On Hold") &&
                            <div  onClick={this.handlePrint} id="preview" className="eppopt">
                              <a id="preview-lbl">Preview</a>
                              <a id="preview-btn-spinner" className="hide"><FontAwesomeIcon icon={faSpinner} pulse/></a>
                            </div>
                          }
                          {(edit || del) &&
                            <>
                                {edit &&
                                  <>
                                    {(this.state.is_fy_closed===false) &&
                                      <div className="eppopt"><a href={"/new-order/edit/"+this.state.target+"/"+this.state.update_id}>Edit</a></div>
                                    }
                                    {(this.state.is_fy_closed===true) &&
                                      <ClosedFYButton className="eppopt" label='Edit' type="div"/>
                                    }
                                  </>
                                }
                                {del &&
                                  <>
                                  {(this.state.is_fy_closed===false) &&
                                    <>
                                      <div className="eppopt no-border-right" id={this.state.update_id} onClick={this.delteBilling} data_target="delete_confirm" data_value={this.state.target} data_id={"delete_"+this.state.target+'_order'}><a>{(this.state.billing_data && this.state.billing_data.is_current===true)?'Delete':'Cancel'}</a></div>
                                    </>
                                  }
                                  {(this.state.is_fy_closed===true) &&
                                    <ClosedFYButton className="eppopt no-border-right" label={(this.state.billing_data && this.state.billing_data.is_current===true)?'Delete':'Cancel'} type="div"/>
                                  }
                                  </>
                                }
                            </>
                        }

                      </div>
                    }
                    </div>
                    </>
                   }
                   {(utils.isMobile()===false) &&
                    <div className="col-md-1 mrtop-10 text-right" style={{padding:'0px'}}>
                      <div className="next-prev-bill">
                          {(this.state.previous_id>0) &&
                            <a href={"/view-order/"+this.state.target+"/"+this.state.previous_id} class="prev-bill">&laquo;</a>
                          }
                          {(this.state.next_id>0) &&
                            <a href={"/view-order/"+this.state.target+"/"+this.state.next_id} class="next-bill">&raquo;</a>
                          }
                        </div>
                    </div>
                  }
                  </div>
                </nav>
                <div id="main-container">
                <div className="container-fluid">
                <ServiceLoader show={this.state.show_loader}/>
                  <div className="content-area">
                     <form id="contact-info-form">

                   
                     <div className="content-head border-bottom no-print-block">
                       <div className="row mrbtm-12">
                        <div className="col-md-4 mrtop-5 mob-content">
                            <label className="bil_no">Invoice {this.state.currrent_sequence} </label>
                        </div>
                        <div className="col-md-4 mrtop-5 text-center mob-content">
                            <label className="bill-amount">Amount : {(this.state.billing_data && this.state.billing_data.total)?this.state.billing_data.total:0}</label>
                        </div> 
                        <div className="col-md-4 mrtop-5 text-right mob-content">
                            <label className="bil_no">Status : {this.state.payment_status}</label>
                            
                        </div> 
                       </div>
                     </div>
                   
                
                     <div className="main-content border-bottom no-print-block">
                        <div className="inner-content">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="bill-to-c text-right">
                                  <span className="fe-title">BILL TO</span>
                                </div>
                                <div className="bill-to-info">
                                  <p className="title">{this.state.contact_info!=''?this.state.contact_info.item_name:''}</p>
                                  {(this.state.contact_info!=='' && this.state.contact_info.address_1!=='' && this.state.contact_info.address_1!==null)?<p>{this.state.contact_info.address_1}</p>:''}
                                  <p>
                                    {(this.state.contact_info!=='' && this.state.contact_info.city!=='' && this.state.contact_info.city!==null)?this.state.contact_info.city:''}
                                    {(this.state.contact_info!=='' && this.state.contact_info.pin!=='' && this.state.contact_info.pin!==null)?'-'+this.state.contact_info.pin:''}
                                    {(this.state.contact_info!=='' && this.state.contact_info.city!=='' && this.state.contact_info.city!==null && this.state.contact_info.display_state!=='' && this.state.contact_info.display_state!==null)?', ':''}

                                    {(this.state.contact_info!=='' && this.state.contact_info.display_state!=='' && this.state.contact_info.display_state!==null)?this.state.contact_info.display_state:''}
                                    </p>
                                  <p>
                                    {(this.state.contact_info!=='' && this.state.contact_info.phone_1!=='' && this.state.contact_info.phone_1!==null)?this.state.contact_info.phone_1:''}

                                    {(this.state.contact_info!=='' && this.state.contact_info.phone_1!=='' && this.state.contact_info.phone_1!==null  && this.state.contact_info.phone_2!=='' && this.state.contact_info.phone_2!==null)?', ':''}
                                    {(this.state.contact_info!=='' && this.state.contact_info.phone_2!=='' && this.state.contact_info.phone_2!==null)?this.state.contact_info.phone_2:''}
                                  </p>
                                  <p>{(this.state.contact_info!=='' && this.state.contact_info!=='' && this.state.contact_info.gst_number!==null && this.state.contact_info.gst_number!=='')?"GSTIN :"+this.state.contact_info.gst_number:''}</p>
                                </div>  
                                <div className="issued-on">
                                  
                                  <div className="input-group">
                                    
                                    <span className="fe-title no-print-block">Issued On : {this.state.billingDate}</span>
                                    
                                  </div>
                                 
                                  <div className="input-group issued_by">
                                      <span className="fe-title">Issued By : {this.state.issued_by}</span>
                                    
                                  </div>
                                  {(this.state.assisted_by!=="") && 
                                  <div className="input-group issued_by">
                                      <span className="fe-title">Assisted By : {this.state.assisted_by}</span>
                                    
                                  </div>
                                  }
                                  {(this.state.billing_data.agent_name!=="") && 
                                  <div className="input-group issued_by">
                                      <span className="fe-title">Agent Name : {this.state.billing_data.agent_name}</span>
                                    
                                  </div>
                                  }
                                  {(this.state.billing_type==='New Sales Return' || this.state.billing_type==='New Purchase Return') && 
                                  
                                    <div className="input-group">
                                      <>
                                        {(this.state.billing_data && this.state.billing_data.cd_sequence!==undefined && this.state.billing_data.cd_sequence!=="") &&
                                           <span className="fe-title">{(this.state.billing_type==='New Sales Return')?'Credit Note No.':'Debit Note No.'} : {(this.state.billing_data && this.state.billing_data.cd_sequence)?this.state.billing_data.cd_sequence:'-'}</span>
                                        }
                                        {(this.state.billing_data && this.state.billing_data.cd_sequence==="") &&
                                           <span className="fe-title">{(this.state.billing_type==='New Sales Return')?'Credit Note Type.':'Debit Note Type.'} : {this.state.payment_status}</span>
                                        }
                                        </>
                                    </div>
                                  }
                                </div>
                              </div> 
                          </div>
                          {this.state.billing_type==='New Purchase' &&
                          <div className="border-top pur-other-info">
                            <div className="row mrtop-15">
                              {(this.state.dispatched_info.supplier_ref_no && this.state.dispatched_info.supplier_ref_no!=="") &&
                                <div className="col-md-3">
                                  <span className="fe-title">Supplier's Reference No:</span>
                                  <p>{this.state.dispatched_info.supplier_ref_no?this.state.dispatched_info.supplier_ref_no:''}</p>
                                </div> 
                              }
                              {(this.state.invoiceDate && this.state.invoiceDate!=="") &&
                                <div className="col-md-3">
                                  <span className="fe-title">Invoice Date:</span>
                                  <p>{this.state.invoiceDate?this.state.invoiceDate:''}</p>
                                </div> 
                              }
                              {(this.state.dispatched_info.other_note && this.state.dispatched_info.other_note!=="") &&
                                <div className="col-md-3">
                                  <span className="fe-title">Other Note from Supplier:</span>
                                  <p>{this.state.dispatched_info.other_note?this.state.dispatched_info.other_note:''}</p>
                                </div>
                              }
                              {(this.state.dispatched_info.other_refrence && this.state.dispatched_info.other_refrence!=="") &&
                                <div className="col-md-3">
                                  <span className="fe-title">Other Reference:</span>
                                   <p>{this.state.dispatched_info.other_refrence?this.state.dispatched_info.other_refrence:''}</p>
                                </div>
                              }
                              {(this.state.dispatched_info.dispatched_through && this.state.dispatched_info.dispatched_through!=="") &&
                                <div className="col-md-3">
                                  <span className="fe-title">Transport:</span>
                                  <p>{this.state.dispatched_info.dispatched_through?this.state.dispatched_info.dispatched_through:''}</p>
                                </div> 
                              }
                              {(this.state.dispatched_info.dispatched_document && this.state.dispatched_info.dispatched_document!=="") &&
                              <div className="col-md-3">
                                <span className="fe-title">LR No Dispatched Document:</span>
                                <p>{this.state.dispatched_info.dispatched_document?this.state.dispatched_info.dispatched_document:''}</p>
                              </div> 
                              }
                              
                              {(this.state.dispatched_info.delivery_note && this.state.dispatched_info.delivery_note!=="") &&
                                <div className="col-md-3">
                                  <span className="fe-title">Delivey Note:</span>
                                  <p>{this.state.dispatched_info.delivery_note?this.state.dispatched_info.delivery_note:''}</p>
                                </div> 
                              }
                              
                              
                              {(this.state.billing_data.pieces_in_parcel && this.state.billing_data.pieces_in_parcel!=="") &&
                                <div className="col-md-3">
                                  <span className="fe-title">Pieces in Parcel:</span>
                                   <p>{this.state.billing_data.pieces_in_parcel?this.state.billing_data.pieces_in_parcel:''}</p>
                                </div>
                              }
                              
                              {(this.state.billing_data.no_of_parcels && this.state.billing_data.no_of_parcels!=="") &&
                                <div className="col-md-3">
                                  <span className="fe-title">No. of Parcels/Bales:</span>
                                   <p>{this.state.billing_data.no_of_parcels?this.state.billing_data.no_of_parcels:''}</p>
                                </div>
                              }
                          </div>
                        </div>
                        }
                        {(this.state.billing_type==='New Sale' && this.state.advance_sale_data===true) &&
                          <>
                          {(this.state.is_e_invoice_generated===true && this.state.is_modify_advance_sale_data===true) &&
                            <div className="main-content">
                                <div className="inner-content">
                                  
                                    <div className={"row"}>
                                        <div className="col-md-3 mrtop-5">
                                            <span className="fe-title">AGENT NAME</span>
                                            <input type="text" className="form-control adv_data_1" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.agent_name)?this.state.dispatched_info.agent_name:''} id="agent_name"/>
                                          </div> 
                                        
                                          <div className="col-md-3 mrtop-5">
                                            <span className="fe-title">TRANSPORT</span>
                                            <input type="text" className="form-control  adv_data_1" placeholder="" id="dispatched_through" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.dispatched_through)?this.state.dispatched_info.dispatched_through:''}/>
                                          </div> 
                                  
                                          <div className="col-md-3 mrtop-5">
                                            <span className="fe-title">LR NO DISPATCHED DOCUMENT</span>
                                            <input type="text" className="form-control  adv_data_1" placeholder="" id="dispatched_document" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.dispatched_document)?this.state.dispatched_info.dispatched_document:''}/>
                                          </div> 
                                        
                                          <div className="col-md-3 mrtop-5">
                                            <span className="fe-title">SUPPLIER'S REFRENCE NO</span>
                                            <input type="text" className="form-control  adv_data_1" placeholder="" id="supplier_ref_no" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.supplier_ref_no)?this.state.dispatched_info.supplier_ref_no:''}/>
                                          </div> 
                                          <div className="col-md-3 mrtop-5">
                                            <span className="fe-title">DELIVERY NOTE</span>
                                            <input type="text" className="form-control  adv_data_1" placeholder="" id="delivery_note" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.delivery_note)?this.state.dispatched_info.delivery_note:''}/>
                                          </div> 
                                          <div className="col-md-3 mrtop-5">
                                          <span className="fe-title">OTHER NOTE FROM SUPPLIER</span>
                                          <input type="text" className="form-control  adv_data_1" placeholder="" id="other_note"  defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.other_note)?this.state.dispatched_info.other_note:''}/>
                                            
                                        </div>
                                    
                                        <div className="col-md-3 mrtop-5">
                                          <span className="fe-title">OTHER REFERENCE</span>
                                            <input type="text" className="form-control  adv_data_1" placeholder="" id="other_refrence" defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.other_refrence)?this.state.dispatched_info.other_refrence:''}/>
                                        </div>
                                        <div className="col-md-3 mrtop-5">
                                          <span className="fe-title">TERMS OF DELIVERY</span>
                                            <input type="text" className="form-control  adv_data_1" placeholder="" id="terms_of_delivery"  defaultValue={(this.state.dispatched_info!=='' && this.state.dispatched_info.terms_of_delivery)?this.state.dispatched_info.terms_of_delivery:''}/>
                                        </div>
                                        <div className="col-md-3 mrtop-5">
                                          <span className="fe-title">PIECES IN PARCEL</span>
                                            <input type="text" className="form-control  adv_data_2" placeholder="" id="pieces_in_parcel"  defaultValue={(this.state.billing_data && this.state.billing_data.pieces_in_parcel)?this.state.billing_data.pieces_in_parcel:''}/>
                                        </div>
                                        <div className="col-md-3 mrtop-5">
                                          <span className="fe-title">NO. OF PARCELS/BALES</span>
                                            <input type="text" className="form-control  adv_data_2" placeholder="" id="no_of_parcels"  defaultValue={(this.state.billing_data && this.state.billing_data.no_of_parcels)?this.state.billing_data.no_of_parcels:''}/>
                                        </div>
                                        <div className="col-md-3 mrtop-5">
                                          <span className="fe-title">PACKED BY</span>
                                            <input type="text" className="form-control  adv_data_2" placeholder="" id="packed_by"  defaultValue={(this.state.billing_data && this.state.billing_data.packed_by)?this.state.billing_data.packed_by:''}/>
                                        </div>
                                        <div className="col-md-3 mrtop-5">
                                          <span className="fe-title">CHECKED BY</span>
                                            <input type="text" className="form-control  adv_data_2" placeholder="" id="checked_by"  defaultValue={(this.state.billing_data && this.state.billing_data.checked_by)?this.state.billing_data.checked_by:''}/>
                                        </div>
                                    </div>
                                    <div className="row mrtop-10">
                                      <div className="col-md-12 text-right">
                                          <button type="button" className="btn btn-secondary btn-sm" onClick={this.handleCloseAdvSaleData}>Close</button>
                                          <button type="button" className="btn btn-success btn-sm mrleft-10" onClick={this.handleSaveAdvanceSaleData} id="save-asd">Save</button>
                                          <button type="button" className="btn btn-success btn-sm mrleft-10 hide"  id="save-asd-btn-spinner"><FontAwesomeIcon icon={faSpinner} spin/></button>
                                      
                                      </div>
                                    </div>
                                </div>
                            </div>
                          }
                          {(this.state.is_modify_advance_sale_data===false) &&
                            <div className="border-top pur-other-info">
                              <div className="row mrtop-15">
                                {(this.state.is_e_invoice_generated===true) &&
                                <div className="col-md-12 text-right">
                                  <a onClick={this.handleShowModifyAdvSaleData} style={{color:'#0056b3',fontSize:'12px'}}>
                                    <FontAwesomeIcon icon={faPencilAlt} /> Modify Advance Sale Data
                                  </a>
                                </div>
                                }
                                {(this.state.dispatched_info.supplier_ref_no && this.state.dispatched_info.supplier_ref_no!=="") &&
                                  <div className="col-md-3">
                                    <span className="fe-title">Supplier's Reference No:</span>
                                    <p>{this.state.dispatched_info.supplier_ref_no?this.state.dispatched_info.supplier_ref_no:''}</p>
                                  </div> 
                                }
                                {(this.state.invoiceDate && this.state.invoiceDate!=="") &&
                                  <div className="col-md-3">
                                    <span className="fe-title">Invoice Date:</span>
                                    <p>{this.state.invoiceDate?this.state.invoiceDate:''}</p>
                                  </div> 
                                }
                                {(this.state.dispatched_info.other_note && this.state.dispatched_info.other_note!=="") &&
                                  <div className="col-md-3">
                                    <span className="fe-title">Other Note from Supplier:</span>
                                    <p>{this.state.dispatched_info.other_note?this.state.dispatched_info.other_note:''}</p>
                                  </div>
                                }
                                {(this.state.dispatched_info.other_refrence && this.state.dispatched_info.other_refrence!=="") &&
                                  <div className="col-md-3">
                                    <span className="fe-title">Other Reference:</span>
                                    <p>{this.state.dispatched_info.other_refrence?this.state.dispatched_info.other_refrence:''}</p>
                                  </div>
                                }
                                {(this.state.dispatched_info.dispatched_through && this.state.dispatched_info.dispatched_through!=="") &&
                                  <div className="col-md-3">
                                    <span className="fe-title">Transport:</span>
                                    <p>{this.state.dispatched_info.dispatched_through?this.state.dispatched_info.dispatched_through:''}</p>
                                  </div> 
                                }
                                {(this.state.dispatched_info.dispatched_document && this.state.dispatched_info.dispatched_document!=="") &&
                                <div className="col-md-3">
                                  <span className="fe-title">LR No Dispatched Document:</span>
                                  <p>{this.state.dispatched_info.dispatched_document?this.state.dispatched_info.dispatched_document:''}</p>
                                </div> 
                                }
                                
                                {(this.state.dispatched_info.delivery_note && this.state.dispatched_info.delivery_note!=="") &&
                                  <div className="col-md-3">
                                    <span className="fe-title">Delivey Note:</span>
                                    <p>{this.state.dispatched_info.delivery_note?this.state.dispatched_info.delivery_note:''}</p>
                                  </div> 
                                }
                                
                                
                                {(this.state.dispatched_info.terms_of_delivery && this.state.dispatched_info.terms_of_delivery!=="") &&
                                  <div className="col-md-3">
                                    <span className="fe-title">Terms of Delivey:</span>
                                    <p>{this.state.dispatched_info.terms_of_delivery?this.state.dispatched_info.terms_of_delivery:''}</p>
                                  </div>
                                }
                                {(this.state.billing_data.pieces_in_parcel && this.state.billing_data.pieces_in_parcel!=="") &&
                                  <div className="col-md-3">
                                    <span className="fe-title">Pieces in Parcel:</span>
                                    <p>{this.state.billing_data.pieces_in_parcel?this.state.billing_data.pieces_in_parcel:''}</p>
                                  </div>
                                }
                                
                                {(this.state.billing_data.no_of_parcels && this.state.billing_data.no_of_parcels!=="") &&
                                  <div className="col-md-3">
                                    <span className="fe-title">No. of Parcels/Bales:</span>
                                    <p>{this.state.billing_data.no_of_parcels?this.state.billing_data.no_of_parcels:''}</p>
                                  </div>
                                }
                                {(this.state.billing_data.checked_by && this.state.billing_data.checked_by!=="") &&
                                  <div className="col-md-3">
                                    <span className="fe-title">Checked By:</span>
                                    <p>{this.state.billing_data.checked_by?this.state.billing_data.checked_by:''}</p>
                                  </div>
                                }
                                {(this.state.billing_data.packed_by && this.state.billing_data.packed_by!=="") &&
                                  <div className="col-md-3">
                                    <span className="fe-title">Packed By:</span>
                                    <p>{this.state.billing_data.packed_by?this.state.billing_data.packed_by:''}</p>
                                  </div>
                                }
                            </div>
                            </div>
                          }
                          </>
                        }
                        </div>
                     </div>
                     </form>  
                     {(this.state.more_rows.length > 0 && this.state.is_items_visible===true) &&
                     <div className="table-responsive">
                     <table className="table">
                        <thead className="thead-light">
                          <tr>
                            
                            <th scope="col" className="item-serial">SN</th>
                            
                            <th scope="col" className="item-name">ITEM NAME</th>
                            
                            <th scope="col" className="not-thermal-print hsn-th">HSN/SAC</th>
                            <th scope="col" className="pr-quantity">
                                <span className="no-print-block">QUANTITY</span>
                                <span className="print-block">QTY.</span>
                            </th>
                            <th scope="col" className="not-thermal-print no-print-block">UNIT</th>
                            {this.state.billing_type==='New Sale' &&
                            <>
                              <th scope="col"  className="item-price">PRICE</th>
                            </>
                            }
                            {this.state.billing_type==='New Purchase' &&
                            <>
                              <th scope="col" className="not-thermal-print">PURCHASE PRICE</th>
                              <th scope="col" className="not-thermal-print">SELLING PRICE</th>
                             </>
                            }
                            {this.state.billing_type==='New Sales Return' &&
                              <>
                                <th scope="col" className="not-thermal-print">SELLING PRICE</th>
                              </>
                            }
                            {this.state.billing_type==='New Purchase Return' &&
                              <>
                                <th scope="col" className="not-thermal-print">PURCHASE PRICE</th>
                              </>
                            }
                            {(this.state.billing_type==='New Purchase'||this.state.billing_type==='New Sale' ) && 
                            <>
                              <th scope="col" className="not-thermal-print no-print-block">DISCOUNT%</th>
                              <th scope="col" className="print-block item-discount">DIS%</th>
                            </>
                            }
                            <th scope="col" className="not-thermal-print gst-th">GST %</th>
                           
                            {(this.state.billing_type==='New Sale' || this.state.billing_type==='New Purchase') && 
                            <>
                              <th scope="col" className="not-thermal-print gst-th">TAX TYPE</th>
                            </>
                            }
                            {(this.state.billing_type==='New Purchase') && 
                            <>
                              <th scope="col" className="not-thermal-print gst-th">CATEGORY</th>
                              <th scope="col" className="not-thermal-print gst-th">RE-ORDER QTY.</th>
                            </>
                            }
                            {((this.state.billing_type==='New Purchase' || this.state.billing_type==='New Sale') && this.state.show_inventory_custom_field===true) && 
                              <>
                                {(this.state.billing_data.custom_data!==undefined && this.state.billing_data.custom_data.length>0) &&
                                <>
                                  {this.state.billing_data.custom_data.map((value,index) => {
                                      return (
                                          <>
                                          {((this.state.billing_type==='New Purchase' && (value.is_purchase_active===true || value.is_purchase_active==="true")) || (this.state.billing_type==='New Sale' && (value.is_sale_active===true || value.is_sale_active==="true"))) &&
                                              <th scope="col" className="not-thermal-print gst-th">{value.name}</th>
                                          }
                                          </>
                                      )
                                  })} 
                                </>
                                }
                              </>
                              }
                            <th scope="col" style={{textAlign:'right'}}>TOTAL</th>
                          </tr>
                        </thead>
                        <tbody id="items-body">
                          {(this.state.more_rows.length > 0) ?this.state.more_rows.map( (value, index) => {
                            total_amount = total_amount+parseFloat((value.total!=='-')?value.total:0);   

                            if(value.is_variation){
                              is_variant = 'is_variant';
                            }else{
                              s_no++;
                            }       
                           return (
                             <tr className={"pitem-row items-row border-bottom "+is_variant} key={index} id={"item-row-"+index} data-id={index}>
                             
                              <td className="item-serial">
                              {(value.is_variation!==true && value.is_variation!=='true') &&
                              <span className="li-item">
                                {s_no} 
                              </span>
                              }
                              </td>
                            
                              <td className="item-name">
                              <>
                                <div className="item-area">
                                <span className="item-name-print">
                                {(value.parent_id!==0 && value.parent_id!=='0') &&
                                  <span style={{margin:'0px',lineHeight:'1.2'}}>
                                    {value.product_name} <span style={{fontWeight:'bold'}}>{value.item_name}</span>
                                  </span>
                                }
                                {(value.parent_id===0 || value.parent_id==='0') &&
                                  <>
                                    <span className="li-item">{value.item_name}</span>
                                  </>
                                }
                                </span>
                                {(value.barcode!=="" && value.barcode!==null) &&
                                    <>
                                      <span className="bcode-name bblk not-thermal-print"> 
                                        <p style={{margin:'0px',marginTop:'3px'}}>{value.barcode}</p>
                                        {(value.alias_barcode) &&
                                          <p className="alias-bc">{value.alias_barcode}</p>
                                        }
                                      </span>
                                    </>
                                }
                              </div>
                              {(value.barcode!=="" && value.barcode!==null) &&
                                <div className="print-block">
                                  <span className="bcode-name bblk thermal_display"> 
                                    <p style={{margin:'0px',marginTop:'3px'}}>{value.barcode}</p>
                                    {(value.alias_barcode) &&
                                      <p className="alias-bc">{value.alias_barcode}</p>
                                    }
                                  </span>
                                </div>
                              }
                              {(value.is_variation!==true) &&
                                <span className="i-desc">{(value && value.desc)?value.desc:''}</span>
                              }
                              </>
                              </td>
                              
                              <td className="not-thermal-print  hsn-td">
                                {(value.is_variation!==true) &&
                                  <span className="li-item">{(value && value.hsn)?value.hsn:''}</span>
                                }
                              </td>
                              <td className="td-quan">
                                  <p style={{display:'inline-flex',lineHeight:'1.2'}}>{(value && value.quantity)?value.quantity:''} 
                                  <span className="print-block">
                                    {(value && value.unit_name)?value.unit_name:''}
                                  </span>
                                  </p>
                                  {(value && value.bundle_quantity>0) &&
                                    <i>  ({value.bundle_quantity} {this.state.billing_data.bundle_text})</i>
                                  }
                              </td> 
                              <td className="units-dd no-print-block">
                                  <span className="li-item">{(value && value.unit_name)?value.unit_name:''}</span>
                              </td>
                             {this.state.billing_type==='New Sale' &&
                              <>
                               
                                <td className="item-price">
                                    <span className="li-item">{(value && value.price)?value.price:''}</span>
                                </td>
                              </>
                            }
                            {this.state.billing_type==='New Purchase' &&
                              <>
                                <td className="not-thermal-print">
                                  <span className="li-item">{(value && value.price)?value.price:''}</span>
                                </td> 
                                <td className="not-thermal-print">
                                  <span className="li-item">{(value && value.price2)?value.price2:''}</span>
                                </td>
                              </>
                            }
                             {this.state.billing_type==='New Sales Return' &&
                                <td className="not-thermal-print">
                                  <span className="li-item">{(value && value.price)?value.price:''}</span>
                                </td>
                              }
                              {this.state.billing_type==='New Purchase Return' &&
                                <td className="not-thermal-print">
                                  <span className="li-item">{(value && value.price)?value.price:''}</span>
                                </td> 
                              }
                              {(this.state.billing_type==='New Purchase'||this.state.billing_type==='New Sale' ) && 
                                <td className="item-discount">
                                  <div className="input-group">
                                    <span className="li-item">{(value && value.discount)?value.discount:0}</span>
                                  </div>
                                </td>
                              }
                            
                              <td className="not-thermal-print gst-td">
                                <div className="input-group">
                                  <span className="li-item">{(value && value.tax)?value.tax:0}</span>
                                </div>
                              </td>
                            
                            {(this.state.billing_type==='New Sale' || this.state.billing_type==='New Purchase') && 
                              <>
                                <td className="not-thermal-print gst-td">
                                  <span className="li-item">
                                    {(value.is_tax_inc==='including' || value.is_tax_inc==='Including GST')?'Inc.GST':'Exc.GST'}
                                  </span>
                                </td>
                              </>
                            }
                            {(this.state.billing_type==='New Purchase') && 
                              <>
                                <td className="not-thermal-print gst-td">
                                  <span className="li-item">
                                    {value.iitem_category_name}
                                  </span>
                                </td>
                                <td className="not-thermal-print gst-td text-center">
                                  <span className="li-item">
                                    {value.re_order_quantity}
                                  </span>
                                </td>
                              </>
                            }
                            {((this.state.billing_type==='New Purchase' || this.state.billing_type==='New Sale') && this.state.show_inventory_custom_field===true) && 
                              <>
                                {(value.custom_fields_json!==undefined && value.custom_fields_json.length>0) &&
                                <>
                                  {value.custom_fields_json.map((cfval,cfidx) => {
                                      return (
                                          <>
                                          {((this.state.billing_type==='New Purchase' && (cfval.is_purchase_active===true || cfval.is_purchase_active==="true")) || (this.state.billing_type==='New Sale' && (cfval.is_sale_active===true || cfval.is_sale_active==="true"))) &&
                                            <td className="not-thermal-print gst-td">
                                              <span className="li-item">
                                              {(cfval.value!=="")?cfval.value:'-'}
                                              </span>
                                            </td>
                                          }
                                          </>
                                      )
                                  })} 
                                </>
                                }
                              </>
                              }
                              <td className="item-rm text-right">
                                {(value && value.total && value.total!=='-') &&
                                  <span className="li-item">₹</span>
                                }
                                <span className="item-total li-item" id={"item-total-"+index} data-id={index}  data-target="item-total">
                                    {(value && value.total && value.total!=='-')?this.currency_format(value.total):'-'}
                                  </span>
                              </td>
                            </tr>
                            )
                            }) : <tr><td colSpan="8" className="text-center">{this.state.loading_process}</td></tr> }
                            
                        </tbody>
                       
                      </table>
                      </div>
                    }

                {(this.state.is_items_visible===false) &&
                  <div className="alert alert-danger" style={{padding:'3px'}}><p style={{margin:'0px',textAlign:'center'}}><strong>List item missing!</strong> Unable to load the the data, Please &nbsp;
                   <button type="button" className="btn btn-danger" id="load-data"  style={{padding: '0px 10px 2px',fontSize:'12px'}} onClick={this.handleLoadData}> Click Here </button> 
                  <button  style={{padding: '0px 10px 2px',fontSize:'12px'}} type="button" id="load-data-btn-spinner" className="hide mrleft-10 btn btn-danger"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                  &nbsp;to load</p></div>  
                }

                      {(this.state.return_more_rows.length>0) &&
                        <>
                        <div className="print-block" style={{paddingRight:'10px',marginTop:'10px'}}>
                          <h4 style={{textAlign:'right'}}>Subtotal : 
                          <span style={{fontWeight:'bold'}} className="rs-sym">₹</span><span className="sp-normal all-out" id="sub-total" style={{fontWeight:'bold'}}>{(this.state.billing_data && this.state.billing_data.sub_total)?this.state.billing_data.sub_total:0}</span></h4>
                        </div>
                        <div style={{margin:'10px 0px 20px 0px'}}>
                          <div className="ret-item-head">
                            <h4>Return Items</h4>
                          </div>
                          <div className="table-responsive">
                           <table className="table item-table border-bottom bilist">
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col" className="item-serial">S.NO.</th>
                                  <th scope="col">ITEM NAME</th>
                                  
                                  <th scope="col" className="text-center">QTY</th>
                                  <th scope="col" className="text-center not-thermal-print">UNIT</th>
                                  {this.state.billing_type==='New Sale' &&
                                    <>
                                      <th scope="col" className="text-center">S. PRICE</th>
                                      <th scope="col" className="text-center">DISC%</th>
                                    </>
                                  }
                                  {this.state.billing_type==='New Purchase' &&
                                    <>
                                      <th scope="col" className="text-center">P. PRICE</th>
                                    </>
                                  }
                                  <th scope="col" className="text-center not-thermal-print">GST%</th>
                                  <th scope="col" className="text-right">TOTAL</th>
                                </tr>
                              </thead>
                              <tbody>
                                  {(this.state.return_more_rows.length > 0) ?this.state.return_more_rows.map( (value, index) => {
                                    
                                   return (
                                     <tr className={"items-row border-bottom "+is_variant} key={index} id={"item-row-"+index} data-id={index}>
                                      <td className="item-serial">
                                        {index+1} 
                                      </td>
                                    
                                      <td className="item-name">
                                      <>
                                        <div className="item-area">
                                        <span className="item-name-print">{value.item_name}</span>
                                        {(value.barcode!=="" && value.barcode!==null) &&
                                        
                                          <span className="item-barcode">
                                            <span className="bcode-sep"> | </span>
                                          <span className="bcode-name"> {value.barcode} </span>
                                          </span>

                                        }
                                      </div>
                                        <p className="i-desc">{(value && value.desc)?value.desc:''}</p>
                                      </>
                                      </td>
                                      
                                      
                                      <td className="text-center">
                                          <p style={{display:'inline-flex'}}>
                                            {(value && value.quantity)?value.quantity:''}
                                          </p> 
                                      </td>

                                      <td className="units-dd text-center not-thermal-print">
                                          {(value && value.unit_name)?value.unit_name:''}
                                      </td>
                                    
                                       
                                      <td className="item-price text-center">
                                          {(value && value.price)?value.price:''}
                                      </td>
                                      {this.state.billing_type==='New Sale' &&
                                        <td className="item-discount text-center">
                                            {(value && value.discount)?value.discount:''}
                                        </td>
                                      }
                                      <td className="not-thermal-print gst-td text-center">
                                         
                                            {(value && value.tax)?value.tax:0}
                                        
                                      </td>
                                      <td className="item-rm text-right">
                                        {(value && value.total && value.total!=='-') &&
                                          <span>₹</span>
                                        }
                                        <span className="item-total" id={"item-total-"+index} data-id={index}  data-target="item-total">
                                            {(value && value.total && value.total!=='-')?this.currency_format(value.total):'-'}
                                          </span>
                                      </td>
                                    </tr>
                                    )
                                    }) : '' }
                                   
                              </tbody>
                            </table>
                           </div>
                        </div>
                        </>
                       }

                     <div className="content-head no-print-block">
                       <div className="row">
                          <div className="col-md-6 mrtop-5">
                              <p className="msg-to-cust">{(this.state.billing_data && this.state.billing_data.note)?this.state.billing_data.note:''}</p>

                              <table width="100%" className="table inline-table" style={{border:'1px solid #dee2e6'}}>
                                  <tr>
                                      <td>
                                        <span className="sp-normal">CGST</span>
                                      </td>
                                      <td className="text-right">
                                        <span className="rs-sym">₹</span><span className="sp-normal all-out" id="total-cgst">{(this.state.billing_data && this.state.billing_data.total_cgst)?this.state.billing_data.total_cgst:0}
                                        </span>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>
                                        <span className="sp-normal">SGST</span>
                                      </td>
                                      <td className="text-right">
                                        <span className="rs-sym">₹</span><span className="sp-normal all-out" id="total-sgst">{(this.state.billing_data && this.state.billing_data.total_sgst)?this.state.billing_data.total_sgst:0}
                                        </span>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td>
                                        <span className="sp-normal">IGST</span>
                                      </td>
                                      <td className="text-right">
                                        <span className="rs-sym">₹</span><span className="sp-normal all-out" id="total-igst">{(this.state.billing_data && this.state.billing_data.total_igst)?this.state.billing_data.total_igst:0}
                                        </span>
                                      </td>
                                  </tr>
                                  <tr>
                                      <th> <span className="sp-normal" style={{fontWeight:'bold'}}>TOTAL TAX</span></th>
                                      <td className="text-right"><span style={{fontWeight:'bold'}} className="rs-sym">₹</span><span className="sp-normal all-out" id="total-tax"  style={{fontWeight:'bold'}}>{(this.state.billing_data && this.state.billing_data.tax)?this.state.billing_data.tax:0}</span></td>
                                  </tr> 
                            </table>
                            {(this.state.billing_type==='New Sale' || this.state.billing_type==='New Purchase') &&
                              <>
                                <table width="100%" className="table inline-table bill-type-table">
                                  <tbody>
                                    <tr>
                                      <td>Bill Type:</td>
                                      <td className="bttitem text-right bill_type">
                                        {(this.state.billing_data && this.state.billing_data.payment_mode_txt)?this.state.billing_data.payment_mode_txt:''}
                                      </td>
                                    </tr>
                                    
                                    {(this.state.billing_data.payment_mode_txt==='payment' || this.state.is_direct_pay===true) &&
                                      <>
                                      {(this.state.billing_data.payment_mode_txt==='payment' && this.state.payment_modes.length > 0) ?this.state.payment_modes.map( (value, index) => {
                                         return (
                                          <>
                                          {(value.status===1) &&
                                            <>
                                            {(this.state.billing_type==='New Sale') &&
                                              <tr key={ index }>
                                                <td>{value.payment_mode}:</td>
                                                <td className="bttitem text-right">
                                                {(value.payment_mode==='Cash') &&
                                                <>
                                                  {(this.state.billing_data && this.state.billing_data.payments_meta_data && this.state.billing_data.payments_meta_data.bill_types && this.state.billing_data.payments_meta_data.bill_types.total_cash_amount)?'₹'+this.state.billing_data.payments_meta_data.bill_types['total_'+value.payment_mode.replaceAll(' ','_').toLowerCase()+'_amount']:'-'}
                                                </>
                                                }
                                                {(value.payment_mode!=='Cash') &&
                                                  <>
                                                   {(this.state.billing_data && this.state.billing_data.payments_meta_data && this.state.billing_data.payments_meta_data.bill_types && this.state.billing_data.payments_meta_data.bill_types['total_'+value.payment_mode.replaceAll(' ','_').toLowerCase()+'_amount']!==undefined && this.state.billing_data.payments_meta_data.bill_types['total_'+value.payment_mode.replaceAll(' ','_').toLowerCase()+'_amount']!=="")?'₹'+this.state.billing_data.payments_meta_data.bill_types['total_'+value.payment_mode.replaceAll(' ','_').toLowerCase()+'_amount']:'-'}
                                                  </>
                                                  }
                                                </td>
                                              </tr>
                                            }

                                            {(this.state.billing_type!=='New Sale' && value.payment_mode!=='Credit') &&
                                            <tr key={ index }>
                                              <td>{value.payment_mode}:</td>
                                              <td className="bttitem text-right">
                                              {(value.payment_mode==='Cash') &&
                                              <>
                                                {(this.state.billing_data && this.state.billing_data.payments_meta_data && this.state.billing_data.payments_meta_data.bill_types && this.state.billing_data.payments_meta_data.bill_types.total_cash_amount)?'₹'+this.state.billing_data.payments_meta_data.bill_types['total_'+value.payment_mode.replaceAll(' ','_').toLowerCase()+'_amount']:'-'}
                                              </>
                                              }
                                              {(value.payment_mode!=='Cash') &&
                                              <>
                                                 {(this.state.billing_data && this.state.billing_data.payments_meta_data && this.state.billing_data.payments_meta_data.bill_types && this.state.billing_data.payments_meta_data.bill_types['total_'+value.payment_mode.replaceAll(' ','_').toLowerCase()+'_amount']!==undefined && this.state.billing_data.payments_meta_data.bill_types['total_'+value.payment_mode.replaceAll(' ','_').toLowerCase()+'_amount']!=="")?'₹'+this.state.billing_data.payments_meta_data.bill_types['total_'+value.payment_mode.replaceAll(' ','_').toLowerCase()+'_amount']:'-'}
                                                </>
                                                }
                                              </td>
                                            </tr>
                                          }
                                          </>
                                        }
                                        </>
                                        )
                                       }) : '' }
                                      {(this.state.billing_data && this.state.billing_data.payments_meta_data && this.state.billing_data.payments_meta_data.bill_types && this.state.billing_data.payments_meta_data.bill_types['customer_advance_amount']!==undefined && this.state.billing_data.payments_meta_data.bill_types['customer_advance_amount']!=="") &&
                                        <tr key={ 'advance-pay' }>
                                          <td>Advance:</td>
                                          <td className="bttitem text-right">
                                             {(this.state.billing_data && this.state.billing_data.payments_meta_data && this.state.billing_data.payments_meta_data.bill_types && this.state.billing_data.payments_meta_data.bill_types['customer_advance_amount']!=="")?'₹'+this.state.billing_data.payments_meta_data.bill_types['customer_advance_amount']:'-'}
                                          </td>
                                        </tr>
                                      }
                                      {(this.state.is_direct_pay===true && (this.state.billing_data && this.state.billing_data.payment_mode!==1)) &&
                                      <>
                                        <tr style={{lineHeight:'.5',fontSize:'14px'}}>
                                          <td>Paid Amount</td>
                                          <td className="bttitem text-right">
                                            {(this.state.billing_data && this.state.billing_data.payments_meta_data && this.state.billing_data.payments_meta_data.bill_types)?'₹'+this.state.billing_data.payments_meta_data.bill_types['total_online_payment_amount']:'-'}
                                          </td>
                                        </tr>
                                        <tr style={{lineHeight:'.5',fontSize:'14px'}}>
                                          <td>Reference No</td>
                                          <td className="bttitem text-right">
                                            {(this.state.billing_data && this.state.billing_data.payments_meta_data && this.state.billing_data.payments_meta_data.bill_types)?this.state.billing_data.payments_meta_data.bill_types['online_ref_no']:'-'}
                                          </td>
                                        </tr>
                                        <tr style={{lineHeight:'.5',fontSize:'14px'}}>
                                          <td>Payment Note</td>
                                          <td className="bttitem text-right">
                                           {(this.state.billing_data && this.state.billing_data.payments_meta_data && this.state.billing_data.payments_meta_data.bill_types)?this.state.billing_data.payments_meta_data.bill_types['online_ref_note']:'-'}
                                          </td>
                                        </tr>
                                        </>
                                      }
                                      
                                      <tr style={{lineHeight:'.5'}}>
                                        <td><label>Bill Amount:</label></td>
                                        <td className="bttitem text-right">
                                          <label id="total-bill-amount">
                                          ₹{(this.state.billing_data && this.state.billing_data.payments_meta_data && this.state.billing_data.payments_meta_data.bill_amount)?this.state.billing_data.payments_meta_data.bill_amount:this.state.billing_data.total}

                                          </label>
                                        </td>
                                      </tr>
                                      <tr style={{lineHeight:'.5'}}>
                                        <td><label>Amount Paid:</label></td>
                                        <td className="bttitem text-right">
                                         <label id="total-paid-amount">₹{(this.state.billing_data && this.state.billing_data.payments_meta_data && this.state.billing_data.payments_meta_data.paid_amount)?this.state.billing_data.payments_meta_data.paid_amount:0}</label>
                                        </td>
                                      </tr>
                                      <tr style={{lineHeight:'.5'}}>
                                        <td><label>{(this.state.billing_data && this.state.billing_data.payments_meta_data && this.state.billing_data.payments_meta_data.return_amount && this.state.billing_data.payments_meta_data.return_amount>=0)?'Return':'Balance'}:</label></td>
                                        <td className="bttitem text-right">
                                          <label id="total-return-amount">{(this.state.billing_data && this.state.billing_data.payments_meta_data && this.state.billing_data.payments_meta_data.return_amount)?'₹'+Math.abs(this.state.billing_data.payments_meta_data.return_amount):0}</label>
                                        </td>
                                      </tr>
                                      </>
                                    }
                                  </tbody>
                                </table>
                              </>
                              }
                              
                          </div>
                          <div className="col-md-1"></div>
                          <div className="col-md-5 mrtop-40">
                            <table className="table billing-total">
                              <tbody>
                                  <tr>
                                      <td><span className="sp-normal">Total Units</span></td>
                                      <td className="text-right"><span className="sp-normal all-out" id="total-unit">{(this.state.billing_data && this.state.billing_data.total_unit)?this.state.billing_data.total_unit:0}</span></td>
                                  </tr>
                                  <tr>
                                      <td><span className="sp-normal">Total Quantity</span></td>
                                      <td className="text-right"><span className="sp-normal all-out" id="total-quantity">{this.state.total_quantity}</span></td>
                                  </tr>
                                  <tr>
                                      <td><span className="sp-normal" style={{fontWeight:'bold'}}>Subtotal</span></td>
                                      <td className="text-right"><span style={{fontWeight:'bold'}} className="rs-sym">₹</span><span className="sp-normal all-out" id="sub-total" style={{fontWeight:'bold'}}>{(this.state.billing_data && this.state.billing_data.sub_total)?this.state.billing_data.sub_total:0}</span></td>
                                  </tr>
                                  {(this.state.billing_type==='New Sale' || this.state.billing_type==='New Purchase') &&
                                    <>
                                      <tr>
                                          <td><span className="sp-normal">Discount</span></td>
                                          <td className="text-right"><span style={{fontWeight:'bold'}} className="rs-sym">₹</span><span className="sp-normal all-out" id="total-discount">{(this.state.billing_data && this.state.billing_data.discount)?this.state.billing_data.discount:0}</span></td>
                                      </tr> 
                                    </>
                                  }
                                  {((this.state.billing_type==='New Sales Return' || this.state.billing_type==='New Purchase Return') && (this.state.billing_data.discount!=="" && this.state.billing_data.discount!=="0" && this.state.billing_data.discount!==0) ) &&
                                    <>
                                      <tr style={{display:(this.state.billing_data && this.state.billing_data.discount && this.state.billing_data.discount>0)?'':'none'}} id="nsr-npr-discount">
                                          <td><span className="sp-normal">Discount</span></td>
                                          <td className="text-right"><span className="rs-sym">₹</span><span className="sp-normal all-out" id="total-discount">{(this.state.billing_data && this.state.billing_data.discount)?this.state.billing_data.discount:0}</span></td>
                                      </tr> 
                                    </>
                                  }
                                  {(this.state.billing_data && this.state.billing_data.global_discount!=='' && this.state.billing_data.global_discount!==0 && this.state.billing_data.global_discount!=='0') &&
                                  <tr>
                                      <td>
                                        <span className="sp-normal">
                                        Discount on Bill 
                                        {(this.state.discount_sym==='per') &&
                                          <>
                                             <span className="rs-sym" style={{fontWeight:'bold'}}> : ₹{this.floating_point(this.state.global_discount_amount)}
                                             </span>
                                          </>
                                        }
                                        </span>
                                      </td>
                                      <td className="text-right">
                                         
                                        <span className="sp-normal">
                                          {(this.state.billing_data && this.state.billing_data.global_discount!=='' && this.state.billing_data.global_discount!==0 && this.state.billing_data.global_discount!=='0') &&
                                            <>
                                              {(this.state.discount_sym==='rs') &&
                                              <>
                                              <span className="rs-sym">₹</span>{this.state.billing_data.global_discount}
                                              </>
                                              }
                                              {(this.state.discount_sym==='per') &&
                                              <>
                                               {this.state.billing_data.global_discount}%
                                               </>
                                              }
                                            </>
                                          }
                                        </span>

                                      </td>
                                  </tr>
                                }
                                  <tr>
                                    <td><span className="sp-normal">Total Before Tax</span></td>
                                    <td className="text-right"><span className="rs-sym">₹</span><span className="sp-normal all-out" id="total-before-tax">{(this.state.billing_data && this.state.billing_data.total_before_tax)?this.currency_format(this.state.billing_data.total_before_tax):0}</span></td>
                                  </tr>
                                  {(this.state.billing_data && this.state.billing_data.commission && this.state.billing_data.commission!=="") &&
                                      <tr>
                                        <td><span className="sp-normal">Staff Commission</span></td>
                                        <td className="text-right"><span className="rs-sym">₹</span>
                                          <span className="sp-normal all-out">{(this.state.billing_data && this.state.billing_data.commission)?this.state.billing_data.commission:0}</span>
                                          </td>
                                      </tr>
                                    }
                                  <tr>
                                    <td><span className="sp-normal">Tax</span></td>
                                    <td className="text-right"><span className="rs-sym">₹</span><span className="sp-normal all-out" id="total-after-tax">{(this.state.billing_data && this.state.billing_data.tax)?this.state.billing_data.tax:0}</span></td>
                                  </tr>
                                  <tr>
                                      <td><span className="sp-normal"  style={{fontWeight:'bold'}}>Total</span></td>
                                      <td className="text-right"><span style={{fontWeight:'bold'}} className="rs-sym">₹</span><span className="sp-normal all-out" style={{fontWeight:'bold'}}>{(this.state.billing_data && this.state.billing_data.calculate_total)?this.currency_format(this.state.billing_data.calculate_total):0}</span></td>
                                  </tr>
                                  {(this.state.billing_data.round_off!=="" && this.state.billing_data.round_off!=="0" && this.state.billing_data.round_off!==0) &&
                                    <tr>
                                        <td><span className="sp-normal">Round Off</span></td>
                                        <td className="text-right"><span className="sp-normal">{(this.state.billing_data && this.state.billing_data.round_off)?this.state.round_off_sym+''+this.state.billing_data.round_off:''}</span></td>
                                    </tr>
                                  }
                                  {(is_cd_note && this.state.billing_data && this.state.billing_data.payment_note_number!=="" && this.state.billing_data.payment_note_number!=="0" && this.state.billing_data.payment_note_number!==0) &&
                                  <tr>
                                    <td>
                                      <span className="sp-normal">{cd_type} Note (if any)</span>
                                    </td>
                                    <td className="text-right">
                                      <span>{(this.state.billing_data && this.state.billing_data.payment_note_number)?this.state.billing_data.payment_note_number:'-'}</span>
                                    </td>
                                  </tr>
                                  }

                                  {(this.state.is_reedeem && is_cd_note)&&
                                    <>
                                    <tr>
                                        <td><span className="sp-normal">Total Before {cd_type} Note Redeem</span></td>
                                        <td className="text-right"><span className="rs-sym">₹</span><span className="sp-normal all-out" id="before-redeem-total">{(this.state.billing_data && this.state.billing_data.before_payment_note_redeem_total)?this.currency_format(this.state.billing_data.before_payment_note_redeem_total):''}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><span className="sp-normal">Redeem Amount</span></td>
                                        <td className="text-right"><span className="rs-sym">₹</span><span className="sp-normal all-out" id="redeem-total">{(this.state.billing_data && this.state.billing_data.payment_note_redeem_total)?this.currency_format(this.state.billing_data.payment_note_redeem_total):''}</span>
                                        </td>
                                    </tr>
                                    </>
                                  }

                                   {(this.state.return_more_rows.length>0) &&
                                      <>
                                      <tr>
                                          <td><span className="sp-normal">Total Before Return</span></td>
                                          <td className="text-right"><span className="rs-sym">₹</span><span className="sp-normal all-out" id="total-before-return">{(this.state.billing_data && this.state.billing_data.return_meta_data && this.state.billing_data.return_meta_data.total_before_return)?this.currency_format(this.state.billing_data.return_meta_data.total_before_return):0}</span>
                                          </td>
                                      </tr>
                                      <tr style={{borderBottom:'1px solid #d7d7d7',background:'rgb(252 252 255)'}}>
                                        <th colSpan="2">Return Info</th>
                                      </tr>
                                      <tr style={{background:'rgb(252 252 255)'}}>
                                        <td><span className="sp-normal">Total Units</span></td>
                                        <td className="text-right">
                                            <span className="sp-normal all-out" id="return-total-unit">{(this.state.billing_data && this.state.billing_data.return_meta_data && this.state.billing_data.return_meta_data.total_unit)?this.state.billing_data.return_meta_data.total_unit:0}</span>
                                        </td>
                                      </tr>
                                      <tr style={{background:'rgb(252 252 255)'}}>
                                        <td><span className="sp-normal">Total Quantity</span></td>
                                        <td className="text-right">
                                            <span className="sp-normal all-out" id="return-total-quantity">{(this.state.billing_data && this.state.billing_data.return_meta_data && this.state.billing_data.return_meta_data.total_quantity)?this.state.billing_data.return_meta_data.total_quantity:0}</span>
                                        </td>
                                      </tr>
                                      <tr style={{background:'rgb(252 252 255)'}}>
                                        <td><span className="sp-normal">Subtotal</span></td>
                                        <td className="text-right">
                                            <span className="rs-sym">₹</span><span className="sp-normal all-out" id="return-subtotal">{(this.state.billing_data && this.state.billing_data.return_meta_data && this.state.billing_data.return_meta_data.subtotal)?this.state.billing_data.return_meta_data.subtotal:0}</span>
                                        </td>
                                      </tr>
                                      <tr style={{background:'rgb(252 252 255)'}}>
                                        <td><span className="sp-normal">Total</span></td>
                                        <td className="text-right">
                                            <span className="rs-sym">₹</span><span className="sp-normal all-out" id="return-total">{(this.state.billing_data && this.state.billing_data.return_meta_data && this.state.billing_data.return_meta_data.total)?this.currency_format(this.state.billing_data.return_meta_data.total):0}</span>
                                            
                                        </td>
                                      </tr>
                                      </>
                                    }
                                  <tr>
                                      <td><span className="sp-normal"  style={{fontWeight:'bold'}}>Grand Total</span></td>
                                      <td className="text-right"><span style={{fontWeight:'bold'}} className="rs-sym">₹</span><span className="sp-normal all-out" id="final-total"  style={{fontWeight:'bold'}}>{(this.state.billing_data && this.state.billing_data.total)?this.state.billing_data.total:0}</span></td>
                                  </tr>
                              </tbody>
                            </table>
                          </div>
                       </div>
                     </div>
                  </div>
                </div>
                 {(this.state.payment_info && this.state.payment_info.length > 0) &&
                    <>
                <div className="container-fluid no-print-block">
                  <div className="content-area">
                     <form id="contact-info-form">
                     <div className="content-head border-bottom payment-head">
                       <div className="row mrbtm-12">
                        <div className="col-md-4 mrtop-5">
                            <label className="bil_no">
                              Payments/Return Goods
                            </label>
                        </div>
                       </div>
                     </div>
                   
                     </form>  
                     
                  </div>
                 
                       <div className="row">
                          <div className="col-md-12">
                            <table className="table">
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col">ID</th>
                                  <th scope="col">DATE</th>
                                  <th scope="col">PAID</th>
                                  <th scope="col">BALANCE</th>
                                  <th scope="col">MODE</th>
                                  
                                </tr>
                              </thead>
                              <tbody id="items-body">
                                {(this.state.payment_info.length > 0) ?this.state.payment_info.map( (value, index) => {
                                 total_payment_paid = total_payment_paid+parseFloat(value.paid_amount.replace(/,/g , ""))

                                 total_payment_balance = parseFloat(value.pending_amount.replace(/,/g , ""))-total_payment_balance;

                                 return (
                                   <tr className={(value.status===1 || value.status===4)?"contact-row border-bottom":"contact-row border-bottom strikeout"} key={index} id={"item-row-"+index} data-id={index}>
                                    <td className="item-name">
                                      {(value.payment_number) &&

                                      <DrawerLink target="payment" url={"/order/view-payment/"+value.order_payment_id} schema={value.schema} name={value.payment_number} />

                                      }

                                      {(value.return_goods) &&
                                        <DrawerLink  target="billing" url={"/view-order/"+value.return_type+"/"+value.return_id} schema={value.schema} name={value.return_goods} />
                                      }
                                      
                                    </td>
                                    <td>
                                       {value.created_on}
                                    </td> 
                                    <td>
                                       ₹{this.currency_format(value.paid_amount.replace(/,/g , ""))}
                                    </td>
                                    <td>
                                      ₹{(value.pending_amount)?this.currency_format(value.pending_amount.replace(/,/g , "")):'-'}
                                    </td> 
                                    <td>
                                      {(value.payment_mode)?value.payment_mode:'-'}
                                    </td> 
                                    
                                  </tr>
                                  )
                                  }) : <tr><td colSpan="4" className="text-center"></td></tr> }
                              </tbody>
                              <tfoot>
                                  <tr>
                                      <th></th>
                                      <th></th>
                                      <th>₹{this.currency_format(total_payment_paid)}</th>
                                      <th>
                                        ₹{(this.state.billing_data && this.state.billing_data.total)?this.currency_format(
                                          parseFloat(this.state.billing_data.total.replace(/,/g , ""))-total_payment_paid):0}
                                      </th>
                                      <th></th>
                                  </tr>
                              </tfoot>
                            </table>
                          </div>
                       </div>
                  
                </div>
                    </>
                }
                
                  <div className="content-head print-block" style={{paddingLeft:'2px',marginTop:'10px'}}>
                    <div className="row">
                      <div className="col-md-12 terms-and-conditions">
                      {(this.state.print_settings && this.state.print_settings.indexOf("terms-and-conditions")<0) &&
                      <>
                      {(this.state.terms_and_conditions.length > 0) &&
                          <div>
                              <h4 style={{color:'gray',fontSize:'12px'}}>TERMS & CONDITIONS</h4>
                              <ol style={{color:'#909090',marginLeft:'-15px'}}>
                                {(this.state.terms_and_conditions.length > 0) ?this.state.terms_and_conditions.map( (value, index) => {
                                 return (
                                   <li style={{fontSize:'12px'}} key={"tandi-"+index}>
                                     {value}
                                   </li>
                                  )
                                  }) : ''}
                              </ol>
                          </div>
                      }
                      </>
                    }
                    
                    </div>
                  </div>
                </div>
              </div>
              </div>

              {(this.state.billing_type==='New Purchase')&&
                <div id="barcode-print-preview" className="modal fade"  style={{overflowY:'scroll'}}>
                  <div className="modal-dialog modal-bpp" style={{textAlign:'center'}} >
                    <div className="modal-content">
                     <div className="modal-header bg-none">
                        <div className="row">
                          <div className="col-md-4 mrtop-5">
                            <h4 style={{color:'black',textTransform:'uppercase'}}>
                            {(this.state.barcode_template_data && this.state.barcode_template_data[0] && this.state.barcode_template_data[0].name) &&
                              this.state.barcode_template_data[0].name
                            }
                            </h4>
                          </div>
                       
                          <div className="col-md-8">
                            <button type="button" onClick={this.handleBarcodePrint} className="btn btn-success pull-right mrleft-5">Print</button>
                            <button type="button" onClick={this.closeBarcodeModel} className="btn btn-secondary pull-right">Cancel</button>
                          </div>
                        </div>
                      </div>
                   
                      <div className="modal-body" style={{padding:'15px',background:'#dedede'}} id="main-barcode-container" >
                          {this.barcode_template}
                      </div>
                    </div>
                  </div>
                </div>  
              }

              <ModalBox id="delete_confirm" target={this.state.target} title="Delete"  data_id="Delete" delete_message={'Do you really want to delete this bill?'}/>
              
              <ModalBox id="generate_e_invoice_popup" title="Generate e-Invoice" invoice_id = {this.state.update_id} target={this.state.target} />

              {(this.state.referer>0) &&
                <PrintBill type={this.state.referer} id={this.state.response_id} target={this.state.print_target} bill_view_response={this.state.bill_view_response} module="orders"/>
              }
            </div>
        );
    }
}