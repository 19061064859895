import React, { Component } from "react";
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Leftnav from "./leftnav";
import '../dasboard.css';
import ServiceLoader from './service-loader.js';
import axios from 'axios';
import { Doughnut } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {faCalendarAlt,faSpinner,faCog,faRedo,faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import utils from '../utils.js';
let {enc_key,app_api,user_info} = require('../library.js');
export default class ViewContact extends Component {
   constructor() {
      super();
      this.state = {
        enable_orders:false,
        is_item_commission:false,
        dashboard_widgets1 : [],
        sales_summary_widget : {sales:'Sales',cogs:'COGS',expenses:'Expenses',commission:'Commission',profit:'Profit'},
        sales_order_summary_widget : {sales_order:'Sales Order',sales_order_cogs:'COGS',sales_order_profit:'Profit'},
        sales_summary_dropdown : {sales_summary:true},
        active_dashboard_widgets1 : {total_sale_bills:false,total_sale:false,total_sale_orders:false,sale_order_return:false,avg_bill_value:false,avg_units_per_bill:false,total_payment_in:false,expenses:false,payment_out:false,sales_return:false,payment_mode_graph:false,payment_mode_data:false},
        FromDate1:  new Date(),
        ToDate1: new Date(),
        summary_data : [],
        data_block1 : [],
        is_setting_visible : '',
        show_loader : true,
      };
      
      this.handleCalendarClick = this.handleCalendarClick.bind(this);
      this.handleShowReport = this.handleShowReport.bind(this);
      this.handleLoadData = this.handleLoadData.bind(this);
      this.handleToggleSettingDropdown = this.handleToggleSettingDropdown.bind(this);
      this.handleCloseSettingDropdown = this.handleCloseSettingDropdown.bind(this);
      this.handleToggleWidgets = this.handleToggleWidgets.bind(this);
      this.handleRefreshWidget = this.handleRefreshWidget.bind(this);
      this.currency_format = utils.currency_format.bind(this);
    }
    handleRefreshWidget(e){
        let key = e.currentTarget.id;
        let type = e.currentTarget.getAttribute('data-id');
        let from_date = this.state.FromDate1;
        let to_date = this.state.ToDate1;
        if(type==='block2'){
            from_date = this.state.FromDate2;
            to_date = this.state.ToDate2;
        }
        let body = {};
        let api = app_api+'/dashboard';
        if(type==='block1'){
        let data_block1 = this.state.data_block1;
        data_block1[key] = '';
        this.setState({data_block1:data_block1});
        let is_active = this.state.active_dashboard_widgets1[key];
        body = {
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          user_id : user_info.user_id,
          type : key,
          is_active : is_active,
          from_date : from_date,
          to_date : to_date,
          widget : type,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
        }
        axios.post(api, body)
        .then(function (response) {
          if(response.data.payments){
            let payments = response.data;
            data_block1[key] = payments;
          }else{
             let return_data = response.data.return_data;
             data_block1[key] = return_data;
           }
           this.setState({data_block1:data_block1});
        }.bind(this)).catch(function(res){
        
        })
      }else if(type==='sales_summary'){
        this.setState({summary_data:[]});
        let sales_summary = {};
        let sales_profit = null;
        Object.keys(this.state.sales_summary_widget).forEach((key, index) => {
            let is_active = this.state.sales_summary_dropdown.sales_summary;
            
            body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              type : key,
              is_active : is_active,
              from_date : from_date,
              to_date : to_date,
              widget : type,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
            }
            axios.post(api, body)
            .then(function (response) {
               let return_data = response.data.return_data;
               sales_summary[key] = return_data;
               this.setState({summary_data:sales_summary});
            }.bind(this)).catch(function(res){
            
            })
          
        });
        Object.keys(this.state.sales_order_summary_widget).forEach((key, index) => {
            let is_active = this.state.sales_summary_dropdown.sales_summary;
            body = {
              store_name : user_info.store_name,
              branch_id : user_info.login_id,
              user_id : user_info.user_id,
              type : key,
              is_active : is_active,
              from_date : from_date,
              to_date : to_date,
              widget : type,
              uuid : utils.uuid(),
              role_id : user_info.access_level,
              token : user_info.token,
              _schema : user_info._schema,
            }
            axios.post(api, body)
            .then(function (response) {
               let return_data = response.data.return_data;
               sales_summary[key] = return_data;
               this.setState({summary_data:sales_summary});
            }.bind(this)).catch(function(res){
            
            })
        });
      }
    }
    handleToggleSettingDropdown(){
        let is_setting_visible = this.state.is_setting_visible;
        if(is_setting_visible==='show'){
            this.setState({is_setting_visible:''});
        }else{
            this.setState({is_setting_visible:'show'});
        }
    }
    
    handleToggleWidgets(e){
        let block = e.currentTarget.getAttribute('data-id');
        let is_cb_checked = e.currentTarget.checked;
        let widget_name = e.currentTarget.value;
        if(block==='block1'){
            let active_dashboard_widgets1 = this.state.active_dashboard_widgets1;
            active_dashboard_widgets1[widget_name] = is_cb_checked;
            this.setState({active_dashboard_widgets1:active_dashboard_widgets1});
        }else if(block==='sales_summary'){
            let sales_summary_dropdown = this.state.sales_summary_dropdown;
            sales_summary_dropdown[widget_name] = is_cb_checked;
            this.setState({sales_summary_dropdown:sales_summary_dropdown});
        }

        let body = {};
        let api = app_api+'/change_widget_visible_status';
        body = {
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          user_id : user_info.user_id,
          widget_name : widget_name,
          is_active : is_cb_checked,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
        }
        axios.post(api, body)
        .then(function (response) {

        }.bind(this)).catch(function(res){
        
        })
    }
    changeFromDate1 = date => {
        this.setState({
          FromDate1: date
        });
    };
    changeToDate1 = date => {
        this.setState({
          ToDate1: date
        });
    };
    handleCalendarClick(e){
      let id = e.currentTarget.id;
      document.getElementById(id+'-date').focus();
    }
    handleShowReport(e){
      let type = e.currentTarget.getAttribute("data-id");
      if(type==='block1'){
        this.handleLoadData('sales_summary');
      }
      this.handleLoadData(type);
    }

    handleLoadData(type){
        
        let from_date = this.state.FromDate1;
        let to_date = this.state.ToDate1;
        if(type==='block2'){
            from_date = this.state.FromDate2;
            to_date = this.state.ToDate2;
        }
        let body = {};
        let api = app_api+'/dashboard';
        if(type==='block1'){
            this.setState({data_block1:[]});
            let data_block1 = {};
            Object.keys(this.state.dashboard_widgets1).forEach((key, index) => {
                let is_active = this.state.active_dashboard_widgets1[key];
                body = {
                  store_name : user_info.store_name,
                  branch_id : user_info.login_id,
                  user_id : user_info.user_id,
                  type : key,
                  is_active : is_active,
                  from_date : from_date,
                  to_date : to_date,
                  widget : type,
                  uuid : utils.uuid(),
                role_id : user_info.access_level,
                token : user_info.token,
                _schema : user_info._schema,
                }
                axios.post(api, body)
                .then(function (response) {
                  if(response.data.payments){
                    let payments = response.data;
                    data_block1[key] = payments;
                  }else{
                     let return_data = response.data.return_data;
                     data_block1[key] = return_data;
                   }
                   this.setState({data_block1:data_block1});

                }.bind(this)).catch(function(res){
                
                })
            });
        }else if(type==='sales_summary'){
            this.setState({summary_data:[]});
            let sales_summary = {};
            let sales_profit = null;
            Object.keys(this.state.sales_summary_widget).forEach((key, index) => {
                let is_active = this.state.sales_summary_dropdown.sales_summary;
                body = {
                  store_name : user_info.store_name,
                  branch_id : user_info.login_id,
                  user_id : user_info.user_id,
                  type : key,
                  is_active : is_active,
                  from_date : from_date,
                  to_date : to_date,
                  widget : type,
                  uuid : utils.uuid(),
                  role_id : user_info.access_level,
                  token : user_info.token,
                  _schema : user_info._schema,
                }
                axios.post(api, body)
                .then(function (response) {
                   let return_data = response.data.return_data;
                   sales_summary[key] = return_data;
                   this.setState({summary_data:sales_summary});
                }.bind(this)).catch(function(res){
                
                })
            });
            Object.keys(this.state.sales_order_summary_widget).forEach((key, index) => {
                let is_active = this.state.sales_summary_dropdown.sales_summary;
                body = {
                  store_name : user_info.store_name,
                  branch_id : user_info.login_id,
                  user_id : user_info.user_id,
                  type : key,
                  is_active : is_active,
                  from_date : from_date,
                  to_date : to_date,
                  widget : type,
                  uuid : utils.uuid(),
                  role_id : user_info.access_level,
                  token : user_info.token,
                  _schema : user_info._schema,
                }
                axios.post(api, body)
                .then(function (response) {
                   let return_data = response.data.return_data;
                   sales_summary[key] = return_data;
                   this.setState({summary_data:sales_summary});
                }.bind(this)).catch(function(res){
                
                })
            });

        }



    }
    handleCloseSettingDropdown(e){
        //this.setState({is_setting_visible:''});
    }

    handleIsEnabledOrder = () =>{
      let keys = "'enable_orders'";
        let set_data = {
            store_name:user_info.store_name,
            branch_id:user_info.login_id,
            user_id : user_info.user_id,
            uuid : utils.uuid(),
            role_id : user_info.access_level,
            token : user_info.token, 
            _schema : user_info._schema,
            keys:keys
        }
        let opt_api = app_api+'/get_settings';
        axios.post(opt_api, set_data)
          .then(function (response) {
            let resp = response.data;
           
            if(resp.enable_orders!==undefined){
              let ps = resp.enable_orders;
              this.setState({enable_orders:ps});
              if(ps===false){
                delete utils.dashboard_widgets1['total_sale_orders']; 
                delete utils.dashboard_widgets1['sale_order_return']; 
                this.setState({dashboard_widgets1:utils.dashboard_widgets1});
              }
            }
           
        }.bind(this)).catch(function(res){
            
        })
    }

    componentDidMount(){
      let keys = "'is_item_commission'";
      let set_data = {
          store_name:user_info.store_name,
          branch_id:user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token, 
          _schema : user_info._schema,
          keys:keys
      }
      let opt_api = app_api+'/get_settings';
      axios.post(opt_api, set_data)
        .then(function (response) {
          let resp = response.data;
          if(resp.is_item_commission===true){
            let ps = resp.is_item_commission;
            this.setState({is_item_commission:ps});
          }else{
            let sum_data = this.state.sales_summary_widget;
            delete sum_data['commission'];
            this.setState({sales_summary_widget:sum_data})
          }
      }.bind(this)).catch(function(res){
          
      })

        this.setState({dashboard_widgets1:utils.dashboard_widgets1});
        let _this = this;
        setTimeout(function(){
            _this.handleLoadData('block1');
            _this.handleLoadData('sales_summary');
        },100);

        let body = {};
        let api = app_api+'/get_widget_option';
        body = {
          store_name : user_info.store_name,
          branch_id : user_info.login_id,
          user_id : user_info.user_id,
          uuid : utils.uuid(),
          role_id : user_info.access_level,
          token : user_info.token,
          _schema : user_info._schema,
        }
        axios.post(api, body)
        .then(function (response) {
            let resp = response.data;
            let sales_summary_dropdown = this.state.sales_summary_dropdown;

            if(resp.sales_summary!==undefined){
              let sales_summary = resp.sales_summary;
              sales_summary_dropdown.sales_summary = sales_summary;
              this.setState({sales_summary_dropdown:sales_summary_dropdown});
              //delete resp['sales_summary'];
            }

            this.setState({active_dashboard_widgets1:resp,show_loader:false});
            if(resp.length!==0){
                let qs = document.querySelectorAll(".widget-cb");
                for(let i=0;i<qs.length;i++){
                    let thisval = qs[i].value;
                    if(resp[thisval]!==undefined){
                        qs[i].checked = resp[thisval];
                    }else{
                        qs[i].checked = true;
                    }
                }
            }else{
               let qs = document.querySelectorAll(".widget-cb");
                for(let i=0;i<qs.length;i++){
                    let thisval = qs[i].value;
                    qs[i].checked = true
                } 
            }
        }.bind(this)).catch(function(res){
        
        })
        this.handleIsEnabledOrder();
    }

    render() {
        return (
            <div className="d-flex dashboard" id="wrapper">
               <Leftnav />
               <div id="page-content-wrapper" onClick={this.handleCloseSettingDropdown}>
                  <nav className="navbar border-bottom">
                     <div className="row">
                        <div className="col-md-8">
                           <div className="mod-title"><a href="/dashboard"> <span>Dashboard</span></a></div>
                        </div>
                        <div className="col-md-4">
                            <div className="dashboard-setting">
                                <div className="dropdown" >
                                {(this.state.is_setting_visible==='') &&
                                  <FontAwesomeIcon icon={faCog} onClick={this.handleToggleSettingDropdown}/>
                                }
                                {(this.state.is_setting_visible==='show') &&
                                    <FontAwesomeIcon icon={faTimesCircle} onClick={this.handleToggleSettingDropdown}/>
                                }
                                  <div className={"dropdown-menu "+this.state.is_setting_visible} id="setting-dd">
                                    <label className="dropdown-item-title">
                                        Hide/Show Widgets
                                    </label>
                                  {Object.keys(this.state.sales_summary_dropdown).map((key, rd) => (
                                  <label className="dropdown-item">
                                      <input type="checkbox" className="widget-cb" value={key} onClick={this.handleToggleWidgets} data-id="sales_summary" defaultChecked={(this.state.sales_summary_dropdown[key]===true || this.state.sales_summary_dropdown[key]===undefined)?true:false}/>&nbsp;
                                      Sales Summary
                                  </label>
                                  ))}
                                  {Object.keys(this.state.dashboard_widgets1).map((key, rd) => (
                                    <>
                                    
                                    <label className={((key==='total_sale_orders' || key==='sale_order_return') && this.state.enable_orders===false)?"dropdown-item hide":"dropdown-item"}>
                                        <input type="checkbox" className="widget-cb" value={key} onClick={this.handleToggleWidgets} data-id="block1" defaultChecked={(this.state.active_dashboard_widgets1[key]===true || this.state.active_dashboard_widgets1[key]===undefined)?true:false}/>&nbsp;
                                        {this.state.dashboard_widgets1[key]}
                                    </label>
                                    </>
                                    ))} 

                                  </div>
                                </div>
                            </div>  
                        </div>
                     </div>
                  </nav>
                  <div className="container-fluid">
                     
                     <div className="content-area">
                        <div className="content-head border-bottom">
                           <div className="row mrbtm-12">
                              <div className="col-md-6">
                                 <label className="bil_no" style={{marginBottom:'0px'}}>Overview</label>
                              </div>
                              <div className="col-md-6">
                                 <div className="row">
                                 <div className="col-md-5 mb-mrbtm-10">
                                      <div className="input-group">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text no-border-right" onClick={this.handleCalendarClick} id="date1-from">
                                            <FontAwesomeIcon icon={faCalendarAlt} />
                                          </span>
                                          <DatePicker className="form-control  no-border-left" selected={this.state.FromDate1} onChange={this.changeFromDate1} dateFormat="dd/MM/yyyy" id="date1-from-date"/>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-5 mb-mrbtm-10">
                                      <div className="input-group">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text  no-border-right" onClick={this.handleCalendarClick}  id="date1-to"><FontAwesomeIcon icon={faCalendarAlt} /></span>
                                          <DatePicker className="form-control  no-border-left" selected={this.state.ToDate1} onChange={this.changeToDate1} dateFormat="dd/MM/yyyy" id="date1-to-date"/>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-2 text-right">
                                        <button type="button" className="btn btn-success" onClick={this.handleShowReport} data-id="block1" id="view-report" data-target={this.state.active_tab}>VIEW</button>
                                        <button type="button" id="view-report-btn-spinner" className="hide btn btn-success"><FontAwesomeIcon icon={faSpinner} pulse/></button>
                                      </div>
                                </div>
                              </div>
                           </div>
                        </div>
                        <div className="main-content pad-30">
                        {(this.state.sales_summary_dropdown.sales_summary===true)&&
                           <div className="row sales-summary">
                            
                            {(this.state.show_loader===false) &&
                              Object.keys(this.state.sales_summary_widget).map((key, rd) => (
                              <>
                                  <div className="col-md-2" style={{padding:'0px'}}>
                                    <div className={"db_sm_widget "+key}>
                                      <p className="wgt-title" style={{margin:(this.state.sales_summary_widget[key].toLowerCase()==='sales' && this.state.summary_data[key] && this.state.summary_data[key]!=='' && this.state.summary_data[key].total_return_amount && this.state.summary_data[key].total_return_amount>0)?'0px':''}}>{this.state.sales_summary_widget[key]}</p>
                                      {(this.state.summary_data[key]!==undefined && this.state.summary_data[key]!=='')?
                                            <>
                                            {(this.state.summary_data[key].total_value!==undefined && this.state.summary_data[key].total_return_amount>0) &&
                                            <p style={{margin:'0px'}}>
                                              <span style={{float:'left',textTransform:'capitalize',fontSize:'13px'}}><span style={{color:'rgb(94, 94, 94)'}}>Total</span>:<span>{(this.state.summary_data[key].is_currency_sym===true)?'₹':''}{this.state.summary_data[key].total_value}</span></span>
                                              
                                              <span style={{float:'right',textTransform:'capitalize',fontSize:'13px'}}><span style={{color:'rgb(94, 94, 94)'}}>Return</span>:<span>{(this.state.summary_data[key].is_currency_sym===true)?'₹':''}{this.state.summary_data[key].total_return_amount}</span></span>
                                            </p>
                                            }
                                             <h5 className={(this.state.summary_data[key].total<0)?"ssblock negative":'ssblock'}>
                                            <>
                                              {(this.state.summary_data[key].is_active===false) && 
                                                   <FontAwesomeIcon icon={faRedo} onClick={this.handleRefreshWidget} id={key} data-id="sales_summary"/>
                                                }
                                             
                                              {(this.state.summary_data[key].is_currency_sym===true)?'₹':''}
                                            </>
                                              {(this.state.summary_data[key].total!==false) && 
                                              <>
                                              {this.currency_format(this.state.summary_data[key].total)}
                                              </>
                                              }
                                            </h5>
                                            {(this.state.summary_data[key].total_stock_transfer!==undefined && this.state.summary_data[key].total_stock_transfer>0) &&
                                              <p style={{margin:'0px'}}>
                                                <span style={{float:'left',textTransform:'capitalize',fontSize:'13px'}}><span style={{color:'rgb(94, 94, 94)'}}>Stock Transfer</span>:<span>{(this.state.summary_data[key].is_currency_sym===true)?'₹':''}{this.state.summary_data[key].total_stock_transfer}</span></span>
                                              </p>
                                            }
                                            </>
                                        :
                                            <div className="spinner-border text-danger" role="status">
                                              <span className="sr-only">Loading...</span>
                                            </div>  
                                        }

                                    </div>
                                  </div>
                                  {(key!=='profit') &&
                                    <div className="col-md-1-2 text-center">
                                    <p className="sales-sum-sym"></p>
                                    </div>
                                  }
                              </>
                              ))
                            }
                              
                            
                           </div>
                         }
                         {(this.state.sales_summary_dropdown.sales_summary===true && this.state.enable_orders===true)&&
                           <div className="row sales-summary">
                            <div className="col-md-2"></div>
                            {(this.state.show_loader===false) &&
                              Object.keys(this.state.sales_order_summary_widget).map((key, rd) => (
                              <>
                                  <div className="col-md-2" style={{padding:'0px'}}>
                                    <div className={"db_sm_widget "+key}>
                                      <p className="wgt-title">{this.state.sales_order_summary_widget[key]}</p>


                                      {(this.state.summary_data[key]!==undefined && this.state.summary_data[key]!=='')?
                                            <>
                                             <h5 className={(this.state.summary_data[key].total<0)?"ssblock negative":'ssblock'}>
                                            <>
                                              {(this.state.summary_data[key].is_active===false) && 
                                                   <FontAwesomeIcon icon={faRedo} onClick={this.handleRefreshWidget} id={key} data-id="sales_summary"/>
                                                }
                                             
                                              {(this.state.summary_data[key].is_currency_sym===true)?'₹':''}
                                            </>
                                              {(this.state.summary_data[key].total!==false) && 
                                                this.currency_format(this.state.summary_data[key].total)
                                              }
                                            </h5>
                                            {(this.state.summary_data[key].total_value!==undefined && this.state.summary_data[key].total_return_amount>0) &&
                                            <>
                                              <span style={{float:'left',textTransform:'capitalize',fontSize:'13px'}}><span style={{color:'rgb(94, 94, 94)'}}>Total</span>:<span>{(this.state.summary_data[key].is_currency_sym===true)?'₹':''}{this.state.summary_data[key].total_value}</span></span>
                                              
                                              <span style={{float:'right',textTransform:'capitalize',fontSize:'13px'}}><span style={{color:'rgb(94, 94, 94)'}}>Return</span>:<span>{(this.state.summary_data[key].is_currency_sym===true)?'₹':''}{this.state.summary_data[key].total_return_amount}</span></span>
                                            </>
                                            }
                                            </>
                                        :
                                            <div className="spinner-border text-danger" role="status">
                                              <span className="sr-only">Loading...</span>
                                            </div>  
                                        }

                                    </div>
                                  </div>
                                  {(key!=='sales_order_profit') &&
                                    <div className="col-md-1 text-center">
                                      <p className="sales-order-sum-sym"></p>
                                    </div>
                                  }
                              </>
                              ))
                            }
                            <div className="col-md-2"></div>
                            
                           </div>
                         }
                           <div className="row">
                            {(this.state.show_loader===false) &&
                                Object.keys(this.state.dashboard_widgets1).map((key, rd) => (
                            <>
                            {(this.state.active_dashboard_widgets1[key]===true || this.state.active_dashboard_widgets1[key]===undefined) &&
                                  <div className={(key!=='payment_mode_graph' && key!=='payment_mode_data' )?"col-md-3":"col-md-6"}>
                                    <div className={"ss db_sm_widget "+key}>
                                        <p className="wgt-title">{this.state.dashboard_widgets1[key]}</p>
                                        {(this.state.data_block1[key]!==undefined && this.state.data_block1[key]!=='')?
                                            <>
                                            {(key==='total_payment_in' || key==='payment_out') &&
                                            <>
                                              <h5>
                                            <>
                                             {/* <img class={key} src={(this.icons[key]!==undefined)?this.icons[key]:''}/>*/}
                                              {(this.state.data_block1[key].payments!==undefined && this.state.data_block1[key].payments.is_currency_sym===true)?'₹':''}
                                            </>
                                                {(this.state.data_block1[key].is_active===false) && 
                                                    <FontAwesomeIcon icon={faRedo} onClick={this.handleRefreshWidget} id={key} data-id="block1"/>
                                                }
                                              {(this.state.data_block1[key].payments!==undefined) && 
                                                    this.currency_format(this.state.data_block1[key].payments.total_payment)
                                              }
                                              </h5>
                                              {(this.state.data_block1[key].payments!==undefined) && 
                                                <>
                                              <label style={{float:'left'}}><span style={{textTransform:'none',color:'#5e5e5e'}}>New :</span> ₹{this.currency_format(this.state.data_block1[key].payments.new_payment)}</label>
                                              <label style={{float:'right'}}><span style={{textTransform:'none',color:'#5e5e5e'}}>Old :</span> ₹{this.currency_format(this.state.data_block1[key].payments.old_payment)}</label>
                                              </>
                                                }
                                            </>
                                            }
                                            {(key!=='total_payment_in' && key!=='payment_out' && key!=='payment_mode_graph' && key!=='payment_mode_data') &&
                                            <>
                                            <h5>
                                            <>
                                              {(this.state.data_block1[key].is_active===false) && 
                                                   <FontAwesomeIcon icon={faRedo} onClick={this.handleRefreshWidget} id={key} data-id="block1"/>
                                                }
                                              {/* <img class={key} src={(this.icons[key]!==undefined)?this.icons[key]:''}/>*/}
                                              {(this.state.data_block1[key].is_currency_sym===true)?'₹':''}
                                            </>
                                              {(this.state.data_block1[key].total!==false) && 
                                                this.currency_format(this.state.data_block1[key].total)
                                              }
                                            </h5>
                                            {(key==='total_sale') && 
                                              <>
                                                <label style={{float:'left'}}>
                                                  <span style={{textTransform:'none',color:'#5e5e5e'}}>Cash :</span> ₹{this.currency_format(this.state.data_block1[key].cash)}
                                                </label>
                                                <label style={{float:'right'}}>
                                                  <span style={{textTransform:'none',color:'#5e5e5e'}}>Credit :</span> ₹{this.currency_format(this.state.data_block1[key].credit)}
                                                </label>
                                              </>
                                            }

                                            {(key==='sales_return' && this.state.data_block1[key].per_day>0) &&
                                               <label style={{float:'right'}}><span style={{textTransform:'none',color:'#5e5e5e'}}>{this.state.data_block1[key].per_day}<sup>(Avg)</sup></span> </label> 
                                            }
                                            </>
                                            }
                                            {(key==='payment_mode_graph') &&
                                                <>
                                                {(this.state.data_block1[key].is_active===false) && 
                                                    <h5>
                                                        <FontAwesomeIcon icon={faRedo} onClick={this.handleRefreshWidget} id={key} data-id="block1"/>
                                                    </h5>
                                                }
                                                {(this.state.data_block1[key].graph_data!==undefined && this.state.data_block1[key].graph_data.data.labels.length>0) && 
                                                    <Doughnut data={this.state.data_block1[key].graph_data.data} />
                                                }
                                                {(this.state.data_block1[key].graph_data!==undefined && this.state.data_block1[key].graph_data.data.labels.length===0) && 
                                                    <p style={{fontSize:'12px'}}>
                                                        No data found to show in graph.
                                                    </p>
                                                }
                                                </>
                                            }
                                            {(key==='payment_mode_data') &&
                                            <>
                                                {(this.state.data_block1[key].data!==undefined && this.state.data_block1[key].data.length > 0) ? this.state.data_block1[key].data.map( (pmv, pmi) => {
                                                   return (
                                                    <div className="pmode-data">
                                                        <div className="pmode-data-name">{pmv.name}</div>
                                                        <div className="pmode-data-bar">
                                                            <div className="inner-data-bar" style={{backgroundColor:pmv.barColor,width:pmv.barPercent}}></div>
                                                        </div>
                                                        <div className="pmode-data-value">
                                                            {(this.state.data_block1[key].is_currency_sym===true)?'₹':''}
                                                            {this.currency_format(pmv.value)}</div>
                                                    </div>
                                                    )
                                                }) : ''}
                                                {(this.state.data_block1[key].data!==undefined && this.state.data_block1[key].data.length === 0) &&
                                                    <p style={{fontSize:'12px'}}>
                                                        No data found to show.
                                                    </p>
                                                }
                                                {(this.state.data_block1[key].is_active===false) && 
                                                    <h5><FontAwesomeIcon icon={faRedo} onClick={this.handleRefreshWidget} id={key} data-id="block1"/></h5>
                                                }
                                            </>
                                            }
                                           </>
                                        :
                                            <div className="spinner-border text-danger" role="status">
                                              <span className="sr-only">Loading...</span>
                                            </div>  
                                        }
                                    </div>
                                  </div>
                              }
                              </>
                            ))}
                            {(this.state.show_loader===true) &&
                                <div className="col-md-12">
                                    <h5 className="text-center">Loading Widgets...</h5>
                                </div>
                            }
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
        );
    }
}